import variables from '../../assets/style/variables'

export const style = {
  profile: {
    marginBottom: 30
  },

  left: {
    marginTop: '10px',
    marginBottom: '20px',
    borderRight: '1px solid ' + variables.black
  },

  menu: {
    fontWeight: 'bold',
    color: variables.grey,
    borderBottom: '1px solid ' + variables.black,
    height: '30px',
    marginRight: '20px',
    paddingTop: '10px',
    paddingBottom: '5px',
    cursor: 'pointer'
  },

  iconRight: {
    position: 'absolute',
    right: 0
  },

  logout: {
    marginTop: '50px',
    textAlign: 'center',
    '& a': {
      fontWeight: 'bold',
      textDecoration: 'none',
      color: variables.black
    }
  }

}
