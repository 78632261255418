import variables from '../../assets/style/variables'

export const style = {

  profileAddresses: {
    '& .mdc-text-field__input': {
      width: '100%',
      paddingTop: '20px !important'
    },

    '& button': {
      marginRight: 20,
      minWidth: 200
    }
  },

  profileAddressesTitle: {
    marginTop: 10,
    marginBottom: 30
  },

  profileAddressesFooter: {
    marginTop: 30,
    marginBottom: 30,
    'width':'300',
    'height':'44px'
  },

  name: {
    fontWeight: 'bold',
    marginRight: '5px'
  },

  add: {
    borderBottom: '1px solid ' + variables.black
  },

  update: {
    color: variables.white + ' !important',
    backgroundColor: variables.grey + ' !important'
  },

  delete: {
    color: variables.grey + ' !important',
    backgroundColor: variables.white + ' !important',
    border: '2px solid ' + variables.grey
  },

  '@media (max-width: 600px)' :{
    profileAddresses:{
      'margin':'7%'
    }
  }

}
