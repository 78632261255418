import React from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import { Typography } from '@rmwc/typography'
import { ButtonIcon } from '@rmwc/button'
import { Icon } from '@rmwc/icon'
import { addToCart, removeFromCart, changeCartItemQty, getGuestCartShippingMethods } from '../../actions'
import { priceFormat } from '../../lib/format'
import {FREE_SHIPPING_MIN_ORDER, routes} from '../../config/settings'
import { Elevation } from 'rmwc'
import KUButton from '../KUButton'
import { style } from './style'
import {Col, Row} from "react-flexbox-grid";
import Grid from "@material-ui/core/Grid";
import {productThumb} from "../../lib/product";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

class AddCartMobile extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      qtToAdd: 1,
      open:false
    }
  }

  handleClose = () => {
    this.setState({
      open: false
    })
  };

  handleCartClick () {
    const { cart, customer } = this.props
    this.props.addToCart({
      cartId: cart.cartId,
      item: {
        cartItem: {
          sku: this.props.product.sku,
          qty: this.state.qtToAdd,
          quoteId: cart.cartId
          // ...productOptions
        }
      },
      customer
    })
    setTimeout(() => (
        this.state.open = true
    ), 500)
  }

  handleChangeQtyForItem (product, operation) {
    this.props.changeCartItemQty({ cart: this.props.cart, item: product, operation: operation })
  }

  render () {
    const { classes } = this.props
    let _discountPrice = this.props.product.custom_attributes
      ? this.props.product.custom_attributes.find(f => f.attribute_code === 'special_price')
        ? this.props.product.custom_attributes.find(f => f.attribute_code === 'special_price').value
        : false
      : false


    return (
      <Grid style={{'display':'block'}}>
        <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            style={{'text-align':'center'}}
            fullWidth={false}
        >
          <div style={{'padding-right': '10px', "padding-top":"10px", "cursor":"pointer"}} onClick={this.handleClose}>
            <div>
              <div className={classes.mdiv1_closex}>
                <div className={classes.mdiv_closex}>
                  <div className={classes.md_closex}></div>
                </div>
              </div>
            </div>
          </div>
          <DialogTitle style={{"width":'231px', "padding":"0px 24px 24px"}}>Prodotto aggiunto al
            carrello</DialogTitle>
          <DialogContent style={{'width':'231px'}}>
            <div className={classes.productThumb}
                 onClick={() => this.handleProductClick(this.props.product)}
            >
              <img src={this.props.product.custom_attributes ?
                  (this.props.product.custom_attributes.find(e => e.attribute_code === 'image') ?
                      productThumb(this.props.product) : "../../../images/no-img.png") :
                  productThumb(this.props.product)}
                   style={{'max-width':'231px', 'max-height':'345'}}
              />
            </div>
            <Typography use='body3' className={classes.productName}
                        style={{'height': '15%', 'text-align':'center'}}
            >
              {this.props.product.name}
            </Typography>
            <br/>
            <Typography use='body3' className={classes.productPrice}>
              {_discountPrice
                  ? (_discountPrice >= this.props.product.price) ?
                      <span>{priceFormat(this.props.product.price)}</span> : <div>
                        <del style={{
                          'font-size': '13px',
                          'margin-right': '5px'
                        }}>{priceFormat(this.props.product.price)}</del>
                        <span className={classes.bold}
                              style={{'color': '#96bf31'}}>{priceFormat(_discountPrice)}</span>
                      </div>
                  : <span>{priceFormat(this.props.product.price)}</span>
              }
            </Typography><br/>
            <div style={{'display': 'flex', 'justify-content': 'center'}}>
              <a href={routes.cart.path} style={{'color': 'white', "width":"100%"}}>
                <Button
                    style={{
                      backgroundColor: '#008837',
                      'height': '44px',
                      'width': '90%',
                      'color': 'white'
                    }}
                >
                  Vai al carrello
                </Button>
              </a>
            </div>
            <div style={{"justify-content":"center", "margin-top":"10px"}}>
              oppure
              <br/>
              <Typography
                  onClick={this.handleClose}
                  className={classes.addButton}
                  style={{
                    'color': 'rgb(0, 136, 55)',
                    "text-decoration":"underline",
                    "font-weight":"bold",
                    "cursor":"pointer"
                  }}
              >Continua gli acquisti
              </Typography>
            </div>
          </DialogContent>
        </Dialog>
        { this.props.product.extension_attributes.stock_item.is_in_stock && this.props.product.extension_attributes.stock_item.qty > 0 && <Row>
            <Col xs={4} align='left'>
              <div className={classes.cartActionQuantity}
                   style={{'padding-left':'11%', 'padding-top':'10px', 'padding-right':'7%', 'display':'block'}}
              >
                <Icon icon='remove' iconOptions={{ strategy: 'ligature' }}
                      style={{'float':'left'}}
                      onClick={() => this.setState({qtToAdd: this.state.qtToAdd > 1 ? this.state.qtToAdd - 1 : 1})}
                />
                <span>{this.state.qtToAdd}</span>
                <Icon icon='add' iconOptions={{ strategy: 'ligature' }}
                      style={{'float':'right'}}
                      onClick={() => this.setState({
                        qtToAdd: this.state.qtToAdd + 1
                      })}
                />
              </div>
            </Col>
            <Col xs={8} align={'right'}>
              <KUButton className={classes.add} unelevated orange
                clickHandler={() => this.handleCartClick()}
                loading={this.props.ui.addToCartLoading === this.props.product.sku}
                        style={{'width':'100%', 'float':'left'}}
              >
                <ButtonIcon icon='shopping_cart' /> AGGIUNGI
              </KUButton>
            </Col>
          </Row> }
      </Grid>
    )
  }
}

const mapStateToProps = function (state) {
  return {
    products: state.cart.items,
    checkout: state.checkout,
    quote: state.cart.quote,
    cart: state.cart,
    customer: state.account.customer,
    ui: state.ui
  }
}

export default connect(
  mapStateToProps,
  { addToCart, removeFromCart, changeCartItemQty, getGuestCartShippingMethods }
)(injectSheet(style)(AddCartMobile))
