import variables from '../../assets/style/variables'

export const style = {
  productContainer: {
    border: '1px solid #7d7d7d',

    '& .mdc-button': {
      width: '100%',
      borderRadius: 0,
      backgroundColor: variables.black
    }
  },

  cartActionQuantity: {
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid ' + variables.black,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100px',
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 3,
    paddingBottom: 3
  },

  cartItemSeparator: {
    borderBottom: `1px solid ${variables.lightGrey}`,
    height: 1,
    width: '100%',
    margin: '20px 0'
  },

  productName: {
    minHeight: '3rem',
    display: 'block',
    marginBottom: '4px',
    padding: '6px',
    color: variables.grey
  },

  productPrice: {
    minHeight: '2rem',
    display: 'block',
    marginBottom: '4px',
    padding: '0 6px',
    color: variables.black
  },

  productThumb: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    overflow: 'hidden',

    '& img': {
      "max-width": '100px',
      'max-height':'100px',
      overflow: 'hidden'
    }
  },

  '@media (max-width: 600px)' :{
    productContainer: {
      border: '1px solid #7d7d7d',
      '& .mdc-button': {
        width: '100%',
        borderRadius: 0,
        backgroundColor: variables.black
      }
    },

    cartActionQuantity: {
      cursor: 'pointer',
      userSelect: 'none',
      border: '1px solid ' + variables.black,
      display: 'flex',
      justifyContent: 'space-between',
      width: '100px',
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 3,
      paddingBottom: 3,
      float: 'right',
      height: '22px',
    },

    cartItemSeparator: {
      borderBottom: `1px solid ${variables.lightGrey}`,
      height: 1,
      width: '100%',
      margin: '20px 0'
    },

    productName: {
      minHeight: '3rem',
      padding: '6px',
      color: variables.grey,
      float: 'right'
    },

    productPrice: {
      minHeight: '2rem',
      marginBottom: '4px',
      padding: '0 6px',
      color: variables.black,
      float: 'right'
    },

    productThumb: {
      justifyContent: 'left',
      alignItems: 'left',
      display: 'flex',
      overflow: 'hidden',
      float: 'left',
      'max-height':'15em',
      'max-width':'12rem',

      '& img': {
        width: '200px',
        maxHeight: '230px',
        overflow: 'hidden',
        float: 'left'
      }
    },


  }

}
