import variables from '../../assets/style/variables'

export const style = {
    productContainer: {
        border: '1px solid #7d7d7d',
        cursor: 'pointer',
        height: '103%',

        '& .mdc-button': {
            width: '100%',
            borderRadius: 0,
            backgroundColor: variables.black
        }
    },

    aggiungiButton: {
        fontWeight: '600'
    },

    addButton:{
        backgroundColor:'#008837',
        'width':'-webkit-fill-available'
    },

    bold:{
        fontWeight: 700
    },

    productName: {
        minHeight: '3rem',
        display: 'block',
        marginBottom: '4px',
        padding: '6px',
        color: '#707070',
        fontSize:'16px !important'
    },

    productPrice: {
        minHeight: '2rem',
        display: 'block',
        marginBottom: '4px',
        padding: '0 6px',
        color: variables.black
    },

    productThumb: {
        minHeight: '12rem',
        maxHeight: '15rem',
        justifyContent: 'center',
        alignItems: 'center',
        height: '15rem',
        display: 'flex',
        overflow: 'hidden',

        '& img': {
            width: '100%',
            maxHeight: '100%'
        }
    }

}
