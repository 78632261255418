// import variables from '../../assets/style/variables'

export const style = {
  signIn: {
    '& .mdc-dialog__container': {
      width: '90%'
      //maxWidth: 'inherit'
    }
  }
}
