import variables from '../../assets/style/variables'

export const style = {
  megaMenuContainer: {
    width: '100%',
    minHeight: 60,
    backgroundColor: variables.blue,
    color: variables.white,
    textAlign: 'center'
  },

  megaMenu: {
    maxWidth: '100%',
    textAlign: 'left',
    fontSize: 10,
    padding: '10px 14px',
    display: 'inline-flex',
    'flex-direction': 'row',
    'justify-content': 'space-around',
    '& a': { 'text-decoration': 'none' },
    '& h4': {
      cursor: 'pointer',
      'text-decoration': 'none',
      fontWeight: 400,
      color: variables.white,
      margin: '0 0 0 10px',
      padding: '10px 0'
    },
    '& .mdc-menu-surface': {
      // position: 'fixed'
    },
    '& .mdc-menu-surface.mdc-menu-surface--open': {
    },

    '& > div:not(:last-child)': {
      borderRight: `1px solid ${variables.whiteTone}`
    },

    '@media(max-width: 1460px)': {
      '& :last-child > .subMenu > div:first-of-type': {
        right: 0
      }
    }
  },

  sep: {
    '& :not(:last-child)': {
      width: 2,
      borderRight: `1px solid ${variables.whiteTone}`
    }
  },
  megaMenuItem: {
    '& a': {
      display: 'block',
      marginLeft: '4px',
      marginRight: '20px'
    },
    'flex-basis': 'auto',
    // maxWidth: 180,
    // width: 'fit-content',
    position: 'relative'
  },

  icon: {
    marginRight: '0px',
    top: '20%',
    right: "2px",
    position: 'absolute'
  },
  subMenu: {
    overflow: 'hidden'
  },
  menuSurfaceHack: {
    top: 'auto !important'
  },
  subMenuUl: {
    '& li': {
      listStyle: 'none',
      marginBottom: '10px',
      fontSize: '16px',
      fontWeight: 'normal',
      cursor: 'pointer'
    },
    margin: 0,
    padding: '6px 0 0 20px'
  }
}
