import React from 'react'
import TextField from "@material-ui/core/TextField";
import KUButton from "../../components/KUButton";
import {GUEST_TYPE, ADMIN_TYPE, CUSTOMER_TYPE} from "../../lib/magento/types";
import {magento} from '../../lib/magento'
import Loader from "react-loader-spinner";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Row} from "react-flexbox-grid";
import history from '../../lib/helper/history'
import Dialog from "@material-ui/core/Dialog";



class indexResetPassword extends React.Component {

    constructor(){
        super()
        this.state = {
            email: '',
            open:false,
            spin: false,
            isDesktop: false
        }
        this.updatePredicate = this.updatePredicate.bind(this);
    }

     async handleSubmit(){
        this.setState({
            spin:true
        })
        const response = await magento.guest.resetPassword(this.state.email)
        if(response.message) {
            console.log(response.message)
        } else {
            console.log('open')
            this.state.open = true
            this.state.spin=false
            this.forceUpdate()
        }
    }


    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    updatePredicate() {
        this.setState({ isDesktop: window.innerWidth > 620 });
    }

    componentWillMount () {
        window.removeEventListener("resize", this.updatePredicate);
    }

    render() {
        let isDesktop = this.state.isDesktop
            return(
                <Grid fluid
                      style={{'padding-left':'12%', 'padding-right':'12%', 'margin-top':'1%', 'margin-bottom':'2%'}}
                >
                    <Row>
                        <Typography use="headline5" style={{'font-size':'1.875rem'}}>Password dimenticata</Typography>
                    </Row>
                    <br/>
                    <Row>
                        <Typography use="headline8">Inserisci la tua email, ti invieremo un link per il reset della password</Typography>
                    </Row>
                    <br/>
                    <Row>
                        <div
                            style={{'height':!isDesktop?'36px':false, 'border':!isDesktop?'1px solid':false, 'width':!isDesktop?'536px':false}}
                        >
                        <input type={'text'}
                            id={"email"}
                            placeholder={'Email'}
                            value={this.state.email}
                            style={{'width':isDesktop?'370px':'100%','min-height':'29px', 'height':'30px','margin-bottom':isDesktop? false : '10px'}}
                            onChange={event => this.setState({ email: event.target.value })}
                        />
                        {true && this.state.success}
                            <KUButton
                            unelevated orange
                            style={{'min-height':'29px', 'height':'36px', 'width':'162px', 'margin-bottom':'2px'}}
                            clickHandler={() => this.handleSubmit()}
                            disabled={this.state.email? false : true}
                        > Invia email
                            </KUButton>
                        </div>
                    </Row>

                    <br/>

                    <Row>
                        {this.state.spin && <Loader
                            type="TailSpin"
                            color="#000"
                            height={50}
                            width={100}

                        />}
                        {this.state.open && "Email inviata con successo"}
                    </Row>
                </Grid>
            )
    }

}

export default indexResetPassword