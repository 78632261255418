import variables from '../../assets/style/variables'

export const style = {
  footerContainer: {
    paddingTop: 10,
    backgroundColor: '#f1f1f1'
  },

  footer: {
    '& a, & p, & div': {
      color: '#808080',
      fontSize: '13px',
      paddingBottom: '20px'
    },

    '& h5': {
      color: variables.black,
      fontSize: '1em',
      margin: '14px 0'
    },

    '& a': {
      textDecoration: 'none',
      lineHeight: '1.6em'
    }
  },

  logoMobile: {
    paddingTop: 6,
    paddingBottom: 6
  },

  bar: {
    backgroundColor: '#1A1F28',
    color: variables.white,
    padding: '12px',
    fontSize: '12px'
  }

}
