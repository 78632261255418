import React from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import { Grid, Col, Row } from 'react-flexbox-grid'
import { GridInner, Select, Typography } from 'rmwc'
import InputRange from 'react-input-range'
import ReactPaginate from 'react-paginate'
import { routes } from '../../config/settings'
import CategoryProduct from '../../components/CategoryProduct'
import {
  addToCart,
  getProductsForCategoryOrChild,
  getProductsForCategory,
    getProductBySku
} from '../../actions'
import { priceFormat } from '../../lib/format'
import { style } from './style'
import variables from '../../assets/style/variables'
import windowSize from 'react-window-size';
import {ADMIN_TYPE} from "../../lib/magento/types";
import Mailchimp from "react-mailchimp-form";
import history from '../../lib/helper/history'

class Category extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      currentCategory: 0,
      perPage: 16,
      currentPage: localStorage.getItem("currentPage") ? localStorage.getItem("currentPage") : 1,
      priceRange: {
        min: 20,
        max: 120
      },
      isDesktop: false,
        categoryName: "",
        change: false
    }
    localStorage.setItem("backButtonProduct", false)
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentWillMount () {
      if(localStorage.getItem("perPage") === 0 || !localStorage.getItem("perPage")){
          localStorage.setItem("perPage", 16)
      }
    this.props.getProductsForCategory({
      id: this.props.match.params.categoryId,
      perPage: localStorage.getItem("perPage"),
      offset: this.state.currentPage
    })
    window.removeEventListener("resize", this.updatePredicate);
  }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        window.history.pushState({name: "browserBack"}, "on browser back click", window.location.href);
        window.addEventListener('popstate', (event) => {
            if (event.state) {
                this.state.change = true
            }
        }, false);
    }

    updatePredicate() {
        this.setState({ isDesktop: window.innerWidth > 620 });
    }

  async componentWillUpdate (prevProps) {
      console.log(this.state.currentPage)
      if (this.props.location !== prevProps.location) {
      await this.setState({ currentPage: this.state.currentPage })
          if(localStorage.getItem("perPage") === 0 || !localStorage.getItem("perPage")){
          localStorage.setItem("perPage", 16)
      }
      this.props.getProductsForCategory({
        id: this.props.match.params.categoryId,
        perPage: localStorage.getItem("perPage"),//this.state.perPage,
        offset: this.state.currentPage
      })
    }
  }

  handlePageClick = async (data) => {
      this.state.currentPage = data.selected + 1
      localStorage.setItem("currentPage", this.state.currentPage)
      if(localStorage.getItem("perPage") === 0 || !localStorage.getItem("perPage")){
          localStorage.setItem("perPage", 16)
      }
    this.props.getProductsForCategory({
      id: this.props.match.params.categoryId,
      perPage: localStorage.getItem("perPage"), //this.state.perPage,
      offset: this.state.currentPage
    })
      window.scrollTo(0,0)
  }

  handleChangeProductsPerPage = (perPage) => {
    this.props.getProductsForCategory({
      id: this.props.match.params.categoryId,
      perPage: perPage,
      offset: this.state.currentPage
    })
  }

    handlerBindClickImage1(){
        window.location.href= "#newsletter"
    }

    handlerBindClickImage(){
        window.scrollTo(0,0)
        history.push(routes.categories.path+"/211/scelti-da-farmakon")
    }

  render () {
    const { classes } = this.props
      const isDesktop = this.state.isDesktop;
    this.state.categoryName = this.props.match.params.categoryName
    if(this.state.categoryName.split('-')) {
        this.state.categoryName = this.state.categoryName.replace(/-/g, " ")
    }
    if(this.state.change){
        if(this.state.currentPage === 1){
            history.push(routes.home.path)
        } else {
            this.state.currentPage = this.state.currentPage-1
            this.props.getProductsForCategory({
                id: this.props.match.params.categoryId,
                perPage: localStorage.getItem("perPage"),
                offset: this.state.currentPage
            })
            this.state.change = false
            window.scrollTo(0,0)
        }

    }

    let scelti_da_farmakon = (this.state.categoryName.toLocaleLowerCase() === 'scelti da farmakon'? true : false)

    if (isDesktop){
    return (
        <React.Fragment>
            {
                scelti_da_farmakon &&
                <React.Fragment>
                <img src={"../../../images/header-category.jpg"}
                     className={classes.headerImage}
                     style={{'width':'100%', 'border-block-end':'6px solid #111B34'}}
                />
                <Typography use={'headline2'}
                style={{'display':'block','text-align':'center','font-weight':'bold', 'margin-top':'2%', 'margin-bottom':'2%'}}
                >Scelti da Farmakon</Typography>
                </React.Fragment>
            }
      <Grid fluid>
        <Row className={classes.category}>
            { !scelti_da_farmakon && <Col md={3} className={classes.sidebar}>
                <img alt={'Banner Scelti Per Te'}
                     onClick={() => this.handlerBindClickImage()}
                     src={'../../../images/banner-home/banner-scelti-per-te.png'}
                     style={{'max-width':'87%', 'margin-top':'15%', 'border':'2px solid', 'margin-bottom':'15%'}}
                />
                  <img alt={'Banner Spedizione'}
                       src={'../../../images/banner-home/banner-spedizione.png'}
                        style={{'max-width':'87%', 'margin-top':'15%', 'border':'2px solid', 'margin-bottom':'15%'}}
                  />
                  <br/>
          </Col>}

          <Col md={scelti_da_farmakon ? 12 : 9} className={classes.products}>
            <Grid>
              <Row end='md'>
                  <Col md={6}>
                      { !scelti_da_farmakon && <Typography use={'body5'} style={{'float':'left'}}>
                          {this.props.totalCount > 0 ? <Typography>Prodotti trovati nella categoria <Typography style={{'font-weight':'bold'}}>{this.state.categoryName}</Typography></Typography> : false}
                      </Typography>}
                  </Col>
                <Col md={6}>
                  <div>
                    <Typography use='body2' style={{ marginRight: 20 }}>
                      <strong>{this.props.totalCount || '-'}</strong> Risultati
                    </Typography>
                    <Typography use='body2'>
                      Per pagina:
                    </Typography>
                    <select className='select-per-page'
                      value={localStorage.getItem("perPage")?localStorage.getItem("perPage") : this.state.perPage }
                      onChange={event => {
                        localStorage.setItem("perPage",event.target.value)
                        this.setState({ perPage: event.target.value })
                        this.handleChangeProductsPerPage(event.target.value)
                      }}
                    >
                      <option value='16'>16</option>
                      <option value='32'>32</option>
                      <option value='48'>48</option>
                      <option value='60'>60</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <hr />

              {this.props.loadingMore && this.props.totalCount > 0 &&
              <Row center='md'>
                <Col md={10} className='category--products-loading'>
                  <svg version='1.1' id='Layer_1' x='0px' y='0px'
                    width='24px' height='30px' viewBox='0 0 24 30'>
                    <rect x='0' y='10' width='4' height='10' fill='#333' opacity='0.2'>
                      <animate attributeName='opacity' attributeType='XML' values='0.2; 1; .2' begin='0s' dur='0.6s' repeatCount='indefinite' />
                      <animate attributeName='height' attributeType='XML' values='10; 20; 10' begin='0s' dur='0.6s' repeatCount='indefinite' />
                      <animate attributeName='y' attributeType='XML' values='10; 5; 10' begin='0s' dur='0.6s' repeatCount='indefinite' />
                    </rect>
                    <rect x='8' y='10' width='4' height='10' fill='#333' opacity='0.2'>
                      <animate attributeName='opacity' attributeType='XML' values='0.2; 1; .2' begin='0.15s' dur='0.6s' repeatCount='indefinite' />
                      <animate attributeName='height' attributeType='XML' values='10; 20; 10' begin='0.15s' dur='0.6s' repeatCount='indefinite' />
                      <animate attributeName='y' attributeType='XML' values='10; 5; 10' begin='0.15s' dur='0.6s' repeatCount='indefinite' />
                    </rect>
                    <rect x='16' y='10' width='4' height='10' fill='#333' opacity='0.2'>
                      <animate attributeName='opacity' attributeType='XML' values='0.2; 1; .2' begin='0.3s' dur='0.6s' repeatCount='indefinite' />
                      <animate attributeName='height' attributeType='XML' values='10; 20; 10' begin='0.3s' dur='0.6s' repeatCount='indefinite' />
                      <animate attributeName='y' attributeType='XML' values='10; 5; 10' begin='0.3s' dur='0.6s' repeatCount='indefinite' />
                    </rect>
                  </svg>
                </Col>
              </Row>
              }

              {parseInt(this.props.totalCount) === 0 && !this.props.loadingMore &&
              <Row center='md'>
                <Col md={10} className='category--no-products-found'>
                  <Typography use='headline5'>
                      Nessun prodotto trovato nella categoria "{this.state.categoryName}"
                  </Typography>
                </Col>
              </Row>
              }


              <GridInner>

                {this.props.products &&
                  Object.values(this.props.products).map((product, i) => (
                    <CategoryProduct
                        product={product}
                        key={i}
                    />
                  ))}
              </GridInner>
                <br/><br/>
                {this.props.totalCount > this.state.perPage &&
                <Row id='react-paginate' center={'md'}>
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.props.totalCount ? this.props.totalCount / this.state.perPage : 1}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        forcePage={this.state.currentPage-1}
                    />
                </Row>
                }
            </Grid>
          </Col>
        </Row>
      </Grid>
          <Grid className="newletterGrid"
                id={'newsletter'}
          >
              <a id={'newsletter'}
                 name="newsletter"><Row className="newsletter">
                  <Col md={6}>
                      <h5>Vuoi rimanere aggiornato sulle nostre offerte e promozioni?</h5>
                      <h3>ISCRIVITI ALLA NEWSLETTER</h3>
                  </Col>
                  <Col md={6} style={{paddingTop: 50}}>
                      <Mailchimp
                          action='https://farmakon.us5.list-manage.com/subscribe/post?u=3aa98a187eba2620895da315d&amp;id=75a7fa5987'
                          style={{backgroundColor: 'rgb(199,77,6)'}}
                          fields={[
                              {
                                  name: 'EMAIL',
                                  placeholder: 'Email',
                                  type: 'email',
                                  required: true
                              }
                          ]}
                          messages = {
                              {
                                  sending: "Iscrivendo alla newsletter...",
                                  success: "Grazie per l'iscrizione!",
                                  error: "C'è stato un errore interno inaspettato",
                                  empty: "Devi scrivere la tua email",
                                  duplicate: "Ci sono già delle iscrizioni per questa email",
                                  button: "Iscriviti!"
                              }
                          }
                          className='newsletterform'
                      />
                  </Col>
              </Row>
                  <Row className="newsletter">
                      <Col md={12} style={{paddingBottom: 20}}>
                          <span>La compilazione del form, spontanea e volontaria, comporta l’acquisizione dei dati personali dell’utente. I campi indicati come obbligatori sono necessari per la sola erogazione del servizio previsto: il mancato conferimento dei dati corrispondenti comporta l’impossibilità di usufruire di tale servizio. Inviando i dati personali si dichiara di aver letto ed accettato i termini relativi al trattamento dei dati, contenuti nell’informativa redatta ai sensi dell’art. 13 del GDPR 679/2016.</span>
                      </Col>
                  </Row></a>
          </Grid>
        </React.Fragment>
    )
      }else{
          return (
              <React.Fragment>
                  {
                      scelti_da_farmakon &&
                      <React.Fragment>
                      <img src={"../../../images/header-category.jpg"}
                           className={classes.headerImage}
                           style={{'width':'100%', 'border-block-end':'6px solid #111B34'}}
                      />
                          <Typography use={'headline5'} style={{'margin-bottom':'50px', 'margin-top':'-72px', 'display':'block','text-align':'center','font-weight':'bold'}}>Scelti da Farmakon</Typography>
                      </React.Fragment>
                  }
          <Grid fluid style={{'text-align':'center'}}>
              <Row className={classes.category}>

                  <Col md={12} className={classes.products}>
                      <Grid style={{'width':'90%'}} gap={105}>
                          <Row end='md' style={{'border':'0 !important'}}>
                              <Col md={12}>
                                  <Typography use={'body5'} style={{'float':'left'}}>
                                      {this.props.totalCount > 0 ? <Typography>Prodotti trovati nella categoria <Typography style={{'font-weight':'bold'}}>{this.state.categoryName}</Typography></Typography> : false}
                                  </Typography>
                              </Col>
                              <Col md={12}>
                                  <div>
                                      <Typography use='body2' style={{ marginRight: 20 }}>
                                          <strong>{this.props.totalCount || '-'}</strong> Risultati
                                      </Typography>
                                      <Typography use='body2'>
                                          Per pagina:
                                      </Typography>
                                      <select className='select-per-page'
                                              value={localStorage.getItem("perPage")?localStorage.getItem("perPage") : this.state.perPage}
                                              onChange={event => {
                                                  localStorage.setItem("perPage", event.target.value)
                                                  this.setState({ perPage: event.target.value })
                                                  this.handleChangeProductsPerPage(event.target.value)
                                              }}
                                      >
                                          <option value='16'>16</option>
                                          <option value='32'>32</option>
                                          <option value='48'>48</option>
                                          <option value='60'>60</option>
                                      </select>
                                  </div>
                                  <hr />
                              </Col>
                          </Row>


                          {this.props.loadingMore && this.props.totalCount > 0 &&
                          <Row center='md'>
                              <Col md={10} className='category--products-loading'>
                                  <svg version='1.1' id='Layer_1' x='0px' y='0px'
                                       width='30px' height='30px' viewBox='0 0 24 30'>
                                      <rect x='0' y='10' width='4' height='10' fill='#333' opacity='0.2'>
                                          <animate attributeName='opacity' attributeType='XML' values='0.2; 1; .2' begin='0s' dur='0.6s' repeatCount='indefinite' />
                                          <animate attributeName='height' attributeType='XML' values='10; 20; 10' begin='0s' dur='0.6s' repeatCount='indefinite' />
                                          <animate attributeName='y' attributeType='XML' values='10; 5; 10' begin='0s' dur='0.6s' repeatCount='indefinite' />
                                      </rect>
                                      <rect x='8' y='10' width='4' height='10' fill='#333' opacity='0.2'>
                                          <animate attributeName='opacity' attributeType='XML' values='0.2; 1; .2' begin='0.15s' dur='0.6s' repeatCount='indefinite' />
                                          <animate attributeName='height' attributeType='XML' values='10; 20; 10' begin='0.15s' dur='0.6s' repeatCount='indefinite' />
                                          <animate attributeName='y' attributeType='XML' values='10; 5; 10' begin='0.15s' dur='0.6s' repeatCount='indefinite' />
                                      </rect>
                                      <rect x='16' y='10' width='4' height='10' fill='#333' opacity='0.2'>
                                          <animate attributeName='opacity' attributeType='XML' values='0.2; 1; .2' begin='0.3s' dur='0.6s' repeatCount='indefinite' />
                                          <animate attributeName='height' attributeType='XML' values='10; 20; 10' begin='0.3s' dur='0.6s' repeatCount='indefinite' />
                                          <animate attributeName='y' attributeType='XML' values='10; 5; 10' begin='0.3s' dur='0.6s' repeatCount='indefinite' />
                                      </rect>
                                  </svg>
                              </Col>
                          </Row>
                          }

                          {parseInt(this.props.totalCount) === 0 && !this.props.loadingMore &&
                          <Row center='md'>
                              <Col md={10} className='category--no-products-found'>
                                  <Typography use='headline5'>
                                      Nessun prodotto trovato nella categoria "{this.state.categoryName}"
                                  </Typography>
                              </Col>
                          </Row>
                          }

                          <Grid style={{'width':'100%','margin-left':'1.5%','margin-right':'-2.5%'}}>
                                  {this.props.products &&
                              <Row>{Object.values(this.props.products).map((product, i) => (
                                  <Col xs={6} style={{'margin-bottom':'5%'}}><CategoryProduct product={product} key={i} /></Col>
                              ))}
                              </Row>
                              }
                          </Grid>
                          <br/>

                          {this.props.totalCount > this.state.perPage &&
                          <Row id='react-paginate' center={'md'}>
                              <ReactPaginate
                                  previousLabel={'<'}
                                  nextLabel={'>'}
                                  breakLabel={'...'}
                                  current={this.state.currentPage}
                                  breakClassName={'break-me'}
                                  pageCount={this.props.totalCount > 1 ? this.props.totalCount / this.state.perPage : 1}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={5}
                                  onPageChange={this.handlePageClick}
                                  containerClassName={'pagination'}
                                  subContainerClassName={'pages pagination'}
                                  activeClassName={'active'}
                                  forcePage={this.state.currentPage-1}
                              />
                          </Row>
                          }
                          <br/>

                  <Col md={3} className={classes.sidebar} style={{'padding-right':'0'}}>
                      <img alt={'Banner Scelti Per Te'}
                           onClick={() => this.handlerBindClickImage()}
                           src={'../../../images/banner-home/banner-scelti-per-te.png'}
                           style={{'max-width':'87%', 'margin-top':'15%', 'border':'2px solid', 'margin-bottom':'15%'}}
                      />
                      <img alt={'Banner Spedizione'}
                           src={'../../../images/banner-home/banner-spedizione.png'}
                           style={{'max-width':'87%', 'margin-top':'15%', 'border':'2px solid', 'margin-bottom':'10%'}}
                      />
                  </Col> </Grid>
                  </Col>
              </Row>
          </Grid>
                  <Grid className="newletterGrid"
                        id={'newsletter'}
                  >
                      <a id={'newsletter'}
                         name="newsletter"><Row className="newsletter">
                          <Col md={6}>
                              <h5>Vuoi rimanere aggiornato sulle nostre offerte e promozioni?</h5>
                              <h3>ISCRIVITI ALLA NEWSLETTER</h3>
                          </Col>
                          <Col md={6} style={{paddingTop: 50}}>
                              <Mailchimp
                                  action='https://keepup.us7.list-manage.com/subscribe/post?u=38e22277dd226d283eab8325a&amp;id=ea45cde229'
                                  style={{backgroundColor: 'rgb(199,77,6)'}}
                                  fields={[
                                      {
                                          name: 'EMAIL',
                                          placeholder: 'Email',
                                          type: 'email',
                                          required: true
                                      }
                                  ]}
                                  messages = {
                                      {
                                          sending: "Iscrivendo alla newsletter...",
                                          success: "Grazie per l'iscrizione!",
                                          error: "C'è stato un errore interno inaspettato",
                                          empty: "Devi scrivere la tua email",
                                          duplicate: "Ci sono già delle iscrizioni per questa email",
                                          button: "Iscriviti!"
                                      }
                                  }
                                  className='newsletterform'
                              />
                          </Col>
                      </Row>
                          <Row className="newsletter">
                              <Col md={12} style={{paddingBottom: 20}}>
                                  <span>La compilazione del form, spontanea e volontaria, comporta l’acquisizione dei dati personali dell’utente. I campi indicati come obbligatori sono necessari per la sola erogazione del servizio previsto: il mancato conferimento dei dati corrispondenti comporta l’impossibilità di usufruire di tale servizio. Inviando i dati personali si dichiara di aver letto ed accettato i termini relativi al trattamento dei dati, contenuti nell’informativa redatta ai sensi dell’art. 13 del GDPR 679/2016.</span>
                              </Col>
                          </Row></a>
                  </Grid>
              </React.Fragment>
      ) }
  }
}

const mapStateToProps = function (state) {
  const category = state.category.current
  const { products, totalCount, loadingMore } = state.category
  const { cart, account } = state
  const canLoadMoreContent = products.length < totalCount

  return {
    category,
    products,
    totalCount,
    cart,
    canLoadMoreContent,
    loadingMore,
    customer: account.customer
  }
}

export default connect(
  mapStateToProps,
  {
    getProductsForCategoryOrChild,
    getProductsForCategory,
    addToCart,
      getProductBySku
  }
)(injectSheet(style)(Category))
