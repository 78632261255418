import React from 'react'
import TextField from "@material-ui/core/TextField";
import KUButton from "../../components/KUButton";
import {GUEST_TYPE, ADMIN_TYPE, CUSTOMER_TYPE} from "../../lib/magento/types";
import {magento} from '../../lib/magento'
import {PWD_REGEX_VALIDATION} from "../../config/settings";
import Loader from 'react-loader-spinner'
import Grid from "@material-ui/core/Grid";
import {Col, Row} from "react-flexbox-grid";
import Typography from "@material-ui/core/Typography";
import {style} from './style'
import injectSheet from "react-jss";
import qs from "query-string";

class resetPassword extends React.Component {

    constructor(){
        super()
        this.state = {
            email: '',
            token: '',
            password: '',
            confirmPassword: '',
            success: 'load',
            spin:false
        }
    }

    async handleSubmit(){
        console.log(this.props)
        this.setState({
            spin:true
        })
        const path = `/V1/customers/resetPassword`

        let resetdata = {
            "email": qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).email,
            "resetToken": qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).token,
            "newPassword": this.state.password? this.state.password : false
        }
        await magento
            .post(path, resetdata, ADMIN_TYPE)
            .then(data => {
                if(data == true){
                    this.setState({
                        success:true,
                        spin: false
                    })
                    this.state.spin = false
                } else {
                    this.setState({
                        success:false,
                        spin:false
                    })
                }

            })
            .catch(e => {
                console.log(e)
            })

    }

    changeSpin(){
        this.state.spin=true
    }

    render() {
        return(
            <Grid fluid
                style={{'padding-left':'12%', 'padding-right':'12%', 'margin-top':'1%', 'margin-bottom':'2%'}}
            >
                <Row>
                    <Typography use="headline5" style={{'font-size':'1.875rem'}}>Inserisci la tua nuova password</Typography>
                </Row>
                <br/>
                <Row>
                    <Col xs={12}>
                    <TextField
                        type={"password"}
                        label={"Nuova password"}
                        style={{'width':'250px'}}
                        value={this.state.password}
                        onChange={event => this.setState({ password: event.target.value })}
                    />
                    <br/><br/>
                    <TextField
                        type={"password"}
                        label={"Conferma password"}
                        style={{'width':'250px'}}
                        value={this.state.confirmPassword}
                        onChange={event => this.setState({ confirmPassword: event.target.value })}
                    /></Col>
                </Row>
                <br/>
                <Row>
                <Col xs={12}>
                    <KUButton unelevated
                          disabled={(this.state.password !== this.state.confirmPassword) ||
                          this.state.password === '' ||
                          !this.state.password.match(PWD_REGEX_VALIDATION)
                          }
                        clickHandler={() => this.handleSubmit()}
                    >
                        PROCEDI
                    </KUButton>
                    <br/>
                </Col>
                </Row>
                <br/>
                <Row>
                    {this.state.spin && <Loader
                        type="TailSpin"
                        color="#000"
                        height={50}
                        width={100}

                    />}
                    {this.state.success === true ? 'Password cambiata con successo!' : this.state.success === false? 'Errore' : false}
                </Row>
            </Grid>
        )
    }

}

export default (injectSheet(style)(resetPassword))