import {
  MAGENTO_CREATE_CART,
  MAGENTO_ADD_TO_CART,
  NAVIGATION_GO_TO,
  MAGENTO_ADD_TO_CART_LOADING,
  MAGENTO_GET_CART,
  MAGENTO_CART_ITEM_PRODUCT,
  MAGENTO_REMOVE_FROM_CART_LOADING,
  MAGENTO_REMOVE_FROM_CART,
  MAGENTO_CHANGE_CART_ITEM_QTY,
  MAGENTO_CHANGE_CART_ITEM_QTY_LOADING,
  MAGENTO_GET_PRODUCT_MEDIA, MAGENTO_CURRENT_CUSTOMER
} from '../actions/types'

const INITIAL_STATE = {
  cartId: false,
  addToCartLoading: false,
  items: false,
  errorMessage: false,
  cart: {},
  products: {},
  quote: {},
  customer: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MAGENTO_CURRENT_CUSTOMER:
      return { ...state, customer: action.payload }
    case MAGENTO_CREATE_CART:
      return { ...state, cartId: action.payload, quote: { items: [] } }
    case MAGENTO_CART_ITEM_PRODUCT: {
      const products = { ...state.products, [action.payload.sku]: action.payload }
      return { ...state, products }
    }
    case NAVIGATION_GO_TO:
      return { ...state, errorMessage: false }
    case MAGENTO_GET_CART:
      /* console.log("state.items", state.items);
      console.log("action.payload.items", action.payload.items); */

      return {
        ...state,
        cartId: action.payload.cartId,
        quote: action.payload.quote,

        items: action.payload.items.length > 0 ? Object.keys(state.items).length !== 0 ? Object.assign(
          [],
          action.payload.items.map(
            item => {
              let index = Object.values(state.items).find(searchItem => searchItem.sku === item.sku)
              let _ret = index ? { ...index, qty: item.qty } : item

              return _ret
            }
          )
        ) : action.payload.items // Object.keys( state.items ).length is 0
          : [] // action.payload.items is empty

      }
    case MAGENTO_CHANGE_CART_ITEM_QTY:
      return { ...state, errorMessage: action.payload.message }
    case MAGENTO_REMOVE_FROM_CART_LOADING:
      return { ...state, addToCartLoading: action.payload }
    case MAGENTO_REMOVE_FROM_CART:
      return { ...state, errorMessage: action.payload.message }
    case MAGENTO_GET_PRODUCT_MEDIA: {
      const items = Object.keys(state.items).length !== 0

      /* Object.assign PREVENTS TO RELATIVE COMPONENTS THE UPDATE ON DATA CHANGES !!!
        Object.assign(
          state.items,
          state.items.map(
            item => item.sku === action.payload.sku ? {...item, media: action.payload.media} : item
          )
        ) */

        ? state.items.map(
          item => item.sku === action.payload.sku ? { ...item, media: action.payload.media } : item
        )
        : state.items

      return { ...state, items }
    }
    default:
      return state
  }
}
