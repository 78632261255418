import React from 'react'
import {Row, Col, Grid} from 'react-flexbox-grid'
import injectSheet from 'react-jss'
import Slider from 'react-slick'
import {GridCell, Icon} from 'rmwc'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {style} from './style'
import ReactDOM from 'react-dom'
import {Typography} from 'rmwc'
import KUButton from '../../components/KUButton'
import {addToCart, getProductsForCategory, getProductBySku, getHomeData} from '../../actions'
import {loadImperdibili, loadImperdibili2, loadImperdibili3} from "../../actions";
import {Card, CardPrimaryAction, CardMedia, CardAction, CardActions, CardActionButtons} from '@rmwc/card'
import {productThumb} from "../../lib/product";
import {priceFormat} from "../../lib/format";
import history from "../../lib/helper/history";
import {routes, STRIPE_APP_ID} from "../../config/settings";
import {magento} from '../../lib/magento'
import {connect} from "react-redux";
import {MAGENTO_GET_PRODUCT} from "../../actions/types";
import Product from '../../components/_Products/ProductItem'
import {CardActionArea, CardContent} from '@material-ui/core'
import img from 'react-image'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Navbar from "../../components/NavBar";
import Mailchimp from 'react-mailchimp-form'
import Link from "@material-ui/core/Link";
import Loader from 'react-loader-spinner'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";


class Home extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentCategory: 59,
            perPage: 16,
            currentPage: 1,
            priceRange: {
                min: 20,
                max: 120
            },
            second_products: [],
            products: [],
            cart_length: '',
            isDesktop: false,
            second_id_products: ['909475624', '923354474', '902105737', '974948731', '974848780', '904648060', '932740727', '977808346', '911974501', '977808359', '971671437'],
            productDialogCart: false,
            openDialogAddToCart: false
        }
        localStorage.setItem("perPage", "16")
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    onPressAddToCart(product) {
        this.state.productDialogCart = product
        const {cart, customer} = this.props
        this.props.addToCart({
            cartId: cart.cartId,
            item: {
                cartItem: {
                    sku: product.sku,
                    qty: 1,
                    quoteId: cart.cartId
                    // ...productOptions
                }
            },
            customer
        })
        setTimeout(() => (
            this.state.openDialogAddToCart = true
        ), 500)
    }


    handleProductClick(product) {
        history.push(`${routes.product.path}/${product.sku}`)

    }

    handlerBindClickImage5() {
        history.push(`${routes.categories.path}/${"200"}/${"fuori-tutto"}`)
    }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    handlerBindClickImage1() {
        history.push(`${routes.search.path}/SPECCHIASOL`)
    }

    handlerBindClickImage2() {
        history.push(`/categoria/214/prevenzione-antivirale`)
    }

    handlerBindClickImage3() {
        history.push(`${routes.search.path}/named`)
    }

    handlerBindClickImage4() {
        history.push(`${routes.search.path}/FILORGA`)
    }

    handleClose = () => {
        this.setState({
            openDialogAddToCart: false
        })
    };

    updatePredicate() {
        this.setState({isDesktop: window.innerWidth > 620});
    }

    componentWillMount() {
        let second_id_products = ['909475624', '923354474', '902105737', '974948731', '974848780', '932740727', '977808346', '911974501', '977808359', '971671437']
        second_id_products.forEach(el => {
            this.props.loadImperdibili(el)
        })
        //let id_imperdibili_2 = ['980427431', '980120404', '972286569', '975346141', '978400733']
        let id_imperdibili_2 = ["975346154", "978400733", "978400733", "975346141", "975346166"]
        id_imperdibili_2.forEach(el => {
            this.props.loadImperdibili2(el)
        })
        //let id_imperdibili_3 = ['976397051', '910880208', '938272352', '931095374', '938805431']
        let id_imperdibili_3 = ["976397051", "972784538", "972784540", "972784577", "972570271"]
        id_imperdibili_3.forEach(el => {
            this.props.loadImperdibili3(el)
        })
        window.removeEventListener("resize", this.updatePredicate);
    }

    calcolaSconto(_discountPrice, price){
        let sconto = _discountPrice ? (((price - _discountPrice) / price)*100) : false
        sconto = (sconto != false && sconto > 0)? sconto.toFixed() : false
        return sconto
    }

    render() {
        const {classes, data} = this.props
        const array2 = this.props.imperdibili
        const isDesktop = this.state.isDesktop

        if (array2.length === 0) {
            setTimeout(function () {
                this.forceUpdate()
            }.bind(this), 1500)
        }

        const slickSettings = {
            dots: false,
            clickable: true,
            infinite: true,
            speed: 600,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            autoplay: true
        }

        var featudedSliderSettings = {
            dots: false,
            clickable: true,
            infinite: true,
            speed: 600,
            slidesToShow: 5,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }

        var featudedSliderSettingsBrand = {
            dots: false,
            clickable: true,
            autoplay: true,
            infinite: true,
            speed: 400,
            slidesToShow: 5,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }

        return (
            <div className={classes.home}>
                <Dialog
                    open={this.state.openDialogAddToCart}
                    onClose={this.handleClose}
                    style={{'text-align': 'center'}}
                    fullWidth={false}
                >
                    <div style={{'padding-right': '10px', "padding-top":"10px", "cursor":"pointer"}} onClick={this.handleClose}>
                        <div>
                            <div className={classes.mdiv1_closex}>
                                <div className={classes.mdiv_closex}>
                                    <div className={classes.md_closex}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <DialogTitle style={{"width": isDesktop ? "450px" : '231px', "padding":"0px 24px 24px"}}>Prodotto aggiunto al
                        carrello</DialogTitle>
                    <DialogContent style={{'width': isDesktop ? '450px' : '231px'}}>
                        <div className={classes.productThumb}
                            onClick={() => this.handleProductClick(this.state.productDialogCart)}
                        >
                            <img src={this.state.productDialogCart.custom_attributes ?
                                (this.state.productDialogCart.custom_attributes.find(e => e.attribute_code === 'image') ?
                                    productThumb(this.state.productDialogCart) : "../../../images/no-img.png") :
                                productThumb(this.state.productDialogCart)}
                                 style={{'max-width': isDesktop ? '290px' : '231px', 'max-height': '345'}}/>
                        </div>
                        <br/><Typography use='body3'
                                         style={{'margin-bottom': '2%'}}
                    >
                        {this.state.productDialogCart.name}
                    </Typography><br/>
                        <Typography use='body3' style={{'margin-bottom': '2%'}}>
                            {(this.state.productDialogCart.custom_attributes
                                ? this.state.productDialogCart.custom_attributes.find(f => f.attribute_code === 'special_price')
                                    ? this.state.productDialogCart.custom_attributes.find(f => f.attribute_code === 'special_price').value
                                    : false
                                : false) ? (this.state.productDialogCart.custom_attributes.find(f => f.attribute_code === 'special_price').value >= this.state.productDialogCart.price) ?
                                <div>{priceFormat(this.state.productDialogCart.price)}</div> :
                                <div>
                                    <del style={{
                                        'font-size': '13px',
                                        'margin-right': '5px'
                                    }}>{priceFormat(this.state.productDialogCart.price)}</del>
                                    <span
                                        style={{
                                            'font-weight': 'bold',
                                            'color': '#96bf31'
                                        }}>{priceFormat(this.state.productDialogCart.custom_attributes.find(f => f.attribute_code === 'special_price').value)}</span>
                                </div>
                                : <div>{priceFormat(this.state.productDialogCart.price)}</div>}
                        </Typography><br/>
                        <div style={{'display': 'flex', 'justify-content': 'center'}}>
                            <a href={routes.cart.path} style={{'color': 'white', "width":"100%"}}>
                                <Button
                                    style={{
                                        backgroundColor: '#008837',
                                        'height': '44px',
                                        'width': '90%',
                                        'color': 'white'
                                    }}
                                >
                                    Vai al carrello
                                </Button>
                            </a>
                        </div>
                        <div style={{"justify-content":"center", "margin-top":"10px"}}>
                            oppure
                            <br/>
                            <Typography
                                onClick={this.handleClose}
                                style={{
                                    'color': 'rgb(0, 136, 55)',
                                    "text-decoration":"underline",
                                    "font-weight":"bold",
                                    "cursor":"pointer"
                                }}
                            >Continua gli acquisti
                            </Typography>
                        </div>
                    </DialogContent>
                </Dialog>
                <Slider {...slickSettings} className={classes.homeSlider}>


                    <div key={3}>
                        <img alt={'Banner'} src={'../../../images/banner-home/Banner-mascherine-e-igienizzanti.jpg'}
                             className={classes.imageSlider}
                             onClick={() => this.handlerBindClickImage2()}
                        />
                    </div>

                    <div key={3}>
                        <img alt={'Banner'} src={'../../../images/banner-home/Banner-Specchiasol.jpg'}
                             className={classes.imageSlider}
                             onClick={() => this.handlerBindClickImage1()}
                        />
                    </div>

                    <div>
                        <img alt={'Banner'} src={'../../../images/banner-home/Banner-4.jpg'}
                             className={classes.imageSlider}
                             onClick={() => this.handlerBindClickImage4()}
                        />
                    </div>

                </Slider>

                <Grid>
                    <Row style={{marginTop: 0}}>
                        <Col md={6}
                             style={{'text-align': isDesktop ? 'center' : false}}
                        >
                            <img alt={'Promo in evidenza'}
                                 src={'../../../images/banner-home/banner-farmakon-2.jpg'}
                                 className={'promoinevidenza'}
                                 data-id={"promoinevidenza"}
                                 onClick={() => this.handlerBindClickImage5()}
                                 style={{'width': isDesktop ? '100%' : '105%', "pointer-events": "all"}}
                            />
                            <KUButton unelevated orange
                                      style={{
                                          'height': '38px',
                                          'padding': '0 !important',
                                          marginTop: '-23%',
                                          marginRight: isDesktop ?/*'2%'*/false : '-5%',
                                          marginLeft: isDesktop ? '62%' : false,
                                          'width': isDesktop ? false : '111px',
                                          'float': isDesktop ? false : 'right',
                                          'font-size': '12px',
                                          'font-weight': '600 !important',
                                          'min-height': '0px !important'
                                      }}
                                      clickHandler={() => {
                                          history.push(`${routes.categories.path}/${"211"}/${"scelti-da-farmakon"}`)
                                      }}
                            >
                                SCOPRI LE OFFERTE
                            </KUButton>
                        </Col>
                        <Col md={6}
                             style={{'text-align': isDesktop ? 'center' : false}}
                        >
                            <img alt={'Promo in evidenza'}
                                 src={'../../../images/banner-home/banner-offerte.png'}
                                 className={'promoinevidenza'}
                                 data-id={"promoinevidenza"}
                                 onClick={() => this.handlerBindClickImage5()}
                                 style={{'width': isDesktop ? '100%' : '105%', "pointer-events": "all"}}
                            />
                            <KUButton unelevated orange
                                      style={{
                                          'height': '38px',
                                          'padding': '0 !important',
                                          marginTop: '-23%',
                                          marginRight: isDesktop ?/*'2%'*/false : '-5%',
                                          marginLeft: isDesktop ? '62%' : false,
                                          'width': isDesktop ? false : '111px',
                                          'float': isDesktop ? false : 'right',
                                          'font-size': '12px',
                                          'font-weight': '600 !important',
                                          'min-height': '0px !important'
                                      }}
                                      clickHandler={() => {
                                          history.push(`${routes.categories.path}/${"200"}/${"fuori-tutto"}`)
                                      }}
                            >
                                SCOPRI LE OFFERTE
                            </KUButton>
                        </Col>
                    </Row>

                    <Row center='md' style={{marginTop: 40}}>
                        <Col md={12}>
                            <Typography use='headline6' style={{'font-weight': 'bold'}}>
                                Prodotti in evidenza
                            </Typography>
                        </Col>
                    </Row>

                    <br/>

                    { this.props.imperdibili_2 && <Row>
                        <Col md={12} style={{padding: 0}}>
                            <Slider {...featudedSliderSettings} className={classes.featuredProduct}>
                                {Object.values(this.props.imperdibili_2).map((product) => {
                                    return product.message ? false : <div key={product.id}>
                                        <Card className={classes.card} style={{'height': '26rem'}}>
                                            <CardActionArea onClick={() => this.handleProductClick(product)}>
                                                <div style={{'height': '225px'}}><img src={productThumb(product)}
                                                                                      alt={product.name} style={{
                                                    'height': '225px !important',
                                                    'width': '-webkit-fill-available !important',
                                                    'max-height': '225px',
                                                    'max-width': '100%',
                                                    'margin-left': 'auto',
                                                    'margin-right': 'auto'
                                                }}/>
                                                </div>
                                                <CardContent style={{'height':'5.6rem'}}>
                                                    <div gutterBottom variant="h5" component="h2"
                                                         style={{'margin-top': '0', 'height': '3rem'}}>
                                                        {product.name}
                                                    </div>
                                                    <div style={{
                                                        'height': '20px',
                                                        'font-align': 'left'
                                                    }}>

                                                        { product.extension_attributes.stock_item.qty < 900 && product.extension_attributes.stock_item.qty > 0 && product.custom_attributes.find(f => f.attribute_code === 'special_price') &&
                                                        <Typography className={classes.sconto}> Sconto {product.sconto} %</Typography>}
                                                        {(product.custom_attributes
                                                            ? product.custom_attributes.find(f => f.attribute_code === 'special_price')
                                                                ? product.custom_attributes.find(f => f.attribute_code === 'special_price').value
                                                                : false
                                                            : false) ? (product.custom_attributes.find(f => f.attribute_code === 'special_price').value >= product.price) ?
                                                            <div style={{"height":product.extension_attributes.stock_item.qty < 900 && product.extension_attributes.stock_item.qty > 0 && product.custom_attributes.find(f => f.attribute_code === 'special_price') ? "1.5rem" : "2rem",
                                                                "padding-top":product.extension_attributes.stock_item.qty < 900 && product.extension_attributes.stock_item.qty > 0 && product.custom_attributes.find(f => f.attribute_code === 'special_price') ? "" :"27px"}}>{priceFormat(product.price)}</div> :
                                                            <div style={{"height":product.extension_attributes.stock_item.qty < 900 && product.extension_attributes.stock_item.qty > 0 && product.custom_attributes.find(f => f.attribute_code === 'special_price') ? "1.5rem" : "2rem",
                                                                "padding-top":product.extension_attributes.stock_item.qty < 900 && product.extension_attributes.stock_item.qty > 0 && product.custom_attributes.find(f => f.attribute_code === 'special_price') ? "" :"27px"}}>
                                                                <del style={{
                                                                    'font-size': '13px',
                                                                    'margin-right': '5px'
                                                                }}>{priceFormat(product.price)}</del>
                                                                <span
                                                                    style={{
                                                                        'font-weight': 'bold',
                                                                        'color': '#96bf31'
                                                                    }}>{priceFormat(product.custom_attributes.find(f => f.attribute_code === 'special_price').value)}</span>
                                                            </div>
                                                            : <div>{priceFormat(product.price)}</div>}</div>
                                                </CardContent>
                                            </CardActionArea>
                                            <CardActions style={{'padding': '0', 'margin': '0'}}>
                                                { product.extension_attributes.stock_item.is_in_stock && product.extension_attributes.stock_item.qty > 0 && <KUButton className={classes.add}
                                                                                                                   unelevated ripple={false}
                                                                                                                   style={{'width': '100%', 'margin-top': '2%'}}
                                                                                                                   loading={this.props.ui.addToCartLoading === product.sku}
                                                                                                                   clickHandler={() => this.onPressAddToCart(product)}
                                                                                                                   disabled={product.extension_attributes.stock_item.qty === 0 || !product.extension_attributes.stock_item.is_in_stock ? true : false}
                                                >
                                                    <Icon icon='add_shopping_cart'/>
                                                    <Typography use='button'>AGGIUNGI</Typography>
                                                </KUButton> }
                                                {(!product.extension_attributes.stock_item.is_in_stock || product.extension_attributes.stock_item.qty <=0) && <div style={{'display': 'flex', 'justify-content': 'center', 'width':'100%'}}>
                                                    <div style={{"text-align": "center"}}>
                                                        <div className={classes.mdiv1}>
                                                            <div className={classes.mdiv}>
                                                                <div className={classes.md}></div>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            'margin-bottom': "auto",
                                                            "margin-top": "auto",
                                                            "font-size": "15px",
                                                            "text-align": "left",
                                                            "padding-left": "25px"
                                                        }}>Non disponibile
                                                        </div>
                                                    </div>
                                                </div>}
                                            </CardActions>
                                        </Card>
                                    </div>
                                })}
                            </Slider>
                        </Col>
                    </Row> }

                    { this.props.imperdibili_3 && <Row>
                        <Col md={12} style={{padding: 0}}>
                            <Slider {...featudedSliderSettings} className={classes.featuredProduct}>
                                {Object.values(this.props.imperdibili_3).map((product) => {
                                    return product.message ? false : <div key={product.id}>
                                        <Card className={classes.card} style={{'height': '26rem'}}>
                                            <CardActionArea onClick={() => this.handleProductClick(product)}>
                                                <div style={{'height': '225px'}}><img src={productThumb(product)}
                                                                                      alt={product.name} style={{
                                                    'height': '225px !important',
                                                    'width': '-webkit-fill-available !important',
                                                    'max-height': '225px',
                                                    'max-width': '100%',
                                                    'margin-left': 'auto',
                                                    'margin-right': 'auto'
                                                }}/>
                                                </div>
                                                <CardContent style={{'height':'5.6rem'}}>
                                                    <div gutterBottom variant="h5" component="h2"
                                                         style={{'margin-top': '0', 'height': '3rem'}}>
                                                        {product.name}
                                                    </div>
                                                    <div style={{
                                                        'height': '20px',
                                                        'font-align': 'left'
                                                    }}>

                                                        { product.extension_attributes.stock_item.qty < 900 && product.extension_attributes.stock_item.qty > 0 && product.custom_attributes.find(f => f.attribute_code === 'special_price') &&
                                                        <Typography className={classes.sconto}> Sconto {product.sconto} %</Typography>}
                                                        {(product.custom_attributes
                                                            ? product.custom_attributes.find(f => f.attribute_code === 'special_price')
                                                                ? product.custom_attributes.find(f => f.attribute_code === 'special_price').value
                                                                : false
                                                            : false) ? (product.custom_attributes.find(f => f.attribute_code === 'special_price').value >= product.price) ?
                                                            <div style={{"height":product.extension_attributes.stock_item.qty < 900 && product.extension_attributes.stock_item.qty > 0 && product.custom_attributes.find(f => f.attribute_code === 'special_price') ? "1.5rem" : "2rem",
                                                                "padding-top":product.extension_attributes.stock_item.qty < 900 && product.extension_attributes.stock_item.qty > 0 && product.custom_attributes.find(f => f.attribute_code === 'special_price') ? "" :"27px"}}>{priceFormat(product.price)}</div> :
                                                            <div style={{"height":product.extension_attributes.stock_item.qty < 900 && product.extension_attributes.stock_item.qty > 0 && product.custom_attributes.find(f => f.attribute_code === 'special_price') ? "1.5rem" : "2rem",
                                                                "padding-top":product.extension_attributes.stock_item.qty < 900 && product.extension_attributes.stock_item.qty > 0 && product.custom_attributes.find(f => f.attribute_code === 'special_price') ? "" :"27px"}}>
                                                                <del style={{
                                                                    'font-size': '13px',
                                                                    'margin-right': '5px'
                                                                }}>{priceFormat(product.price)}</del>
                                                                <span
                                                                    style={{
                                                                        'font-weight': 'bold',
                                                                        'color': '#96bf31'
                                                                    }}>{priceFormat(product.custom_attributes.find(f => f.attribute_code === 'special_price').value)}</span>
                                                            </div>
                                                            : <div>{priceFormat(product.price)}</div>}</div>
                                                </CardContent>
                                            </CardActionArea>
                                            <CardActions style={{'padding': '0', 'margin': '0'}}>
                                                { product.extension_attributes.stock_item.is_in_stock && product.extension_attributes.stock_item.qty > 0 && <KUButton className={classes.add}
                                                                                                                   unelevated ripple={false}
                                                                                                                   style={{'width': '100%', 'margin-top': '2%'}}
                                                                                                                   loading={this.props.ui.addToCartLoading === product.sku}
                                                                                                                   clickHandler={() => this.onPressAddToCart(product)}
                                                                                                                   disabled={product.extension_attributes.stock_item.qty === 0 || !product.extension_attributes.stock_item.is_in_stock ? true : false}
                                                >
                                                    <Icon icon='add_shopping_cart'/>
                                                    <Typography use='button'>AGGIUNGI</Typography>
                                                </KUButton> }
                                                {(!product.extension_attributes.stock_item.is_in_stock || product.extension_attributes.stock_item.qty <= 0) && <div style={{'display': 'flex', 'justify-content': 'center', 'width':'100%'}}>
                                                    <div style={{"text-align": "center"}}>
                                                        <div className={classes.mdiv1}>
                                                            <div className={classes.mdiv}>
                                                                <div className={classes.md}></div>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            'margin-bottom': "auto",
                                                            "margin-top": "auto",
                                                            "font-size": "15px",
                                                            "text-align": "left",
                                                            "padding-left": "25px"
                                                        }}>Non disponibile
                                                        </div>
                                                    </div>
                                                </div>}
                                            </CardActions>
                                        </Card>
                                    </div>
                                })}
                            </Slider>
                        </Col>
                    </Row> }

                    <Row center='md' style={{marginTop: 40}}>
                        <Col md={12}>
                            <Typography use='headline6' style={{'font-weight': 'bold'}}>
                                Che prodotto stai cercando?
                            </Typography>
                        </Col>
                    </Row>
                    <div className={classes.homeCategories}>
                        <Row xs={12} style={{'text-align': 'center' /*,'width':isDesktop? false : '96%'*/}}>

                            <Col md={4} xs={12} className="category-home" id="cosmesi-bellezza">
                                <a className="link-category-home" href="/categoria/42/cosmesi-e-bellezza">
                                    <div className="categoria-home">
                                        COSMESI E BELLEZZA
                                    </div>
                                </a>
                            </Col>

                            <Col md={4} xs={12} className="category-home" id="mamma-bambino">
                                <a className="link-category-home" href="/categoria/43/mamma-e-bambino">
                                    <div className="categoria-home">
                                        MAMMA E BAMBINO
                                    </div>
                                </a>
                            </Col>

                            <Col md={4} xs={12} className="category-home" id="fitoterapia">
                                <a className="link-category-home" href="/categoria/44/fitoterapia-e-omeopatia">
                                    <div className="categoria-home">
                                        FITOTERAPIA
                                    </div>
                                </a>
                            </Col>
                        </Row><Row style={{'width': isDesktop ? false : '96%'}}>
                        <Col md={4} xs={12} className="category-home" id="integratori">
                            <a className="link-category-home" href="/categoria/45/integratori-e-benessere">
                                <div className="categoria-home" id={"categoria-home"}>
                                    INTEGRATORI E BENESSERE
                                </div>
                            </a>
                        </Col>

                        <Col md={4} xs={12} className="category-home" id="dispositivi-medici">
                            <a className="link-category-home" href="/categoria/48/dispositivi-medici-e-sanitari">
                                <div className="categoria-home">
                                    DISPOSITIVI MEDICI E SANITARI
                                </div>
                            </a>
                        </Col>

                        <Col md={4} xs={12} className="category-home" id="igiene-cura">
                            <a className="link-category-home" href="/categoria/49/igiene-e-cura-della-persona">
                                <div className="categoria-home">
                                    IGIENE E CURA DELLA PERSONA
                                </div>
                            </a>
                        </Col>
                    </Row>

                    </div>
                    <br/>
                    <Row center='md'>
                        <Col md={12}>
                            <Typography use='headline6' style={{'font-weight': 'bold'}}>
                                Gli imperdibili
                            </Typography>
                        </Col>
                    </Row>
                    <br/>
                    {/*{array2.length === this.state.second_id_products.length ?*/}
                    { this.props.imperdibili?
                        <Row>
                            <Col md={12} style={{padding: 0}}>
                                <Slider {...featudedSliderSettings} className={classes.featuredProduct}>
                                    {Object.values(array2).map((product) => {
                                        return product.message ? false : <div key={product.id}>
                                            <Card className={classes.card} style={{'height': '26rem'}}>
                                                <CardActionArea onClick={() => this.handleProductClick(product)}>
                                                    <div style={{'height': '225px'}}><img src={productThumb(product)}
                                                                                          alt={product.name} style={{
                                                        'height': '225px !important',
                                                        'width': '-webkit-fill-available !important',
                                                        'max-height': '225px',
                                                        'max-width': '100%',
                                                        'margin-left': 'auto',
                                                        'margin-right': 'auto'
                                                    }}/>
                                                    </div>
                                                    <CardContent style={{'height':'5.6rem'}}>
                                                        <div gutterBottom variant="h5" component="h2"
                                                             style={{'margin-top': '0', 'height': '3rem'}}>
                                                            {product.name}
                                                        </div>
                                                        <div style={{
                                                            'height': '20px',
                                                            'font-align': 'left'
                                                        }}>

                                                            { product.extension_attributes.stock_item.qty < 900 && product.extension_attributes.stock_item.qty > 0 && product.custom_attributes.find(f => f.attribute_code === 'special_price') &&
                                                            <Typography className={classes.sconto}> Sconto {product.sconto} %</Typography>}
                                                            {(product.custom_attributes
                                                            ? product.custom_attributes.find(f => f.attribute_code === 'special_price')
                                                                ? product.custom_attributes.find(f => f.attribute_code === 'special_price').value
                                                                : false
                                                            : false) ? (product.custom_attributes.find(f => f.attribute_code === 'special_price').value >= product.price) ?
                                                            <div style={{"height":product.extension_attributes.stock_item.qty < 900 && product.extension_attributes.stock_item.qty > 0 && product.custom_attributes.find(f => f.attribute_code === 'special_price') ? "1.5rem" : "2rem",
                                                                "padding-top":product.extension_attributes.stock_item.qty < 900 && product.extension_attributes.stock_item.qty > 0 && product.custom_attributes.find(f => f.attribute_code === 'special_price') ? "" :"27px"}}>{priceFormat(product.price)}</div> :
                                                            <div style={{"height":product.extension_attributes.stock_item.qty < 900 && product.extension_attributes.stock_item.qty > 0 && product.custom_attributes.find(f => f.attribute_code === 'special_price') ? "1.5rem" : "2rem",
                                                                "padding-top":product.extension_attributes.stock_item.qty < 900 && product.extension_attributes.stock_item.qty > 0 && product.custom_attributes.find(f => f.attribute_code === 'special_price') ? "" :"27px"}}>
                                                                <del style={{
                                                                    'font-size': '13px',
                                                                    'margin-right': '5px'
                                                                }}>{priceFormat(product.price)}</del>
                                                                <span
                                                                    style={{
                                                                        'font-weight': 'bold',
                                                                        'color': '#96bf31'
                                                                    }}>{priceFormat(product.custom_attributes.find(f => f.attribute_code === 'special_price').value)}</span>
                                                            </div>
                                                            : <div>{priceFormat(product.price)}</div>}</div>
                                                    </CardContent>
                                                </CardActionArea>
                                                <CardActions style={{'padding': '0', 'margin': '0'}}>
                                                    { product.extension_attributes.stock_item.is_in_stock && product.extension_attributes.stock_item.qty > 0 && <KUButton className={classes.add}
                                                              unelevated ripple={false}
                                                              style={{'width': '100%', 'margin-top': '2%'}}
                                                              loading={this.props.ui.addToCartLoading === product.sku}
                                                              clickHandler={() => this.onPressAddToCart(product)}
                                                              disabled={product.extension_attributes.stock_item.qty === 0 || !product.extension_attributes.stock_item.is_in_stock ? true : false}
                                                    >
                                                        <Icon icon='add_shopping_cart'/>
                                                        <Typography use='button'>AGGIUNGI</Typography>
                                                    </KUButton> }
                                                    {(!product.extension_attributes.stock_item.is_in_stock || product.extension_attributes.stock_item.qty <= 0) && <div style={{'display': 'flex', 'justify-content': 'center', 'width':'100%'}}>
                                                        <div style={{"text-align": "center"}}>
                                                            <div className={classes.mdiv1}>
                                                                <div className={classes.mdiv}>
                                                                    <div className={classes.md}></div>
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                'margin-bottom': "auto",
                                                                "margin-top": "auto",
                                                                "font-size": "15px",
                                                                "text-align": "left",
                                                                "padding-left": "25px"
                                                            }}>Non disponibile
                                                            </div>
                                                        </div>
                                                    </div>}
                                                </CardActions>
                                            </Card>
                                        </div>
                                    })}
                                </Slider>
                            </Col>
                        </Row> : <p style={{'align': 'center', 'text-align': 'center'}}><Loader
                            type="TailSpin"
                            color="#000"
                            height={50}
                            width={100}

                        /></p>}
                    <br/>
                    <Row>
                        <text>
                            <text style={{'font-weight': 'bold'}}>Farmakon </text>
                            è la catena di parafarmacie che si prende cura della tua persona, dove salute, bellezza e
                            benessere si fondono per una shopping experience unica. Il progetto nasce dall’idea di
                            abbinare servizio al cliente, qualità del prodotto, consiglio professionale e innovazione,
                            per riuscire ad offrire al nostro cliente professionalità, convenienza e assistenza pre /
                            post vendita.
                        </text>
                        <br/>
                        <text>Il Team di <text style={{'font-weight': 'bold'}}>Farmakon</text> è formato da giovani
                            professionisti, preparati, intraprendenti e dinamici, con l’unico obiettivo di rispondere a
                            tutte le esigenze del cliente. Per questo sono in continuo aggiornamento, così come prestano
                            attenzione assoluta a novità e proposte innovative.
                            Da oggi è attivo anche il nostro negozio on line, grazie al quale garantiamo disponibilità e
                            promozioni su oltre 20.000 articoli, selezionati tra le migliori marche del mondo cosmetico,
                            naturale e nutraceutico.
                        </text>
                        <br/>
                        Siamo contattabili H24 per e-mail info@farmakon.it e in orario di ufficio al numero
                        05734477444(dalle 09:00 alle 13:00 e dalle 14:00 alle 18:00). In alternativa siamo contattabili
                        anche tramite Facebook o Instagram.
                    </Row>
                </Grid>
                <Grid>
                    <br/>
                    <Row center='md'>
                        <Col md={12}>
                            <Typography use='headline6' style={{'font-weight': 'bold'}}>
                                I nostri brand
                            </Typography>
                        </Col>
                    </Row>
                    <br/>
                    <Slider {...featudedSliderSettingsBrand} className={classes.featuredBrand}>
                        <Card className={classes.cardBrand}>
                            <div className={classes.divImgBrand}>
                                <img alt={'Brand'}
                                     src={'../../../images/brand/brand-1.png'}
                                     className={classes.imgBrand}
                                     data-id={"brand"}
                                     onClick={() => {
                                         history.push(`${routes.search.path}/FILORGA`);
                                         window.scrollTo(0, 0)
                                     }}
                                />
                            </div>
                        </Card>
                        <Card className={classes.cardBrand}>
                            <div key={2} className={classes.divImgBrand} style={{'margin-top': '3%'}}>
                                <img alt={'Brand'}
                                     src={'../../../images/brand/brand-2.png'}
                                     className={classes.imgBrand}
                                     data-id={"brand"}
                                     onClick={() => {
                                         history.push(`${routes.search.path}/HAUSCHKA`);
                                         window.scrollTo(0, 0)
                                     }}
                                />
                            </div>
                        </Card>
                        <Card className={classes.cardBrand}>
                            <div key={3} className={classes.divImgBrand}>
                                <img alt={'Brand'}
                                     src={'../../../images/brand/brand-3.jpg'}
                                     className={classes.imgBrand}
                                     data-id={"brand"}
                                     onClick={() => {
                                         history.push(`${routes.search.path}/NUXE`);
                                         window.scrollTo(0, 0)
                                     }}
                                />
                            </div>
                        </Card>
                        <Card className={classes.cardBrand}>
                            <div key={4} className={classes.divImgBrand} style={{'margin-top': '4%'}}>
                                <img alt={'Brand'}
                                     src={'../../../images/brand/brand-4.jpg'}
                                     className={classes.imgBrand}
                                     data-id={"brand"}
                                />
                            </div>
                        </Card>
                        <Card className={classes.cardBrand}>
                            <div key={5} className={classes.divImgBrand}>
                                <img alt={'Brand'}
                                     src={'../../../images/brand/brand-5.png'}
                                     className={classes.imgBrand}
                                     data-id={"brand"}
                                     onClick={() => {
                                         history.push(`${routes.search.path}/BIODERMA`);
                                         window.scrollTo(0, 0)
                                     }}
                                />
                            </div>
                        </Card>
                        <Card className={classes.cardBrand}>
                            <div key={6} className={classes.divImgBrand}>
                                <img alt={'Brand'}
                                     src={'../../../images/brand/brand-6.png'}
                                     className={classes.imgBrand}
                                     data-id={"brand"}
                                     onClick={() => {
                                         history.push(`${routes.search.path}/BABYGELLA`);
                                         window.scrollTo(0, 0)
                                     }}
                                />
                            </div>
                        </Card>
                        <Card className={classes.cardBrand}>
                            <div key={7} className={classes.divImgBrand}>
                                <img alt={'Brand'}
                                     src={'../../../images/brand/brand-7.png'}
                                     className={classes.imgBrand}
                                     data-id={"brand"}
                                     onClick={() => {
                                         history.push(`${routes.search.path}/VICHY`);
                                         window.scrollTo(0, 0)
                                     }}
                                />
                            </div>
                        </Card>
                        <Card className={classes.cardBrand}>
                            <div key={8} className={classes.divImgBrand}>
                                <img alt={'Brand'}
                                     src={'../../../images/brand/brand-8.png'}
                                     className={classes.imgBrand}
                                     data-id={"brand"}
                                     onClick={() => {
                                         history.push(`${routes.search.path}/LA ROCHE`);
                                         window.scrollTo(0, 0)
                                     }}
                                />
                            </div>
                        </Card>
                    </Slider>

                </Grid>
                <br/>
                <Grid className="newletterGrid"
                      id={'newsletter'}
                >
                    <a id={'newsletter'}
                       name="newsletter"><Row className="newsletter">
                        <Col md={6}>
                            <h5>Vuoi rimanere aggiornato sulle nostre offerte e promozioni?</h5>
                            <h3>ISCRIVITI ALLA NEWSLETTER</h3>
                        </Col>
                        <Col md={6} style={{paddingTop: 50}}>
                            <Mailchimp
                                action='https://farmakon.us5.list-manage.com/subscribe/post?u=3aa98a187eba2620895da315d&amp;id=75a7fa5987'
                                style={{backgroundColor: 'rgb(199,77,6)'}}
                                fields={[
                                    {
                                        name: 'EMAIL',
                                        placeholder: 'Email',
                                        type: 'email',
                                        required: true
                                    }
                                ]}
                                messages={
                                    {
                                        sending: "Iscrivendo alla newsletter...",
                                        success: "Grazie per l'iscrizione!",
                                        error: "C'è stato un errore interno inaspettato",
                                        empty: "Devi scrivere la tua email",
                                        duplicate: "Ci sono già delle iscrizioni per questa email",
                                        button: "Iscriviti!"
                                    }
                                }
                                className='newsletterform'
                            />
                        </Col>
                    </Row>
                        <Row className="newsletter">
                            <Col md={12} style={{paddingBottom: 20}}>
                                <span>La compilazione del form, spontanea e volontaria, comporta l’acquisizione dei dati personali dell’utente. I campi indicati come obbligatori sono necessari per la sola erogazione del servizio previsto: il mancato conferimento dei dati corrispondenti comporta l’impossibilità di usufruire di tale servizio. Inviando i dati personali si dichiara di aver letto ed accettato i termini relativi al trattamento dei dati, contenuti nell’informativa redatta ai sensi dell’art. 13 del GDPR 679/2016.</span>
                            </Col>
                        </Row></a>
                </Grid>
            </div>)
    }
}

const mapStateToProps = function (state) {
    const {products, second_products, totalCount, loadingMore} = state
    const {cart, account, ui} = state


    return {
        products,
        totalCount,
        cart,
        loadingMore,
        ui,
        data: state.product.current.data,
        customer: account.customer,
        imperdibili: state.home.imperdibili,
        imperdibili_2: state.home.imperdibili_2,
        imperdibili_3: state.home.imperdibili_3
    }
}

export default connect(
    mapStateToProps, {
        getProductBySku,
        getHomeData,
        addToCart,
        loadImperdibili,
        loadImperdibili2,
        loadImperdibili3
    })(injectSheet(style)(Home))