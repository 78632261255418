import React from "react";
import { slide as HamburgerMenu } from "react-burger-menu";
import {Link, Route} from "react-router-dom";
import {routes, SITE_NAME} from "../../config/settings";
import variables from "../../assets/style/variables";
import {Grid, Row} from "react-flexbox-grid";
import {magento} from "../../lib/magento";
import Dialog from '@material-ui/core/Dialog';
import { getCategoryTree, setCurrentCategory } from '../../actions'
import {connect} from "react-redux";
import injectSheet from "react-jss";
import { ReactMegaMenu } from 'react-mega-menu'
import IosSearch from 'react-ionicons/lib/IosSearch'
import {style} from "../MegaMenu/style";
import { Icon } from '@rmwc/icon'
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapsible from 'react-collapsible';
import logo from "../../assets/images/logo-FarmaKon.png";
import { showLogin } from '../../actions'
import Login from '../Login'
import debounce from "lodash/debounce";
import history from "../../lib/helper/history";
import TextField from "@material-ui/core/es/TextField/TextField";
import Search from '../../containers/Search'
import KUButton from '../KUButton'
import {BrowserRouter as Router} from 'react-router-dom';
import ReactDOM from 'react-dom'
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";

//import { slide as Menu } from 'react-burger-menu'


class sidebar extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            searchInputDisabled: true,
            textt:'',
            nomeUtente:'',
            flag:false
        }
        this.changeState = this.changeState.bind(this)
        localStorage.setItem("currentPage", 1)
    }

    flagTrue(){
        this.state.flag = true
    }

    flagFalse(){
        this.state.flag = false
    }

    changeState(){
        history.push(routes.login.path)
    }

    handleChange(value){
        this.setState({textt:value})
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            localStorage.setItem("perPage", 16)
            window.location.href=`${routes.search.path}/${this.state.textt}`
        }
    }

    componentWillMount() {
        this.props.getCategoryTree()
    }

    getName(){
        this.setState({
            nomeUtente: localStorage.getItem('nome')
        })
    }

    componentDidMount() {
        this.getName()
    }


    render() {
        const {classes, categoryTree} = this.props
        return (
            <React.Fragment>

                <HamburgerMenu className={"menu"}>

                    <a href={'/'} >
                        <img src={logo} alt={SITE_NAME} width={100} style={{'margin-left':'30px', 'margin-top':'-15px'}} />
                    </a>


                    <Typography
                        style={{'margin-left':'107px', 'margin-top':'-51px', color:'#778899', 'height':'50px'}}
                    >
                        {this.props.magento.customerToken == undefined || this.props.magento.customerToken == null?
                            <a href={routes.login.path}>Accedi/Registrati</a> :
                            <a href={routes.personal.path}>
                                {localStorage.getItem('nome') == ' '? 'Benvenuto' :  "Ciao "+localStorage.getItem('nome') }</a>}
                    </Typography>


                    <ul>
                        {categoryTree && categoryTree.children_data.map((menu) => {
                            return  <React.Fragment> {menu.is_active? <a className={"menu-item"}
                                                                         href={`${routes.categories.path}/${menu.id}/${encodeURIComponent(menu.name.replace(/\s/g, '-').toLowerCase())}`}
                                                                         onClick={() => {
                                                                             localStorage.setItem("perPage", 16)
                                                                         }}
                                                                        >
                                {menu.name}
                                    {menu.children_data == null || menu.children_data == ''? this.flagFalse() : this.flagTrue()}
                                    { this.state.flag && <Collapsible trigger={<ExpandMore/>}>

                                <li>
                                    <ul>
                                        {menu.children_data.map((item) => {
                                            return <a className={"menu-item"}
                                                      href={`${routes.categories.path}/${item.id}/${encodeURIComponent(item.name.replace(/\s/g, '-').toLowerCase())}`}
                                                      onClick={() => {
                                                          localStorage.setItem("perPage", 16)
                                                      }}>
                                                {item.name}
                                                    {item.children_data == null || item.children_data == ''? this.flagFalse() : this.flagTrue()}
                                                {this.state.flag? false : <React.Fragment><br/></React.Fragment>}
                                                    {this.state.flag && <Collapsible trigger={<ExpandMore/>}><li>
                                                <ul>
                                                    {item.children_data.map((item2) => {
                                                        return <a className={"menu-item"}
                                                                  href={`${routes.categories.path}/${item2.id}/${encodeURIComponent(item2.name.replace(/\s/g, '-').toLowerCase())}`}
                                                                  onClick={() => {
                                                                      localStorage.setItem("perPage", 16)
                                                                  }}
                                                                >
                                                            <li>{item2.name}</li></a>
                                                    }) }
                                                </ul>

                                            </li></Collapsible>}</a>
                                        })}
                                    </ul>
                                </li>
                            </Collapsible>}<hr style={{'width':'90%'}}/></a> : false }</React.Fragment>
                        })}

                    </ul>

                    <div style={{'border':'0', 'width':'117%', 'margin-left':'-9%', 'margin-top':'-9%'}}>
                    <Input
                        type={"text"}
                        placeholder='Inserisci il nome del prodotto'
                        style={{'width':'236px','margin-left':'10px', 'margin-top':'-30px', 'height':'30px', 'font-size':'16px'}}
                        name='search_product'
                        autoComplete='off'
                        onChange={(e) => this.handleChange(e.target.value)}
                        onKeyDown={this._handleKeyDown}

                    />
                        <Typography tabIndex={'0'}
                                  style={{'width': 'fit-content', 'color':'black', 'float': 'right', 'margin-right':'1.5%'}}
                                  disabled={(this.state.textt == '')}  >
                            <a href={`${routes.search.path}/${this.state.textt}`} color={'black'} onClick={()=>{localStorage.setItem("perPage", 16)}}><IosSearch style={{'fill':'black', 'width':'27px', 'height':'28px'}}/></a>
                        </Typography>
                    </div>
                    {this.state.open}
                </HamburgerMenu>
            </React.Fragment>
        )
    }

}


const mapStateToProps = ({ magento, categoryTree , state}) => {
    
    return { magento, categoryTree,}
}

export default connect(
    mapStateToProps,
    { getCategoryTree, setCurrentCategory, showLogin }
)(
    injectSheet(style)(sidebar)
)