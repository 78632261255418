import React from 'react'
import {connect} from 'react-redux'
import injectSheet from 'react-jss'
import {Grid, Col, Row} from 'react-flexbox-grid'
// import { Link } from 'react-router-dom'
import {Icon} from '@rmwc/icon'
import {Typography} from 'rmwc/Typography'
import AddCart from '../../components/AddCart'
import AddCartMobile from '../../components/AddCartMobile'
import {getProductBySku, getProductMedia, addToCart} from '../../actions'
import {productThumb} from '../../lib/product'
import {style} from './style'
import {priceFormat} from "../../lib/format";
import HighlightOffOutlined from '@material-ui/icons/HighlightOffOutlined'
import TagManager from 'react-gtm-module'
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import LocalShippingOutlined from '@material-ui/icons/LocalShippingOutlined'
import SecurityOutlined from '@material-ui/icons/SecurityOutlined'
import LockOutlined from '@material-ui/icons/LockOutlined'

ReactGA.plugin.require('ecommerce');

class Product extends React.Component {
    constructor() {
        super()
        this.state = {
            isDesktop: false
        }
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    updatePredicate() {
        this.setState({isDesktop: window.innerWidth > 620});
    }

    async initializeTagManager() {
        //ReactGA.pageview(window.location.pathname + window.location.search);
        ReactGA.plugin.execute('ecommerce', 'detail', {
            id: this.props.match.params.productSku,
        });
        ReactPixel.track('ViewContent', {
            content_type: 'product',
            currency: 'EUR',
            content_ids: [this.props.match.params.productSku],
            contents: [
                {
                    id: this.props.match.params.productSku,
                    quantity: 1
                }
            ]
        });
        const tagManagerArgs = {
            gtmId: 'GTM-NF73NS7',
            dataLayer: {
                event: 'eec.detail',
                ecommerce: {
                    detail: {
                        actionField: {
                            list: 'View Product Page'
                        },
                        products: [{
                            id: this.props.match.params.productSku,
                        }]
                    }
                }
            }
        }
        console.log(tagManagerArgs)
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: 'eec.detail',
            ecommerce: {
                detail: {
                    actionField: {
                        list: 'View Product Page'
                    },
                    products: [{
                        id: this.props.match.params.productSku,
                    }]
                }
            }
        })
    }

    componentWillMount() {
        window.removeEventListener("resize", this.updatePredicate);
        const _sku = this.props.match.params.productSku
        this.props.getProductBySku(_sku)
    }

    componentDidUpdate() {
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    render() {
        const {classes} = this.props
        const {data} = this.props
        const isDesktop = this.state.isDesktop
        this.initializeTagManager()

        if (!data) {
            return <div style={{textAlign: 'center', minHeight: 400, marginTop: 120}}>
                <svg version='1.1' id='Layer_1' x='0px' y='0px'
                     width='24px' height='30px' viewBox='0 0 24 30'>
                    <rect x='0' y='10' width='4' height='10' fill='#333' opacity='0.2'>
                        <animate attributeName='opacity' attributeType='XML' values='0.2; 1; .2' begin='0s' dur='0.6s'
                                 repeatCount='indefinite'/>
                        <animate attributeName='height' attributeType='XML' values='10; 20; 10' begin='0s' dur='0.6s'
                                 repeatCount='indefinite'/>
                        <animate attributeName='y' attributeType='XML' values='10; 5; 10' begin='0s' dur='0.6s'
                                 repeatCount='indefinite'/>
                    </rect>
                    <rect x='8' y='10' width='4' height='10' fill='#333' opacity='0.2'>
                        <animate attributeName='opacity' attributeType='XML' values='0.2; 1; .2' begin='0.15s'
                                 dur='0.6s' repeatCount='indefinite'/>
                        <animate attributeName='height' attributeType='XML' values='10; 20; 10' begin='0.15s' dur='0.6s'
                                 repeatCount='indefinite'/>
                        <animate attributeName='y' attributeType='XML' values='10; 5; 10' begin='0.15s' dur='0.6s'
                                 repeatCount='indefinite'/>
                    </rect>
                    <rect x='16' y='10' width='4' height='10' fill='#333' opacity='0.2'>
                        <animate attributeName='opacity' attributeType='XML' values='0.2; 1; .2' begin='0.3s' dur='0.6s'
                                 repeatCount='indefinite'/>
                        <animate attributeName='height' attributeType='XML' values='10; 20; 10' begin='0.3s' dur='0.6s'
                                 repeatCount='indefinite'/>
                        <animate attributeName='y' attributeType='XML' values='10; 5; 10' begin='0.3s' dur='0.6s'
                                 repeatCount='indefinite'/>
                    </rect>
                </svg>
            </div>
        }

        const _info = {}
        if (data['custom_attributes']) {
            for (const _attribute of data['custom_attributes']) {
                _info[_attribute['attribute_code']] = _attribute['value']
            }
        }
        let _image
        try {
            _image = productThumb(data) // IMG_URL_BASE + media[0].file
        } catch (error) {
        }

        let _discountPrice = data.custom_attributes
            ? data.custom_attributes.find(f => f.attribute_code === 'special_price')
                ? data.custom_attributes.find(f => f.attribute_code === 'special_price').value
                : false
            : false

        let sconto = _discountPrice ? (((data.price - _discountPrice) / data.price) * 100) : false
        sconto = (sconto != false && sconto > 0) ? sconto.toFixed() : false

        let brand = data.custom_attributes ?
            data.custom_attributes.find(f => f.attribute_code === 'brand') ?
                data.custom_attributes.find(f => f.attribute_code === 'brand').value
                : false
            : false

        let short_description = data.custom_attributes ?
            data.custom_attributes.find(f => f.attribute_code === 'short_description') ?
                data.custom_attributes.find(f => f.attribute_code === 'short_description').value
                : false
            : false

        console.log("CUSTOM ATTRIBUTES")
        console.log(data.custom_attributes)

        let scelti_farmakon = data.custom_attributes ?
            data.custom_attributes.find(f => f.attribute_code === 'sceltifarmakon') ?
                data.custom_attributes.find(f => f.attribute_code === 'sceltifarmakon').value
                : false
            : false

        let flag_scelti_farmakon = scelti_farmakon ? (scelti_farmakon === '4' ? false : true) : false

        if (flag_scelti_farmakon) {
            return (
                <React.Fragment>
                    <img src={"../../../images/header-medical.jpg"}
                         className={classes.headerImage}
                    />
                    <div id='mobile_add_cart'>
                        <AddCartMobile product={data}/>
                    </div>
                    <Grid fluid className={classes.product}>
                        <Row>
                            <Col xs={12} lg={4} className={classes.productImageContainer}>
                                <img className={classes.productImage}
                                     src={_image ? _image : "../../../images/no-img.png"}
                                     alt={data.name}/>
                            </Col>
                            <Col xs={12} lg={4}>
                                <Typography className={classes.productName} use='headline5' tag='div'>
                                    {data.name}
                                </Typography>
                                <Typography className={classes.productMedical} use='body1' tag='div'>
                                    {brand && `Brand: ${brand}`}
                                </Typography>
                                <Typography className={classes.productMedical} use='body1' tag='div'>
                                    SKU: {data.sku}
                                </Typography>
                                <Typography id='prezzo_mobile' use='body1' className={classes.cartActionCost}>
                                    {_discountPrice
                                        ? (_discountPrice >= data.price) ?
                                            <span className={classes.price}>{priceFormat(data.price)}</span> :
                                            <div>
                                                <del style={{'font-size': '100%'}}>{priceFormat(data.price)}</del>
                                                <span className={classes.price}>{priceFormat(_discountPrice)}</span>
                                            </div>
                                        : <span
                                            className={classes.price}>{priceFormat(data.custom_attributes.price)}</span>
                                    }
                                </Typography>
                                {!isDesktop && data.extension_attributes.stock_item.qty < 900 && data.extension_attributes.stock_item.qty > 0 && sconto &&
                                <Typography className={classes.sconto}>Sconto {sconto}%</Typography>}
                                <div className={classes.productMedical}
                                     style={{"display": "flex", "justify-content": "flex-start"}}>
                                    {(data.extension_attributes.stock_item.is_in_stock && data.extension_attributes.stock_item.qty > 0) ?
                                        <div style={{"padding-right": "15px"}}><Icon icon='check' style={{
                                            'color': 'green',
                                            'width': '15px',
                                            'margin-top': '2px'
                                        }}
                                                                                     iconOptions={{strategy: 'ligature'}}/>
                                        </div> :
                                        <div style={{
                                            'display': 'flex',
                                            'justify-content': 'center',
                                            "padding-right": "5px"
                                        }}>
                                            <div style={{"text-align": "center"}}>
                                                <div className={classes.mdiv1}>
                                                    <div className={classes.mdiv}>
                                                        <div className={classes.md}></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    }
                                    {(data.extension_attributes.stock_item.is_in_stock && data.extension_attributes.stock_item.qty > 0)?
                                        <div style={{"margin-top": "auto", "margin-bottom": "auto"}}>Prodotto
                                            disponibile</div> :
                                        <div style={{"margin-top": "auto", "margin-bottom": "auto"}}>Prodotto non
                                            disponibile</div>
                                    }
                                </div>

                                <Row>
                                    {
                                        data.extension_attributes.stock_item.qty <= 10 ?
                                            data.extension_attributes.stock_item.qty > 0 ?
                                                data.extension_attributes.stock_item.qty == 1 ?
                                                    <div style={{
                                                        'border': "1px solid #C74D06",
                                                        "padding": "3px",
                                                        "color": "#C74D06"
                                                    }}><span style={{"font-weight": "bold"}}> Ultimo </span> prodotto
                                                        disponibile in magazzino</div> :
                                                    <div style={{
                                                        'border': "1px solid #C74D06",
                                                        "padding": "3px",
                                                        "color": "#C74D06"
                                                    }}>Ultimi <span
                                                        style={{"font-weight": "bold"}}> {data.extension_attributes.stock_item.qty} </span> prodotti
                                                        disponibili in magazzino</div>
                                                : false
                                            : false
                                    }
                                </Row>

                                {!isDesktop && <Row className={classes.rowsicurezza}>
                                    <Col md={12} className={classes.boxsicurezza}>
                                        <div className={classes.cartHeader}>
                                            <Row className={classes.cartHeaderItem}>
                                                <Col xs={2} className={classes.sicurezzaicone}>
                                                    <LocalShippingOutlined/>
                                                </Col>
                                                <Col xs={10} className={classes.descrizionesicurezza}>
                                                    Spedizione gratuita da €59,90
                                                </Col>
                                            </Row>

                                            <Row className={classes.cartHeaderItem}>
                                                <Col xs={2} className={classes.sicurezzaicone}>
                                                    <SecurityOutlined/>
                                                </Col>
                                                <Col xs={10} className={classes.descrizionesicurezza}>
                                                    Solo prodotti certificati e
                                                    garantiti
                                                </Col>
                                            </Row>

                                            <Row className={classes.cartHeaderItem}>
                                                <Col xs={2} className={classes.sicurezzaicone}>
                                                    <LockOutlined/>
                                                </Col>
                                                <Col xs={10} className={classes.descrizionesicurezza}>
                                                    Pagamenti sicuri. Dati criptati
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>

                                </Row>}
                                <div dangerouslySetInnerHTML={{__html: _info.text_1}}/>
                                <br/>
                            </Col>
                            <Col xs={4} id={'desktop_add_cart'}>
                                <Typography use={"body1"} className={classes.productMedical}>
                                    <AddCart product={data}/>
                                </Typography>
                            </Col>
                        </Row>
                        <br/>
                        <hr/>
                        <br/>
                        <Row>
                            <Col lg={8} xs={12}>
                                {_info.text_2 &&
                                <React.Fragment> <Row use={"headline9"} className={classes.boxDescrizione} tag={'div'}>
                                    <Col xs={2} lg={1}>
                                        <Icon icon="chevron_right"
                                              style={{background: '#111B34', color: 'white', borderRadius: '60px'}}/>
                                    </Col>
                                    <Col xs={10} lg={11}>
                                        <div className={classes.boxDescrizioneSecondario}
                                             dangerouslySetInnerHTML={{__html: _info.text_2}}/>
                                    </Col>
                                </Row>
                                    <hr/>
                                </React.Fragment>}
                                {_info.text_3 &&
                                <React.Fragment><Row use={"headline9"} className={classes.boxDescrizione} tag={'div'}>
                                    <Col xs={2} lg={1}>
                                        <Icon icon="chevron_right"
                                              style={{background: '#111B34', color: 'white', borderRadius: '60px'}}/>
                                    </Col>
                                    <Col xs={10} lg={11}>
                                        <div className={classes.boxDescrizioneSecondario}
                                             dangerouslySetInnerHTML={{__html: _info.text_3}}/>
                                    </Col>
                                </Row>
                                    <hr/>
                                </React.Fragment>}

                                {_info.text_4 && <Row use={"headline9"} className={classes.boxDescrizione} tag={'div'}>
                                    <Col xs={2} lg={1}>
                                        <Icon icon="chevron_right"
                                              style={{background: '#111B34', color: 'white', borderRadius: '60px'}}/>
                                    </Col>
                                    <Col xs={10} lg={11}>
                                        <div className={classes.boxDescrizioneSecondario}
                                             dangerouslySetInnerHTML={{__html: _info.text_4}}/>
                                    </Col>
                                </Row>}
                                <div dangerouslySetInnerHTML={{__html: _info.text_5}}/>
                                <div dangerouslySetInnerHTML={{__html: _info.text_6}}/>
                            </Col>
                            <Col xs={12} lg={4} style={{paddingLeft: '2%'}}>
                                <Row style={{paddingBottom: '2%'}}>
                                    <Col lg={4} xs={4}>
                                        <div className={classes.boxIcon}>
                                            <Icon icon="eco" className={classes.icon}/>
                                        </div>
                                    </Col>
                                    <Col lg={8} xs={8}>
                                        <Typography use={"headline10"} style={{fontWeight: 'bold'}}>
                                            Componenti
                                        </Typography>
                                        <div className={classes.boxRight}
                                             dangerouslySetInnerHTML={{__html: _info.text_7}}/>
                                    </Col>
                                </Row>

                                <Row style={{paddingBottom: '2%'}}>
                                    <Col lg={4} xs={4}>
                                        <div className={classes.boxIcon}>
                                            <Icon icon="local_offer" className={classes.icon}/>
                                        </div>
                                    </Col>
                                    <Col lg={8} xs={8}>
                                        <Typography use={"headline10"} style={{fontWeight: 'bold'}}>
                                            Impiego
                                        </Typography>
                                        <div className={classes.boxRight}
                                             dangerouslySetInnerHTML={{__html: _info.text_8}}/>
                                    </Col>
                                </Row>

                                <Row style={{paddingBottom: '2%'}}>
                                    <Col lg={4} xs={4}>
                                        <div className={classes.boxIcon}>
                                            <Icon icon="collections" className={classes.icon}/>
                                        </div>
                                    </Col>
                                    <Col lg={8} xs={8}>
                                        <Typography use={"headline10"} style={{fontWeight: 'bold'}}>
                                            Contenuto
                                        </Typography>
                                        <div className={classes.boxRight}
                                             dangerouslySetInnerHTML={{__html: _info.text_9}}/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Grid>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div id='mobile_add_cart'>
                        <AddCartMobile product={data}/>
                    </div>
                    <Grid fluid className={classes.product}>
                        {console.log(data.custom_attributes)}
                        {console.log(data.custom_attributes.brand)}
                        <Row>
                            <Col xs={12} md={3} lg={4} className={classes.productImageContainer}>
                                <img className={classes.productImage}
                                     src={_image ? _image : "../../../images/no-img.png"}
                                     alt={data.name}/>
                            </Col>
                            <Col xs={12} md={3} lg={4}>
                                <Typography className={classes.productName} use='body1' tag='div'>
                                    {data.name}
                                </Typography>
                                <Typography className={classes.productBrand} use='body1' tag='div'>
                                    {brand && `Brand: ${brand}`}
                                </Typography>
                                <Typography className={classes.productCode} use='body1' tag='div'>
                                    SKU: {data.sku}
                                </Typography>
                                <Typography id='prezzo_mobile' use='body1' className={classes.cartActionCost}>
                                    {_discountPrice
                                        ? (_discountPrice >= data.price) ?
                                            <span className={classes.price}>{priceFormat(data.price)}</span> :
                                            <div>
                                                <del style={{'font-size': '100%'}}>{priceFormat(data.price)}</del>
                                                <span className={classes.price}>{priceFormat(_discountPrice)}</span>
                                            </div>
                                        : <span
                                            className={classes.price}>{priceFormat(data.custom_attributes.price)}</span>
                                    }
                                </Typography>
                                {!isDesktop && data.extension_attributes.stock_item.qty < 900 && data.extension_attributes.stock_item.qty > 0 && sconto &&
                                <Typography className={classes.sconto}>Sconto {sconto}%</Typography>}
                                <div style={{"display": "flex", "justify-content": "flex-start"}}>
                                    {(data.extension_attributes.stock_item.is_in_stock && data.extension_attributes.stock_item.qty > 0) ?
                                        <div style={{"padding-right": "15px"}}><Icon icon='check' style={{
                                            'color': 'green',
                                            'width': '15px',
                                            'margin-top': '2px'
                                        }}
                                                                                     iconOptions={{strategy: 'ligature'}}/>
                                        </div> :
                                        <div style={{
                                            'display': 'flex',
                                            'justify-content': 'center',
                                            "padding-right": "5px"
                                        }}>
                                            <div style={{"text-align": "center"}}>
                                                <div className={classes.mdiv1}>
                                                    <div className={classes.mdiv}>
                                                        <div className={classes.md}></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>}
                                    {(data.extension_attributes.stock_item.is_in_stock && data.extension_attributes.stock_item.qty > 0) ?
                                        <div style={{'margin-bottom': "auto", "margin-top": "auto"}}>Prodotto
                                            disponibile</div> :
                                        <div style={{'margin-bottom': "auto", "margin-top": "auto"}}>Prodotto non
                                            disponibile</div>
                                    }
                                </div>
                                {short_description && <Typography use='body1' tag='div'
                                                                  dangerouslySetInnerHTML={{__html: short_description}}/>}
                                <Row>
                                    {
                                        data.extension_attributes.stock_item.qty <= 10 ?
                                            data.extension_attributes.stock_item.qty > 0 ?
                                                data.extension_attributes.stock_item.qty == 1 ?
                                                    <div style={{
                                                        'border': "1px solid #C74D06",
                                                        "padding": "3px",
                                                        "color": "#C74D06"
                                                    }}><span style={{"font-weight": "bold"}}> Ultimo </span> prodotto
                                                        disponibile in magazzino</div> :
                                                    <div style={{
                                                        'border': "1px solid #C74D06",
                                                        "padding": "3px",
                                                        "color": "#C74D06"
                                                    }}>Ultimi <span
                                                        style={{"font-weight": "bold"}}> {data.extension_attributes.stock_item.qty} </span> prodotti
                                                        disponibili in magazzino</div>
                                                : false
                                            : false
                                    }
                                </Row>
                                {!isDesktop && <Row className={classes.rowsicurezza}>
                                    <Col md={12} className={classes.boxsicurezza}>
                                        <div className={classes.cartHeader}>
                                            <Row className={classes.cartHeaderItem}>
                                                <Col xs={2} className={classes.sicurezzaicone}>
                                                    <LocalShippingOutlined/>
                                                </Col>
                                                <Col xs={10} className={classes.descrizionesicurezza}>
                                                    Spedizione gratuita da €59,90
                                                </Col>
                                            </Row>

                                            <Row className={classes.cartHeaderItem}>
                                                <Col xs={2} className={classes.sicurezzaicone}>
                                                    <SecurityOutlined/>
                                                </Col>
                                                <Col xs={10} className={classes.descrizionesicurezza}>
                                                   Solo prodotti certificati e
                                                        garantiti
                                                </Col>
                                            </Row>

                                            <Row className={classes.cartHeaderItem}>
                                                <Col xs={2} className={classes.sicurezzaicone}>
                                                    <LockOutlined/>
                                                </Col>
                                                <Col xs={10} className={classes.descrizionesicurezza}>
                                                    Pagamenti sicuri. Dati criptati
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>

                                </Row>}
                            </Col>
                            <Col xs={6} md={6} lg={4} id='desktop_add_cart'>
                                <AddCart product={data}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Typography className={classes.productDescriptionTitle} use='body1' tag='div'>
                                    DESCRIZIONE
                                </Typography>
                                <div dangerouslySetInnerHTML={{__html: _info.description}}/>
                            </Col>
                        </Row>
                    </Grid>
                </React.Fragment>
            )
        }
    }
}

const mapStateToProps = function (state) {
    return {
        media: state.product.current.media,
        data: state.product.current.data
    }
}

export default connect(mapStateToProps, {getProductBySku, getProductMedia, addToCart})(injectSheet(style)(Product))
