import { magento } from '../magento'
import { initMagento, getCart, setCurrentCustomer } from '../../actions'

export const onAppStart = async (store) => {
  store.dispatch(initMagento())

  const customerToken = await window.localStorage.getItem('customerToken')

  // TODO: create checkTokenValidity in magento.lib

  if (customerToken) {
    try {
      magento.setCustomerToken(customerToken)
      const customer = await magento.customer.getCurrentCustomer()

      if (customer.message) {
        window.localStorage.removeItem('customerToken')
        magento.setCustomerToken(false)
      } else {
        store.dispatch(setCurrentCustomer(customer))
      }
    } catch (error) {
      console.log('onAppStart -> unable to retrieve current customer', error)
    }
  }
  store.dispatch(getCart())
}
