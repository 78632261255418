import variables from '../../assets/style/variables'

export const style = {
  breadcrumpsBar: {
    paddingTop: '12px',
    paddingBottom: '12px',
    backgroundColor: variables.lightGrey,
    color: variables.grey,
    fontSize: '0.9em'

  }

}
