import variables from '../../assets/style/variables'

export const style = {

  profileData: {
    '& .mdc-text-field__input': {
      width: '100%',
      paddingTop: '20px !important'
    },
    '& button': {
      marginRight: 20,
      minWidth: 200
    }
  },

  profileDataTitle: {
    marginTop: 10,
    marginBottom: 30
  },

  profileDataFooter: {
    marginTop: 30,
    marginBottom: 30
  },

  submit: {
    marginTop: '50px',
    marginBottom: '10px',
    fontSize: '13px',
    color: variables.black + ' !important',
    backgroundColor: variables.white + ' !important',
    border: '2px solid ' + variables.black
  },

  '@media (max-width: 600px)' :{
    profileData:{
      'margin-top':'5%',
      'margin-bottom':'5%'
    }
  }

}
