import _ from 'lodash'
import { getPriceFromChildren } from '../lib/helper/product'
import {
  HOME_SCREEN_DATA, LOAD_IMPERDIBILI,
  MAGENTO_GET_FEATURED_PRODUCTS,
  MAGENTO_UPDATE_FEATURED_CONF_PRODUCT
} from '../actions/types'
import {magento} from "../lib/magento";
import {ADMIN_TYPE} from "../lib/magento/types";

const INITIAL_STATE = {
  slider: [],
  featuredProducts: {},
  imperdibili: [],
  imperdibili_2: [],
  imperdibili_3: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_IMPERDIBILI : {
      state.imperdibili.push(action.payload)
      state.imperdibili.forEach(e => {
        let _discountPrice = e.custom_attributes
            ? e.custom_attributes.find(f => f.attribute_code === 'special_price')
                ? e.custom_attributes.find(f => f.attribute_code === 'special_price').value
                : false
            : false
        let price = e.price
        let sconto = _discountPrice ? (((price - _discountPrice) / price)*100) : false
        sconto = (sconto != false && sconto > 0)? sconto.toFixed() : false
        e.sconto = sconto
      });
      return { ...state, imperdibili: state.imperdibili }
    }

    case "LOAD_IMPERDIBILI_2" : {
      if(!action.payload.message){
        state.imperdibili_2.push(action.payload)
        state.imperdibili_2.forEach(e => {
          let _discountPrice = e.custom_attributes
              ? e.custom_attributes.find(f => f.attribute_code === 'special_price')
                  ? e.custom_attributes.find(f => f.attribute_code === 'special_price').value
                  : false
              : false
          let price = e.price
          let sconto = _discountPrice ? (((price - _discountPrice) / price)*100) : false
          sconto = (sconto != false && sconto > 0)? sconto.toFixed() : false
          e.sconto = sconto
        });
      }
      return { ...state, imperdibili_2: state.imperdibili_2 }
    }

    case "LOAD_IMPERDIBILI_3" : {
      if(!action.payload.message){
        state.imperdibili_3.push(action.payload)
        state.imperdibili_3.forEach(e => {
          let _discountPrice = e.custom_attributes
              ? e.custom_attributes.find(f => f.attribute_code === 'special_price')
                  ? e.custom_attributes.find(f => f.attribute_code === 'special_price').value
                  : false
              : false
          let price = e.price
          let sconto = _discountPrice ? (((price - _discountPrice) / price)*100) : false
          sconto = (sconto != false && sconto > 0)? sconto.toFixed() : false
          e.sconto = sconto
        });
      }
      return { ...state, imperdibili_3: state.imperdibili_3 }
    }

    case MAGENTO_UPDATE_FEATURED_CONF_PRODUCT: {
      const { sku, children } = action.payload

      let featuredProducts = {}
      _.forEach(state.featuredProducts, (products, categoryId) => {
        const items = products.items.map(product => {
          if (product.sku === sku) {
            return {
              ...product,
              children,
              price: getPriceFromChildren(children)
            }
          }
          return product
        })

        featuredProducts = { ...featuredProducts, [categoryId]: { ...products, items } }
      })

      return { ...state, featuredProducts }
    }
    case MAGENTO_GET_FEATURED_PRODUCTS: {
      const { categoryId, products } = action.payload
      const featuredProducts = { ...state.featuredProducts, [categoryId]: products }
      return { ...state, featuredProducts }
    }
    case HOME_SCREEN_DATA:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
