import React from 'react'
import {Grid} from "@material-ui/core";
import {Col, Row} from "react-flexbox-grid";
import Typography from "@material-ui/core/Typography";
import GoogleMapReact from 'google-map-react'
import IosPinOutlined from 'react-ionicons/lib/IosPinOutline'
import IosCallOutlined from 'react-ionicons/lib/IosCallOutline'
import IosPrintOutlined from 'react-ionicons/lib/IosPrintOutline'


class Contattaci extends React.Component {

    constructor(){
        super()

    }

    static defaultProps = {
        center: {
            lat: 43.849575,
            lng: 10.978889
        },
        zoom: 18
    };

    render() {
        const AnyReactComponent = ({ text }) => <div>{text}</div>;
        return(
            <Grid class={'container-fluid'} style={{'padding-left':'16px', 'padding-right':'16px', 'margin-left':'auto','margin-right':'auto','width':'80%', 'margin-top':'2%','margin-bottom':'2%'}}>
                <Row>
                    <Col xs={6}>
                        <div style={{ height: '40vh', width: '80%' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: 'AIzaSyBwVxwQ371YI-xvVDjQoCa_HYySj9prShg' }}
                                defaultCenter={this.props.center}
                                defaultZoom={this.props.zoom}
                            >
                                <AnyReactComponent
                                    lat={43.849575}
                                    lng={10.978889}
                                    text="FarmaKon"
                                />
                            </GoogleMapReact>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <Row>
                        <Typography use="headline5" style={{'font-size':'1.875rem'}}>Dove siamo</Typography>
                        </Row>
                        <Row>
                            <Typography use="headline5" style={{'font-size':'100%'}}>Pharmamed S.R.L.</Typography>
                        </Row>
                        <br/>
                        <Row><IosPinOutlined style={{'width':'30px', 'height':'50px'}}/>
                            <span style={{'display':'inline-block', 'padding-left':'15px'}}>
                                <b>Indirizzo</b><br/>
                                <span>Via Montalbano 65, Pistoia 51100</span>
                            </span>
                        </Row>

                        <Row>
                            <IosCallOutlined style={{'width':'30px', 'height':'50px'}}/>
                            <br/>
                            <span style={{'display':'inline-block', 'padding-left':'15px'}}>
                                <b>Telefono</b><br/>
                                <span>+39 0573 4477444</span>
                            </span>
                        </Row>

                        <Row>
                            <IosPrintOutlined style={{'width':'30px', 'height':'50px'}}/>
                            <br/>
                            <span style={{'display':'inline-block', 'padding-left':'15px'}}>
                                <b>Fax</b><br/>
                                <span>+39 0573 985599</span>
                            </span>
                        </Row>
                    </Col>
                </Row>
            </Grid>
        )
    }

}

export default Contattaci