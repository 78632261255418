import {ADMIN_TYPE, CUSTOMER_TYPE, GUEST_TYPE} from '../../types'
import { MAGENTO_STORE_VIEW } from '../../../../config/settings'
import {magento} from "../../index";
import {MAGENTO_ADD_TO_CART, MAGENTO_CREATE_CART} from "../../../../actions/types";
import {addToCartLoading} from "../../../../actions";

export default magento => {
  return {
    getCurrentCustomer: () => {
      // GET /rest/V1/customers/me
      return new Promise((resolve, reject) => {
        const path = '/V1/customers/me'

        magento
          .get(path, undefined, undefined, CUSTOMER_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    /* 🍁code */
    changePassword: (currentPassword, newPassword) => {
      // PUT /V1/customers/me/password

      return new Promise((resolve, reject) => {
        const path = '/V1/customers/me/password'

        let data = {
          'currentPassword': currentPassword,
          'newPassword': newPassword
        }

        magento
          .put(path, data, CUSTOMER_TYPE)
          .then(response => {
            resolve(response)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    getCustomerCart: () => {
      // GET /V1/carts/mine
      return new Promise((resolve, reject) => {
        const path = '/V1/carts/mine'

        magento
          .get(path, undefined, undefined, CUSTOMER_TYPE)
          .then(data => {
            resolve({
              cartId: data.id,
              items: data.items || [],
              quote: data
            })
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    createCart: customerId => {
      return new Promise((resolve, reject) => {
        const path = `/V1/customers/${customerId}/carts`

        magento
          .post(path, undefined, CUSTOMER_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    addItemToCart: item => {
      // POST /V1/carts/mine/items
      return new Promise((resolve, reject) => {
        const path = '/V1/carts/mine/items'

        magento
          .post(path, item, CUSTOMER_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    changeCartItemQty: (cartId, item, operation) => {
      // PUT /V1/carts/mine/items/${item.item_id}

      return new Promise((resolve, reject) => {
        const path = `/V1/carts/mine/items/${item.item_id}`

        let itemData = {
          'cartItem': {
            item_id: item.item_id,
            qty: operation === 'inc' ? item.qty + 1 : item.qty - 1,
            quote_id: cartId
          }
        }

        magento
          .put(path, itemData, CUSTOMER_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    /* 🍁code */
    removeItemFromCart: (cartId, itemId) => {
      // DELETE /V1/carts/mine/items
      return new Promise((resolve, reject) => {
        const path = `/V1/carts/mine/items/${itemId}`

        magento
          .delete(path, undefined, CUSTOMER_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    addCartBillingAddress: (address) => {
      // POST /V1/carts/mine/billing-address
      return new Promise((resolve, reject) => {
        const path = '/V1/carts/mine/billing-address'

        magento
          .post(path, address, CUSTOMER_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    cartEstimateShippingMethods: (address) => {
      // POST /V1/carts/mine/estimate-shipping-methods"
      return new Promise((resolve, reject) => {
        const path = '/V1/carts/mine/estimate-shipping-methods'

        magento
          .post(path, address, CUSTOMER_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    addCartShippingInfo: (address) => {
      // POST /V1/carts/mine/shipping-information
      return new Promise((resolve, reject) => {
        const path = '/V1/carts/mine/shipping-information'

        magento
          .post(path, address, CUSTOMER_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    /* 🍁code */
    addCartShippingAddress: (customer, address) => {
      // PUT /V1/customers/me
      return new Promise((resolve, reject) => {
        const path = '/V1/customers/me'

        let data = {
          'customer': {
            ...customer,
            addresses: customer.addresses.concat({
              ...address,
              customer_id: customer.id,
              firstname: customer.firstname,
              lastname: customer.lastname,
              telephone: '00-00000'
            })
          }
        }

        magento
          .put(path, data, CUSTOMER_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    /* 🍁code */
    updateCustomerProfile: (customer) => {
      // PUT /V1/customers/me
      return new Promise((resolve, reject) => {
        const path = '/V1/customers/me'

        let data = {
          customer
        }

        magento
          .put(path, data, CUSTOMER_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    getCartShippingMethods: (
      address = {
        'address': {
          'city': '',
          'country_id': 'IT',
          'postcode': ''
        }
      }
    ) => {
      // GET /V1/carts/mine/estimate-shipping-methods
      return new Promise((resolve, reject) => {
        const path = '/V1/carts/mine/estimate-shipping-methods'

        magento
          .post(path, address, CUSTOMER_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    getCartPaymentMethods: () => {
      // GET /V1/carts/mine/payment-methods
      return new Promise((resolve, reject) => {
        const path = '/V1/carts/mine/payment-methods'

        magento
          .get(path, undefined, undefined, CUSTOMER_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    placeCartOrderOri: (payment) => {
      // PUT /V1/carts/mine/order
      return new Promise((resolve, reject) => {
        const path = '/V1/carts/mine/order'

        magento
          .put(path, payment, CUSTOMER_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    placeCartOrder: (payment) => {
      // PUT /V1/carts/mine/payment-information
      return new Promise((resolve, reject) => {
        const path = '/V1/carts/mine/payment-information'

        magento
          .post(path, payment, CUSTOMER_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    getOrderInformation: (order_id) => {
      // GET /rest/V1/customers/me
      return new Promise((resolve, reject) => {
        const path = '/V1/orders/'+order_id
        localStorage.setItem("order_id", order_id)
        magento
            .get(path, undefined, undefined, ADMIN_TYPE)
            .then(data => {
              resolve(data)
            })
            .catch(e => {
              console.log(e)
              reject(e)
            })
      })
    },

    /* 🍁code */
    convertGuestCartoToCustomer: customerId => {
      // PUT /V1/guest-carts/
      return new Promise(async (resolve, reject) => {
        const localStorageCartId = await window.localStorage.getItem('mage_guest_cartId')
        if (!localStorageCartId) return false

        const deleteCartPath = `/V1/carts/`

        await magento
          .post(deleteCartPath, undefined, ADMIN_TYPE)
          .then(data => {
            console.log('POST')
            console.log(data)
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })

        const convertDataPath = '/V1/guest-carts/' + localStorageCartId

        const convertData = {
          customerId: customerId,
          storeId: 2/*magento.getStoreId()*/
        }

        magento
          .put(convertDataPath, convertData, CUSTOMER_TYPE)
          .then(data => {
            console.log('PUT')
            console.log(data)
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    convertGuestCartoToCustomer3: customerId => {
      // PUT /V1/guest-carts/
      return new Promise(async (resolve, reject) => {
        const localStorageCartId = await window.localStorage.getItem('mage_guest_cartId')
        if (!localStorageCartId) return false
        let items_guest
        const deleteCartPath = `/V1/guest-carts/${localStorageCartId}`
        await magento
            .get(deleteCartPath, undefined,undefined, ADMIN_TYPE)
            .then(data => {
              console.log(data)
              items_guest = data.items
            })
            .catch(e => {
              console.log(e)
              reject(e)
            })

        const deleteCartPathCoupon = `/V1/guest-carts/${localStorageCartId}/totals`
        let coupon
        await magento
            .get(deleteCartPathCoupon, undefined,undefined, ADMIN_TYPE)
            .then(data => {
              console.log(data)
              coupon = data.coupon_code
            })
            .catch(e => {
              console.log(e)
              reject(e)
            })

        const cartId = await magento.admin.getCart(customerId)
        let path = `/V1/carts/mine/items`
        let i
        for(i=0; i<items_guest.length;i++){
          let data = {
            cartItem:{
              sku: items_guest[i].sku,
              qty: items_guest[i].qty,
              quote_id: cartId
            }
          }
          await magento
                .post(path, data, CUSTOMER_TYPE)
                .then(data => {
                  console.log(data)
                })
          }

        if(coupon != "" || coupon != undefined){
          let path2 = `/V1/carts/${cartId}/coupons/${coupon}`
          magento.put(path2, GUEST_TYPE)
              .then(data =>{
                    console.log(data? 'true':'false')
                  }
              )
              .catch(e =>
                  console.log(e)
              )
        }
      })

    }


  }
}
