import variables from '../../assets/style/variables'

export const style = {

    cartContainer: {
        border: '1px solid ' + variables.lightGrey,
        backgroundColor: variables.whiteTone,
        marginBottom: 10
    },

    mdiv1: {
        "width": "16px",
        "height": "16px",
        "float": "left",
        "border": "1px solid #F83636",
        "borderRadius": "50px",
        "margin-top": "3px"
    },
    mdiv: {
        "height": "16px",
        "width": "1px",
        "marginLeft": "8px",
        "backgroundColor": "#F83636",
        "transform": "rotate(45deg)",
        "ZIndex": "1"
    },
    md: {
        "height": "16px",
        "width": "1px",
        "backgroundColor": "#F83636",
        "transform": "rotate(90deg)",
        "ZIndex": "2"
    },

    mdiv1_closex: {
        "width": "20px",
        "float": "right",
        "height": "20px",
        "border": "1px solid #808080",
        "marginTop": "3px",
        "borderRadius": "50px"
    },
    mdiv_closex: {
        "width": "1px",
        "height": "12px",
        "ZIndex": "1",
        "transform": "rotate(\n45deg\n)",
        "marginLeft": "10px",
        "backgroundColor": "#808080",
        "marginTop": "4px"
    },
    md_closex: {
        "width": "1px",
        "height": "12px",
        "ZIndex": "2",
        "transform": "rotate(\n90deg\n)",
        "backgroundColor": "#808080",
        "marginTop": "4px",
        "marginLeft": "0px"
    },

    immagineAggiungiAlCarrello: {
        "height": "20rem",
        "display": "flex",
        "minHeight": "12rem",
        "maxHeight": "20rem",
        "alignItems": "center",
        "justifyContent": "center"
    },

    sconto: {
        "display": "flex", "float": "right", "marginTop": "-25px", "paddingBottom": "15px", "color":"#F64084"
    },

    price: {
        fontSize: '20px'
    },

    cart: {
        // width: '90%',
        padding: '3rem 2rem'
    },
    add: {
        width: '100%',
        height: '1rem'
    },

    cartAction: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 20,
        '& i': {
            cursor: 'pointer'
        }
    },

    cartActionQuantity: {
        backgroundColor: variables.white,
        border: '1px solid ' + variables.black,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100px',
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 3,
        paddingBottom: 3,
        userSelect: 'none'
    },

    cartActionCost: {
        fontWeight: 'bold',
        fontSize: '1.1em',
        paddingTop: 3,
        '& del': {
            fontSize: '0.7em',
            fontWeight: 'normal',
            marginRight: 10
        }
    },

    cartHeader: {
        display: 'flex',
        marginTop: 10
    },

    cartHeaderItem: {
        marginTop: 20,
        marginLeft: 5,
        marginRight: 5,
        textAlign: 'left',
        '& .mdc-typography--body1': {
            display: 'block',
            textAlign: 'left',
            fontSize: 12,
            lineHeight: '16px'
        },
        '& i': {
            display: 'block',
            marginBottom: 5,
            marginLeft: 3,
            marginRight: 3
        }
    },

    cartFooter: {
        borderTop: '1px solid ' + variables.black,
        marginTop: 10,
        paddingTop: 10,
        display: 'flex',
        '& .mdc-typography--body1': {
            display: 'block',
            textAlign: 'left',
            fontSize: 12,
            lineHeight: '16px'
        },
        '& i': {
            marginRight: 5
        }
    }
}
