import React from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Icon, Typography } from 'rmwc'
import { Grid, Col, Row } from 'react-flexbox-grid'
import injectSheet from 'react-jss'
import { removeFromCart, changeCartItemQty, getGuestCartShippingMethods } from '../../actions/api'
import { priceFormat } from '../../lib/format'
import { productThumb } from '../../lib/product'
import KUButton from '../KUButton'
import { style } from './style'
import DeleteOutlined from '@material-ui/icons/DeleteOutline'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import TextField from "../ProfileAddresses";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";

class CartProduct extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      isDesktop: false,
      open: false
    }
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 620 });
  }

  componentWillMount () {
    window.removeEventListener("resize", this.updatePredicate);
  }


  handleRemoveFromCart (product) {
    this.props.removeFromCart({ cart: this.props.cart, item: product })
    this.initializeTagManager(product)
  }

  handleChangeQtyForItem (product, operation) {
    this.props.changeCartItemQty({ cart: this.props.cart, item: product, operation: operation })
    this.initializeTagManager(product, operation)
  }

  async initializeTagManager(product, operation) {
    let quantita=0
    let action
    if(operation == 'inc'){
      quantita= parseInt(product.qty)+1
      action = 'eec.add'
    }else{
      quantita= parseInt(product.qty)-1
      action = 'eec.remove'
    }
    ReactPixel.track('AddToCart', {
      content_type: 'product',
      currency: 'EUR',
      contents: [
        {
          id: product.sku,
          quantity: quantita
        }
      ]
    });
    const tagManagerArgs = {
      gtmId: 'GTM-NF73NS7',
      dataLayer: {
        event: action,
        ecommerce: {
          remove: {
            actionField: {
              list: 'Shopping cart'
            },
            products: [{
              id: product.sku,
              quantity: quantita
            }]
          }
        }
      }
    }
    console.log(tagManagerArgs)
    TagManager.initialize(tagManagerArgs)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: action,
      ecommerce: {
        remove: {
          actionField: {
            list: 'Shopping cart'
          },
          products: [{
            id: product.sku,
            quantity: quantita
          }]
        }
      }
    })

  }

  openDialog () {
    this.setState({open: true})
  }

  handleClose = () => {
    this.setState({
      open:false
    })
  };


  render () {
    const { classes, product, key } = this.props
    const isDesktop = this.state.isDesktop
    let imgSrc
    var img = product.extension_attributes.image_url
    var result = img.indexOf("placeholder")
    if(result != -1) {
      imgSrc = "../../../images/no-img.png"
    } else {
      imgSrc = product.extension_attributes.image_url? product.extension_attributes.image_url : productThumb(product)
    }

    if (isDesktop){
      return (
          <div className='cartListItems' style={{'text-align':'left'}}>
            <Row key={key}>
              <Col md={2} className={classes.productThumb}>
                {console.log(imgSrc)}
                { !imgSrc && <div style={{ width: '150%', minHeight: '80px', backgroundColor: 'grey' }} /> }
                {  imgSrc && <img src={imgSrc} />}
              </Col>
              <Col md={10}>
                <Row>
                  <Col md={8}>

                    <div id={'nome'}>{product.name}</div>
                    <br />
                    <div>
                      {product.sku}
                    </div>
                  </Col>
                  <Col md={4} align='right'>
                    <div className={classes.cartActionQuantity}>
                      <Icon icon='remove' iconOptions={{ strategy: 'ligature' }}
                            onClick={() => this.handleChangeQtyForItem(product, 'dec')}
                      />
                      <span>{product.qty}</span>
                      <Icon icon='add' iconOptions={{ strategy: 'ligature' }}
                            onClick={() => this.handleChangeQtyForItem(product, 'inc')}
                      />
                    </div>
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col md={8}>
                    <Typography use='body2' id={'elimina'}>
                      <KUButton clickHandler={() => this.openDialog()}><DeleteOutlined/> Elimina</KUButton>
                      <Dialog
                          title="Elimina articolo"
                          open={this.state.open}
                          onClose={this.handleClose}

                      >
                        <form action="/" method="POST" onSubmit={(e) => { e.preventDefault(); this.handleClose(); } }>
                          <div style={{'width':'412px', 'height':'150px', 'text-align':'center'}}><br/>
                            <p style={{'font-weight':'semi-bold'}}>Sicuro di voler eliminare l'articolo dal carrello?</p>
                            <br/>
                            <KUButton style={{backgroundColor:'white', color: '#111B34', 'margin-right':'20px','border':'2px solid' }}>Annulla</KUButton>
                            <KUButton style={{backgroundColor:'#111B34', color: '#fff'}} clickHandler={() => this.handleRemoveFromCart(product)}>Elimina</KUButton>
                          </div>
                        </form>
                      </Dialog>
                    </Typography>
                  </Col>

                  <Col md={4} align='right'>
                    <Typography use='body1' className={classes.cartActionCost} id={'quantita'}>
                      { priceFormat(product.price * product.qty) }
                    </Typography>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div key={1 + key * 1 + key} className={classes.cartItemSeparator} />
          </div>
      )} else {
      return (
          <div  className='cartListItems' >
            <Grid container direction={'column'} spacing={5} justify={'space-between'} alignItems={'flex-start'}>
              <Row key={key} spacing={5} >
                <Col xs={6} style={{'margin-left':'-15%'}}>{ !imgSrc && <div/> }
                  { imgSrc && <img src={imgSrc} alt={`${product.name}`} className={classes.productThumb} /> }
                </Col>
                <Col xs={6} style={{'margin-left':'15%', 'margin-top':'7%'}}>
                  <div id={product.name}>{product.name}
                    <br></br><br></br>
                    {product.sku}
                    <br></br><br></br>
                    <div onClick={() => this.handleRemoveFromCart(product)}><KUButton><DeleteOutlined/> Elimina</KUButton></div>
                  </div></Col>
              </Row>
              <br/>
              <Row>
                <Col xs={6} style={{'margin-left':'-15%'}}>
                  <div>
                    { priceFormat(product.price * product.qty) }
                  </div>
                </Col>
                <Col xs={6} style={{'margin-left':'15%', 'margin-top':'-2%'}}>
                  <div className={classes.cartActionQuantity}>
                    <Icon icon='remove' iconOptions={{ strategy: 'ligature' }}
                          onClick={() => this.handleChangeQtyForItem(product, 'dec')}
                    />
                    <span>{product.qty}</span>
                    <Icon icon='add' iconOptions={{ strategy: 'ligature' }}
                          onClick={() => this.handleChangeQtyForItem(product, 'inc')}
                    />

                  </div>
                </Col>
              </Row>
            </Grid>
          </div>)
    }
  }
}

const mapStateToProps = function (state) {
  const category = state.category.current
  const { products, totalCount, loadingMore } = state.category
  const { cart, account } = state
  const canLoadMoreContent = products.length < totalCount

  return {
    category,
    products,
    totalCount,
    cart,
    canLoadMoreContent,
    loadingMore,
    customer: account.customer
  }
}

export default connect(mapStateToProps, { removeFromCart, changeCartItemQty, getGuestCartShippingMethods })(injectSheet(style)(CartProduct))