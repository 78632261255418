import React from 'react'
import {Grid} from "@material-ui/core";
import {Row} from "react-flexbox-grid";
import Typography from "@material-ui/core/Typography";


class TempiDiSpedizione extends React.Component {

    constructor() {
        super()
    }

    render() {
        return (
            <Grid class={'container-fluid'} style={{
                'padding-left': '16px',
                'padding-right': '16px',
                'margin-left': 'auto',
                'margin-right': 'auto',
                'width': '80%',
                'margin-top': '2%',
                'margin-bottom': '2%'
            }}>
                <Row>
                    <Typography use="headline5" style={{'font-size': '1.875rem'}}>Tempi e spese di
                        spedizione</Typography>
                </Row>
                <br/>
                <Row>
                    <text>La merce acquistata su Farmakon.it viene consegnata su tutto il territorio italiano e
                        all'Estero tramite il servizio di <text style={{'font-weight': 'bold'}}>GLS</text>.
                    </text>
                    <br/><br/>
                    <text>
                        La spedizione avviene entro le 24 / 48 h.
                        <br/>
                        <text style={{'font-weight': 'bold'}}>Spese di spedizione € 5.90 per ordini inferiori a 59,90€
                            oltre è gratuita.
                        </text>
                    </text>
                </Row>
            </Grid>
        )
    }

}

export default TempiDiSpedizione