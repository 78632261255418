import variables from '../../assets/style/variables'

export const style = {
  signUp: {
    width: '100%',
    maxWidth: "1200px !important",
    position: 'relative',
    backgroundColor: variables.white,
    color: variables.black,
    padding: '20px',
    textAlign: 'center',

    '& .mdc-text-field__input': {
      paddingTop: '15px !important'
    }
  },

  agreementsBlock: {
    textAlign: 'left',
    '& .mdc-form-field': {
      marginBottom: 15
    }
  },

  mdiv1_closex: {
    "width": "20px",
    "float": "right",
    "height": "20px",
    "border": "1px solid #808080",
    "marginTop": "3px",
    "borderRadius": "50px"
  },
  mdiv_closex: {
    "width": "1px",
    "height": "12px",
    "ZIndex": "1",
    "transform": "rotate(\n45deg\n)",
    "marginLeft": "10px",
    "backgroundColor": "#808080",
    "marginTop": "4px"
  },
  md_closex: {
    "width": "1px",
    "height": "12px",
    "ZIndex": "2",
    "transform": "rotate(\n90deg\n)",
    "backgroundColor": "#808080",
    "marginTop": "4px",
    "marginLeft": "0px"
  },

  spunte : {
    "margin-bottom":"15px"
  },

  submit: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '10px'
  },

  indietrobutton : {
      color: "#16782A !important",
      "background-color":"#fff !important",
      "border":"1px solid #16782A !important",
      "width":"105%"
  },

  '@media only screen and (max-width: 600px)' :{
    signUp: {
      width: '100%',
      position: 'relative',
      backgroundColor: variables.white,
      color: variables.black,
      textAlign: 'center',
      '& .mdc-text-field__input': {
        paddingTop: '15px !important'
      }
    },

".container" : {
      "width":"85% !important"
}
  }

}
