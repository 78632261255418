import { IMG_URL_BASE } from '../config/settings'
import {magento} from "./magento";
import {ADMIN_TYPE} from '../lib/magento/types'
import axios from 'axios'

export function productThumb(product) {
  if (!product) {
    return ''
  } else if (product.media_gallery_entries && product.media_gallery_entries[0] && product.custom_attributes
    && product.custom_attributes.filter(
          (key) => key['attribute_code'] === 'image'
      )[0]
    && product.custom_attributes.filter(
          (key) => key['attribute_code'] === 'image'
      )[0].value != product.media_gallery_entries[0].file
  ) {
      if(product.media_gallery_entries[0].types.length === 0){
          return IMG_URL_BASE+ product.custom_attributes.filter(
              (key) => key['attribute_code'] === 'image'
          )[0].value
      } else {
          return IMG_URL_BASE + product.media_gallery_entries[0].file
      }
  } else if (product.media_gallery_entries && product.media_gallery_entries[0]) {
    return IMG_URL_BASE + product.media_gallery_entries[0].file
  } else if (product.extension_attributes && product.extension_attributes.image_url) {
    return IMG_URL_BASE + product.media[0].file
  } else if (product.media) {
    return IMG_URL_BASE + product.media[0].file
  } else if (product.custom_attributes) {
    let _srcToRet = ''

    try {
      _srcToRet = IMG_URL_BASE + product.custom_attributes.filter(
          (key) => key['attribute_code'] === 'thumbnail'
      )[0].value || ''
    } catch (error) {

    }
    return _srcToRet
  } else {
    return ''
  }
}
