export const MAGENTO_INIT = 'magento_init'
export const MAGENTO_GET_COUNTRIES = 'magento_get_countries'

export const MAGENTO_GET_CATEGORY_TREE = 'magento_get_category_tree'
export const MAGENTO_CURRENT_CATEGORY = 'magento_current_category'
export const MAGENTO_GET_CATEGORY_PRODUCTS = 'magento_get_category_products'
export const MAGENTO_GET_FEATURED_PRODUCTS = 'magento_get_featured_products'
export const MAGENTO_LOAD_MORE_CATEGORY_PRODUCTS = 'magento_load_more_category_products'

export const MAGENTO_UPDATE_CONF_PRODUCT = 'magento_update_conf_product'
export const MAGENTO_UPDATE_FEATURED_CONF_PRODUCT = 'magento_update_featured_conf_product'
export const MAGENTO_GET_CONF_OPTIONS = 'magento_get_conf_options'
export const MAGENTO_PRODUCT_ATTRIBUTE_OPTIONS = 'magento_product_attribute_options'
export const MAGENTO_CURRENT_PRODUCT = 'magento_current_product'
export const MAGENTO_GET_PRODUCT = 'magento_get_product'
export const MAGENTO_GET_PRODUCT_MEDIA = 'magento_get_product_media'

export const MAGENTO_CREATE_CART = 'magento_create_cart'
export const MAGENTO_ADD_TO_CART_LOADING = 'magento_add_to_cart_loading'
export const MAGENTO_ADD_TO_CART = 'magento_add_to_cart'
export const MAGENTO_GET_CART = 'magento_get_cart'
export const MAGENTO_ADD_CART_BILLING_ADDRESS = 'magento_add_cart_billing_address'
export const MAGENTO_GET_CART_SHIPPING_METHODS = 'magento_get_cart_shipping_methods'
export const MAGENTO_ADD_SHIPPING_TO_CART = 'magento_add_shipping_to_cart'
export const MAGENTO_GET_CART_PAYMENT_METHODS = 'magento_get_cart_payment_methods'
export const MAGENTO_CART_ITEM_PRODUCT = 'magento_cart_item_product'
export const MAGENTO_PLACE_GUEST_CART_ORDER = 'magento_place_guest_cart_order'
export const MAGENTO_REMOVE_FROM_CART_LOADING = 'magento_remove_from_cart_loading'
export const MAGENTO_CHANGE_CART_ITEM_QTY_LOADING = 'magento_change_cart_item_qty_loading'
export const MAGENTO_REMOVE_FROM_CART = 'magento_remove_from_cart'
export const MAGENTO_CHANGE_CART_ITEM_QTY = 'magento_change_cart_item_qty'

export const NAVIGATION_GO_TO = 'navigation_go_to_screen'

export const MAGENTO_CREATE_CUSTOMER = 'magento_checkout_create_customer'
export const MAGENTO_CURRENT_CUSTOMER = 'magento_current_customer'

export const LOAD_IMPERDIBILI = 'load_imperdibili'

export const MAGENTO_AUTH = 'magento_auth'
export const MAGENTO_LOGOUT = 'magento_logout'
export const MAGENTO_AUTH_ERROR = 'magento_auth_error'
export const MAGENTO_AUTH_LOADING = 'magento_auth_loading'
export const MAGENTO_PASSWORD_RESET_LOADING = 'magento_password_reset_loading'
export const MAGENTO_SUCCESS_MESSAGE = 'magento_success_message'

export const UI_PRODUCT_QTY_INPUT = 'ui_product_qty_input'
export const UI_PRODUCT_UPDATE_OPTIONS = 'ui_product_update'
export const UI_CHECKOUT_SHIPPING_SELECTED = 'ui_checkout_shipping_selected'
export const UI_CHECKOUT_PAYMENT_SELECTED = 'ui_checkout_payment_selected'
export const UI_CHECKOUT_CUSTOMER_NEXT_LOADING = 'ui_checkout_customer_next_loading'
export const UI_CHECKOUT_UPDATE = 'ui_checkout_update'
export const UI_CHECKOUT_ACTIVE_SECTION = 'ui_checkout_active_section'

export const UI_SIGNIN_SHOW = 'ui_show_sign_up'
export const UI_LOGIN_SHOW = 'ui_login_show'
export const UI_SHOW_CART_SPINNER = 'ui_showspinner'

export const HOME_SCREEN_DATA = 'home_screen_data'
