import 'material-components-web/dist/material-components-web.min.css'
import 'react-input-range/lib/css/index.css'
import PropTypes from 'prop-types';
import React, { Suspense, lazy } from 'react'
import { Helmet } from 'react-helmet'
import injectSheet from 'react-jss'
import { RMWCProvider, ThemeProvider } from 'rmwc'
import { Grid } from 'react-flexbox-grid'
import { Switch, Route, withRouter } from 'react-router-dom'
import { routes, SEO_TITLE_PREFIX } from '../../config/settings'
import MobileDrawer from '../../components/Mobile/Drawer/MobileDrawer'
import Navbar from '../../components/NavBar'
import NavBarMini from '../../components/NavBarMini'
import Breadcrumbs from '../../components/Breadcrumbs'
import MegaMenu from '../../components/MegaMenu'
import Home from '../Home'
import Footer from '../Footer'
import Modal from '../Modal'
import Category from '../Category'
import Cart from '../Cart'
import Profile from '../Profile'
import Product from '../Product'
import Search from '../Search'
import indexResetPassword from '../Pages/indexResetPassword'
import resetPassword from '../../components/ResetPassword'
import chiSiamo from '../Pages'
import ProfileCoupons from '../../components/ProfileCoupons'
import TempiDiSpedizione from '../Pages/indexTempiDiSpedizione'
import MetodiDiPagamento from '../Pages/indexMetodiDiPagamento'
import RecessoGaranzia from '../Pages/indexRecessoGaranzia'
import NoteLegali from '../Pages/indexNoteLegali'
import Contattaci from '../Pages/indexContattaci'
import ProfileOrders from '../../components/ProfileOrders'
import ProfileData from '../../components/ProfileData'
import ProfileAddresses from '../../components/ProfileAddresses'
import ProfilePassword from '../../components/ProfilePassword'
import Brand from '../Brand/index'
import Login from '../../components/Login'
import { style } from './style'
import WebFont from 'webfontloader'
import GoogleTagManager from '../GoogleTagManager'


const Checkout = lazy(() => import('../Checkout'))

WebFont.load({
  google: {
    families: ['Roboto:300,500,700', 'Material Icons']
  }
})

class App extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      drawer: false,
      login: false,
      isDesktop: false
    }
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  drawerToggle () {
    this.setState({ ...this.state, drawer: !this.state.drawer })
  }

  loginToggle () {
    this.setState({ ...this.state, login: !this.state.login })
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 620 });
  }

  componentWillMount () {
    window.removeEventListener("resize", this.updatePredicate);
  }

  render () {
    const { classes } = this.props
    const isDesktop = this.state.isDesktop
    const BreadcrumbsWithNav = withRouter(Breadcrumbs)
    return (
      <RMWCProvider>
        <GoogleTagManager gtmId={"GTM-NF73NS7"}/>

        <Helmet>
          <title>{ SEO_TITLE_PREFIX }</title>
        </Helmet>

        <ThemeProvider options={{
          primary: '#000',
          secondary: 'grey',
          onPrimary: '#dedede',
          textPrimaryOnBackground: '#000'
          // textPrimaryOnBackground: '#dedede'
        }}>

          <Switch>
            <Route exact path='/checkout/:step(ordine-completato)?/' render={
              () => <Suspense fallback={<div>Loading...</div>}><Checkout /></Suspense>
            } />
            <Route path='*' render={() => <div>
              {/* <MobileDrawer modalOpen={this.state.drawer} toggle={this.drawerToggle} /> */}
              <div className='body'>
                {/* TOPBAR */}
                {isDesktop? <NavBarMini drawerToggle={this.drawerToggle} login={this.loginToggle} /> : false}
                <Navbar />
                <MegaMenu />
                <BreadcrumbsWithNav />
                <Grid fluid className={classes.body}>

                  <Route
                    path='/'
                    exact
                    component={Home} />

                  <Route
                    path={`${routes.categories.path}/:categoryId/:categoryName`}
                    exact={false}
                    strict={false}
                    sensitive={false}
                    component={Category} />

                  <Route
                    path={`${routes.search.path}/:searchCriteria`}
                    exact={false}
                    strict={false}
                    sensitive={false}
                    component={Search} />

                  <Route
                    path={routes.cart.path}
                    exact={false}
                    strict={false}
                    sensitive={false}
                    component={Cart} />

                  <Route
                      path={routes.chiSiamo.path}
                      exact={false}
                      strict={false}
                      sensitive={false}
                      component={chiSiamo} />

                  <Route
                      path={`${routes.brand.path}/:brandName`}
                      exact={false}
                      strict={false}
                      sensitive={false}
                      component={Brand} />

                  <Route
                      path={routes.coupons.path}
                      exact={false}
                      strict={false}
                      sensitive={false}
                      component={ProfileCoupons} />

                  <Route
                      path={routes.noteLegali.path}
                      exact={false}
                      strict={false}
                      sensitive={false}
                      component={NoteLegali} />

                  <Route
                      path={routes.login.path}
                      exact={false}
                      strict={false}
                      sensitive={false}
                      component={Login} />

                  <Route
                      path={routes.resetPassword.path}
                      exact={false}
                      strict={false}
                      sensitive={false}
                      component={resetPassword} />


                  <Route
                      path={routes.contattaci.path}
                      exact={false}
                      strict={false}
                      sensitive={false}
                      component={Contattaci} />

                  <Route
                      path={routes.tempiDiSpedizione.path}
                      exact={false}
                      strict={false}
                      sensitive={false}
                      component={TempiDiSpedizione} />

                  <Route
                      path={routes.metodiPagamento.path}
                      exact={false}
                      strict={false}
                      sensitive={false}
                      component={MetodiDiPagamento} />

                  <Route
                      path={routes.recessoGaranzia.path}
                      exact={false}
                      strict={false}
                      sensitive={false}
                      component={RecessoGaranzia} />

                  <Route
                      path={routes.orders.path}
                      exact={false}
                      strict={false}
                      sensitive={false}
                      component={ProfileOrders} />

                  <Route
                      path={routes.changepassword.path}
                      exact={false}
                      strict={false}
                      sensitive={false}
                      component={ProfilePassword} />

                  <Route
                      path={routes.addresses.path}
                      exact={false}
                      strict={false}
                      sensitive={false}
                      component={ProfileAddresses} />

                  <Route
                      path={routes.profiledata.path}
                      exact={false}
                      strict={false}
                      sensitive={false}
                      component={ProfileData} />

                  <Route
                      path={routes.indexResetPassword.path}
                      exact={false}
                      strict={false}
                      sensitive={false}
                      component={indexResetPassword} />

                  <Route
                    path={`${routes.product.path}/:productSku`}
                    exact={false}
                    strict={false}
                    sensitive={false}
                    component={Product} />

                  <Route
                    path={routes.personal.path}
                    exact
                    strict
                    sensitive={false}
                    component={Profile} />

                </Grid> { /* body-cmp */ }

                <Footer />
                <Modal />
              </div> { /* body */ }
            </div>} />
          </Switch>

        </ThemeProvider>
      </RMWCProvider>
    )
  }
}

export default injectSheet(style)(App)
