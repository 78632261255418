import {magento} from '../lib/magento'

const initialState = {
    brand_products: [],
    loadingMore: false,
    totalCount: 0
};

const reducer = (state = initialState, action) => {
    const newState = {...state};

    if(action.type === "MAGENTO_GET_BRAND_PRODUCTS"){
        newState.brand_products = action.payload.items
        newState.totalCount = action.payload.items.length
        newState.loadingMore = false
    }
    if(action.type === 'MAGENTO_LOADING_MORE_BRAND_PRODUCTS'){
        newState.loadingMore = action.payload
    }

    return newState
}


export default reducer;