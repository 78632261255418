import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import injectSheet from 'react-jss'
import { style } from './style'
import {routes, SITE_NAME} from "../../config/settings";
import logo from "../../assets/images/logo-FarmaKon.png";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";



class Footer extends React.Component {

  constructor(props){
    super()
    this.state = {
      isDesktop: false
    }
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 620 });
  }

  componentWillMount () {
    window.removeEventListener("resize", this.updatePredicate);
  }

  render () {
    const {classes} = this.props
    const isDesktop = this.state.isDesktop
    return (
      <div>
        <Grid fluid className={classes.footerContainer} style={{'margin-left':isDesktop?false:'2%'}}>
          <Grid fluid className={classes.footer}>
            <Row>
              <Col xs={6} md={3}>
                <a href={routes.home.path}>
                  <div className={classes.logoMobile}>
                    <img src={logo} alt={SITE_NAME} width={isDesktop? '50%' : '100%'}/>
                  </div>
                </a>
                <p>
                  Ci prendiamo cura della tua persona.
                  <br/>
                  Siamo professionisti sempre attenti alle esigenze dei nostri clienti.
                </p>
              </Col>
              <Col xs={6} md={3}>
                <h5>INFO UTILI</h5>
                <a href={routes.chiSiamo.path}>Chi siamo</a><br/>
                {/*<Link to='/'>Dicono di noi</Link><br/>*/}
                <a href={routes.contattaci.path}>Contattaci</a><br />
                <a href={routes.noteLegali.path}>Note legali</a><br />

                <h5>CONTATTI</h5>
            Sede Legale:<br />
            Via Montalbano 65<br />
            51100 Pistoia<br />
                <br />
            Telefono: +39 0573 4477444<br />
            Fax: +39 0573 985599<br />
              </Col>
              <Col xs={6} md={3}>
                <h5>BISOGNO DI AIUTO?</h5>

                {localStorage.getItem('customerToken') === ''? <a href={'/'}>Il mio account</a> : <Link to={routes.personal.path}>Il mio account</Link>}<br />
                {/*<Link to='/'>Domande frequenti</Link><br />
                <Link to='/'>Guida all'acquisto</Link><br />*/}
                <a href={routes.metodiPagamento.path}>Metodi di pagamento</a><br />
                <a href={routes.tempiDiSpedizione.path}>Tempi di spedizione</a><br />
                <a href={routes.recessoGaranzia.path}>Recesso e garanzia</a><br />
                <a target="_blank" rel="noopener noreferrer" href={"https://www.gls-italy.com/it/servizi-per-destinatari/dettaglio-spedizione"}>Dov'è il mio ordine</a><br />
                {/*<Link to='/'>Coupon sconto</Link><br />*/}
                <br />
              </Col>
              <Col xs={6} md={3}>
                <h5>PUOI PAGARE CON</h5>
                <img style={{'max-width':'100%'}} src={"../../../images/footer/metodi_pagamento.png"}/>
                <p><b>Contrassegno</b></p>
                <h5>SPEDIAMO CON</h5>
                <img src={"../../../images/footer/gls.png"} />
              </Col>
            </Row>
          </Grid>
        </Grid> { /* body-cmp */ }

        <Grid fluid className={classes.bar}>
          <Row style={{'padding-left':'2%'}}>
            <Col md={12}>  Pharmamed SRL | Via Montalbano 65 - Pistoia PT - 51100 | Partita IVA 01882360470 |  <a href={"javascript:showPrivacy(24)"}>Privacy Policy</a> | <a href={"javascript:showCookie(24)"}>Cookie Policy</a> </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}

export default injectSheet(style)(Footer)
