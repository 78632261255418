import variables from '../../assets/style/variables'

export const style = {

  cartContainer: {
    border: '1px solid ' + variables.lightGrey,
    backgroundColor: variables.whiteTone,
    marginBottom: 10
  },

  cart: {
    // width: '90%',
    padding: '3rem 2rem'
  },
  add: {
    width: '80%',
    height: '1rem',
    marginBottom: '-2px'
  },

  mdiv1_closex: {
    "width": "20px",
    "float": "right",
    "height": "20px",
    "border": "1px solid #808080",
    "marginTop": "3px",
    "borderRadius": "50px"
  },
  mdiv_closex: {
    "width": "1px",
    "height": "12px",
    "ZIndex": "1",
    "transform": "rotate(\n45deg\n)",
    "marginLeft": "10px",
    "backgroundColor": "#808080",
    "marginTop": "4px"
  },
  md_closex: {
    "width": "1px",
    "height": "12px",
    "ZIndex": "2",
    "transform": "rotate(\n90deg\n)",
    "backgroundColor": "#808080",
    "marginTop": "4px",
    "marginLeft": "0px"
  },

  cartAction: {
    display: 'inline-block',
    justifyContent: 'space-between',
    marginBottom: 0,
    '& i': {
      cursor: 'pointer'
    },
    width: '20%',
    minHeight: 56
  },

  cartActionQuantity: {
    backgroundColor: variables.white,
    border: '1px solid ' + variables.black,
    display: 'inline-block',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    userSelect: 'none',
    textAlign: 'center',
    height: 42
  },

  cartActionCost: {
    fontWeight: 'bold',
    fontSize: '1.1em',
    paddingTop: 3,
    '& del': {
      fontSize: '0.7em',
      fontWeight: 'normal',
      marginRight: 10
    }
  },

  cartHeader: {
    display: 'flex',
    marginTop: 10
  },

  cartHeaderItem: {
    marginTop: 20,
    marginLeft: 5,
    marginRight: 5,
    textAlign: 'left',
    '& .mdc-typography--body1': {
      display: 'block',
      textAlign: 'left',
      fontSize: 12,
      lineHeight: '16px'
    },
    '& i': {
      display: 'block',
      marginBottom: 5,
      marginLeft: 3,
      marginRight: 3
    }
  },

  cartFooter: {
    borderTop: '1px solid ' + variables.black,
    marginTop: 10,
    paddingTop: 10,
    display: 'flex',
    '& .mdc-typography--body1': {
      display: 'block',
      textAlign: 'left',
      fontSize: 12,
      lineHeight: '16px'
    },
    '& i': {
      marginRight: 5
    }
  }
}
