import variables from '../../assets/style/variables'

export const style = {
    productContainer: {
        border: '1px solid #7d7d7d',
        cursor: 'pointer',
        height: '25rem',

        '& .mdc-button:disabled': {
            width: '100%',
            borderRadius: 0,
            color: variables.white,
            backgroundColor: variables.grey,
            minHeight: '44px',
            fontWeight: 800,
            textAlign: 'center',
            padding: '0 20px',
            cursor: "none"
        },

        '& .mdc-button:disabled:hover': {
            cursor: "not-allowed"
        }
    },

    mdiv1: {
        "width": "16px",
        "height": "16px",
        "float": "left",
        "border": "1px solid #F83636",
        "borderRadius": "50px",
        "margin-top":"3px"
    },
    mdiv: {
        "height": "16px",
        "width": "1px",
        "marginLeft": "8px",
        "backgroundColor": "#F83636",
        "transform": "rotate(45deg)",
        "ZIndex": "1"
    },
    md: {
        "height": "16px",
        "width": "1px",
        "backgroundColor": "#F83636",
        "transform": "rotate(90deg)",
        "ZIndex": "2"
    },

    mdiv1_closex: {
        "width": "20px",
        "float": "right",
        "height": "20px",
        "border": "1px solid #808080",
        "marginTop": "3px",
        "borderRadius": "50px"
    },
    mdiv_closex: {
        "width": "1px",
        "height": "12px",
        "ZIndex": "1",
        "transform": "rotate(\n45deg\n)",
        "marginLeft": "10px",
        "backgroundColor": "#808080",
        "marginTop": "4px"
    },
    md_closex: {
        "width": "1px",
        "height": "12px",
        "ZIndex": "2",
        "transform": "rotate(\n90deg\n)",
        "backgroundColor": "#808080",
        "marginTop": "4px",
        "marginLeft": "0px"
    },

    sconto: {
        "display": "flex", "justify-content": "center", "color":"#F64084", "margin-top":"-15px", "padding-bottom":'4px'
    },

    aggiungiButton: {
        fontWeight: '600',
        width: '-webkit-fill-available'
    },

    addButton: {
        backgroundColor: '#008837',
        'width': '-webkit-fill-available'
    },

    "& addButton:disabled": {
        backgroundColor: 'grey !important',
        'width': '-webkit-fill-available'
    },

    bold: {
        fontWeight: 700
    },

    productName: {
        minHeight: '3rem',
        display: 'block',
        marginBottom: '4px',
        padding: '6px',
        color: '#707070',
        fontSize: '16px !important'
    },

    productPrice: {
        minHeight: '2rem',
        display: 'block',
        marginBottom: '4px',
        padding: '0 6px',
        color: variables.black,
        textAlign: 'center'
    },

    productThumb: {
        minHeight: '12rem',
        maxHeight: '15rem',
        justifyContent: 'center',
        alignItems: 'center',
        height: '15rem',
        display: 'flex',
        overflow: 'hidden',

        '& img': {
            maxWidth: '100%',
            maxHeight: '100%'
        }
    }

}
