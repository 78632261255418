import React from 'react'
import injectSheet from 'react-jss'
import {Grid, Col, Row} from 'react-flexbox-grid'
import {Link} from 'react-router-dom'
import {List, ListItem} from '@rmwc/list'
import {Icon} from '@rmwc/icon'
import ProfilePassword from '../../components/ProfilePassword'
import ProfileOrders from '../../components/ProfileOrders'
import ProfileAddresses from '../../components/ProfileAddresses'
import ProfileData from '../../components/ProfileData'
import ProfileCoupons from '../../components/ProfileCoupons'
import history from '../../lib/helper/history'
import {connect} from 'react-redux'
import {logout} from '../../actions'
import {Typography} from 'rmwc/Typography'
import {style} from './style'
import {magento} from "../../lib/magento";
import {ADMIN_TYPE} from "../../lib/magento/types";
import {routes} from '../../config/settings'
import {getCustomerOrders} from "../../actions";

class Profile extends React.Component {
    constructor() {
        super()
        this.state = {
            section: 'data',
            isDesktop: false
        }
        this.updatePredicate = this.updatePredicate.bind(this);
    }


    updatePredicate() {
        this.setState({isDesktop: window.innerWidth > 620});
    }

    async componentWillMount() {
        setTimeout(async () => {
            if (!this.props.account.customer) {
                history.push('/')
            } else {
                await this.props.getCustomerOrders(this.props.account.customer.email)
            }
        }, 2000)
        window.removeEventListener("resize", this.updatePredicate);
    }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }


    handleLogout() {
        this.props.logout()
        history.push('/')
    }

    render() {
        const {classes} = this.props
        localStorage.setItem("cap", this.props.account.customer ? this.props.account.customer.addresses[0].postcode : false)
        let isDesktop = this.state.isDesktop
        return (
            <Grid fluid className={classes.profile}>
                <Row style={{'padding-left': isDesktop ? false : '6%'}}>
                    <Col xs={isDesktop ? 4 : 12} className={classes.left}>
                        <List>
                            <ListItem className={classes.menu} selected={this.state.section === 'data'}
                                      onClick={isDesktop ? () => {
                                          this.setState({section: 'data'})
                                      } : () => {
                                          history.push(routes.profiledata.path)
                                      }}
                            >
                                Dati personali
                                <Icon className={classes.iconRight} icon='chevron_right'
                                      iconOptions={{strategy: 'ligature'}}/>
                            </ListItem>
                            <ListItem className={classes.menu}
                                      onClick={isDesktop ? () => {
                                          this.setState({section: 'orders'})
                                      } : () => {
                                          history.push(routes.orders.path)
                                      }}
                            >
                                Ordini effettuati
                                <Icon className={classes.iconRight} icon='chevron_right'
                                      iconOptions={{strategy: 'ligature'}}/>
                            </ListItem>
                            <ListItem className={classes.menu}
                                      onClick={isDesktop ? () => {
                                          this.setState({section: 'addresses'})
                                      } : () => {
                                          history.push(routes.addresses.path)
                                      }}
                            >
                                Indirizzi di spedizione
                                <Icon className={classes.iconRight} icon='chevron_right'
                                      iconOptions={{strategy: 'ligature'}}/>
                            </ListItem>
                            <ListItem className={classes.menu}
                                      onClick={isDesktop ? () => {
                                          this.setState({section: 'coupon'})
                                      } : () => {
                                          history.push(routes.coupons.path)
                                      }}
                            >
                                Coupon di sconto
                                <Icon className={classes.iconRight} icon='chevron_right'
                                      iconOptions={{strategy: 'ligature'}}/>
                            </ListItem>
                            <ListItem className={classes.menu}
                                      onClick={isDesktop ? () => {
                                          this.setState({section: 'password'})
                                      } : () => {
                                          history.push(routes.changepassword.path)
                                      }}
                            >
                                Cambia Password
                                <Icon className={classes.iconRight} icon='chevron_right'
                                      iconOptions={{strategy: 'ligature'}}/>
                            </ListItem>
                        </List>
                        <div className={classes.logout}>
                            <Typography use='body1'>Vuoi effettuare il Logout?</Typography>
                            <br/>
                            <a href={'/'} onClick={() => this.handleLogout()}>
                                <Typography use='BUTTON'>ESCI</Typography>
                            </a>
                        </div>
                    </Col>
                    <Col xs={isDesktop ? 8 : 0} className={classes.right}
                         style={{'display': isDesktop ? false : 'none'}}>
                        {
                            (() => {
                                switch (this.state.section) {
                                    case 'orders':
                                        return <ProfileOrders
                                            orders={this.props.orders}
                                        />
                                    case 'data':
                                        return <ProfileData/>
                                    case 'addresses':
                                        return <ProfileAddresses/>
                                    case 'password':
                                        return <ProfilePassword/>
                                    case 'coupon':
                                        return <ProfileCoupons/>
                                    default:
                                        return <React.Fragment/>
                                }
                            })()
                        }
                    </Col>
                </Row>
            </Grid>
        )
    }
}

const mapStateProps = (state) => (
    {
        account: state.account,
        orders: state.account.orders
    }
)

export default connect(
    mapStateProps,
    {
        logout, getCustomerOrders
    }
)(injectSheet(style)(Profile))
