import React, {Component} from 'react'
import injectSheet from 'react-jss'
import {Grid, Col, Row} from 'react-flexbox-grid'
import {connect} from 'react-redux'
// import { Icon } from '@rmwc/icon'
// import { Typography } from '@rmwc/typography'
import {TextField} from '@rmwc/textfield'
import {Checkbox} from '@rmwc/checkbox'
import {signUp} from '../../actions'
import {Icon} from '@rmwc/icon'
import {showSignIn, auth} from '../../actions'
import {style} from './style'
import {Typography} from 'rmwc'
import {Select} from '@rmwc/select'
import KUButton from '../KUButton'
import {PWD_REGEX_VALIDATION, routes} from '../../config/settings';
import history from '../../lib/helper/history'
import {magento} from '../../lib/magento'
import Dialog from "@material-ui/core/Dialog";
import Link from "@material-ui/core/Link";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import * as ReactDOM from "react-dom";
import Loader from "../../containers/Home";
import axios from 'axios'


class SignUp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errore: false,
            email: '',
            notifica_sanitaria: true,
            default_billing: '',
            firstname: '',
            lastname: '',
            streetName: '',
            streetNumber: '',
            city: '',
            regionCode: '',
            telephone: '',
            postcode: '',
            password: '',
            confirmPassword: '',
            dob: '',
            privatoOazienda: 0,
            pIva: '',
            sdi: '',
            pec: '',
            company: '',
            codiceFiscale: '',
            newsletter: false,
            informativa_1: false,
            informativa_2: false,
            website_id: 2,
            opensignin: true,
            open: false,
            loading: false,
            isDesktop: false,
            step: 1
        }
        this.updatePredicate = this.updatePredicate.bind(this);
        // this.props = props
        //this.handleClose = this.handleClose.bind(this)
    }

    updatePredicate() {
        this.setState({isDesktop: window.innerWidth > 620});
    }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    componentWillMount() {
        window.removeEventListener("resize", this.updatePredicate);
    }

    async handleSubmit() {
        this.setState({loading: true})
        let valid = await this.checkCodiceFiscale()
        const {
            email, password, firstname, lastname, streetName, confirmPassword,
            streetNumber, city, regionCode, telephone, postcode, dob, privatoOazienda, pIva,
            sdi, pec, codiceFiscale, company, newsletter, informativa_1, informativa_2, website_id, notifica_sanitaria
        } = this.state
        if (!this.state.informativa_2 ||
            (this.state.privatoOazienda === 1 ? !this.state.codiceFiscale && !this.state.pIva : false) ||
            (this.state.privatoOazienda != 1 ? !this.state.codiceFiscale : false)) {
            this.state.errore = true
            const element = <p style={{'color': 'red', 'font-size': '14px'}}>* Compila tutti i campi del form</p>
            ReactDOM.render(element, document.getElementById('errore'));
        } else if ((this.state.password !== this.state.confirmPassword) ||
            this.state.password === '' ||
            !this.state.password.match(PWD_REGEX_VALIDATION)) {
            this.state.errore = true
            const element = <p style={{'color': 'red', 'font-size': '14px'}}>* Le password inserite non coincidono</p>
            ReactDOM.render(element, document.getElementById('errore'));
        } else if (!valid) {
            this.state.errore = true
            const element = <p style={{'color': 'red', 'font-size': '14px'}}>* Codice fiscale non valido</p>
            ReactDOM.render(element, document.getElementById('errore'));
        } else {
            this.state.errore = false
        }

        if (!this.state.errore) {
            localStorage.setItem("cap", postcode)
            const customer = {
                customer: {
                    website_id,
                    email,
                    firstname,
                    lastname,
                    dob,
                    taxvat: pIva,
                    addresses: [
                        {
                            default_billing: true,
                            firstname,
                            lastname,
                            street: [
                                streetName,
                                streetNumber
                            ],
                            country_id: 'IT',
                            city,
                            region_id: 0,
                            region: {
                                region_code: regionCode,
                                region: regionCode,
                                region_id: 0
                            },
                            telephone,
                            postcode
                        }
                    ],
                    custom_attributes: [
                        {
                            attribute_code: 'newsletter',
                            value: newsletter ? 1 : 0
                        },
                        {
                            attribute_code: 'privato_azienda',
                            value: privatoOazienda === 0 ? 1 : 0
                        },
                        {
                            attribute_code: 'tipo_azienda',
                            value: privatoOazienda
                        },
                        {
                            attribute_code: 'informativa_1',
                            value: informativa_1 ? 1 : 0
                        },
                        {
                            attribute_code: 'informativa_2',
                            value: informativa_2 ? 1 : 0
                        },
                        {
                            attribute_code: 'sdi',
                            value: sdi
                        },
                        {
                            attribute_code: 'notifica_sanitaria',
                            value: notifica_sanitaria ? 1 : 0
                        },
                        {
                            attribute_code: 'pec',
                            value: pec
                        },
                        {
                            attribute_code: 'codice_fiscale',
                            value: codiceFiscale
                        },
                        {
                            attribute_code: 'azienda',
                            value: company
                        }
                    ]
                },
                password
            }
            localStorage.setItem("signUp", "")
            await this.props.signUp(customer)
            if (localStorage.getItem("signUp") === "false") {
                this.setState({open: true})
            } else {
                this.handleClose();
            }
            history.push(routes.personal.path)
        }
    }

    handleClose = () => {
        this.props.showSignIn(false)
        this.setState({
            opensignin: false
        })
    };

    change() {
        this.setState({open: !this.state.open})
        this.handleClose()
    }

    indietro(step) {
        this.setState({step: step})
    }

    async procedi(current, step) {
        const {
            email, password, firstname, lastname, streetName, confirmPassword,
            streetNumber, city, regionCode, telephone, postcode, dob, privatoOazienda, pIva,
            sdi, pec, codiceFiscale, company, newsletter, informativa_1, informativa_2, website_id, notifica_sanitaria
        } = this.state
        this.setState({loading: true, errore: false})
        switch (current) {
            case 1:
                if (!firstname || !lastname || !password || !email || !dob || !confirmPassword) {
                    this.state.errore = true
                    const element = <p style={{'color': 'red', 'font-size': '14px'}}>* Tutti i campi sono
                        obbligatori</p>
                    ReactDOM.render(element, document.getElementById('errore'));
                } else if ((this.state.password !== this.state.confirmPassword) ||
                    this.state.password === '') {
                    this.state.errore = true
                    const element = <p style={{'color': 'red', 'font-size': '14px'}}>* Le password inserite non
                        coincidono</p>
                    ReactDOM.render(element, document.getElementById('errore'));
                } else if (!this.state.password.match(PWD_REGEX_VALIDATION)) {
                    this.state.errore = true
                    const element = <p style={{'color': 'red', 'font-size': '14px'}}>* Password troppo debole</p>
                    ReactDOM.render(element, document.getElementById('errore'));
                } else {
                    const element = <p style={{'color': 'red', 'font-size': '14px', "display": "none"}}></p>
                    await ReactDOM.render(element, document.getElementById('errore'));
                    this.setState({step: step})
                }
                break;
            case 2:
                let valid = await this.checkCodiceFiscale()
                if (!telephone || !codiceFiscale || (privatoOazienda != 0 && (!pIva || !sdi || !pec || !company))) {
                    this.state.errore = true
                    const element = <p style={{'color': 'red', 'font-size': '14px'}}>* Tutti i campi sono
                        obbligatori</p>
                    ReactDOM.render(element, document.getElementById('errore'));
                } else if (!valid) {
                    this.state.errore = true
                    const element = <p style={{'color': 'red', 'font-size': '14px'}}>* Codice fiscale non valido</p>
                    ReactDOM.render(element, document.getElementById('errore'));
                } else {
                    const element = <p style={{'color': 'red', 'font-size': '14px', "display": "none"}}></p>
                    ReactDOM.render(element, document.getElementById('errore'));
                    this.setState({step: step})
                }
                break;
            case 3:
                if (!streetName || !streetNumber || !city || !regionCode || !postcode) {
                    this.state.errore = true
                    const element = <p style={{'color': 'red', 'font-size': '14px'}}>* Tutti i campi sono
                        obbligatori</p>
                    ReactDOM.render(element, document.getElementById('errore'));
                } else {
                    const element = <p style={{'color': 'red', 'font-size': '14px', "display": "none"}}></p>
                    ReactDOM.render(element, document.getElementById('errore'));
                    this.setState({step: step, errore: false})
                }
                break;
            case 4:
                if(!informativa_2){
                    this.state.errore = true
                    const element = <p style={{'color': 'red', 'font-size': '14px'}}>* Compila tutti i dati obbligatori</p>
                    ReactDOM.render(element, document.getElementById('errore'));
                } else {
                    const element = <p style={{'color': 'red', 'font-size': '14px', "display": "none"}}></p>
                    ReactDOM.render(element, document.getElementById('errore'));
                    this.setState({step: step, errore: false})
                    this.handleSubmit()
                }

        }
        //this.setState({step: step})
    }

    async checkCodiceFiscale() {
        var regex = /^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/;
        let ret = regex.test(this.state.codiceFiscale);
        return ret
    }

    render() {
        const {classes} = this.props
        const isDesktop = this.state.isDesktop

        if (isDesktop) {
            return (
                <React.Fragment style={{'max-width': '1200px !important'}}>
                    <Dialog style={{'max-width': '1200px !important', 'height': '100%', 'margin': '0', 'padding': '0'}}
                            open={this.state.opensignin}
                            onClose={this.handleClose}>
                        <div style={{'padding-right': '10px', "padding-top":"10px", "cursor":"pointer"}} onClick={this.handleClose}>
                            <div>
                                <div className={classes.mdiv1_closex}>
                                    <div className={classes.mdiv_closex}>
                                        <div className={classes.md_closex}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p style={{
                            'text-align': 'center',
                            'font-weight': 'bold',
                            color: '#000',
                            'margin-top': '-15px',
                            'font-size': '25px'
                        }}>Registrati</p>

                        {/*<a href={'/'} style={{'width': '5%', 'height': '20%'}}><Icon icon={'keybord_arrow_left'}*/}
                                                                                     {/*style={{*/}
                                                                                         {/*'font-size': '70px',*/}
                                                                                         {/*'margin-left': '-570px',*/}
                                                                                         {/*color: '#000'*/}
                                                                                     {/*}}></Icon></a>*/}
                        <Grid className={classes.signUp}>
                            <Dialog
                                open={this.state.open}
                                onClose={() => this.change()}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"Errore"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Errore. Email già registrata.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => this.change()}
                                            style={{backgroundColor: 'green', color: 'white'}}>
                                        Ok, ho capito!
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Row>
                                <Col md={8}>

                                    <Grid className={classes.left}>
                                        <Row>
                                            <Col md={4}>
                                                <TextField fullwidth label='Nome' required
                                                           value={this.state.firstname}
                                                           onChange={event => this.setState({firstname: event.target.value})}
                                                />

                                                <TextField fullwidth label='Cognome' required
                                                           value={this.state.lastname}
                                                           onChange={event => this.setState({lastname: event.target.value})}
                                                />
                                                <TextField fullwidth label='Email' required
                                                           value={this.state.email}
                                                           onChange={event => this.setState({email: event.target.value})}
                                                />
                                                <TextField fullwidth label='Telefono' required
                                                           value={this.state.telephone}
                                                           onChange={event => this.setState({telephone: event.target.value})}
                                                />
                                                <TextField fullwidth label='Data di nascita' required
                                                           value={this.state.dob} type='date'
                                                           onChange={event => this.setState({dob: event.target.value})}
                                                />
                                            </Col>
                                            <Col md={4}>
                                                <TextField fullwidth label='Indirizzo' required
                                                           value={this.state.streetName}
                                                           onChange={event => this.setState({streetName: event.target.value})}
                                                />
                                                <TextField fullwidth label='Numero civico' required
                                                           value={this.state.streetNumber}
                                                           onChange={event => this.setState({streetNumber: event.target.value})}
                                                />
                                                <TextField fullwidth label='Città' required
                                                           value={this.state.city}
                                                           onChange={event => this.setState({city: event.target.value})}
                                                />
                                                <Row>
                                                    <Col md={6}>
                                                        <TextField fullwidth label='CAP' required
                                                                   value={this.state.postcode}
                                                                   onChange={event => this.setState({postcode: event.target.value})}
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                        <TextField fullwidth label='Provincia' required
                                                                   value={this.state.regionCode}
                                                                   onChange={event => this.setState({regionCode: event.target.value})}
                                                        />
                                                    </Col>
                                                </Row>
                                                <div>
                                                    <Select
                                                        label='Fatturazione'
                                                        options={[
                                                            {label: 'Privato', value: 0},
                                                            {label: 'Ditta individuale', value: 1},
                                                            {label: 'Società di persone', value: 2},
                                                            {label: 'Società di capitali', value: 3}
                                                        ]}
                                                        style={{width: '100%'}}
                                                        onChange={event => this.setState({privatoOazienda: event.target.value})}
                                                        value={this.state.privatoOazienda}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                {this.state.privatoOazienda != '0'
                                                    ? <div>
                                                        <TextField
                                                            label='Nome ditta'
                                                            fullwidth required
                                                            value={this.state.company}
                                                            onChange={event => this.setState({company: event.target.value})}
                                                        />
                                                        <TextField
                                                            label='Codice Fiscale'
                                                            fullwidth required
                                                            value={this.state.codiceFiscale}
                                                            onChange={event => this.setState({codiceFiscale: event.target.value})}
                                                        />
                                                        <TextField
                                                            label='P.IVA'
                                                            fullwidth required
                                                            value={this.state.pIva}
                                                            onChange={event => this.setState({pIva: event.target.value})}
                                                        />
                                                        <TextField
                                                            label='SDI'
                                                            fullwidth required
                                                            value={this.state.sdi}
                                                            onChange={event => this.setState({sdi: event.target.value})}
                                                        />
                                                        <TextField
                                                            label='PEC'
                                                            fullwidth required
                                                            value={this.state.pec}
                                                            onChange={event => this.setState({pec: event.target.value})}
                                                        />
                                                    </div>
                                                    : <div>
                                                        <TextField
                                                            label='Codice Fiscale'
                                                            fullwidth required
                                                            value={this.state.codiceFiscale}
                                                            onChange={event => this.setState({codiceFiscale: event.target.value})}
                                                        />
                                                    </div>
                                                }
                                                <TextField type='password' fullwidth label='Password'
                                                           value={this.state.password}
                                                           onChange={event => this.setState({password: event.target.value})}
                                                />
                                                <TextField type='password' fullwidth label='Conferma password'
                                                           value={this.state.confirmPassword}
                                                           onChange={event => this.setState({confirmPassword: event.target.value})}
                                                />
                                            </Col>
                                        </Row>
                                    </Grid>
                                </Col>

                                <Col md={4}>

                                    <Grid className={classes.agreementsBlock}>
                                        <Row>
                                            <Col md={12}>
                                                <Checkbox
                                                    checked={this.state.notifica_sanitaria || false}
                                                    onChange={event => this.setState({notifica_sanitaria: event.target.checked})}>
                                                    <Typography use='body2'>
                                                        Notifica al sistema sanitario
                                                    </Typography>
                                                </Checkbox>
                                                <Checkbox
                                                    checked={this.state.newsletter || false}
                                                    onChange={event => this.setState({newsletter: event.target.checked})}>
                                                    <Typography use='body2'>
                                                        Acconsento a ricevere newsletter, promozioni e offerte da
                                                        FarmaKon. Consulta l'<a href={"javascript:showPrivacy(24)"}>informativa
                                                        sulla privacy.</a>
                                                    </Typography>
                                                </Checkbox>
                                                <br/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Checkbox
                                                    checked={this.state.informativa_1 || false}
                                                    onChange={event => this.setState({informativa_1: event.target.checked})}>
                                                    <Typography use='body2'>
                                                        Acconsento alla profilazione per ricevere offerte personalizzate
                                                        sulla base delle mie abitudini di consumo.
                                                    </Typography>
                                                </Checkbox>
                                                <br/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Checkbox
                                                    checked={this.state.informativa_2 || false}
                                                    onChange={event => {
                                                        this.setState({informativa_2: event.target.checked})
                                                    }}
                                                    required>
                                                    <Typography use='body2'>
                                                        Dichiaro di aver preso visione ai <a
                                                        href={routes.noteLegali.path}>Termini e Condizioni Generali
                                                        d’Acquisto di FarmaKon</a> e di prestare il consenso al
                                                        trattamento dei miei dati personali.
                                                    </Typography>

                                                </Checkbox>
                                                <br/>
                                            </Col>
                                        </Row>
                                        <div id={'errore'}></div>
                                        <Row>
                                            <Col md={12}>
                                                <KUButton className={classes.submit} unelevated
                                                          clickHandler={() => this.handleSubmit()}
                                                >
                                                    PROCEDI
                                                </KUButton>
                                            </Col>
                                        </Row>
                                    </Grid>

                                </Col>
                            </Row>
                        </Grid>
                    </Dialog></React.Fragment>)
        } else {
            return (
                <React.Fragment style={{'max-width': '1200px !important'}}>
                    <Dialog /*style={{'max-width': '1200px !important', 'height': '100%', 'margin': '0', 'padding': '0'}}*/
                        fullWidth={true}
                        fullHeight={true}
                        open={this.state.opensignin}
                        onClose={this.handleClose}>
                        <div style={{'padding-right': '10px', "padding-top":"10px", "cursor":"pointer"}} onClick={this.handleClose}>
                            <div>
                                <div className={classes.mdiv1_closex}>
                                    <div className={classes.mdiv_closex}>
                                        <div className={classes.md_closex}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p style={{
                            'text-align': 'center',
                            'font-weight': 'bold',
                            color: '#000',
                            'font-size': '25px',
                            "margin-top":"-5px"
                        }}>Registrati</p>

                        <Grid style={{"width":"85% !important"}}> {/*qui height 500*/}
                            <Dialog
                                open={this.state.open}
                                onClose={() => this.change()}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"Errore"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Errore. Email già registrata.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => this.change()}
                                            style={{backgroundColor: 'green', color: 'white'}}>
                                        Ok, ho capito!
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"/>
                            <Row>
                                {(() => {
                                        switch (this.state.step) {
                                            case 1:
                                                return (
                                                    <React.Fragment>
                                                        <Grid style={{"width":"-webkit-fill-available"}}>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <TextField fullwidth label='Nome' required
                                                                               value={this.state.firstname}
                                                                               onChange={event => this.setState({firstname: event.target.value})}
                                                                    />

                                                                    <TextField fullwidth label='Cognome' required
                                                                               value={this.state.lastname}
                                                                               onChange={event => this.setState({lastname: event.target.value})}
                                                                    />
                                                                    <TextField fullwidth label='Email' required
                                                                               value={this.state.email}
                                                                               onChange={event => this.setState({email: event.target.value})}
                                                                    />
                                                                    <TextField fullwidth label='Data di nascita'
                                                                               required
                                                                               value={this.state.dob} type='date'
                                                                               onChange={event => this.setState({dob: event.target.value})}
                                                                    />
                                                                    <TextField type='password' fullwidth
                                                                               label='Password'
                                                                               value={this.state.password}
                                                                               onChange={event => this.setState({password: event.target.value})}
                                                                    />
                                                                    <TextField type='password' fullwidth
                                                                               label='Conferma password'
                                                                               value={this.state.confirmPassword}
                                                                               onChange={event => this.setState({confirmPassword: event.target.value})}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Grid>
                                                        <div id={'errore'}></div>
                                                        <Row style={{"width": "100%", "padding-top": "20px"}}>
                                                            <Col md={12} style={{
                                                                "padding-top": "15px",
                                                                "padding-bottom": "15px"
                                                            }}>
                                                                <KUButton className={classes.submit} unelevated
                                                                          clickHandler={() => this.procedi(1, 2)}
                                                                >
                                                                    PROCEDI
                                                                </KUButton>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )

                                            case 2:
                                                return (
                                                    <React.Fragment>
                                                        <Grid style={{"width":"-webkit-fill-available"}}>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <div>
                                                                        <Select
                                                                            label='Fatturazione'
                                                                            options={[
                                                                                {label: 'Privato', value: 0},
                                                                                {label: 'Ditta individuale', value: 1},
                                                                                {label: 'Società di persone', value: 2},
                                                                                {label: 'Società di capitali', value: 3}
                                                                            ]}
                                                                            style={{width: '100%'}}
                                                                            onChange={event => this.setState({privatoOazienda: event.target.value})}
                                                                            value={this.state.privatoOazienda}
                                                                        />
                                                                    </div>
                                                                    {this.state.privatoOazienda != '0'
                                                                        ? <div>
                                                                            <TextField
                                                                                label='Nome ditta'
                                                                                fullwidth required
                                                                                value={this.state.company}
                                                                                onChange={event => this.setState({company: event.target.value})}
                                                                            />
                                                                            <TextField
                                                                                label='Codice Fiscale'
                                                                                fullwidth required
                                                                                value={this.state.codiceFiscale}
                                                                                onChange={event => this.setState({codiceFiscale: event.target.value})}
                                                                            />
                                                                            <TextField
                                                                                label='P.IVA'
                                                                                fullwidth required
                                                                                value={this.state.pIva}
                                                                                onChange={event => this.setState({pIva: event.target.value})}
                                                                            />
                                                                            <TextField
                                                                                label='SDI'
                                                                                fullwidth required
                                                                                value={this.state.sdi}
                                                                                onChange={event => this.setState({sdi: event.target.value})}
                                                                            />
                                                                            <TextField
                                                                                label='PEC'
                                                                                fullwidth required
                                                                                value={this.state.pec}
                                                                                onChange={event => this.setState({pec: event.target.value})}
                                                                            />
                                                                        </div>
                                                                        : <div>
                                                                            <TextField
                                                                                label='Codice Fiscale'
                                                                                fullwidth required
                                                                                value={this.state.codiceFiscale}
                                                                                onChange={event => this.setState({codiceFiscale: event.target.value})}
                                                                            />
                                                                        </div>
                                                                    }
                                                                    <TextField fullwidth label='Telefono' required
                                                                               value={this.state.telephone}
                                                                               onChange={event => this.setState({telephone: event.target.value})}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Grid>
                                                        <div id={'errore'}></div>
                                                        <Row style={{"padding-top": "20px"}}>
                                                            <Col xs={6} style={{
                                                                "padding-top": "15px",
                                                                "padding-bottom": "15px"
                                                            }}>
                                                                <KUButton className={classes.indietrobutton} unelevated
                                                                          clickHandler={() => this.indietro(1)}
                                                                >
                                                                    INDIETRO
                                                                </KUButton>
                                                            </Col>
                                                            <Col xs={6} style={{"padding-top": "5px"}}>
                                                                <KUButton className={classes.submit} style={{"width":"105%"}} unelevated
                                                                          clickHandler={() => this.procedi(2, 3)}
                                                                >
                                                                    PROCEDI
                                                                </KUButton>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )

                                            case 3:
                                                return (
                                                    <React.Fragment>
                                                        <Grid style={{"width":"-webkit-fill-available"}}>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <TextField fullwidth label='Indirizzo' required
                                                                               value={this.state.streetName}
                                                                               onChange={event => this.setState({streetName: event.target.value})}
                                                                    />
                                                                    <TextField fullwidth label='Numero civico' required
                                                                               value={this.state.streetNumber}
                                                                               onChange={event => this.setState({streetNumber: event.target.value})}
                                                                    />
                                                                    <TextField fullwidth label='Città' required
                                                                               value={this.state.city}
                                                                               onChange={event => this.setState({city: event.target.value})}
                                                                    />

                                                                    <TextField fullwidth label='CAP' required
                                                                               value={this.state.postcode}
                                                                               onChange={event => this.setState({postcode: event.target.value})}
                                                                    />

                                                                    <TextField fullwidth label='Provincia'
                                                                               required
                                                                               value={this.state.regionCode}
                                                                               onChange={event => this.setState({regionCode: event.target.value})}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Grid>
                                                        <div id={'errore'}></div>
                                                        <Row style={{"padding-top": "20px"}}>
                                                            <Col xs={6} style={{
                                                                "padding-top": "15px",
                                                                "padding-bottom": "15px"
                                                            }}>
                                                                <KUButton className={classes.indietrobutton} unelevated
                                                                          clickHandler={() => this.indietro(2)}
                                                                >
                                                                    INDIETRO
                                                                </KUButton>
                                                            </Col>
                                                            <Col xs={6} style={{"padding-top": "5px"}}>
                                                                <KUButton className={classes.submit} style={{"width":"105%"}} unelevated
                                                                          clickHandler={() => this.procedi(3, 4)}
                                                                >
                                                                    PROCEDI
                                                                </KUButton>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )

                                            case 4:
                                                return (
                                                    <React.Fragment>
                                                        <Row>
                                                            <Col xs={12} className={classes.spunte}>
                                                                <Checkbox
                                                                    checked={this.state.notifica_sanitaria || false}
                                                                    onChange={event => this.setState({notifica_sanitaria: event.target.checked})}>
                                                                    <Typography use='body2'>
                                                                        Notifica al sistema sanitario
                                                                    </Typography>
                                                                </Checkbox>
                                                                <Checkbox
                                                                    checked={this.state.newsletter || false}
                                                                    onChange={event => this.setState({newsletter: event.target.checked})}>
                                                                    <Typography use='body2'>
                                                                        Acconsento a ricevere newsletter, promozioni e
                                                                        offerte da
                                                                        FarmaKon. Consulta l'<a
                                                                        href={"javascript:showPrivacy(24)"}>informativa
                                                                        sulla privacy.</a>
                                                                    </Typography>
                                                                </Checkbox>
                                                                <br/>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={12} className={classes.spunte}>
                                                                <Checkbox
                                                                    checked={this.state.informativa_1 || false}
                                                                    onChange={event => this.setState({informativa_1: event.target.checked})}>
                                                                    <Typography use='body2'>
                                                                        Acconsento alla profilazione per ricevere
                                                                        offerte personalizzate
                                                                        sulla base delle mie abitudini di consumo.
                                                                    </Typography>
                                                                </Checkbox>
                                                                <br/>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={12} className={classes.spunte}>
                                                                <Checkbox
                                                                    checked={this.state.informativa_2 || false}
                                                                    onChange={event => {
                                                                        this.setState({informativa_2: event.target.checked})
                                                                    }}
                                                                    required>
                                                                    <Typography use='body2'>
                                                                        Dichiaro di aver preso visione ai <a
                                                                        href={routes.noteLegali.path}>Termini e
                                                                        Condizioni Generali
                                                                        d’Acquisto di FarmaKon</a> e di prestare il
                                                                        consenso al
                                                                        trattamento dei miei dati personali.
                                                                    </Typography>

                                                                </Checkbox>
                                                                <br/>
                                                            </Col>
                                                        </Row>
                                                        <div id={'errore'}></div>
                                                        <Row style={{"padding-top": "20px"}}>
                                                            <Col xs={6} style={{
                                                                "padding-top": "15px",
                                                                "padding-bottom": "15px"
                                                            }}>
                                                                <KUButton className={classes.indietrobutton} unelevated
                                                                          clickHandler={() => this.indietro(3)}
                                                                >
                                                                    INDIETRO
                                                                </KUButton>
                                                            </Col>
                                                            <Col xs={6} style={{"padding-top": "5px"}}>
                                                                <KUButton className={classes.submit} style={{"width":"105%"}} unelevated
                                                                          clickHandler={() => this.procedi(4, 4)}
                                                                >
                                                                    REGISTRATI
                                                                </KUButton>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )
                                        }
                                    }
                                )()}
                            </Row>
                        </Grid>
                    </Dialog>
                </React.Fragment>
            )
        }
    }
}

const mapStateToProps = function (state) {
    return {}
}

export default connect(mapStateToProps, {signUp, showSignIn, auth})(injectSheet(style)(SignUp))
