import React from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import {getProductsByBrand} from '../../actions'
import { GridInner, Select, Typography } from 'rmwc'
import BrandProduct from '../../components/BrandProduct'
import {style} from './style'
import {Col, Grid, Row} from "react-flexbox-grid";
import variables from "../../assets/style/variables";
import ReactPaginate from "../Category";

class Brand extends React.Component {
    constructor(props) {
        super()
        this.state = {
            perPage: 16,
            currentPage: 1,
            priceRange: {
                min: 20,
                max: 120
            }
        }
    }

    componentWillMount() {
        let brandName = []
        brandName = window.location.href.split('/')
        let name = brandName[brandName.length-1].replace('%20', ' ')
        console.log(name)
        console.log(this.props.match.params.brandName)
        this.props.getProductsByBrand(
            this.props.match.params.brandName,
            this.state.perPage,
            this.state.currentPage
        )
    }

    async componentWillUpdate (prevProps) {
        if (this.props.location !== prevProps.location) {
            await this.setState({ currentPage: 1 })
            this.props.getProductsByBrand(
                this.props.match.params.brandName,
                this.state.perPage,
                this.state.currentPage
            )
        }
    }

    handleChangeProductsPerPage = (perPage) => {
        this.props.getProductsByBrand(
            this.props.match.params.brandName,
            perPage,
            this.state.currentPage
        )
    }

    handlePageClick = async (data) => {
        let currentPage = data.selected + 1
        await this.setState({ currentPage: currentPage })
        this.props.getProductsByBrand(
            this.props.match.params.brandName,
            this.state.perPage,
            this.state.currentPage
        )
    }

    render(){
        const {classes} = this.props
        console.log(this.props.brand_products)
        console.log(this.props)
        return(
            <Grid fluid>
                <Row className={classes.category}>
                    <Col md={3} className={classes.sidebar}>
                        <img alt={'Banner Spedizione'}
                             src={'../../../images/banner-home/banner-spedizione.png'}
                             style={{'max-width':'87%', 'margin-top':'15%', 'border':'2px solid', 'margin-bottom':'15%'}}
                        />

                        {/*<Row center='md'>
                            <Col md={10} style={{
                                backgroundColor: variables.lightGrey,
                                height: '250px',
                                color: 'white',
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                                marginBottom: 40
                            }}>
                                <Typography use='body1'>
                                    Banner Prodotti a Marchio
                                </Typography>
                            </Col>
                        </Row>

                        <Row center='md'>
                            <Col md={10} style={{
                                backgroundColor: variables.white,
                                border: '2px solid ' + variables.grey,
                                height: '250px',
                                color: variables.black,
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                                marginBottom: 40
                            }}>
                                <Typography use='body1'>
                                    Banner Prodotti a Marchio
                                </Typography>
                            </Col>
                        </Row>*/}
                    </Col>

                    <Col md={9} className={classes.products}>
                        <Grid>
                            <Row end='md'>
                                <Col md={6} />
                                <Col md={6}>
                                    <div>
                                        <Typography use='body2' style={{ marginRight: 20 }}>
                                            <strong>{this.props.totalCount || '-'}</strong> Risultati
                                        </Typography>
                                        <Typography use='body2'>
                                            Per pagina:
                                        </Typography>
                                        <select className='select-per-page'
                                                value={this.state.perPage}
                                                onChange={event => {
                                                    this.setState({ perPage: event.target.value })
                                                    this.handleChangeProductsPerPage(event.target.value)
                                                }}
                                        >
                                            <option value='16'>16</option>
                                            <option value='32'>32</option>
                                            <option value='48'>48</option>
                                            <option value='60'>60</option>
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <hr />

                            {this.props.loadingMore && this.props.totalCount > 0 &&
                            <Row center='md'>
                                <Col md={10} className='category--products-loading'>
                                    <svg version='1.1' id='Layer_1' x='0px' y='0px'
                                         width='24px' height='30px' viewBox='0 0 24 30'>
                                        <rect x='0' y='10' width='4' height='10' fill='#333' opacity='0.2'>
                                            <animate attributeName='opacity' attributeType='XML' values='0.2; 1; .2' begin='0s' dur='0.6s' repeatCount='indefinite' />
                                            <animate attributeName='height' attributeType='XML' values='10; 20; 10' begin='0s' dur='0.6s' repeatCount='indefinite' />
                                            <animate attributeName='y' attributeType='XML' values='10; 5; 10' begin='0s' dur='0.6s' repeatCount='indefinite' />
                                        </rect>
                                        <rect x='8' y='10' width='4' height='10' fill='#333' opacity='0.2'>
                                            <animate attributeName='opacity' attributeType='XML' values='0.2; 1; .2' begin='0.15s' dur='0.6s' repeatCount='indefinite' />
                                            <animate attributeName='height' attributeType='XML' values='10; 20; 10' begin='0.15s' dur='0.6s' repeatCount='indefinite' />
                                            <animate attributeName='y' attributeType='XML' values='10; 5; 10' begin='0.15s' dur='0.6s' repeatCount='indefinite' />
                                        </rect>
                                        <rect x='16' y='10' width='4' height='10' fill='#333' opacity='0.2'>
                                            <animate attributeName='opacity' attributeType='XML' values='0.2; 1; .2' begin='0.3s' dur='0.6s' repeatCount='indefinite' />
                                            <animate attributeName='height' attributeType='XML' values='10; 20; 10' begin='0.3s' dur='0.6s' repeatCount='indefinite' />
                                            <animate attributeName='y' attributeType='XML' values='10; 5; 10' begin='0.3s' dur='0.6s' repeatCount='indefinite' />
                                        </rect>
                                    </svg>
                                </Col>
                            </Row>
                            }

                            {parseInt(this.props.brand_products.length) === 0 &&
                            <Row center='md'>
                                <Col md={10} className='category--no-products-found'>
                                    <Typography use='headline5'>
                                        Nessun prodotto trovato in questa categoria
                                    </Typography>
                                </Col>
                            </Row>
                            }

                            <GridInner>
                                {this.props.brand_products &&
                                Object.values(this.props.brand_products).map((product, i) => (
                                    <BrandProduct
                                        product={product}
                                        key={i}
                                        props={this.props.props}
                                    />
                                ))}
                            </GridInner>

                            {this.props.totalCount > this.state.perPage &&
                            <Row id='react-paginate' center={'md'}>
                                <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={this.props.totalCount > 1 ? this.props.totalCount / this.state.perPage : 1}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                />
                            </Row>
                            }

                        </Grid>
                    </Col>
                </Row>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        brand_products: state.brand.brand_products,
        loadingMore: state.brand.loadingMore,
        totalCount: state.brand.totalCount,
        props: state
    }
}

export default connect(mapStateToProps, {getProductsByBrand})(injectSheet(style)(Brand))
