import variables from '../../assets/style/variables'

export const style = {
  cart: {
    marginBottom: '0.5rem'
  },
  bodyCart: {
    marginTop: '10px',
    marginBottom: '20px',
    padding: 20
  },
  cartTitles: {
    marginBottom: '2rem'
  },
  cartAction: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  cartImage: {
    width: '100%',
    minHeight: '80px',
    backgroundColor: variables.grey
  },

  cartHeader: {
    display: 'flex',
    marginTop: 10
  },
  cartHeaderItem: {
    '& span': { fontSize: '0.9em' },
    '& i': {
      display: 'block',
      marginBottom: 5,
      marginLeft: 3,
      marginRight: 3
    }
  },
  products: {
    padding: 0
  },
  cartActionCost: {
    fontWeight: 'bold',
    paddingTop: 3
  },

  cartCoupon: {
    '& input': {
      width: '100%',
      border: `2px solid ${variables.lightGrey}`,
      padding: 10,
      marginTop: 10,
      marginBottom: 0,
      fontSize: '1em',
      '-webkit-box-sizing': 'border-box',
      '-moz-box-sizing': 'border-box',
      'box-sizing': 'border-box'
    }
  },

  cartProceed: {
    '& button': {
      width: '100%',
      height: 'auto',
      marginTop: 10,
      marginBottom: 10,
      borderRadius: 0,
      padding: 12,
      '& span': {
        fontWeight: 'bold'
      }
    }
  },
  rowsicurezza :{
    'padding-top':'15px',
    'padding-bottom':'10px'
  },

  boxsicurezza : {
    backgroundColor: "#F4F8F5",
    'padding':'5px',
    'padding-top':'10px'
  },
  sicurezzaicone:{
    display: "flex",
    "align-items":"center",
    'justify-content':'center'
  },
  descrizionesicurezza:{
    "font-size":"14px",
    display: 'flex',
    'align-items': 'center'
  },
  rowboxsicurezza:{
    'padding-bottom':'5px'
  },
  '@media (max-width: 600px)' :{
    cartTitles: {
      marginBottom: '2rem',
    }
  }
  
}
