import { magento } from '../lib/magento'
import {
  MAGENTO_PASSWORD_RESET_LOADING,
  MAGENTO_CURRENT_CUSTOMER,
  MAGENTO_SUCCESS_MESSAGE,
  MAGENTO_CREATE_CUSTOMER,
  MAGENTO_AUTH_LOADING,
  MAGENTO_AUTH,
  MAGENTO_LOGOUT,
  MAGENTO_AUTH_ERROR, MAGENTO_CREATE_CART, LOAD_IMPERDIBILI
} from './types'

import { getCart } from './api'
import {ADMIN_TYPE} from "../lib/magento/types";

export const signUp = customer => {
  return async dispatch => {
    try {
      const response = await magento.guest.createCustomer(customer)
      dispatch({ type: MAGENTO_CREATE_CUSTOMER, payload: response })
      if (response.id && response.group_id) {
        localStorage.setItem("signUp", "true")
        const token = await magento.guest.auth(
          customer.customer.email,
          customer.password
        )
        if (token.message) {
          authFail(dispatch, token.message)
        } else {
          authSuccess(dispatch, token)
        }
      } else if (response.message) {
        localStorage.setItem("signUp", "false")
        authFail(dispatch, response.message)
      } else {
        authFail(dispatch, 'Something went wrong. Pleas try again later.')
      }
    } catch (e) {
      authFail(dispatch, e.message)
    }
  }
}

export const auth = (username, password, convertCart = false) => {
  return async dispatch => {
    try {
      dispatch({ type: MAGENTO_AUTH_LOADING, payload: true })
      const response = await magento.guest.auth(username, password)
      if (response.message) {
        authFail(dispatch, response.message)
      } else {
        authSuccess(dispatch, response, convertCart)
      }
    } catch (e) {
      authFail(dispatch, e.message)
    }
  }
}

const authSuccess = async (dispatch, token, convertCart = false) => {
  dispatch({ type: MAGENTO_AUTH, payload: token })
  try {
    window.localStorage.setItem('customerToken', token)
    let customer = await magento.customer.getCurrentCustomer()
    window.localStorage.setItem('email', customer.email)
    dispatch(setCurrentCustomer(customer))

    if (convertCart || window.localStorage.getItem('convertCart')) {
      window.localStorage.setItem('convertCart', false)
      await magento.customer.convertGuestCartoToCustomer3(customer.id)
    }

    dispatch({ type: MAGENTO_AUTH_LOADING, payload: false })

      dispatch(getCart())

  } catch (e) {
    authFail(dispatch, 'Something went wrong. Pleas try again later.')
  }
}

const authFail = (dispatch, message) => {
  dispatch(errorMessage(message))
  dispatch({ type: MAGENTO_AUTH_LOADING, payload: false })
}

export const errorMessage = error => {
  return { type: MAGENTO_AUTH_ERROR, payload: error }
}

export const logout = () => {
  return async dispatch => {
    dispatch({ type: MAGENTO_AUTH, payload: '' })
    dispatch({ type: MAGENTO_LOGOUT })
    magento.setCustomerToken(false)
    await window.localStorage.setItem('customerToken', '')
    await window.localStorage.setItem("cap", false)
    dispatch(getCart())
  }
}

export const initiatePasswordReset = email => {
  return async dispatch => {
    try {
      dispatch({ type: MAGENTO_PASSWORD_RESET_LOADING, payload: true })
      await magento.guest.initiatePasswordReset(email)
      const message = `If there is an account associated with ${email} you will 
        receive an email with a link to reset your password.`
      dispatch({ type: MAGENTO_PASSWORD_RESET_LOADING, payload: false })
      dispatch({ type: MAGENTO_SUCCESS_MESSAGE, payload: message })
    } catch (e) {
      console.log(e.message)
    }
  }
}

export const changePassword = (currentPassword, newPassword) => {
  return async dispatch => {
    try {
      dispatch({ type: MAGENTO_PASSWORD_RESET_LOADING, payload: true })
      await magento.customer.changePassword(currentPassword, newPassword)
      dispatch({ type: MAGENTO_PASSWORD_RESET_LOADING, payload: false })
    } catch (e) {
      console.log(e.message)
    }
  }
}

export const currentCustomer = () => {
  return async dispatch => {
    try {
      const customer = await magento.customer.getCurrentCustomer()
      dispatch({
        type: MAGENTO_CURRENT_CUSTOMER,
        payload: customer
      })
    } catch (error) {
      console.log(error)
    }
  }
}

export const loadImperdibili = (sku) => {
  return async dispatch => {
    try {
      const response = await magento.admin.getProductBySku(sku)
      console.log(response)
      dispatch({
        type: LOAD_IMPERDIBILI,
        payload: response
      })
    } catch (error) {
      console.log(error)
    }
  }
}

export const loadImperdibili2 = (sku) => {
  return async dispatch => {
    try {
      const response = await magento.admin.getProductBySku(sku)
      console.log(response)
      dispatch({
        type: "LOAD_IMPERDIBILI_2",
        payload: response
      })
    } catch (error) {
      console.log(error)
    }
  }
}

export const loadImperdibili3 = (sku) => {
  return async dispatch => {
    try {
      const response = await magento.admin.getProductBySku(sku)
      console.log(response)
      dispatch({
        type: "LOAD_IMPERDIBILI_3",
        payload: response
      })
    } catch (error) {
      console.log(error)
    }
  }
}

export const setCurrentCustomer = customer => {
  return {
    type: MAGENTO_CURRENT_CUSTOMER,
    payload: customer
  }
}


export default logout
