import variables from '../../assets/style/variables'

export const style = {
  login: {
    width: '100%',
    position: 'relative',
    backgroundColor: variables.white,
    color: variables.black,
    padding: '20px',
    textAlign: 'center',

    '& a': {
      fontWeight: 'bold',
      color: variables.black + '!important'
    },

    '& .mdc-text-field__input': {
      width: '100%',
      paddingLeft: '36px !important',
      paddingTop: '15px !important'
    },

    '& .mdc-text-field--with-leading-icon .mdc-text-field__icon': {
      left: 0
    },

    '& .mdc-text-field--with-leading-icon .mdc-floating-label': {
      left: 36
    }
  },

  submit: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '10px'
  },

  social: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '10px',
    fontSize: '13px',
    color: variables.blue + ' !important',
    backgroundColor: variables.white + ' !important',
    border: '2px solid ' + variables.blue,
    '& svg': {
      color: variables.blue,
      float: 'left',
      marginRight: '5px'
    }
  },

  socialRow: {
    marginTop: '20px'
  },

  link: {
    cursor: 'pointer'
  },

  click: {
    fontWeight: 'bold',
    color: variables.black
  },

  '@media only screen and (max-width: 600px)' :{
    body : {
      backgroundColor: variables.black,
      color: variables.black,
      textAlign: 'left'
    },
    '& .mdc-text-field__input': {
      width: '50% !important',
      paddingLeft: '36px !important',
      paddingTop: '15px !important'
    },
    '& .text-field-5dc0buo5vt.mdc-text-field__input':{
      width: '55% !important'
    }
  }

}
