import React from 'react'
import injectSheet from 'react-jss'
import {connect} from 'react-redux'
import {Button, ButtonIcon} from '@rmwc/button'
// import { Icon } from '@rmwc/icon'
import {Link} from 'react-router-dom'
import {GridCell, GridInner, Typography} from 'rmwc'
import {Grid} from 'react-flexbox-grid'
import MiniCartProduct from '../MiniCartProduct'
import {routes} from '../../config/settings'
import {cartTotal} from '../../lib/cart'
import {priceFormat, cartShippingFormat} from '../../lib/format'
import {style} from './style'
import {getCartTotals} from '../../actions/api'
import {currentCustomer} from "../../actions";

class MiniCart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            totale: '',
            sconto: '',
            coupon_code: '',
            cap: false,
            capNotFree: false
        }
        this.checkCapNotFree()
        this.checkCap()
    }

    checkCoupon() {
        let data = getCartTotals(localStorage.getItem('cartId'))
        let items_total = cartTotal(this.props.products)

        this.state.totale = localStorage.getItem('sconto')
        if (items_total <= parseFloat('59.90')) {
            this.state.totale = parseFloat(items_total) + parseFloat(this.state.totale) + (this.state.cap ? parseFloat('7.90') : (this.state.capNotFree ? parseFloat('35.00') : parseFloat('5.90')))
        }
        if (items_total > 59.90 && this.state.capNotFree) {
            this.state.totale = parseFloat(items_total) + parseFloat(this.state.totale) + (this.state.cap ? parseFloat('7.90') : (this.state.capNotFree ? parseFloat('35.00') : parseFloat('5.90')))
        }
        if (items_total > 59.90 && !this.state.capNotFree) {
            this.state.totale = parseFloat(this.state.totale) + parseFloat(items_total)
        }
        this.state.sconto = localStorage.getItem('sconto')
        this.state.coupon_code = localStorage.getItem('coupon_code')
    }

    checkCap() {
        let customer_cap = this.props.customer ? this.props.customer.addresses[0].postcode : localStorage.getItem("cap")
        if (customer_cap) {
            let a = ["04020", "04027", "07024", "07046", "09014", "22060", "23030", "25050", "30100", "30121", "30122", "30123", "30124", "30125", "30126", "30132", "30133", "30135", "30141", "30142", "57030", "57031", "57032", "57033", "57034", "57036", "57037", "57038", "57039", "58012", "58019", "71040", "80070", "80071", "80073", "80074", "80075", "80076", "80077", "80079", "90010", "91010", "91017", "91023", "92010", "98050", "98055"]
            let b = ["90", "91", "92", "93", "94", "95", "96", "97", "98", "09", "08", "07", "30"]
            var i
            for (i = 0; i < a.length; i++) {
                if (a[i] === customer_cap) {
                    this.state.cap = true
                }
                if (b[i] === customer_cap.substr(0, 2)) {
                    this.state.cap = true
                }
            }
        }
    }

    checkCapNotFree() {
        var check = false
        let customer_cap = this.props.customer ? this.props.customer.addresses[0].postcode : localStorage.getItem("cap")
        if (customer_cap) {
            let a = ["23030", "23041", "22060", "92010", "91017"]
            var i
            for (i = 0; i < a.length; i++) {
                if (a[i] === customer_cap) {
                    check = true
                }
            }
        }
        if (check) {
            if (customer_cap != "23041") {
                this.state.cap = false
            }
        }
        this.state.capNotFree = check
    }

    calcolaTotale() {
        let items_total = cartTotal(this.props.products)
        let ret = 0
        if (this.state.coupon_code == undefined || this.state.coupon_code == 'undefined') {
            if (items_total <= 59.90) {
                if (this.state.cap) {
                    ret = items_total + 7.90
                } else if (this.state.capNotFree) {
                    ret = items_total + 35.00
                } else {
                    ret = items_total + 5.90
                }
            } else {
                if (!this.state.capNotFree) {
                    ret = items_total
                } else {
                    ret = items_total + 35.00
                }
            }
        } else {
            ret = this.state.totale
        }
        return ret
    }

    calcolaSpeseSpedizione() {
        let items_total = cartTotal(this.props.products)
        let ret = 0
        console.log(this.state.capNotFree)
        if (items_total <= 59.90) {
            if (this.state.cap) {
                ret = 7.90
            } else if (this.state.capNotFree) {
                ret = 35.00
            } else {
                ret = 5.90
            }
        }
        if (items_total > 59.90) {
            if (this.state.capNotFree) {
                ret = 35.00
            } else {
                ret = 0
            }
        }
        console.log(ret)
        return ret
    }

    render() {
        const {classes, products} = this.props
        console.log(this.props)
        this.checkCap()
        this.checkCapNotFree()
        this.checkCoupon()
        let shipping_cost = this.calcolaSpeseSpedizione()
        let total = this.calcolaTotale()
        if (products.length > 0) {
            return (
                <div className={classes.miniCart}>
                    <div style={{'padding-bottom': '20px', "cursor":"pointer"}}>
                        <div>
                            <div className={classes.mdiv1}>
                                <div className={classes.mdiv}>
                                    <div className={classes.md}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.miniCartContainer}>
                        <Grid className={classes.miniCartProducts}>
                            {products && products.map((product, key) => {
                                return <MiniCartProduct product={product} key={key}/>
                            })
                            }
                        </Grid>


                        <Grid className={classes.miniCartProducts}>
                            <GridCell span={4}>
                                <GridInner>
                                    <GridCell span='4'>
                                        <Typography use='body1'>Spedizione</Typography>
                                    </GridCell>
                                    <GridCell span='8' className={classes.miniCartPrice}>
                                        <Typography
                                            use='body1'>{shipping_cost == 0 ? '€ 0,00' : priceFormat(shipping_cost)}</Typography>
                                    </GridCell>
                                </GridInner>

                                <GridInner>
                                    <GridCell span='4'>
                                        <Typography use='body1'>
                                            Coupon {this.state.coupon_code == undefined || this.state.coupon_code == 'undefined' ? false : " " + this.state.coupon_code}
                                        </Typography>
                                    </GridCell>
                                    <GridCell span='8' className={classes.miniCartPrice}>
                                        <Typography
                                            use='body1'>{this.state.coupon_code == undefined || this.state.coupon_code == 'undefined' ? "€ 0,00" : priceFormat(this.state.sconto)}</Typography>
                                    </GridCell>
                                </GridInner>

                                <GridInner>
                                    <GridCell span='4' className={classes.miniCartTotal}>
                                        <Typography use='body1'>
                                            Totale
                                        </Typography>
                                    </GridCell>
                                    <GridCell span='8' className={classes.miniCartPrice}>
                                        <Typography use='body1'>
                                            {total == 0 ? '€ 0,00' : priceFormat(total)}
                                        </Typography>
                                    </GridCell>
                                </GridInner>
                            </GridCell>
                        </Grid>

                        <a href={routes.cart.path} onClick={this.props.toggleMiniCartView}>
                            <Button className={classes.add} unelevated>
                                <ButtonIcon icon='shopping_cart'/> PROCEDI
                            </Button>
                        </a>

                    </div>
                </div>
            )
        } else {
            return (<div className={classes.miniCart}>
                <div style={{"cursor":"pointer"}}>
                    <div>
                        <div className={classes.mdiv1}>
                            <div className={classes.mdiv}>
                                <div className={classes.md}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <h4 style={{'font-size': '15px'}}>Carrello vuoto</h4>
                Non hai ancora aggiunto nessun prodotto al tuo carrello
            </div>)
        }
    }
}

const mapStateToProps = (state) => ({
    products: state.cart.items
})

export default connect(mapStateToProps, {currentCustomer})(injectSheet(style)(MiniCart))