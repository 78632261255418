import React from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import { Grid, Col, Row } from 'react-flexbox-grid'
import { GridInner, Select, Typography } from 'rmwc'
import InputRange from 'react-input-range'
import ReactPaginate from 'react-paginate'
import CategoryProduct from '../../components/CategoryProduct'
import {
  addToCart,
  searchProducts
} from '../../actions'
import { priceFormat } from '../../lib/format'
import variables from '../../assets/style/variables'
import { style } from './style'

class Search extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      searchCriteria: '',
      perPage: 16,
      currentPage: 1,
      priceRange: {
        min: 20,
        max: 120
      },
      isDesktop: false
    }
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 620 });
  }

  componentWillMount () {
    // this.props.searchProductsOrChild( this.props.category )
    this.props.searchProducts({
      searchCriteria: this.props.match.params.searchCriteria,
      perPage: localStorage.getItem("perPage"),//this.state.perPage,
      offset: this.state.currentPage
    })
    window.removeEventListener("resize", this.updatePredicate);

  }

  async componentWillUpdate (prevProps) {
    if (this.props.location !== prevProps.location) {
      await this.setState({ currentPage: 1 })
      this.props.searchProducts({
        searchCriteria: this.props.match.params.searchCriteria,
        perPage: localStorage.getItem("perPage"),//this.state.perPage,
        offset: this.state.currentPage
      })
    }
  }

  handlePageClick = async (data) => {
    let currentPage = data.selected + 1
    await this.setState({ currentPage: currentPage })
    this.props.searchProducts({
      searchCriteria: this.props.match.params.searchCriteria,
      perPage: this.state.perPage,
      offset: this.state.currentPage
    })
  }

  handleChangeProductsPerPage = (perPage) => {
    this.props.searchProducts({
      searchCriteria: this.props.match.params.searchCriteria,
      perPage: this.state.perPage,
      offset: this.state.currentPage
    })
  }

  render () {
    const { classes } = this.props
    const isDesktop = this.state.isDesktop
    if(isDesktop){
      return (
          <Grid fluid>
            <Row className={classes.category}>
              <Col md={3} className={classes.sidebar}>
                <img alt={'Banner Spedizione'}
                     src={'../../../images/banner-home/banner-spedizione.png'}
                     style={{'max-width':'87%', 'margin-top':'15%', 'border':'2px solid', 'margin-bottom':'15%'}}
                />

                {/* <Row center='md'>
                  <Col md={10} style={{
                    backgroundColor: variables.lightGrey,
                    height: '250px',
                    color: 'white',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    marginBottom: 40
                  }}>
                    <Typography use='body1'>
                      Banner Prodotti a Marchio
                    </Typography>
                  </Col>
                </Row>

                <Row center='md'>
                  <Col md={10} style={{
                    backgroundColor: variables.white,
                    border: '2px solid ' + variables.grey,
                    height: '250px',
                    color: variables.black,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    marginBottom: 40
                  }}>
                    <Typography use='body1'>
                      Banner Prodotti a Marchio
                    </Typography>
                  </Col>
                </Row>*/}

                {/* <input
              className={classes.filterInput}
              type='text'
              name='search'
              placeholder='Cerca un prodotto'
            />

            <h4 className={classes.filterTitle}>FILTRA PER</h4>

            <p>
              <select className={classes.filterSelect} name='filter_category'>
                <option value='2'>Farmaci da banco</option>
                <option value='3'>Igiene e Bellezza</option>
                <option value=''>...</option>
              </select>
            </p>

            <p>
              <select className={classes.filterSelect} name='filter_brand'>
                <option value='9'>Bayer</option>
                <option value='4'>Fissan</option>
                <option value=''>...</option>
              </select>
            </p>

            <br />

            <h4>RANGE DI PREZZO</h4>

            <InputRange
              maxValue={150}
              minValue={5}
              formatLabel={priceFormat}
              value={this.state.priceRange}
              onChange={value => this.setState({ priceRange: value })}
              / * onChangeComplete={ value => console.log(value) } * /
            />
           */}
              </Col>

              <Col md={9} className={classes.products}>
                <Grid>
                  <Row end='md'>
                    <Col md={6}>
                      <Typography use='body1' className={classes.resultsForText}
                      >
                        Risultati di ricerca per "{this.props.match.params.searchCriteria}"
                      </Typography>
                    </Col>
                    <Col md={6}>
                      <div>
                        <Typography use='body2' style={{ marginRight: 20 }}>
                          <strong>{this.props.totalCount || '-'}</strong> Risultati
                        </Typography>
                        <Typography use='body2'>
                          Per pagina:
                        </Typography>
                        <select className='select-per-page'
                                value={localStorage.getItem("perPage")?localStorage.getItem("perPage") : this.state.perPage}
                                onChange={event => {
                                  localStorage.setItem("perPage", event.target.value)
                                  this.setState({ perPage: event.target.value })
                                  this.handleChangeProductsPerPage(event.target.value)
                                }}
                        >
                          <option value='16'>16</option>
                          <option value='32'>32</option>
                          <option value='48'>48</option>
                          <option value='60'>60</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <hr />

                  {this.props.loadingMore && this.props.totalCount > 0 &&
                  <Row center='md'>
                    <Col md={10} className='category--products-loading'>
                      <svg version='1.1' id='Layer_1' x='0px' y='0px'
                           width='24px' height='30px' viewBox='0 0 24 30'>
                        <rect x='0' y='10' width='4' height='10' fill='#333' opacity='0.2'>
                          <animate attributeName='opacity' attributeType='XML' values='0.2; 1; .2' begin='0s' dur='0.6s' repeatCount='indefinite' />
                          <animate attributeName='height' attributeType='XML' values='10; 20; 10' begin='0s' dur='0.6s' repeatCount='indefinite' />
                          <animate attributeName='y' attributeType='XML' values='10; 5; 10' begin='0s' dur='0.6s' repeatCount='indefinite' />
                        </rect>
                        <rect x='8' y='10' width='4' height='10' fill='#333' opacity='0.2'>
                          <animate attributeName='opacity' attributeType='XML' values='0.2; 1; .2' begin='0.15s' dur='0.6s' repeatCount='indefinite' />
                          <animate attributeName='height' attributeType='XML' values='10; 20; 10' begin='0.15s' dur='0.6s' repeatCount='indefinite' />
                          <animate attributeName='y' attributeType='XML' values='10; 5; 10' begin='0.15s' dur='0.6s' repeatCount='indefinite' />
                        </rect>
                        <rect x='16' y='10' width='4' height='10' fill='#333' opacity='0.2'>
                          <animate attributeName='opacity' attributeType='XML' values='0.2; 1; .2' begin='0.3s' dur='0.6s' repeatCount='indefinite' />
                          <animate attributeName='height' attributeType='XML' values='10; 20; 10' begin='0.3s' dur='0.6s' repeatCount='indefinite' />
                          <animate attributeName='y' attributeType='XML' values='10; 5; 10' begin='0.3s' dur='0.6s' repeatCount='indefinite' />
                        </rect>
                      </svg>
                    </Col>
                  </Row>
                  }

                  {parseInt(this.props.totalCount) === 0 && !this.props.loadingMore &&
                  <Row center='md'>
                    <Col md='10' className='category--no-products-found'>
                      <Typography use='headline5'>
                        Nessun prodotto trovato
                      </Typography>
                    </Col>
                  </Row>
                  }

                  <GridInner>
                    {this.props.products &&
                    Object.values(this.props.products).map((product, i) => (
                        <CategoryProduct product={product} key={i} />
                    ))}
                  </GridInner>

                  {this.props.totalCount > this.state.perPage &&
                  <Row id='react-paginate' center={'md'}>
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.props.totalCount ? this.props.totalCount / this.state.perPage : 1}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                  </Row>
                  }
                </Grid>
              </Col>
            </Row>
          </Grid>
      )
    } else {
      return (
          <Grid fluid style={{'text-align':'center'}}>
            <Row className={classes.category}>
              <Col md={3} className={classes.sidebar}>

              </Col>

              <Col md={9} className={classes.products} style={{'padding-right':'0'}}>
                <Grid style={{'width':'90%'}}>
                  <Row end='md'>
                    <Col md={6}>
                      <Typography use='body1' className={classes.resultsForText}>
                        Risultati di ricerca per "{this.props.match.params.searchCriteria}"
                      </Typography>
                    </Col>
                    <Col md={6}>
                      <div>
                        <Typography use='body2' style={{ marginRight: 20 }}>
                          <strong>{this.props.totalCount || '-'}</strong> Risultati
                        </Typography>
                        <Typography use='body2'>
                          Per pagina:
                        </Typography>
                        <select className='select-per-page'
                                value={localStorage.getItem("perPage")?localStorage.getItem("perPage") : this.state.perPage}
                                onChange={event => {
                                  localStorage.setItem("perPage", event.target.value)
                                  this.setState({ perPage: event.target.value })
                                  this.handleChangeProductsPerPage(event.target.value)
                                }}
                        >
                          <option value='16'>16</option>
                          <option value='32'>32</option>
                          <option value='48'>48</option>
                          <option value='60'>60</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <hr />

                  {this.props.loadingMore && this.props.totalCount > 0 &&
                  <Row center='md'>
                    <Col md={10} className='category--products-loading'>
                      <svg version='1.1' id='Layer_1' x='0px' y='0px'
                           width='24px' height='30px' viewBox='0 0 24 30'>
                        <rect x='0' y='10' width='4' height='10' fill='#333' opacity='0.2'>
                          <animate attributeName='opacity' attributeType='XML' values='0.2; 1; .2' begin='0s' dur='0.6s' repeatCount='indefinite' />
                          <animate attributeName='height' attributeType='XML' values='10; 20; 10' begin='0s' dur='0.6s' repeatCount='indefinite' />
                          <animate attributeName='y' attributeType='XML' values='10; 5; 10' begin='0s' dur='0.6s' repeatCount='indefinite' />
                        </rect>
                        <rect x='8' y='10' width='4' height='10' fill='#333' opacity='0.2'>
                          <animate attributeName='opacity' attributeType='XML' values='0.2; 1; .2' begin='0.15s' dur='0.6s' repeatCount='indefinite' />
                          <animate attributeName='height' attributeType='XML' values='10; 20; 10' begin='0.15s' dur='0.6s' repeatCount='indefinite' />
                          <animate attributeName='y' attributeType='XML' values='10; 5; 10' begin='0.15s' dur='0.6s' repeatCount='indefinite' />
                        </rect>
                        <rect x='16' y='10' width='4' height='10' fill='#333' opacity='0.2'>
                          <animate attributeName='opacity' attributeType='XML' values='0.2; 1; .2' begin='0.3s' dur='0.6s' repeatCount='indefinite' />
                          <animate attributeName='height' attributeType='XML' values='10; 20; 10' begin='0.3s' dur='0.6s' repeatCount='indefinite' />
                          <animate attributeName='y' attributeType='XML' values='10; 5; 10' begin='0.3s' dur='0.6s' repeatCount='indefinite' />
                        </rect>
                      </svg>
                    </Col>
                  </Row>
                  }

                  {parseInt(this.props.totalCount) === 0 && !this.props.loadingMore &&
                  <Row center='md'>
                    <Col md='10' className='category--no-products-found'>
                      <Typography use='headline5'>
                        Nessun prodotto trovato
                      </Typography>
                    </Col>
                  </Row>
                  }

                  <Grid style={{'width':'100%','margin-left':'1.5%','margin-right':'-2.5%'}}>
                    {this.props.products &&
                    <Row>{Object.values(this.props.products).map((product, i) => (
                        <Col xs={6} style={{'margin-bottom':'5%'}}><CategoryProduct product={product} key={i} /></Col>
                    ))}
                    </Row>
                    }
                  </Grid>

                  <br/>

                  {this.props.totalCount > this.state.perPage &&
                  <Row id='react-paginate' center={'md'}>
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.props.totalCount ? this.props.totalCount / this.state.perPage : 1}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                  </Row>
                  }

                  <br/>

                  <img alt={'Banner Spedizione'}
                       src={'../../../images/banner-home/banner-spedizione.png'}
                       style={{'max-width':'87%', 'margin-top':'15%', 'border':'2px solid', 'margin-bottom':'15%'}}
                  />

                  {/*<Row center='md'>
                    <Col md={10} style={{
                      backgroundColor: variables.lightGrey,
                      height: '250px',
                      color: 'white',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      marginBottom: 40
                    }}>
                      <Typography use='body1'>
                        Banner Prodotti a Marchio
                      </Typography>
                    </Col>
                  </Row>

                  <Row center='md'>
                    <Col md={10} style={{
                      backgroundColor: variables.white,
                      border: '2px solid ' + variables.grey,
                      height: '250px',
                      color: variables.black,
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      marginBottom: 40
                    }}>
                      <Typography use='body1'>
                        Banner Prodotti a Marchio
                      </Typography>
                    </Col>
                  </Row>*/}

                  {/* <input
              className={classes.filterInput}
              type='text'
              name='search'
              placeholder='Cerca un prodotto'
            />

            <h4 className={classes.filterTitle}>FILTRA PER</h4>

            <p>
              <select className={classes.filterSelect} name='filter_category'>
                <option value='2'>Farmaci da banco</option>
                <option value='3'>Igiene e Bellezza</option>
                <option value=''>...</option>
              </select>
            </p>

            <p>
              <select className={classes.filterSelect} name='filter_brand'>
                <option value='9'>Bayer</option>
                <option value='4'>Fissan</option>
                <option value=''>...</option>
              </select>
            </p>

            <br />

            <h4>RANGE DI PREZZO</h4>

            <InputRange
              maxValue={150}
              minValue={5}
              formatLabel={priceFormat}
              value={this.state.priceRange}
              onChange={value => this.setState({ priceRange: value })}
              / * onChangeComplete={ value => console.log(value) } * /
            />
           */}
                </Grid>
              </Col>
            </Row>
          </Grid>
      )
    }

  }
}

const mapStateToProps = function (state) {
  const category = state.category.current
  const { products, totalCount, loadingMore } = state.category
  const { cart, account } = state
  const canLoadMoreContent = products.length < totalCount

  return {
    category,
    products,
    totalCount,
    cart,
    canLoadMoreContent,
    loadingMore,
    customer: account.customer
  }
}

export default connect(
  mapStateToProps,
  {
    searchProducts,
    addToCart
  }
)(injectSheet(style)(Search))
