import React from 'react'
import {Grid} from "@material-ui/core";
import {Row} from "react-flexbox-grid";
import Typography from "@material-ui/core/Typography";


class RecessoGaranzia extends React.Component {

    constructor(){
        super()
    }

    render() {
        return(
            <Grid class={'container-fluid'} style={{'padding-left':'16px', 'padding-right':'16px', 'margin-left':'auto','margin-right':'auto','width':'80%', 'margin-top':'2%','margin-bottom':'2%'}}>
                <Row>
                    <Typography use="headline5" style={{'font-size':'1.875rem'}}>Tempi e spese di spedizione</Typography>
                </Row>
                <Row>
                    <br/>
                    <text>
                        Su <text style={{'font-weight':'bold'}}>Farmakon.it</text> è possibile effettuare il reso della merce in modo semplice e veloce, avvalendosi del diritto di recesso come previsto dal Decreto Legislativo 6 settembre 2005, n. 206.
                        Per rendere la merce acquistata è necessario inviare comunicazione scritta a: <text style={{'font-weight':'bold'}}>Pharmamed Srl</text>. Via Montalbano 65 - <text style={{'font-weight':'bold'}}>51100 - Pistoia (PT)</text>
                        <br/>
                        <text style={{'font-weight':'bold'}}>P.Iva</text> 01882360470
                        <br/>
                        <br/>
                        Nota bene: Non possono esercitare il diritto di recesso i clienti che acquistano con Partita IVA.
                        Ricorda che il diritto di recesso non vale per farmaci (OTC e SOP) , dispositivi medici ed alimenti venduti online
                        <br/><br/>
                        Mediante lettera raccomandata con avviso di ricevimento. Il cliente può avvalersi del diritto di recesso entro 14 giorni lavorativi dalla ricezione della merce. La comunicazione può essere inviata, entro lo stesso termine, anche mediante telegramma, telex, posta elettronica e fax, a condizione che sia confermata mediante lettera raccomandata con avviso di ricevimento entro le quarantotto ore successive. Affinchè il reso venga accettato la merce deve essere integra ed imballata in modo corretto, così da non subire danni durante il trasporto. Il rimborso, equivalente all'importo del prodotto ed alle spese di consegna originarie, viene ricevuto dal cliente entro 14 giorni dal momento della recessione del contratto di acquisto. Le spese di spedizione per la restituzione dei prodotti è a carico del cliente. Farmakon si avvale della facoltà di non accettare merce danneggiata o rotta.
                    </text>
                </Row>
            </Grid>
        )
    }

}

export default RecessoGaranzia