import _ from 'lodash'
import { magento } from '../lib/magento'
import { magentoOptions } from '../config/settings'
import history from '../lib/helper/history'
import {
  MAGENTO_INIT,
  MAGENTO_GET_CATEGORY_TREE,
  MAGENTO_CURRENT_CATEGORY,
  MAGENTO_GET_CATEGORY_PRODUCTS,
  MAGENTO_UPDATE_CONF_PRODUCT,
  MAGENTO_GET_CONF_OPTIONS,
  MAGENTO_LOAD_MORE_CATEGORY_PRODUCTS,
  MAGENTO_PRODUCT_ATTRIBUTE_OPTIONS,
  MAGENTO_CURRENT_PRODUCT,
  MAGENTO_GET_PRODUCT,
  MAGENTO_GET_PRODUCT_MEDIA,
  MAGENTO_CREATE_CART,
  MAGENTO_ADD_TO_CART_LOADING,
  MAGENTO_ADD_CART_BILLING_ADDRESS,
  MAGENTO_GET_CART_SHIPPING_METHODS,
  MAGENTO_GET_CART_PAYMENT_METHODS,
  MAGENTO_PLACE_GUEST_CART_ORDER,
  MAGENTO_ADD_SHIPPING_TO_CART,
  MAGENTO_ADD_TO_CART,
  MAGENTO_GET_CART,
  MAGENTO_CART_ITEM_PRODUCT,
  MAGENTO_GET_COUNTRIES,
  MAGENTO_CREATE_CUSTOMER,
  UI_CHECKOUT_ACTIVE_SECTION,
  UI_CHECKOUT_CUSTOMER_NEXT_LOADING,
  HOME_SCREEN_DATA,
  MAGENTO_GET_FEATURED_PRODUCTS,
  MAGENTO_UPDATE_FEATURED_CONF_PRODUCT,
  MAGENTO_REMOVE_FROM_CART,
  MAGENTO_REMOVE_FROM_CART_LOADING,
  MAGENTO_CURRENT_CUSTOMER
  // MAGENTO_CHANGE_CART_ITEM_QTY
} from './types'
import {CUSTOMER_TYPE, GUEST_TYPE} from "../lib/magento/types";

export const initMagento = () => {
  magento.setOptions(magentoOptions)

  return async dispatch => {
    try {
      await magento.init()
      dispatch({ type: MAGENTO_INIT, payload: magento })
      magento.admin.getStoreConfig()
    } catch (error) {
      console.log(error)
    }
  }
}

export const getHomeData = () => {
  return async dispatch => {
    try {
      await magento.admin.getStoreConfig()
      const value = await magento.getHomeData()
      console.log('home', value)
      const payload = JSON.parse(value.content.replace(/<\/?[^>]+(>|$)/g, ''))
      dispatch({ type: HOME_SCREEN_DATA, payload })

      _.forEach(payload.featuredCategories, (details, categoryId) =>
        getFeaturedCategoryProducts(categoryId, dispatch)
      )
    } catch (e) {
      console.log(e)
    }
  }
}

const getFeaturedCategoryProducts = async (categoryId, dispatch) => {
  try {
    const products = await magento.admin.getProducts(categoryId)
    dispatch({
      type: MAGENTO_GET_FEATURED_PRODUCTS,
      payload: { categoryId, products }
    })
    updateConfigurableProductsPrices(
      products.items,
      dispatch,
      MAGENTO_UPDATE_FEATURED_CONF_PRODUCT
    )
  } catch (e) {
    console.log(e)
  }
}

export const getCategoryTree = () => dispatch => (
  magento.admin
    .getCategoriesTree()
    .then(payload => {
      dispatch({ type: MAGENTO_GET_CATEGORY_TREE, payload })
    })
    .catch(error => {
      console.log(error)
    })
)

export const searchProducts = ({ searchCriteria, perPage, offset }) => {
  return dispatch => {
    if (offset) {
      dispatch({ type: MAGENTO_LOAD_MORE_CATEGORY_PRODUCTS, payload: true })
    }
    magento.admin
      .searchProducts(searchCriteria, perPage, offset)
      .then(payload => {
        dispatch({ type: MAGENTO_GET_CATEGORY_PRODUCTS, payload })
        dispatch({ type: MAGENTO_LOAD_MORE_CATEGORY_PRODUCTS, payload: false })
        updateConfigurableProductsPrices(payload.items, dispatch)
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const getProductsForCategory = ({ id, perPage, offset }) => {
  return dispatch => {
    if (offset) {
      dispatch({ type: MAGENTO_LOAD_MORE_CATEGORY_PRODUCTS, payload: true })
    }
    magento.admin
      .getProducts(id, perPage, offset)
      .then(payload => {
        dispatch({ type: MAGENTO_GET_CATEGORY_PRODUCTS, payload })
        dispatch({ type: MAGENTO_LOAD_MORE_CATEGORY_PRODUCTS, payload: false })
        updateConfigurableProductsPrices(payload.items, dispatch)
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const getProductsByBrand = (brandName, perPage, offset) => {
  return dispatch => {
    dispatch({type: 'MAGENTO_LOADING_MORE_BRAND_PRODUCTS', payload: true})
    magento.admin.getProductsByBrand(brandName, perPage, offset).then(payload => {
          dispatch({type: 'MAGENTO_GET_BRAND_PRODUCTS', payload})
        }
    ).catch(error => {
      console.log(error)
    })
  }
}

export const getCoupons = () => {
  return dispatch => {
    magento.admin.getCoupons().then(
        payload => {dispatch({type: 'MAGENTO_GET_COUPONS', payload})}
    ).catch(error => {
      console.log(error)
    })
  }
}

export const getProductsHome =({data}) => {
  return dispatch => {
    magento.admin
        .getProductsHome(data)
        .then(payload => {
          dispatch({ type: MAGENTO_GET_CATEGORY_PRODUCTS, payload })
          dispatch({ type: MAGENTO_LOAD_MORE_CATEGORY_PRODUCTS, payload: false })
          updateConfigurableProductsPrices(payload.items, dispatch)
        })
        .catch(error => {
          console.log(error)
        })
  }
}

export const getProductsForCategoryOrChild = (category, offset) => {
  return async dispatch => {
    if (offset) {
      dispatch({ type: MAGENTO_LOAD_MORE_CATEGORY_PRODUCTS, payload: true })
    }

    try {
      const payload = await magento.admin
        .getSearchCreteriaForCategoryAndChild(category, 10, offset)
      dispatch({ type: MAGENTO_GET_CATEGORY_PRODUCTS, payload })
      dispatch({ type: MAGENTO_LOAD_MORE_CATEGORY_PRODUCTS, payload: false })
      updateConfigurableProductsPrices(payload.items, dispatch)
    } catch (e) {
      console.log(e)
    }
  }
}

export const getConfigurableProductOptions = sku => {
  return dispatch => {
    magento.admin
      .getConfigurableProductOptions(sku)
      .then(data => {
        dispatch({ type: MAGENTO_GET_CONF_OPTIONS, payload: data })
        data.forEach(option => {
          magento.admin
            .getAttributeByCode(option.attribute_id)
            .then(attributeOptions => {
              dispatch({
                type: MAGENTO_PRODUCT_ATTRIBUTE_OPTIONS,
                payload: {
                  attributeId: option.attribute_id,
                  options: attributeOptions.options,
                  attributeCode: attributeOptions.attribute_code
                }
              })
            })
            .catch(error => {
              console.log(error)
            })
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
}

const updateConfigurableProductsPrices = (products, dispatch, type) => {
  products.forEach(product => {
    if (product.type_id === 'configurable') {
      updateConfigurableProductPrice(product, dispatch, type)
    }
  })
}

const updateConfigurableProductPrice = async (
  product,
  dispatch,
  type = MAGENTO_UPDATE_CONF_PRODUCT
) => {
  const { sku } = product
  try {
    const data = await magento.admin.getConfigurableChildren(sku)
    dispatch({ type, payload: { sku, children: data } })
  } catch (e) {
    console.log(e)
  }
}

export const getProductMedia = ({ sku }) => {
  return dispatch => {
    magento.admin
      .getProductMedia(sku)
      .then(data => {
        dispatch({ type: MAGENTO_GET_PRODUCT_MEDIA, payload: { sku: sku, media: data } })
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const setCurrentCategory = category => {
  return {
    type: MAGENTO_CURRENT_CATEGORY,
    payload: category
  }
}

export const setCurrentProduct = product => {
  return {
    type: MAGENTO_CURRENT_PRODUCT,
    payload: product
  }
}

export const createCustomerCart = customerId => {
  return async dispatch => {
    if (customerId) {
      const cartId = await magento.admin.getCart(customerId)
      dispatch({ type: MAGENTO_CREATE_CART, payload: cartId })
    }
  }
}

export const getCart = () => {
  // INVOKED BY index.js --> helper/app.js 😉
  return async dispatch => {
    try {
      let cart
      if (magento.isCustomerLogin()) {
        cart = await magento.customer.getCustomerCart()
      } else {
        cart = await magento.guest.initGuestCart()
      }

      dispatch({ type: MAGENTO_GET_CART, payload: cart })

      /* cart.items.forEach(currentItem => {
        dispatch(getProductMedia(currentItem))
      })  */
    } catch (error) {
      console.log(error)
    }
  }
}

export const addToCartLoading = isLoading => {
  return {
    type: MAGENTO_ADD_TO_CART_LOADING,
    payload: isLoading
  }
}

/*export const addToWishList = ({wishlist_id, item, customer}) => {
    if(window.localStorage.getItem('mage_guest_cartId')){
      //guest
    } else {
      magento.admin.addToWishList(item)
    }
}*/

export const addToCart = ({ cartId, item, customer }) => {
  return async dispatch => {
    try {
      if (cartId) {
        return dispatchAddToCart(dispatch, cartId, item)
      }

      const updatedItem = item
      if (magento.isCustomerLogin()) {
        const customerCartId = await magento.admin.getCart(customer.id)
        dispatch({ type: MAGENTO_CREATE_CART, payload: customerCartId })
        updatedItem.cartItem.quoteId = customerCartId
        return dispatchAddToCart(dispatch, customerCartId, updatedItem)
      }

      console.log('non è customer')

      /* 🍁code */
      let guestCartId
      const localStorageCartId = window.localStorage.getItem('mage_guest_cartId')

      if (localStorageCartId) {
        console.log('cè il mage guest cart')
        guestCartId = localStorageCartId // await magento.guest.getGuestCart(localStorage_cartId)
      } else {
        console.log('si crea totalmente')
        guestCartId = await magento.guest.createGuestCart()
        window.localStorage.setItem('mage_guest_cartId', guestCartId)
      }

      dispatch({ type: MAGENTO_CREATE_CART, payload: guestCartId })
      updatedItem.cartItem.quoteId = guestCartId
      return dispatchAddToCart(dispatch, guestCartId, updatedItem)
    } catch (error) {
      console.log(error)
    }
  }
}

const dispatchAddToCart = async (dispatch, cartId, item) => {
  try {
    dispatch(addToCartLoading(item.cartItem.sku))

    let result
    if (magento.isCustomerLogin()) {
      result = await magento.customer.addItemToCart(item)
    } else {
      result = await magento.guest.addItemToCart(cartId, item)
    }
    await dispatch({ type: MAGENTO_ADD_TO_CART, payload: result })
    await dispatchGetGuestCart(dispatch, cartId)
    // await dispatch(getProductMedia(item.cartItem))
    await dispatch(addToCartLoading(false))
  } catch (e) {
    console.log(e)
  }
}

const dispatchGetGuestCart = async (dispatch, cartId) => {
  try {
    let data
    if (magento.isCustomerLogin()) {
      data = await magento.customer.getCustomerCart()
    } else {
      data = await magento.guest.getGuestCart(cartId)
    }
    dispatch({ type: MAGENTO_GET_CART, payload: data })
  } catch (e) {
    console.log(e)
  }
}

export const getProductBySku = function (sku) {
  return async dispatch => {
    await magento.admin
      .getProductBySku(sku)
      .then(data => {
        dispatch({ type: MAGENTO_GET_PRODUCT, payload: data })
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const cartItemProduct = sku => {
  return dispatch => {
    magento.admin
      .getProductBySku(sku)
      .then(data => {
        dispatch({ type: MAGENTO_CART_ITEM_PRODUCT, payload: data })
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const addCartBillingAddress = (cartId = '', address, shouldEstimateShippingMethods = true) => {
  return async dispatch => {
    try {
      let data
      if (magento.isCustomerLogin()) {
        data = await magento.customer.addCartBillingAddress(address)
      } else {
        data = await magento.guest.addGuestCartBillingAddress(cartId, address)
      }
      dispatch({ type: MAGENTO_ADD_CART_BILLING_ADDRESS, payload: data })
    } catch (error) {
      console.log(error)
    }

    if (shouldEstimateShippingMethods) {
      try {
        let data
        if (magento.isCustomerLogin()) {
          data = await magento.customer.cartEstimateShippingMethods(address)
        } else {
          data = await magento.guest.guestCartEstimateShippingMethods(
            cartId,
            address
          )
        }
        dispatch({ type: MAGENTO_GET_CART_SHIPPING_METHODS, payload: data })
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export const updateCustomerProfile = (customer) => {
  return async dispatch => {
    try {
      let data
      if (magento.isCustomerLogin()) {
        data = await magento.customer.updateCustomerProfile(customer)
      }

      dispatch({ type: MAGENTO_CURRENT_CUSTOMER, payload: data })
    } catch (error) {
      console.log(error)
    }
  }
}

export const getGuestCartShippingMethods = cartId => {
  return async dispatch => {
    try {
      let data
      if (magento.isCustomerLogin()) {
        data = await magento.customer.getCartShippingMethods()
      } else {
        data = await magento.guest.getGuestCartShippingMethods(cartId)
      }
      dispatch({ type: MAGENTO_GET_CART_SHIPPING_METHODS, payload: data })
    } catch (error) {
      console.log(error)
    }
  }
}

export const addGuestCartShippingInfo = (cartId, address) => {
  return async dispatch => {
    try {
      dispatch({ type: UI_CHECKOUT_CUSTOMER_NEXT_LOADING, payload: true })

      let data
      if (magento.isCustomerLogin()) {
        data = await magento.customer.addCartShippingInfo(address)
      } else {
        data = await magento.guest.addGuestCartShippingInfo(cartId, address)
      }

      dispatch({ type: MAGENTO_ADD_SHIPPING_TO_CART, payload: data })
      dispatch({ type: UI_CHECKOUT_CUSTOMER_NEXT_LOADING, payload: false })
      dispatch({ type: UI_CHECKOUT_ACTIVE_SECTION, payload: 3 })
      dispatch(getCart())

    } catch (error) {
      console.log(error)
    }
  }
}

/* 🍁code */
export const addShippingAddress = (address) => {
  return async dispatch => {
    try {
      let data
      if (magento.isCustomerLogin()) {
        let customer = await magento.customer.getCurrentCustomer()
        data = await magento.customer.addCartShippingAddress(customer, address)

        dispatch({ type: MAGENTO_CURRENT_CUSTOMER, payload: data })
        dispatch({ type: UI_CHECKOUT_CUSTOMER_NEXT_LOADING, payload: false })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const getGuestCartPaymentMethods = cartId => {
  return async dispatch => {
    try {
      let data
      if (magento.isCustomerLogin()) {
        data = await magento.customer.getCartPaymentMethods()
      } else {
        data = await magento.guest.getGuestCartPaymentMethods(cartId)
      }
      dispatch({ type: MAGENTO_GET_CART_PAYMENT_METHODS, payload: data })
      dispatch({ type: UI_CHECKOUT_CUSTOMER_NEXT_LOADING, payload: false })
      dispatch({ type: UI_CHECKOUT_ACTIVE_SECTION, payload: 4 })
    } catch (error) {
      console.log(error)
    }
  }
}

export const getCountries = () => {
  return dispatch => {
    magento.guest
      .getCountries()
      .then(data => {
        dispatch({ type: MAGENTO_GET_COUNTRIES, payload: data })
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const placeGuestCartOrder = (cartId, payment) => {
  return async dispatch => {
    try {
      dispatch({ type: UI_CHECKOUT_CUSTOMER_NEXT_LOADING, payload: true })

      let data
      if (magento.isCustomerLogin()) {
        data = await magento.customer.placeCartOrder(payment)
      } else {
        data = await magento.guest.placeGuestCartOrder(cartId, payment)
      }

      console.log('MAGENTO_PLACE_GUEST_CART_ORDER', data)
      if (data && !data.message) {
        var data_2 = await magento.customer.getOrderInformation(data)
        dispatch({ type: MAGENTO_PLACE_GUEST_CART_ORDER, payload: data_2.increment_id })
      } else {
        // TODO: manage exception RETRY

        if (magento.isCustomerLogin()) {
          data = await magento.customer.placeCartOrder(payment)
        } else {
          data = await magento.guest.placeGuestCartOrder(cartId, payment)
        }
        if (data && !data.message) {
          var data_2 = await magento.customer.getOrderInformation(data)
          dispatch({ type: MAGENTO_PLACE_GUEST_CART_ORDER, payload: data_2.increment_id })
        }
      }

      dispatch({ type: UI_CHECKOUT_CUSTOMER_NEXT_LOADING, payload: false })
    } catch (error) {
      console.log(error)
    }
  }
}

export const createCustomer = customer => {
  return dispatch => {
    magento.guest
      .createCustomer(customer)
      .then(data => {
        dispatch({ type: MAGENTO_CREATE_CUSTOMER, payload: data })
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const removeFromCartLoading = isLoading => {
  return {
    type: MAGENTO_REMOVE_FROM_CART_LOADING,
    payload: isLoading
  }
}


export const removeFromCart = ({ cart, item }) => {
  return async dispatch => {
    try {
      dispatch({ type: MAGENTO_REMOVE_FROM_CART_LOADING, payload: true })
      if (cart.quote) {
        dispatchRemoveFromCart(dispatch, cart, item)
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: MAGENTO_REMOVE_FROM_CART, payload: error.message })
    }
  }
}


export const getCartTotals = (cartId) => {
  let ret_data
  localStorage.setItem('cartId', cartId)
  console.log(cartId)
  new Promise((resolve, reject) => {
    let cart = localStorage.getItem('customerToken') == '' || localStorage.getItem('customerToken') == null? 'guest-carts' : 'carts'
    const path = `/V1/${cart}/${cartId}/totals`
    magento
        .get(path)
        .then(data => {
          ret_data = data
          localStorage.setItem('cartId', cartId)
          localStorage.setItem('sconto', ret_data.discount_amount)
          localStorage.setItem('totale', ret_data.grand_total)
          localStorage.setItem('coupon_code', ret_data.coupon_code)
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    return ret_data

  })
  return ret_data

}


const dispatchRemoveFromCart = async (dispatch, cart, item) => {
  try {
    /* 🍁code */

    let result
    if (magento.isCustomerLogin()) {
      result = await magento.customer.removeItemFromCart(cart.cartId, item.item_id)
    } else {
      result = await magento.guest.removeItemFromCart(cart.cartId, item.item_id)
    }

    dispatch({ type: MAGENTO_REMOVE_FROM_CART, payload: result })
    dispatchGetCart(dispatch, cart.cartId)
    dispatch({ type: MAGENTO_REMOVE_FROM_CART_LOADING, payload: false })
  } catch (e) {
    dispatch({ type: MAGENTO_REMOVE_FROM_CART, payload: e.message })
    dispatch({ type: MAGENTO_REMOVE_FROM_CART_LOADING, payload: false })
    console.log(e)
  }
}

const dispatchGetCart = async (dispatch, cartId) => {
  try {
    let data
    if (magento.isCustomerLogin()) {
      data = await magento.customer.getCustomerCart()
    } else {
      data = await magento.guest.getGuestCart(cartId)
    }

    dispatch({ type: MAGENTO_GET_CART, payload: data })
  } catch (e) {
    console.log(e)
  }
}

export const changeCartItemQty = ({ cart, item, operation }) => {
  return async dispatch => {
    try {
      dispatchChangeCartItemQty(dispatch, cart, item, operation)
    } catch (error) {
      console.log(error)
      // dispatch({ type: MAGENTO_REMOVE_FROM_CART, payload: error.message })
    }
  }
}

const dispatchChangeCartItemQty = async (dispatch, cart, item, operation) => {
  try {
    /* 🍁code */
    let isCustomer = magento.isCustomerLogin()

    if (cart.quote) {
      isCustomer
        ? await magento.customer
          .changeCartItemQty(cart.cartId, item, operation)
        : await magento.guest
          .changeCartItemQty(cart.cartId, item, operation)

      // dispatch({ type: MAGENTO_CHANGE_CART_ITEM_QTY, payload: result })
      dispatchGetCart(dispatch, cart.cartId)
    } else {
      console.log('No cart quote')
    }
    /*
    let result
    if ( magento.isCustomerLogin() ) {
      result = await magento.customer.removeItemFromCart(cart.cartId, item.item_id)
    } else {
      result = await magento.guest.removeItemFromCart(cart.cartId, item.item_id)
    }

    */
  } catch (e) {
    /*
    dispatch({ type: MAGENTO_REMOVE_FROM_CART, payload: e.message })
    dispatch({ type: MAGENTO_REMOVE_FROM_CART_LOADING, payload: false })
    */
    console.log(e)
  }
}

export const getCustomerOrders = function (email) {
  return async dispatch => {
    await magento.admin
        .getOrders(email)
        .then(data => {
          dispatch({ type: "CUSTOMER_ORDERS", payload: data })
        })
        .catch(error => {
          console.log(error)
        })
  }
}
