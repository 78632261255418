import variables from '../../assets/style/variables'

export const style = {
    miniCart: {
        padding: '0.5rem'
    },
    cartContainer: {
        width: '100%'
    },
    miniCartProducts: {
        padding: '0.5rem'
    },

    add: {
        width: '100%',
        backgroundColor: variables.orange + ' !important'
    },

    mdiv1: {
        "width": "16px",
        "float": "right",
        "height": "16px",
        "border": "1px solid #808080",
        "marginTop": "3px",
        "borderRadius": "50px"
    },
    mdiv: {
        "width": "1px",
        "height": "10px",
        "ZIndex": "1",
        "transform": "rotate(\n45deg\n)",
        "marginLeft": "8px",
        "backgroundColor": "#808080",
        "marginTop": "3px"
    },
    md: {
        "width": "1px",
        "height": "10px",
        "ZIndex": "2",
        "transform": "rotate(\n90deg\n)",
        "backgroundColor": "#808080",
        "marginTop": "3px",
        "marginLeft": "0px"
    },

    cartAction: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 20
    },

    cartActionQuantity: {
        border: '1px solid ' + variables.black,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100px',
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 3,
        paddingBottom: 3
    },

    cartActionCost: {
        fontWeight: 'bold',
        paddingTop: 3
    },

    cartHeader: {
        display: 'flex',
        marginTop: 10
    },
    cartHeaderItem: {
        '& i': {
            display: 'block',
            marginBottom: 5,
            marginLeft: 3,
            marginRight: 3
        }
    },

    cartFooter: {
        borderTop: '1px solid ' + variables.black,
        marginTop: 10,
        paddingTop: 10,
        display: 'flex',
        '& i': {
            marginRight: 5
        }
    },

    miniCartTotal: {
        '& .mdc-typography--body1': {
            fontWeight: 'bold'
        }
    },

    miniCartPrice: {
        textAlign: 'right'
    }
}
