import React, { Component } from 'react'
import injectSheet from 'react-jss'
import { Grid, Col, Row } from 'react-flexbox-grid'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { MenuSurface, MenuSurfaceAnchor } from '@rmwc/menu'
// import {Toolbar, ToolbarRow, ToolbarSection, ToolbarMenuIcon, ToolbarTitle, ToolbarIcon, TopAppBarRow, TopAppBarSection, TopAppBarNavigationIcon, TopAppBar} from 'rmwc';
import { FREE_SHIPPING_MIN_ORDER, routes } from '../../config/settings'
import { priceFormat } from '../../lib/format'
import { showLogin } from '../../actions'
import Login from '../Login'
import { style } from './style'
import "./css_style.css"
import LocalShippingOutlined from '@material-ui/icons/LocalShippingOutlined'
import PhoneOutlined from '@material-ui/icons/PhoneOutlined'
import cornice from '../../assets/images/cornice.png'

class NavBarMini extends Component {
  render () {
    const { classes } = this.props

    return (
        <React.Fragment>
        {/*<Row className={"cornice"}>*/}

        {/*</Row>*/}
      <Grid fluid className={classes.navBarMini}>
        <Row>
          <Col sm={2} xs={12}>
            <MenuSurfaceAnchor>
              <MenuSurface open={this.props.showModalLogin}
                onClose={() => this.props.showLogin(false)}
                className={classes.loginContainer}>
                <Login />
              </MenuSurface>
              {this.props.account.customer != null? localStorage.setItem('nome', this.props.account.customer.firstname) : localStorage.setItem('nome', ' ')}
              { !this.props.account.customer ? (
                <div className={classes.miniLogin}
                  onClick={() => this.props.showLogin(!this.props.showModalLogin)}
                >
                Accedi / Registrati
                </div>
              ) : (
                <div className={classes.miniLogin}>
                  <Link to={routes.personal.path}>
                    Ciao {this.props.account.customer.firstname}!
                  </Link>
                </div>
              )}
            </MenuSurfaceAnchor>
          </Col>

          <Col sm={5} xs={6}>
            <div>Bisogno di assistenza?
              <PhoneOutlined fontSize={'20px'} color={'white'} style={{'padding':0, 'position':'absolute','margin-left':'0.5%'}}/>
            <b style={{'margin-left':'5.5%'}}>CHIAMACI 0573 4477444</b>
            </div>
          </Col>
          <Col sm={5} xs={6} className={classes.freeShipping}>
            <LocalShippingOutlined fontSize={'20px'} style={{'margin-left':'-2%', 'padding':0, 'position':'absolute'}}/>
            <a href='#/shipping-info'>
              SPEDIZIONE GRATUITA PER ORDINI SUPERIORI A {priceFormat(FREE_SHIPPING_MIN_ORDER)}
            </a>
          </Col>
        </Row>
      </Grid>
        </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  ...state.ui
})

export default connect(
  mapStateToProps,
  { showLogin }
)(injectSheet(style)(NavBarMini))
