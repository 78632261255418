import React, {Component} from 'react'
// import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import injectSheet from 'react-jss'
import {Grid, Col, Row} from 'react-flexbox-grid'
import {Button, Icon} from 'rmwc'
import {Elevation} from '@rmwc/elevation'
import {Typography} from 'rmwc/Typography'
import {removeFromCart, changeCartItemQty, getGuestCartShippingMethods, putCoupon} from '../../actions/api'
import {currentCustomer} from "../../actions";
import {priceFormat} from '../../lib/format'
import CartProduct from '../../components/CartProduct'
import {style} from './style'
import KUButton from "../../components/KUButton";
import TextField from "@material-ui/core/es/TextField/TextField";
import {getCartTotals} from '../../actions/api'
import {CUSTOMER_TYPE, GUEST_TYPE} from "../../lib/magento/types";
import {magento} from '../../lib/magento'
import LocalShippingOutlined from '@material-ui/icons/LocalShippingOutlined'
import SecurityOutlined from '@material-ui/icons/SecurityOutlined'
import LockOutlined from '@material-ui/icons/LockOutlined'
import {Dialog} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";


class Cart extends Component {

    constructor(props) {
        super(props)
        console.log(this.props)
        const cap = this.checkCap()
        this.state = {
            items_qty: 0,
            items_total: 0,
            shipping_cost: 0,
            cart_total: 0,
            isDesktop: false,
            coupon: '',
            totale: '',
            sconto: '',
            coupon_code: '',
            error_coupon: false,
            cap: cap,
            capNotFree: false
        }
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    checkCapNotFree() {
        var check = false
        console.log("ciao")
        console.log(this.props)
        if (this.props.cart.customer) {
            var customer_cap = this.props.cart.customer.addresses[0].postcode
            let a = ["23030", "23041", "22060", "92010", "91017"]
            var i
            for (i = 0; i < a.length; i++) {
                console.log("dentro")
                if (a[i] === customer_cap) {
                    check = true

                }
            }
        }
        if (check) {
            if (customer_cap != "23041") {
                this.state.cap = false
            }
        }
        this.state.capNotFree = check
    }

    checkCap() {
        var check = false
        if (this.props.cart.customer) {
            let customer_cap = this.props.cart.customer.addresses[0].postcode
            let a = ["04020", "04027", "07024", "07046", "09014", "22060", "23030", "25050", "30100", "30121", "30122", "30123", "30124", "30125", "30126", "30132", "30133", "30135", "30141", "30142", "57030", "57031", "57032", "57033", "57034", "57036", "57037", "57038", "57039", "58012", "58019", "71040", "80070", "80071", "80073", "80074", "80075", "80076", "80077", "80079", "90010", "91010", "91017", "91023", "98050", "98055"]
            let b = ["90", "91", "92", "93", "94", "95", "96", "97", "98", "09", "08", "07", "30"]
            var i
            for (i = 0; i < a.length; i++) {
                if (a[i] === customer_cap) {
                    check = true
                }
                if (b[i] === customer_cap.substr(0, 2)) {
                    check = true
                }
            }
        }
        return check
    }

    async getCoupon() {
        if (this.state.coupon != '') {
            const response = await magento.guest.putCoupon(this.state.coupon, this.props.cart.cartId)
            if (response.message) {
                this.setState({error_coupon: true})
            } else {
                window.location.reload()
            }
        }
        this.checkCoupon()
    }

    checkCoupon() {
        let data = getCartTotals(this.props.cart.cartId)
        this.state.totale = localStorage.getItem('sconto')
        if (this.state.items_total <= parseFloat('59.90')) {
            this.state.totale = parseFloat(this.state.items_total) + parseFloat(this.state.totale) + (this.state.cap ? parseFloat('7.90') : (this.state.capNotFree ? parseFloat('35.00') : parseFloat('5.90')))
        }
        if (this.state.items_total > 59.90 && this.state.capNotFree) {
            this.state.totale = parseFloat(this.state.items_total) + parseFloat(this.state.totale) + (this.state.cap ? parseFloat('7.90') : (this.state.capNotFree ? parseFloat('35.00') : parseFloat('5.90')))
        }
        if (this.state.items_total > 59.90 && !this.state.capNotFree) {
            this.state.totale = parseFloat(this.state.totale) + parseFloat(this.state.items_total)
        }
        this.state.sconto = localStorage.getItem('sconto')
        this.state.coupon_code = localStorage.getItem('coupon_code')
    }

    updatePredicate() {
        this.setState({isDesktop: window.innerWidth > 620});
    }

    componentWillMount() {
        window.removeEventListener("resize", this.updatePredicate);
    }

    componentDidMount() {
        this.setState({
            items_qty: this.props.products.length,
            items_total: Object.values(this.props.products).map((el, i) => el.price * el.qty).reduce((prev, next) => (prev + next), 0),
            cart_total: this.state.items_total + this.state.shipping_cost,
        })
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    componentDidUpdate(prevProps) {
        if (this.props.products !== prevProps.products) {
            this.setState({
                items_total: Object.values(this.props.products).map((el, i) => el.price * el.qty).reduce((prev, next) => (prev + next), 0)
            })

            setTimeout(() => (
                this.props.getGuestCartShippingMethods(this.props.cart.cartId)
            ), 300)
        }

        if (this.props.checkout !== prevProps.checkout) {
            console.log(parseFloat(this.state.items_total) + parseFloat(this.calcolaSpeseSpedizione()))
            this.setState({
                shipping_cost: this.calcolaSpeseSpedizione(),
                cart_total: this.props.checkout.shipping[0] ? (parseFloat(this.state.items_total) + parseFloat(this.calcolaSpeseSpedizione())) : 0, //((this.state.cap ? this.props.checkout.shipping[3].amount : (this.state.capNotFree? this.props.checkout.shipping[4].amount : this.props.checkout.shipping[0].amount) )) : 0,
                items_qty: this.props.products.length
            })
        }
    }

    calcolaSpeseSpedizione() {
        let ret
        if (this.props.checkout.shipping[0]) {
            if (this.state.cap && this.state.items_total <= 59.90) {
                ret = this.props.checkout.shipping[3].amount
            } else if (this.state.capNotFree) {
                ret = this.props.checkout.shipping[4].amount
            } else if (!this.state.cap && !this.state.capNotFree && this.state.items_total <= 59.90) {
                ret = this.props.checkout.shipping[0].amount
            } else if (this.state.items_total > 59.90) {
                ret = 0
            }
        }
        return ret
    }

    handleClose = () => {
        this.setState({
            error_coupon: false
        })
    };

    async deleteCoupon() {
        const response = await magento.guest.deleteCoupon("delete", this.props.cart.cartId)
        if (response.message) {
            window.location.reload()
        }
    }


    render() {
        const {classes, quote, key} = this.props
        const isDesktop = this.state.isDesktop
        this.state.cap = this.checkCap()
        this.checkCapNotFree()
        this.checkCoupon()

        return (
            <Grid style={{'margin-left': isDesktop ? false : '-10%'}}>
                <Dialog open={this.state.error_coupon}
                        onClose={() => this.handleClose()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Errore"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Il coupon inserito non è corretto.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleClose()} style={{backgroundColor: 'green', color: 'white'}}>
                            Ok, ho capito!
                        </Button>
                    </DialogActions>
                </Dialog>
                <Row>
                    <Col md={8}>
                        <Elevation z={isDesktop ? 3 : 0} className={classes.bodyCart}>
                            <div className={classes.cartTitles} style={{'margin-left': isDesktop ? 0 : '20%'}}>
                                <Typography use='headline6'>
                                    <strong style={{'font-size': '17px'}}>
                                        Carrello ({quote.items_qty} articoli)
                                    </strong>
                                </Typography>
                            </div>
                            <Grid className={classes.products} style={{
                                'width': '151%',
                                'margin-left': isDesktop ? false : '14%',
                                'text-align': 'center'
                            }}>
                                {this.props.products &&
                                Object.values(this.props.products)
                                    .map((product, i) =>
                                        <React.Fragment>
                                            <CartProduct product={product} key={i}/>
                                            <br/>
                                            {isDesktop ? false : <hr style={{'width': '98%'}}/>}
                                        </React.Fragment>
                                    )
                                }
                            </Grid>
                        </Elevation>
                    </Col>

                    <Col md={4}>
                        <Elevation z={isDesktop ? 3 : 0} className={classes.bodyCart}>
                            <Grid style={{
                                'width': isDesktop ? false : '160%',
                                'margin-right': isDesktop ? 0 : 'auto',
                                'padding-left': isDesktop ? 0 : '10%'
                            }}>
                                <Row>
                                    <Col md={12}>
                                        <div className={classes.cartTitles}
                                             style={{'margin-top': isDesktop ? '0' : '-70px'}}>
                                            <Typography use='headline6' className={classes.cartTitles}>
                                                <strong style={{'font-size': '20px'}}>
                                                    Totale
                                                </strong>
                                            </Typography>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8}>
                                        <Typography use='body1'>
                                            Totale prodotti
                                        </Typography>
                                    </Col>
                                    <Col md={4} align='right' style={{'margin-top': isDesktop ? '0' : '-22px'}}>
                                        <Typography use='body1'>
                                            {priceFormat(this.state.items_total)}
                                        </Typography>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col md={8}>
                                        <Typography use='body1'>
                                            Spedizione
                                        </Typography>
                                    </Col>
                                    <Col md={4} align='right' style={{'margin-top': isDesktop ? '0' : '-22px'}}>
                                        <Typography use='body1'>
                                            {priceFormat(this.state.shipping_cost) == 0 ? '€ 0,00' : priceFormat(this.state.shipping_cost)}
                                        </Typography>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col md={8}>
                                        <Typography use='body1'>
                                            {this.state.coupon_code == undefined || this.state.coupon_code == 'undefined' ? false :
                                                <Typography onClick={() => this.deleteCoupon()}
                                                            style={{'font-weight': '900', "cursor":"pointer"}}>X </Typography>}
                                            Coupon {this.state.coupon_code == undefined || this.state.coupon_code == 'undefined' ? false : " " + this.state.coupon_code}

                                        </Typography>
                                    </Col>
                                    <Col md={4} align='right' style={{'margin-top': isDesktop ? '0' : '-22px'}}>
                                        <Typography use='body1'>
                                            {this.state.coupon_code == undefined || this.state.coupon_code == 'undefined' ? "€ 0,00" : priceFormat(this.state.sconto)}
                                        </Typography>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <div className={classes.cartItemSeparator}/>
                                    </Col>
                                </Row>

                                <hr/>
                                <Row>
                                    <Col md={12} className={classes.cartCoupon}>
                                        <Typography use='body1'>
                                            Hai un codice sconto?
                                        </Typography>
                                        <br/>

                                        <div style={{'width': '100%', 'height': '48px'}}>
                                            <TextField
                                                style={{'width': '70%', 'height': '48px'}}
                                                placeholder={'Inseriscilo qui'}
                                                value={this.state.coupon}
                                                onChange={event => this.setState({coupon: event.target.value})}
                                            ></TextField>
                                            <KUButton
                                                style={{
                                                    'background-color': '#111B34',
                                                    'color': 'white',
                                                    'width': '30%',
                                                    'float': 'right',
                                                    'margin-right': '0',
                                                    'margin-top': '3.3%',
                                                    'border': '0',
                                                    'height': '41px'
                                                }}
                                                clickHandler={() => this.getCoupon()}
                                                disabled={this.state.coupon === '' ? true : false}
                                            >Inserisci</KUButton>
                                        </div>

                                    </Col>
                                </Row>
                                <br/>
                                <br/>
                                <Row>
                                    <Col md={8}>
                                        <div>
                                            <Typography use='headline6'>
                                                <strong style={{'font-size': '20px'}}>Totale</strong> (tasse incluse)
                                            </Typography>
                                        </div>
                                    </Col>
                                    <Col md={4} align='right'
                                         style={{'margin-top': isDesktop ? '0' : '-22px', 'font-size': '20px'}}>
                                        {
                                            this.state.coupon_code == undefined || this.state.coupon_code == 'undefined' ?
                                                ((priceFormat(this.state.items_total) + priceFormat(this.state.shipping_cost)) == 0 ? '€ 0,00' : priceFormat(this.state.cart_total)) :
                                                (priceFormat(this.state.items_total) + priceFormat(this.state.shipping_cost)) == 0 ? '€ 0,00' :
                                                    <div>
                                                        <del>{priceFormat(this.state.items_total + this.state.shipping_cost)}</del>
                                                        <p style={{
                                                            'fontWeight': 'bold',
                                                            'font-size': '17px'
                                                        }}>{priceFormat(this.state.totale)}</p></div>
                                        }
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col md={12} className={classes.cartProceed}>
                                        <Link to='/checkout'>
                                            <Button unelevated
                                                    ripple={false}
                                                    disabled={!this.state.items_qty > 0}
                                                    style={{'width': isDesktop ? '106%' : '100%'}}
                                            >
                                                <Typography use='button'>PROCEDI</Typography>
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>
                                <br/>
                                {isDesktop &&
                                <Row>
                                    <Col md={12}>
                                        <div className={classes.cartHeader}>
                                            <div className={classes.cartHeaderItem}>
                                                <LocalShippingOutlined/>
                                                <Typography use='body1'>Spedizione gratuita da €59,90</Typography>
                                            </div>

                                            <div className={classes.cartHeaderItem}>
                                                <SecurityOutlined/>
                                                <Typography use='body1'>Solo prodotti certificati e
                                                    garantiti</Typography>
                                            </div>

                                            <div className={classes.cartHeaderItem}>
                                                <LockOutlined/>
                                                <Typography use='body1'>Pagamenti sicuri. Dati criptati</Typography>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>}

                                {!isDesktop && <Row className={classes.rowsicurezza}>
                                    <Col xs={12} className={classes.boxsicurezza}>
                                        <div>
                                            <Row className={classes.rowboxsicurezza}>
                                                <Col xs={2} className={classes.sicurezzaicone}>
                                                    <LocalShippingOutlined/>
                                                </Col>
                                                <Col xs={10} className={classes.descrizionesicurezza}>
                                                    Spedizione gratuita da €59,90
                                                </Col>
                                            </Row>

                                            <Row className={classes.rowboxsicurezza}>
                                                <Col xs={2} className={classes.sicurezzaicone}>
                                                    <SecurityOutlined/>
                                                </Col>
                                                <Col xs={10} className={classes.descrizionesicurezza}>
                                                    Solo prodotti certificati e
                                                    garantiti
                                                </Col>
                                            </Row>

                                            <Row className={classes.rowboxsicurezza}>
                                                <Col xs={2} className={classes.sicurezzaicone}>
                                                    <LockOutlined/>
                                                </Col>
                                                <Col xs={10} className={classes.descrizionesicurezza}>
                                                    Pagamenti sicuri. Dati criptati
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>

                                </Row>}
                            </Grid>

                        </Elevation>
                    </Col>
                </Row>
            </Grid>
        )
    }


}

const mapStateToProps = (state) => ({
    quote: state.cart.quote,
    products: state.cart.items,
    checkout: state.checkout,
    cart: state.cart
})

export default connect(
    mapStateToProps,
    {removeFromCart, changeCartItemQty, getGuestCartShippingMethods, currentCustomer}
)(injectSheet(style)(Cart))
