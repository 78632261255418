import React, { Component } from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import { Grid, Col, Row } from 'react-flexbox-grid'
import { TextField } from '@rmwc/textfield'
import { Typography } from '@rmwc/typography'
import { Select } from 'rmwc'
import KUButton from '../KUButton'
import { style } from './style'
import { updateCustomerProfile } from '../../actions'
import {routes} from "../../config/settings";
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'

class ProfileData extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isEditing: false,
      email: '',
      default_billing: '',
      firstname: '',
      lastname: '',
      streetName: '',
      streetNumber: '',
      city: '',
      regionCode: '',
      telephone: '',
      postcode: '',
      password: '',
      confirmPassword: '',
      dob: '',
      privatoOazienda: 0,
      pIva: '',
      sdi: '',
      pec: '',
      company: '',
      codiceFiscale: '',
      newsletter: '',
      informativa1: '',
      informativa2: '',
      isDesktop: false
    }
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 620 });
  }
  componentWillMount () {
    window.removeEventListener("resize", this.updatePredicate);
  }

  componentDidMount () {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
    if (this.props.customer) {
      let customer = this.props.customer
      let defaultAddress = //customer.addresses.find((address) => address.id === parseInt(customer.default_billing)) ||
                           customer.addresses[0]

      this.setState({
        email: customer.email,
        default_billing: false,
        firstname: customer.firstname,
        lastname: customer.lastname,
        dob: customer.dob,
        pIva: customer.taxvat,
        streetName: defaultAddress.street[0],
        streetNumber: defaultAddress.street[1],
        city: defaultAddress.city,
        regionCode: defaultAddress.region.region,
        telephone: defaultAddress.telephone,
        postcode: defaultAddress.postcode,
        privatoOazienda: customer.custom_attributes.find(e => e.attribute_code === 'tipo_azienda') ? customer.custom_attributes.find(e => e.attribute_code === 'tipo_azienda').value : 0,
        sdi: customer.custom_attributes.find(e => e.attribute_code === 'sdi') ? customer.custom_attributes.find(e => e.attribute_code === 'sdi').value : '',
        pec: customer.custom_attributes.find(e => e.attribute_code === 'pec') ? customer.custom_attributes.find(e => e.attribute_code === 'pec').value : '',
        company: customer.custom_attributes.find(e => e.attribute_code === 'azienda') ? customer.custom_attributes.find(e => e.attribute_code === 'azienda').value : '',
        codiceFiscale: customer.custom_attributes.find(e => e.attribute_code === 'codice_fiscale') ? customer.custom_attributes.find(e => e.attribute_code === 'codice_fiscale').value : '',
        newsletter: customer.custom_attributes.find(e => e.attribute_code === 'newsletter') ? customer.custom_attributes.find(e => e.attribute_code === 'newsletter').value : '',
        informativa1: customer.custom_attributes.find(e => e.attribute_code === 'informativa_1') ? customer.custom_attributes.find(e => e.attribute_code === 'informativa_1').value : '',
        informativa2: customer.custom_attributes.find(e => e.attribute_code === 'informativa_2') ? customer.custom_attributes.find(e => e.attribute_code === 'informativa_2').value : ''
      })
    }
  }
  componentWillUpdate (nextProps) {

    if (nextProps.customer !== this.props.customer) {
      let customer = nextProps.customer
      let defaultAddress = //customer.addresses.find((address) => address.id === parseInt(customer.default_billing)) ||
                           customer.addresses[0] //|| { street: [], region: '' }

      this.setState({
        email: customer.email,
        default_billing: false,
        firstname: customer.firstname,
        lastname: customer.lastname,
        dob: customer.dob,
        pIva: customer.taxvat,

        streetName: defaultAddress.street[0],
        streetNumber: defaultAddress.street[1],
        city: defaultAddress.city,
        regionCode: defaultAddress.region.region,
        telephone: defaultAddress.telephone,
        postcode: defaultAddress.postcode,
        privatoOazienda: customer.custom_attributes.find(e => e.attribute_code === 'tipo_azienda') ? customer.custom_attributes.find(e => e.attribute_code === 'tipo_azienda').value : 0,
        sdi: customer.custom_attributes.find(e => e.attribute_code === 'sdi') ? customer.custom_attributes.find(e => e.attribute_code === 'sdi').value : '',
        pec: customer.custom_attributes.find(e => e.attribute_code === 'pec') ? customer.custom_attributes.find(e => e.attribute_code === 'pec').value : '',
        company: customer.custom_attributes.find(e => e.attribute_code === 'azienda') ? customer.custom_attributes.find(e => e.attribute_code === 'azienda').value : '',
        codiceFiscale: customer.custom_attributes.find(e => e.attribute_code === 'codice_fiscale') ? customer.custom_attributes.find(e => e.attribute_code === 'codice_fiscale').value : '',
        newsletter: customer.custom_attributes.find(e => e.attribute_code === 'newsletter') ? customer.custom_attributes.find(e => e.attribute_code === 'newsletter').value : '',
        informativa1: customer.custom_attributes.find(e => e.attribute_code === 'informativa_1') ? customer.custom_attributes.find(e => e.attribute_code === 'informativa_1').value : '',
        informativa2: customer.custom_attributes.find(e => e.attribute_code === 'informativa_2') ? customer.custom_attributes.find(e => e.attribute_code === 'informativa_2').value : ''
      })
    }
  }

  handleSubmit () {
    const { email, firstname, lastname, streetName, streetNumber, city,
      regionCode, telephone, postcode, dob, privatoOazienda, pIva,
      sdi, pec, codiceFiscale, company, newsletter, informativa1, informativa2 } = this.state
    localStorage.setItem("cap", postcode)
   let customer = {
      ...this.props.customer,
      email,
     default_billing: false,
      firstname,
      lastname,
      dob,
      taxvat: pIva,
      addresses: [
         ...this.props.customer.addresses,//.filter(e => e.id !== parseInt(this.props.customer.default_billing)),
        {
          id:parseInt(this.props.customer.default_billing), //this.props.customer.addresses.find(e => e.id === parseInt(this.props.customer.default_billing)) ? (this.props.customer.addresses.filter(e => e.id === parseInt(this.props.customer.default_billing))).find(e => e.id === parseInt(this.props.customer.default_billing)).value : (this.props.customer.addresses.filter(e => e.id !== parseInt(this.props.customer.default_billing))).find(e => e.id === parseInt(this.props.customer.default_billing)).value,//parseInt(this.props.customer.default_billing),
          default_billing: false,
          firstname,
        lastname,
        street: [
      streetName,
      streetNumber
    ],
        country_id: 'IT',
        city,
        region_id: 0,
        region: {
      region_code: regionCode,
          region: regionCode,
          region_id: 0
    },
    telephone,
        postcode
        }
      ],
      custom_attributes: Object.assign(
        [],
        this.props.customer.custom_attributes,
        [{
          attribute_code: 'newsletter',
          value: newsletter ? 1 : 0
        },
        {
          attribute_code: 'privato_azienda',
          value: privatoOazienda == 0? 1 : 0
        },
          {
            attribute_code: 'tipo_azienda',
            value: privatoOazienda
          },
        {
          attribute_code: 'informativa_1',
          value: informativa1 ? 1 : 0
        },
        {
          attribute_code: 'informativa_2',
          value: informativa2 ? 1 : 0
        },
        {
          attribute_code: 'sdi',
          value: sdi || ''
        },
        {
          attribute_code: 'pec',
          value: pec || ''
        },
        {
          attribute_code: 'codice_fiscale',
          value: codiceFiscale || ''
        },
        {
          attribute_code: 'azienda',
          value: company || ''
        }]
      )}

  customer.addresses[0].default_billing = true;
    this.props.updateCustomerProfile(customer)
    this.setState({ isEditing: false })
  }

  render () {
    const { classes } = this.props
    let isDesktop = this.state.isDesktop
    return (
      <Grid className={classes.profileData}>
        {isDesktop ?
            <Row className={classes.profileDataTitle}>
              <Col xs={12}>
                <Typography use='headline5'>Riepilogo dei tuoi dati</Typography>
              </Col>
            </Row>
            : <Row className={classes.profileAddressesTitle}>
              <Col xs={1}>
                <a href={routes.personal.path}><KeyboardBackspace
                    style={{'color': 'black', 'margin-top': '18%', 'margin-left': '-100%'}}/></a>
              </Col>
              <Col xs={11}><Typography use='headline7'>Riepilogo dei tuoi dati</Typography></Col>
            </Row>
        }
        <Row>
          <Col md={4}>
            <TextField fullwidth label='Nome' required disabled={!this.state.isEditing}
              value={this.state.firstname}
              onChange={event => this.setState({ firstname: event.target.value })}
            />

            <TextField fullwidth label='Cognome' required disabled={!this.state.isEditing}
              value={this.state.lastname}
              onChange={event => this.setState({ lastname: event.target.value })}
            />
            <TextField fullwidth label='Email' required disabled={!this.state.isEditing}
              value={this.state.email}
              onChange={event => this.setState({ email: event.target.value })}
            />
            <TextField fullwidth label='Telefono' required disabled={!this.state.isEditing}
              value={this.state.telephone}
              onChange={event => this.setState({ telephone: event.target.value })}
            />
            <TextField fullwidth label='Data di nascita' required disabled={!this.state.isEditing}
              value={this.state.dob} type='date'
              onChange={event => this.setState({ dob: event.target.value })}
            />
          </Col>
          <Col md={4}>
            <TextField fullwidth label='Indirizzo' required disabled={!this.state.isEditing}
              value={this.state.streetName}
              onChange={event => this.setState({ streetName: event.target.value })}
            />
            <TextField fullwidth label='Numero civico' required disabled={!this.state.isEditing}
              value={this.state.streetNumber}
              onChange={event => this.setState({ streetNumber: event.target.value })}
            />
            <TextField fullwidth label='Città' required disabled={!this.state.isEditing}
              value={this.state.city}
              onChange={event => this.setState({ city: event.target.value })}
            />
            <Row>
              <Col md={6}>
                <TextField fullwidth label='CAP' required disabled={!this.state.isEditing}
                  value={this.state.postcode}
                  onChange={event => this.setState({ postcode: event.target.value })}
                />
              </Col>
              <Col md={6}>
                <TextField fullwidth label='Provincia' required disabled={!this.state.isEditing}
                  value={this.state.regionCode}
                  onChange={event => this.setState({ regionCode: event.target.value })}
                />
              </Col>
            </Row>
            <div>
              <Select
                label='Fatturazione'
                options={[
                  { label: 'Privato', value: 0 },
                  { label: 'Ditta individuale', value: 1},
                  { label: 'Società di persone', value: 2},
                  { label: 'Società di capitali', value: 3}
                ]}
                style={{ width: '100%' }}
                onChange={event => this.setState({ privatoOazienda: event.target.value })}
                value={this.state.privatoOazienda}
                disabled={!this.state.isEditing}
              />
            </div>
          </Col>
          <Col md={4}>
            { this.state.privatoOazienda != '0'
                ? this.state.privatoOazienda === '2' || this.state.privatoOazienda === '3'? <div>
                      <TextField
                          label='Nome ditta'
                          fullwidth required disabled={!this.state.isEditing}
                          value={this.state.company}
                          onChange={event => this.setState({ company: event.target.value })}
                      />
                      <TextField
                          label='Codice Fiscale'
                          fullwidth required disabled={!this.state.isEditing}
                          value={this.state.codiceFiscale}
                          onChange={event => this.setState({ codiceFiscale: event.target.value })}
                      />
                      <TextField
                          label='SDI'
                          fullwidth required disabled={!this.state.isEditing}
                          value={this.state.sdi}
                          onChange={event => this.setState({ sdi: event.target.value })}
                      />
                      <TextField
                          label='PEC'
                          fullwidth required disabled={!this.state.isEditing}
                          value={this.state.pec}
                          onChange={event => this.setState({ pec: event.target.value })}
                      />
                    </div>

                    : <div>
                      <TextField
                          label='Nome ditta'
                          fullwidth required disabled={!this.state.isEditing}
                          value={this.state.company}
                          onChange={event => this.setState({ company: event.target.value })}
                      />
                      <TextField
                          label='Codice Fiscale'
                          fullwidth required disabled={!this.state.isEditing}
                          value={this.state.codiceFiscale}
                          onChange={event => this.setState({ codiceFiscale: event.target.value })}
                      />
                      <TextField
                          label='P.IVA'
                          fullwidth required disabled={!this.state.isEditing}
                          value={this.state.pIva}
                          onChange={event => this.setState({ pIva: event.target.value })}
                      />
                      <TextField
                          label='SDI'
                          fullwidth required disabled={!this.state.isEditing}
                          value={this.state.sdi}
                          onChange={event => this.setState({ sdi: event.target.value })}
                      />
                      <TextField
                          label='PEC'
                          fullwidth required disabled={!this.state.isEditing}
                          value={this.state.pec}
                          onChange={event => this.setState({ pec: event.target.value })}
                      />
                    </div>
                : <div>
                  <TextField
                      label='Codice Fiscale'
                      fullwidth required disabled={!this.state.isEditing}
                      value={this.state.codiceFiscale}
                      onChange={event => this.setState({ codiceFiscale: event.target.value })}
                  />
                </div>
            }
          </Col>
        </Row>
        <br/>
        <Row className={classes.profileDataFooter}>
          <Col xs={8}>
            { !this.state.isEditing &&
              <KUButton unelevated blue
                clickHandler={() => this.setState({ isEditing: true })}
              >
                MODIFICA DATI
              </KUButton>
            }
            { this.state.isEditing &&
              <div>
                <KUButton unelevated outlined blue
                  clickHandler={() => this.setState({ isEditing: false })}
                >
                  ANNULLA
                </KUButton>

                <KUButton unelevated blue
                  clickHandler={() => this.handleSubmit()}
                >
                  SALVA
                </KUButton>
              </div>
            }
          </Col>
        </Row>
      </Grid>
    )
  }
}

const mapStateProps = (state) => (
  {
    customer: state.account.customer
  }
)

export default connect(
  mapStateProps,
  {
    updateCustomerProfile
  }
)(injectSheet(style)(ProfileData))
