import React from 'react'
import {Grid} from "@material-ui/core";
import {Row} from "react-flexbox-grid";
import Typography from "@material-ui/core/Typography";


class chiSiamo extends React.Component {

    constructor(){
        super()
    }

    render() {
        return(
            <Grid class={'container-fluid'} style={{'padding-left':'16px', 'padding-right':'16px', 'margin-left':'auto','margin-right':'auto','width':'80%', 'margin-top':'2%','margin-bottom':'2%'}}>
                <Row>
                    <Typography use="headline5" style={{'font-size':'1.875rem'}}>Chi siamo</Typography>
                </Row>
                <br/>
                <Row>
                    <text><text style={{'font-weight':'bold'}}>Farmakon</text> è la catena di parafarmacie che si prende cura della tua persona, dove salute, bellezza e benessere si fondono per una shopping experience unica. Il progetto nasce dall’idea di abbinare servizio al cliente, qualità del prodotto, consiglio professionale e innovazione, per riuscire ad offrire al nostro cliente professionalità, convenienza e assistenza pre / post vendita.
                    </text>
                    <br/>
                    <text>Il Team di <text style={{'font-weight':'bold'}}>Farmakon</text> è formato da giovani professionisti, preparati, intraprendenti e dinamici, con l’unico obiettivo di rispondere a tutte le esigenze del cliente. Per questo sono in continuo aggiornamento, così come prestano attenzione assoluta a novità e proposte innovative.
                    Da oggi è attivo anche il nostro negozio on line, grazie al quale garantiamo disponibilità e promozioni su oltre 20.000 articoli, selezionati tra le migliori marche del mondo cosmetico, naturale e nutraceutico.
                    </text>
                    <br/>
                    Siamo contattabili H24 per e-mail info@farmakon.it e in orario di ufficio al numero 0573 4477444 (dalle 09:00 alle 13:00 e dalle 14:00 alle 18:00). In alternativa siamo contattabili anche tramite Facebook o Instagram.
                </Row>
            </Grid>
        )
    }

}

export default chiSiamo