import React, { Component } from 'react'
import injectSheet from 'react-jss'
import { Grid } from 'react-flexbox-grid'
import { style } from './style'
import {Breadcrumb} from "react-breadcrumbs";

class Breadcrumbs extends Component {
  render () {
    const { classes } = this.props

    return (
      false && // @todo: re-activate breadcrumbs
      this.props.match && this.props.match.url !== '/' &&
      <Grid fluid className={classes.breadcrumpsBar}>

        {/*
          <Breadcrumb data={{
            title: props.title,
            pathname: routeProps.match.url,
            search: includeSearch ? routeProps.location.search : null
          }}>
            { Component ? <Component { ...routeProps } /> : render(routeProps) }
          </Breadcrumb>
          */
        }

      </Grid>

    )
  }
}

export default injectSheet(style)(Breadcrumbs)
