import variables from '../../assets/style/variables'

export const style = {
  '@global': {
    'hr': {
      margin: '18px 0',
      border: '0',
      height: '1px',
      background: '#333',
      backgroundImage: 'linear-gradient(to right, #eee, #dedede, #eee)'
    },

    '.subMenu > div:first-of-type': {
      '& a': {
        textTranformation: 'none',
        color: variables.grey
      },
      '& a:hover': {
        color: variables.seawater
      },
      border: `1px solid ${variables.lightGrey}`,
      padding: '20px',
      overflow: 'hidden',
      backgroundColor: 'white',
      color: variables.grey,
      'box-shadow': '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
      zIndex: 10
    },

    '.mdc-typography--body2': {
      fontSize: 12
    },

    '.mdc-text-field:not(.mdc-text-field--with-leading-icon) label.mdc-floating-label, .mdc-select .mdc-floating-label': {
      left: 0
    },

    /* SELECT SECTION */
    '.mdc-select--disabled': {
      backgroundColor: 'white'
    },

    '.mdc-select--disabled .mdc-select__native-control': `
    border-bottom-color: rgba(0,0,0,0.12);
    border-bottom-style: solid;
    `,

    '.mdc-select::before, .mdc-select::after': {
      backgroundColor: 'white'
    },

    '.mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-floating-label': {
      color: 'inherit'
    },

    '.mdc-select__native-control, label.mdc-floating-label.mdc-floating-label--float-above': {
      paddingLeft: 0,
      fontSize: 12
    },

    '.mdc-select:not(.mdc-select--disabled)': {
      backgroundColor: '#ffffff'
    },

    '.mdc-select:hover::before': {
      opacity: 0
    },
    /* _-SELECT SECTION-_ */

    '.mdc-line-ripple': {
      height: 1
    },

    '.mdc-text-field--disabled': {
      backgroundColor: 'white'
    },

    '.mdc-text-field__input': {
      fontSize: '12px'
    },

    'button.fullwidth': {
      width: '100%'
    },

    '.mdc-button--unelevated:disabled': {
      backgroundColor: `${variables.green}`,
      color: `${variables.white}`,
      'border-radius': '0px',
      minHeight: '44px',
      fontWeight: 800,
      textAlign: 'center',
      padding: '0 20px'
    },
    '.mdc-button--unelevated:disabled:hover': {
      cursor: 'not-allowed'
    },

    '.mdc-button--unelevated:not(:disabled)': {
      'border-radius': '0px',
      backgroundColor: `${variables.green}`,
      color: `${variables.white}`,
      minHeight: '44px',
      fontWeight: 800,
      textAlign: 'center',
      padding: '0 20px'
    },

    '.mdc-button--unelevated.blue:not(.mdc-button--outlined):not(:disabled)': {
      backgroundColor: `${variables.blue}`,
      color: `${variables.white}`
    },

    '.mdc-button--unelevated.orange:not(.mdc-button--outlined):not(:disabled)': {
      backgroundColor: `${variables.orange}`,
      color: `${variables.white}`
    },

    '.mdc-button--unelevated.mdc-button--outlined.blue': {
      border: `2px solid ${variables.blue}`,
      color: variables.blue,
      backgroundColor: `white`
    },

    '.mdc-button--unelevated.mdc-button--outlined.orange': {
      border: `2px solid ${variables.orange}`,
      color: variables.orange,
      backgroundColor: `white`
    },

    '.rc-steps-item-process .rc-steps-item-tail:after, .rc-steps-item-wait .rc-steps-item-tail:after': {
      backgroundColor: '#707070'
    },

    '.mdc-floating-label, .mdc-text-field .mdc-floating-label': {
      fontSize: 12,
      color: variables.grey
    },

    '.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label': {
      color: variables.grey
    },
    '.mdc-text-field:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-floating-label': `
      max-width: calc(100% - 10px);
    `,

    /* -HOME PAGE- */

    /* _-HOME PAGE-_ */

    /* -PAGINATION- */
    '#react-paginate ul': `
      display: inline-block;
      padding-left: 15px;
      padding-right: 15px;
    `,

    '#react-paginate li': `
      display: inline-block;
      border: 2px solid ${variables.blue};
      margin: 5px;
      cursor: pointer;
    `,

    '#react-paginate li.active': `
      background-color: ${variables.blue};
      color: ${variables.white}
    `,

    '#react-paginate li a': `
      display: block;
      padding: 8px 14px;
      cursor: pointer;
    `,
    /* -PAGINATION- */

    /* -CATEGORY PAGE- */
    '.category--no-products-found': {
      marginBottom: 40,
      /*minHeight: 200*/
    },
    '.category--products-loading': {
      marginTop: 20,
      marginBottom: 40
    },
    /* -CATEGORY PAGE- */

    /* -CHECKOUT PAGE- */
    '.step-progress:before': `
      margin: 0 auto;
      content: "";
      width: 70%;
      border-bottom: 1px solid #dedede;
      display: block;
      text-align: center;
      top: 39px;
      position: relative;
      z-index: 0;
    `,

    'ol.progtrckr': `
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
      display: flex;
      flex-direction: row;
      padding: 0;
    `,

    'ol.progtrckr li': `
      pointer-events: none;
      flex: 1;
      list-style: none;
      text-align: center;
      position: relative;
      z-index: 0;
    `,

    'ol.progtrckr li.progtrckr-done:before, ol.progtrckr li.progtrckr-doing:before, ol.progtrckr li.progtrckr-todo:before': `
      content: "";
      display: block;      
      width: 40px;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      padding: 0;
      margin: 0 auto;
      /* margin-left: -11px; */
      /* bottom: -3.4rem; */
      /* left: 50%; */
      font-size: 14px;
      font-style: normal;
      font-weight: bold;
      color: white;
      border-radius: 26px;
      border: 2px solid #ccc;
      border-color: #121C32;
      background-color: #ffffff;
      text-align: center;
      transition: background-color 0.3s, border-color 0.3s;
      pointer-events: all;
    `,

    'ol.progtrckr li.progtrckr-doing span': `
      font-weight:  bold;
    `,

    'ol.progtrckr li.progtrckr-doing:before': `
      border-color: #121C32;
      background-color: #121C32;
    `,

    'ol.progtrckr li em': `
      pointer-events: none;
      padding: 0;
      position: relative;
      top: -2.6rem;
      font-size: 14px;
      font-style: normal;
      font-weight: bold;
      display: block;
      /* left: 33%; */
      /* color: white; */
      width: 42px;
      height: 0px;
      line-height: 40px;
      margin: 0 auto;
      margin-bottom: 10px;
    `,

    'ol.progtrckr li.progtrckr-todo:before, ol.progtrckr li.progtrckr-doing em': `
      color: white;
    `,
    'ol.progtrckr li.progtrckr-todo em': `
      color: #121C32;
    `,
    /* _-CHECKOUT PAGE-_ */

    /* MEDIA QUERIES */

    '@media (max-width: 768px)': {
      'button.mdc-button': {
        'border-radius': '1px'
      }
    }
    /* _- MEDIA QUERIES -_ */

  }, // @global

  body: {
    padding: 0
  }

}
