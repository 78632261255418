import React, { Component } from 'react'
import injectSheet from 'react-jss'
import { Button, Icon } from 'rmwc'
import { style } from './style'

class KUButton extends Component {
  render () {
    // const { classes } = this.props

    return (
      <Button
        disabled={this.props.disabled}
        onClick={this.props.clickHandler}
        className={`${this.props.blue && 'blue'} ${this.props.orange && 'orange'} ${this.props.className}`}
        style={this.props.style || {}}
        outlined={this.props.outlined}
        unelevated={this.props.unelevated}
      >
        { this.props.children }
        { this.props.loading
          ? <Icon icon={
            <svg strategy='component' x='0px' y='0px' width='30px' height='30px' viewBox='0 0 50 50' style={{ enableBackground: 'new 0 0 50 50' }}>
              <path fill='#ffffff' d='M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z'>
                <animateTransform attributeType='xml'
                  attributeName='transform'
                  type='rotate'
                  from='0 25 25'
                  to='360 25 25'
                  dur='1.2s'
                  repeatCount='indefinite' />
              </path>
            </svg>
          } />
          : (() => {
            switch (this.props.icon) {
              case 'keyboard_arrow_right': return <Icon icon='keyboard_arrow_right' />
              default: return ''
            }
          })()
        }
      </Button>
    )
  }
}

export default injectSheet(style)(KUButton)
