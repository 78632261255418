import variables from '../../assets/style/variables'

export const style = {
  profileOrders: {
    '& .mdc-text-field__input': {
      width: '100%',
      paddingTop: '30px !important'
    },
    '& table': {
      borderSpacing: 0,
      width: '100%'
    },
    '& .rmwc-data-table__row td': {
      borderTop: '1px solid ' + variables.black,
      marginLeft: 1,
      marginRight: 1,
      color: variables.grey
    },
    '& .rmwc-data-table__cell': {
      height: '40px',
    }
  },

  details: {
    color: variables.white + ' !important',
    backgroundColor:  '#111B34 !important',
  },

  '@media (max-width: 600px)' : {
      profileOrders: {
        'margin-top': '5%',
        'margin-bottom': '5%'
      }
  }
}
