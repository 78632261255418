import variables from '../../assets/style/variables'

export const style = {
    profileCoupons: {
        '& .mdc-text-field__input': {
            width: '100%',
            paddingTop: '30px !important'
        }
    },

    profileCouponsTitle: {
        marginTop: 10,
        marginBottom: 30
    },

    profileCouponsFooter: {
        marginTop: 30,
        marginBottom: 30
    },

    '@media (max-width: 600px)' :{
        profileCoupons:{
            'margin-top':'5%',
            'margin-bottom':'5%'
        }
    }

}
