const initialState = {
    coupons:[]
};

const reducer = (state = initialState, action) => {
    const newState = {...state};

    if(action.type === "MAGENTO_GET_COUPONS"){
        console.log(action.payload.items)
        newState.coupons = action.payload.items
    }
    return newState
}


export default reducer;