import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import { Grid, Col, Row } from 'react-flexbox-grid'
import { Link } from 'react-router-dom'
import { TextField } from '@rmwc/textfield'
import { Typography } from '@rmwc/typography'
import { Button } from '@rmwc/button'
import { showSignIn, showLogin, auth } from '../../actions'
import { style } from './style'
import { Icon } from '@rmwc/icon'
import LogoFacebook from 'react-ionicons/lib/LogoFacebook'
import LogoGoogle from 'react-ionicons/lib/LogoGoogle'
import KUButton from '../KUButton'
import history from '../../lib/helper/history'
import { routes, REDIRECT_TO_CUSTOMER_AREA_AFTER_LOGIN } from '../../config/settings'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {magento} from "../../lib/magento";
import sidebar from "../NavBar/sidebar";
import SignUp from '../SignUp'


class Login extends Component {

    constructor (props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            isLogginIn: false,
            isActive: false,
            isDesktop: false,
            open: true,
            opensignin: false
        }
        this.updatePredicate = this.updatePredicate.bind(this);
        this.changeState = this.changeState.bind(this)
    }

    changeState(){
        this.state.opensignin = true
    }


    setName(){
        if(this.props.account.customer != null) {
            localStorage.setItem('nome', this.props.account.customer.firstname)
        }
    }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
        this.setName()
    }


    updatePredicate() {
        this.setState({ isDesktop: window.innerWidth > 620 });
    }


    handleClickSignIn () {
        this.state.opensignin = true
        this.props.showSignIn(true)
        this.props.showLogin(false)
    }

    componentWillMount () {
        window.removeEventListener("resize", this.updatePredicate);
    }

    openDialog () {
        this.setState({open: true})
    }

    handleClose = () => {
        this.setState({
            open:false,
            opensignin: this.state.opensignin?true:false
        })
    };
    handleCloseSignIn = () => {
        this.setState({
            opensignin : false
        })
    };


    async handleSubmit () {
        const { email, password } = this.state
        if (email && password) {
            this.props.auth(email, password)
            console.log(this.props)
            const response = await magento.guest.auth(email, password)
            if (response.message) {
                console.log("Non loggato")
                this.setState({isLogginIn:false, isActive:true})
            } else {
                console.log("Loggato")
                //this.props.handleClose()
                this.handleClose()
                this.state.isLogginIn=true
                history.push(routes.personal.path)
                //window.location.reload()
            }
        }
    }

    change(){
        this.setState({isActive:!this.state.isActive})
    }

    componentWillUpdate (nextProp) {
        if (nextProp.account !== this.props.account) {
            this.props.showLogin(false)
            if (REDIRECT_TO_CUSTOMER_AREA_AFTER_LOGIN && this.state.isLogginIn) {
                history.push(routes.personal.path)
            }
        }
    }

    handleClickPassword(){
        this.props.showLogin(false)
        history.push(routes.indexResetPassword.path)
    }

    render () {
        const { classes } = this.props
        const { email, password, isActive, isLogginIn } = this.state
        const isDesktop = this.state.isDesktop
        this.setName()
        if(isDesktop){
            return (<Grid className={classes.login}>
                <Dialog
                    open={this.state.isActive}
                    onClose={() => this.change()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Errore"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            L'utente o la password inseriti non sono corretti.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.change()} style={{backgroundColor:'green', color:'white'}}>
                            Ok, ho capito!
                        </Button>
                    </DialogActions>
                </Dialog>
                <Row>
                    <Col xs={12}>
                        <Typography use='headline5'>Accedi</Typography>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <TextField fullwidth withLeadingIcon='account_circle' label='Username o Email'
                                   value={this.state.email}
                                   onChange={event => this.setState({ email: event.target.value })}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <TextField type='password' fullwidth withLeadingIcon='lock' label='Password'
                                   value={this.state.password}
                                   onChange={event => this.setState({ password: event.target.value })}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <KUButton className={classes.submit} unelevated
                                  disabled={!email || !password}
                                  clickHandler={() => this.handleSubmit()}
                                  loading={this.props.ui.authLoading}
                        >
                            ENTRA
                        </KUButton>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        Hai dimenticato la password? <a href={routes.indexResetPassword.path}>CLICCA QUI</a>
                    </Col>
                </Row>
                    <Col xs={12} className={classes.link}
                         onClick={() => this.handleClickSignIn()}>
                        Non sei ancora registrato? <span className={classes.click}>CLICCA QUI</span>
                    </Col>
            </Grid>)
        } else {
            return (
                    <Grid>
                        <p style={{'text-align':'center', 'font-weight':'bold', color:'#000', 'font-size':'25px'}}> Accedi</p>
                        <TextField withLeadingIcon='account_circle' label='Username o Email'
                                   style={{'width':'100%', backgroundColor:'#fff'}}
                                   value={this.state.email}
                                   onChange={event => this.setState({ email: event.target.value })}
                        />
                        <br/><br/>
                        <TextField type='password' withLeadingIcon='lock' label='Password'
                                   style={{'width':'100%', backgroundColor:'#fff'}}
                                   value={this.state.password}
                                   onChange={event => this.setState({ password: event.target.value })}
                        />
                        <br/><br/>
                        {this.state.isActive && <Typography style={{'color':'red'}}>Utente o password errati</Typography>}
                        <p style={{'text-align':'center'}}><KUButton className={classes.submit} unelevated
                                                                     disabled={!email || !password}
                                                                     clickHandler={() => this.handleSubmit()}
                                                                     loading={this.props.ui.authLoading}
                                                                     //onClick={() => this.getError()}
                                                                     style={{'width':'100%'}}
                        >
                            ENTRA
                        </KUButton></p>

                        <p style={{'text-align':'center', color:'#000'}}>Hai dimenticato la password? <Link to={routes.indexResetPassword.path}>CLICCA QUI</Link>
                            <Col xs={12} className={classes.link}
                                 onClick={() => this.handleClickSignIn()}>
                                Non sei ancora registrato? <span className={classes.click}>CLICCA QUI</span>
                            </Col></p>
                    </Grid>)
        }

    }
}

const mapStateToProps = function (state) {
    return {
        account: state.account,
        ui: state.ui
    }
}


export default connect(mapStateToProps, { showSignIn, showLogin, auth })(injectSheet(style)(Login))