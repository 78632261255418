import React, {Component} from 'react'
import {connect} from 'react-redux'
import injectSheet from 'react-jss'
import {style} from './style'
import {Grid, Col, Row} from "react-flexbox-grid";
import {Typography} from '@rmwc/typography'
import {routes} from "../../config/settings";
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import {getCoupons} from '../../actions'
import {magento} from "../../lib/magento";
import {ADMIN_TYPE} from "../../lib/magento/types";
import Loader from "react-loader-spinner";

class ProfileCoupons extends Component {
    constructor(props) {
        super()
        this.state = {
            isDesktop: false
        }
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    updatePredicate() {
        this.setState({isDesktop: window.innerWidth > 620});
    }

    componentWillMount() {
        window.removeEventListener("resize", this.updatePredicate);
        this.props.getCoupons()
    }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        //this.props.getCoupons()
    }

    render() {
        const {classes} = this.props
        let isDesktop = this.state.isDesktop
        return (
            <Grid className={classes.profileCoupons}>
                {isDesktop ?
                    <Row className={classes.profileCouponsTitle}
                         style={{'margin-top': '10px'}}
                    >
                        <Col xs={12}>
                            <Typography use='headline5'>I tuoi coupon di sconto</Typography>
                        </Col>
                    </Row>
                    : <Row className={classes.profileCouponsTitle}>
                        <Col xs={1}>
                            <a href={routes.personal.path}><KeyboardBackspace
                                style={{'color': 'black', 'margin-top': '18%', 'margin-left': '-100%'}}/></a>
                        </Col>
                        <Col xs={11}><Typography use='headline7'>I tuoi coupon di sconto</Typography></Col>
                    </Row>
                }
                <Row>
                    {this.props.coupons.length === 0 && <Loader
                        type="TailSpin"
                        color="#000"
                        height={50}
                        width={100}
                        timeout={3000}
                    />}
                    <br/>
                    {this.props.coupons.length > 0 && this.props.coupons.map(coupon => (
                            coupon.sort_order === 99 && coupon.is_active && <Col xs={isDesktop ? 6 : 12} style={{
                                'text-align': 'center',
                                'padding-left': '5%',
                                'padding-right': '5%',
                                'padding-bottom': '2%'
                            }}>
                                <div style={{
                                    'background-color': 'grey',
                                    'height': '200px',
                                    'width': isDesktop ? '330px' : '280px',
                                    'text-align': 'center'
                                }}>
                                    <span style={{
                                        'color': 'white',
                                        'font-size': '30px',
                                        'font-weight': '600'
                                    }}>{coupon.description}</span>
                                </div>
                            </Col>
                        )
                    )}
                    {this.props.coupons.length === 0 &&
                    <Row>Nessun coupon sconto utilizzabile sul sito</Row>}
                </Row>


            </Grid>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        coupons: state.coupons.coupons
    }
}

export default connect(mapStateToProps, {getCoupons})(injectSheet(style)(ProfileCoupons))