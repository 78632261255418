import React from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import {style} from './style'
import { GridCell, Typography, Icon } from 'rmwc'
import {getProductsByBrand, addToCart} from "../../actions";
import { priceFormat } from '../../lib/format'
import { productThumb } from '../../lib/product'
import KUButton from '../KUButton'
import history from "../../lib/helper/history";
import {routes} from "../../config/settings";
import FavoriteBorder from '@material-ui/icons/FavoriteBorder'
import Favorite from '@material-ui/icons/Favorite'
import {Col, Row} from "react-flexbox-grid";

class BrandProduct extends React.Component{

    constructor(props){
        super()
        this.state = {
            product : props.product,
            key: props.key,
            props: props.props,
            filled: false
        }
    }

    onPressAddToCart(product){
        const customer = this.state.props.account.customer
        const cart = this.state.props.cart
        this.props.addToCart({
            cartId: cart.cartId,
            item: {
                cartItem: {
                    sku: product.sku,
                    qty: 1,
                    quoteId: cart.cartId
                    // ...productOptions
                }
            },
            customer
        })
    }

    addToWishList(product){
        if(this.state.filled){
            console.log('Rimuovo da wishlist')
            //REST
        } else {
            console.log('Aggiungo su wishlist')
            //REST
        }
        this.setState({filled: !this.state.filled})
    }

    handleProductClick (product) {
        history.push(`${routes.product.path}/${product.sku}`)
    }

render(){
        const {classes} = this.props
        let _discountPrice = this.state.product.custom_attributes
        ? this.state.product.custom_attributes.find(f => f.attribute_code === 'special_price')
            ? this.state.product.custom_attributes.find(f => f.attribute_code === 'special_price').value
            : false
        : false

    if(this.state.product === null
        || this.state.product === 'undefined'
        || this.state.product === ''){
        return(<React.Fragment/>) } else {
        return(
            <GridCell span={3} tablet={2} align='bottom' className={classes.productContainer}
            >
                <div className={classes.productThumb}
                     onClick={() => this.handleProductClick(this.props.product)}
                >
                    <img src={this.state.product.custom_attributes ? 'https://app.farmakon.it/pub/media/catalog/product'+this.props.product.custom_attributes.find(e => e.attribute_code === 'image').value : productThumb(this.props.product)} alt={this.props.product.name} />
                </div>
                <Typography use='body2' className={classes.productName}
                            style={{'height':'15%'}}
                >
                    {this.state.product.name}
                </Typography>
                <Row>
                    <Col xs={9}>
                        <Typography use='subtitle2' className={classes.productPrice}>
                            {_discountPrice
                                ? <div>
                                    <del>{priceFormat(this.props.product.price)}</del>
                                    <span>{priceFormat(_discountPrice)}</span>
                                </div>
                                : <span>{priceFormat(this.props.product.price)}</span>
                            }
                        </Typography>
                    </Col>
                    <Col xs={3}>
                        <Typography
                            nohover
                            style={{'margin':'auto', 'background-color':'transparent'}}
                            onClick={() => (this.addToWishList(this.state.product))}
                        >
                            {this.state.filled?
                                <Favorite style={{'color':'red'}}/> : <FavoriteBorder/>}
                        </Typography>
                    </Col>
                </Row>
                <KUButton unelevated ripple={false}
                          disabled={this.state.product.extension_attributes.stock_item.is_in_stock? false : true}
                          clickHandler={() => this.onPressAddToCart(this.props.product)}
                          className={classes.addButton}
                >
                    <Icon icon='add_shopping_cart'/>
                    <Typography use='button' className={classes.aggiungiButton}>AGGIUNGI</Typography>
                </KUButton>

            </GridCell>
        )
    }
}

}

const mapStateToProps = function (state) {
    return {
    }
}

export default connect(mapStateToProps, {addToCart})(injectSheet(style)(BrandProduct))