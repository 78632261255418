import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { MenuSurface, MenuSurfaceAnchor } from '@rmwc/menu'
import { Icon } from '@rmwc/icon'
import debounce from 'lodash/debounce'
import injectSheet from 'react-jss'
import { Grid, Col, Row } from 'react-flexbox-grid'
import MiniCart from '../MiniCart'
//import logo from '../../assets/images/logo-FarmaKon-Natale.png'
import logo from '../../assets/images/logo-FarmaKon.png'
import {SITE_NAME, routes, FREE_SHIPPING_MIN_ORDER} from '../../config/settings'
import { style } from './style'
import Badge from '@material-ui/core/Badge';
import history from '../../lib/helper/history'
import {priceFormat} from "../../lib/format";
import SideBar from "./sidebar";
import Cart from '../../containers/Cart'
import "./stylemobile.css";
import IosSearch from 'react-ionicons/lib/IosSearch'
import IosCartOutline from 'react-ionicons/lib/IosCartOutline'
import KUButton from "../KUButton";
import {connect} from "react-redux";
import {addToCart, getHomeData, getProductBySku} from "../../actions";
import IosArrowDown from 'react-ionicons/lib/IosArrowDown'

class Navbar extends Component {
  constructor (props) {
    super(props)

    this.state = {
      miniCartIsOpen: false,
      searchInputDisabled: true,
      text: '',
      searchCheck:false
    }
    this.toggleMiniCartView = this.toggleMiniCartView.bind(this)
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if(this.state.searchCheck){
        localStorage.setItem("perPage", 16)
        window.location.href=`${routes.search.path}/${this.state.text}`
      }
    }
  }

  toggleMiniCartView () {
    this.setState({
      miniCartIsOpen: !this.state.miniCartIsOpen
    })
  }

  handleSearchProduct = (
    (text) => {
      return text
        ? this.setState({text: text, searchCheck:true})
        : history.push(routes.home.path)
    }
  );

  render () {
    const { classes } = this.props

    return (
      <React.Fragment>
      <Grid fluid id='mobile'>
        <Row style={{'background-color':'white'}}>
          <Col xs={3}>
            <div id="App">
              <SideBar pageWrapId={"page-wrap"} outerContainerId={"App"} />
            </div>
          </Col>
          <Col xs={6}>

            <a href={routes.home.path}>
              <div className={classes.logoMobile}>
                <img src={logo} alt={SITE_NAME} width={100} />
              </div>
            </a>
          </Col>
          <Col xs={3}>
            <MenuSurfaceAnchor>
              <MenuSurface className={classes.miniCartContainer}
                           open={this.state.miniCartIsOpen}
                           onClose={() => this.setState({ miniCartIsOpen: false })}
                           anchorCorner='bottomStart'>
                <MiniCart toggleMiniCartView={this.toggleMiniCartView} customer={this.props.cart.customer}/>
              </MenuSurface>

              <div className={classes.miniCartMobile}
                   onClick={this.toggleMiniCartView}
                   style={{'padding-top':'16%'}}
              >

                {this.props.cart.quote.items_qty ? <Badge className={classes.margin} badgeContent={this.props.cart.quote.items_qty} color={'secondary'}>
                  <IosCartOutline fontSize={'35px'}/>
                </Badge> : <IosCartOutline fontSize={'35px'}/>}
                <div className={classes.miniCartText} key='cart-counter'></div>
                <Icon className={classes.icon} icon='keyboard_arrow_down' iconOptions={{ strategy: 'ligature' }} />
              </div>

            </MenuSurfaceAnchor>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className={classes.freeShipping}>
            <a href='#/shipping-info'>
              <Icon icon='local_shipping' iconOptions={{ strategy: 'ligature' }} />
              SPEDIZIONE GRATUITA PER ORDINI SUPERIORI A {priceFormat(FREE_SHIPPING_MIN_ORDER)}
            </a>
          </Col>
        </Row>
      </Grid>
      <Grid fluid className={classes.navBar} id='desktop'>
        <Row middle='md'>
          <Col sm={2}>
            <a href={'/'}>
            <div>
                <img src={logo} alt={SITE_NAME} width={150} />

            </div>
          </a>
          </Col>
          <Col sm={8} className={classes.topSearch} style={{'display':'block', 'text-align':'center'}}>
            <input
              type='text'
              placeholder='  Che prodotto stai cercando? ...'
              name='search_product'
              autoComplete='off'
              readOnly={this.state.searchInputDisabled}
              style={{'width':'80%'}}
              onClick={() => this.setState({ searchInputDisabled: false })}
              onChange={(e) => this.handleSearchProduct(e.target.value)}
              onKeyDown={this._handleKeyDown}
            />
              <KUButton style={{'border-radius':'0px','background-color':'#111B34', 'height': '41px','border': '1px solid','padding': '5px', 'margin-top':'-2px','width':'5%'}}
                        disabled={(this.state.text == '')}
              >
                <a href={`${routes.search.path}/${this.state.text}`} color={'black'}><IosSearch style={{'fill':'white', 'width':'27px', 'height':'28px'}}
                onClick={() => {localStorage.setItem("perPage", 16)}}
                /></a>
              </KUButton>
          </Col>
          <Col sm={2}>
            <MenuSurfaceAnchor>
              <MenuSurface className={classes.miniCartContainer}
                open={this.state.miniCartIsOpen}
                onClose={() => this.setState({ miniCartIsOpen: false })}
                anchorCorner='bottomStart'>
                <MiniCart toggleMiniCartView={this.toggleMiniCartView} />
              </MenuSurface>

              <div className={classes.miniCartMobile}
                onClick={this.toggleMiniCartView}
                   style={{'border':'2px solid', 'text-align':'center', 'padding-top':'5%'}}
              >
                <IosCartOutline fontSize={'28px'} className={classes.icon} icon='shopping_cart' iconOptions={{ strategy: 'ligature' }} />
                <div className={classes.miniCartText}>Carrello  {this.props.cart.items.length === 0? '0' : this.props.cart.quote.items_qty}  </div>
                {localStorage.setItem("cap", this.props.cart.customer ? this.props.cart.customer.addresses[0].postcode : false)}
                <div className={classes.miniCartText} key='cart-counter'>{ this.props.productsCountInCart }</div>
                {/*<Icon className={classes.icon} icon='keyboard_arrow_down' iconOptions={{ strategy: 'ligature' }} />*/}
                  <IosArrowDown className={classes.icon} style={{"width":"18px", "margin-left":"2px", "margin-right":"2px", "margin-top":"3px" }} />
              </div>

            </MenuSurfaceAnchor>
          </Col>
        </Row>
      </Grid>
      </React.Fragment>
    )
  }
}

const mapStateToProps = function (state) {
  const {products, second_products, totalCount, loadingMore} = state
  const {cart, account, ui} = state


  return {
    products,
    second_products,
    totalCount,
    cart,
    loadingMore,
    ui,
    customer: account.customer
  }
}

export default connect(mapStateToProps)(injectSheet(style)(Navbar))