import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@rmwc/dialog'
import { Row, Col } from 'react-flexbox-grid'
import { TextField } from 'rmwc'
import injectSheet from 'react-jss'
import { addShippingAddress } from '../../actions/api'
import KUButton from '../KUButton'
//import { style } from './style'
export class ModalModifyCustomerAddress extends Component {
    state = {
        address: {
            country_id: 'IT',
            street: ['', ''],
            city: '',
            postcode: '',
            region: {
                region: ''
            }
        }
    }

    handleAddShippingAddress = async (address) => {
        this.props.addShippingAddress(this.state.address)
        this.props.toggleAddNewAddressModal(false)
        // TODO: select last address
    }

    render () {
        const { classes } = this.props

        return (
            <Dialog className={classes.addNewAddressDialog}
                    open
                    onClose={() => {this.props.toggleModifyAddressModal(false)}}
            >

                <DialogContent>
                    Modifica indirizzo di spedizione
                    <Row>
                        <Col md={12}>
                            <DialogTitle>
                                Modifica indirizzo di spedizione
                            </DialogTitle>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>
                            Indirizzo
                            <TextField required fullwidth label='Indirizzo'
                                       value={this.state.address.street[0]}

                            />
                        </Col>
                        <Col md={3}>
                            Numero civico
                            <TextField required fullwidth label={`Numero civico`}
                                       value={this.state.address.street[1]}

                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            Città
                            <TextField required fullwidth label='Città'
                                       value={this.state.address.city}

                            />
                        </Col>
                        <Col md={3}>
                            CAP
                            <TextField required fullwidth label='CAP'
                                       value={this.state.address.postcode}

                            />
                        </Col>
                        <Col md={3}>
                            Provincia
                            <TextField required fullwidth label='Provincia'
                                       value={this.state.address.region.region}

                            />
                        </Col>
                    </Row>
                </DialogContent>
                <DialogActions className={classes.dialogFooter}>
                    <KUButton
                        unelevated blue={this.props.blueButton} outlined
                        clickHandler={() => this.props.toggleAddNewAddressModal(false)}
                        style={{backgroundColor: 'white', color:'black'}}
                    >
                        ANNULLA
                    </KUButton>
                    <KUButton unelevated blue={this.props.blueButton}
                              clickHandler={() => this.handleAddShippingAddress(this.state.address)}
                    >
                        MODIFICA
                    </KUButton>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
    addShippingAddress
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectSheet()(ModalModifyCustomerAddress))