import variables from '../../assets/style/variables'

export const style = {
  navBar: {
    backgroundColor: variables.white,
    color: variables.black,
    fontSize: 10,
    padding: 10,
    verticalAlign: 'middle',
    marginTop: '14px',
    marginBottom: '14px',
    '& h1': {
      //fontSize: '4em',
      fontWeight: 400,
      margin: 0,
      color: variables.black
    },
    '& a': {
      textDecoration: 'none'
    }
  },
  loginMiniMenu: {
    width: 340,
    padding: 10,
    '& .mdc-text-field': {
      width: '100%',
      backgroundColor: variables.white,
      margin: '6px 0'
    }
  },
  loginButton: {
    width: '100%',
    height: 60,
    margin: '10px 0',
    borderRadius: 0,
    //fontSize: '1.6em'
  },
  paddingTop: {
    paddingTop:8
  },
  miniCart: {
    cursor: 'pointer',
    display: 'flex',
    border: '2px solid ' + variables.black + ' !important',
    justifyContent: 'space-around',
    position: 'relative',
    //fontSize: 16,
    height: 32,
    verticalAlign: 'middle',
    paddingTop: 7,
  },
  miniCartMobile: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-around',
    position: 'relative',
    //fontSize: 16,
    //height: 32,
    verticalAlign: 'middle',
    'padding-top': '17%',
    'padding-bottom':'6px',
    float: 'right'
  },
  logoMobile: {
    textAlign: 'center',
    paddingTop: 6,
    paddingBottom: 6
  },
  icon: {
    // marginLeft: '5px',
    // bottom: '-5px',
    // position: 'absolute'
  },
  miniCartText: {
    marginTop: 2
  },
  miniCartContainer: {
    width: 418
  },
  topSearch: {
    '& input': {
      border: '1px solid',
      //fontSize: '2em',
      padding: '8px',
      width: '90%'
    }
  },
  freeShipping: {
    backgroundColor: '#4AB2B2',
    textAlign: 'center',
    '& a': {
      fontWeight: 'bold',
      color: variables.white
    },
    '& i': {
      //fontSize: '16px',
      marginRight: 4,
    }
  }
}
