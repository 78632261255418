import {
  UI_SIGNIN_SHOW,
  UI_LOGIN_SHOW,
  UI_SHOW_CART_SPINNER,
  MAGENTO_AUTH_LOADING,
  UI_CHECKOUT_CUSTOMER_NEXT_LOADING,
  MAGENTO_CHANGE_CART_ITEM_QTY_LOADING,
  MAGENTO_ADD_TO_CART_LOADING,
  MAGENTO_ADD_TO_CART,
  MAGENTO_PASSWORD_RESET_LOADING
} from '../actions/types'

const INITIAL_STATE = {
  show: false,
  loading: false,
  authLoading: false,
  cartSpinner: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UI_SIGNIN_SHOW:
    case UI_LOGIN_SHOW:
      return { ...state, ...action }

    case MAGENTO_CHANGE_CART_ITEM_QTY_LOADING:
      return { ...state, addToCartLoading: action.payload }
    case MAGENTO_ADD_TO_CART_LOADING:
      return { ...state, addToCartLoading: action.payload }
    case MAGENTO_ADD_TO_CART:
      return { ...state, addToCartLoading: false, errorMessage: action.payload.message }

    case MAGENTO_PASSWORD_RESET_LOADING:
      return { ...state, reset_loading: action.payload }

    case UI_CHECKOUT_CUSTOMER_NEXT_LOADING: {
      const ui = { ...state.ui, loading: action.payload }
      return { ...state, ui }
    }
    case UI_SHOW_CART_SPINNER:
      return { ...state, cartSpinner: action.payload }
    case MAGENTO_AUTH_LOADING: {
      return { ...state, authLoading: action.payload }
    }
    default:
      return state
  }
}
