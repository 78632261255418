import React from 'react'
// import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {GridCell, Typography, Icon} from 'rmwc'
import injectSheet from 'react-jss'
import {addToCart, getProductsForCategoryOrChild, getProductsForCategory, getProductBySku} from '../../actions'
//import {addToWishList} from '../../actions/api'
import history from '../../lib/helper/history'
import {routes} from '../../config/settings'
import {priceFormat} from '../../lib/format'
import {productThumb} from '../../lib/product'
import KUButton from '../KUButton'
import {style} from './style'
import {magento} from "../../lib/magento";
import {ADMIN_TYPE} from "../../lib/magento/types";
import {MAGENTO_GET_PRODUCT} from "../../actions/types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import HighlightOffOutlined from '@material-ui/icons/HighlightOffOutlined'
import {Col, Row} from 'react-flexbox-grid'

class CategoryProduct extends React.Component {

    constructor(props) {
        super()
        this.state = {
            current_product: null,
            open: false,
            isDesktop: false
        }
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    updatePredicate() {
        this.setState({isDesktop: window.innerWidth > 620});
    }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    componentWillMount() {
        window.removeEventListener("resize", this.updatePredicate);
    }

    async onPressAddToCart(product) {
        const {cart, customer} = this.props
        this.props.addToCart({
            cartId: cart.cartId,
            item: {
                cartItem: {
                    sku: product.sku,
                    qty: 1,
                    quoteId: cart.cartId
                    // ...productOptions
                }
            },
            customer
        })

        setTimeout(() => (
            this.state.open = true
        ), 500)

    }

    onPressAddToWishList(product) {
        const {customer} = this.props
        /* this.props.addToWishList({
           wishlist_id: customer.id,
           item: {
             wishlistItem: {
               sku: product.sku,
               qty: 1,
               //quoteId: cart.cartId
               // ...productOptions
             }
           },
           customer
         })*/
    }

    handleProductClick(product) {
        history.push(`${routes.product.path}/${product.sku}`)
        localStorage.setItem("backButtonProduct", true)
    }

    /*async getProduct(){
      await magento.admin
          .getProductBySku(this.props.product.sku)
          .then(data => {
            console.log(data)
            this.state.current_product = data
          })
    }*/

    handleClose = () => {
        this.setState({
            open: false
        })
    };

    render() {
        const {classes} = this.props
        const isDesktop = this.state.isDesktop

        let _image = productThumb(this.props.product)
        let _discountPrice = this.props.product.custom_attributes
            ? this.props.product.custom_attributes.find(f => f.attribute_code === 'special_price')
                ? this.props.product.custom_attributes.find(f => f.attribute_code === 'special_price').value
                : false
            : false

        let sconto = _discountPrice ? (((this.props.product.price - _discountPrice) / this.props.product.price)*100) : false
        sconto = (sconto != false && sconto > 0)? sconto.toFixed() : false

        if (this.props.product === null
            || this.props.product === 'undefined'
            || this.props.product === '' && this.props.products.length > 0) {
            return (<React.Fragment/>)
        } else {
            return (
                <GridCell span={3} tablet={2} align='bottom' className={classes.productContainer}
                >
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        style={{'text-align': 'center'}}
                        fullWidth={false}
                    >

                        <div style={{'padding-right': '10px', "padding-top":"10px", "cursor":"pointer"}} onClick={this.handleClose}>
                            <div>
                                <div className={classes.mdiv1_closex}>
                                    <div className={classes.mdiv_closex}>
                                        <div className={classes.md_closex}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DialogTitle style={{"width": isDesktop ? "450px" : '231px', "padding":"0px 24px 24px"}}>Prodotto aggiunto al
                            carrello</DialogTitle>
                        <DialogContent style={{'width': isDesktop ? '450px' : '231px'}}>
                            <div className={classes.productThumb}
                                 onClick={() => this.handleProductClick(this.props.product)}
                            >
                                <img src={_image ? _image : "../../../images/no-img.png"}
                                     style={{'max-width': isDesktop ? '290px' : '231px', 'max-height': '345'}}
                                />
                            </div>
                            <Typography use='body2' className={classes.productName}
                                        style={{'height': '15%', 'text-align': 'center'}}
                            >
                                {this.props.product.name}
                            </Typography>
                            <Typography use='subtitle2' className={classes.productPrice}>
                                {_discountPrice
                                    ? (_discountPrice >= this.props.product.price) ?
                                        <span>{priceFormat(this.props.product.price)}</span> : <div>
                                            <del style={{
                                                'font-size': '13px',
                                                'margin-right': '5px'
                                            }}>{priceFormat(this.props.product.price)}</del>
                                            <span className={classes.bold}
                                                  style={{'color': '#96bf31'}}>{priceFormat(_discountPrice)}</span>
                                        </div>
                                    : <span>{priceFormat(this.props.product.price)}</span>
                                }
                            </Typography>

                            <div style={{'display': 'flex', 'justify-content': 'center'}}>
                                <a href={routes.cart.path} style={{'color': 'white', "width":"100%"}}>
                                    <Button
                                        style={{
                                            backgroundColor: '#008837',
                                            'height': '44px',
                                            'width': '90%',
                                            'color': 'white'
                                        }}
                                    >
                                        Vai al carrello
                                    </Button>
                                </a>
                            </div>
                            <div style={{"justify-content":"center", "margin-top":"10px"}}>
                                oppure
                                <br/>
                                <Typography
                                    onClick={this.handleClose}
                                    style={{
                                        'color': 'rgb(0, 136, 55)',
                                        "text-decoration":"underline",
                                        "font-weight":"bold",
                                        "cursor":"pointer"
                                    }}
                                >Continua gli acquisti
                                </Typography>
                            </div>
                        </DialogContent>
                    </Dialog>
                    <div className={classes.productThumb}
                         onClick={() => this.handleProductClick(this.props.product)}
                    >
                        <img src={this.props.product.custom_attributes ?
                            (this.props.product.custom_attributes.find(e => e.attribute_code === 'image') ?
                                productThumb(this.props.product) : "../../../images/no-img.png") :
                            productThumb(this.props.product)}/>
                    </div>
                    <Typography use='body2' className={classes.productName}
                                style={{'height': '15%','word-break': 'break-word'}}
                    >
                        {this.props.product.name}
                    </Typography>
                    <Typography use='subtitle2' className={classes.productPrice}>
                        { this.props.product.extension_attributes.stock_item.qty < 900 && this.props.product.extension_attributes.stock_item.qty > 0 && sconto && <Typography className={classes.sconto}>Sconto {sconto}%</Typography>}
                        {_discountPrice
                            ? (_discountPrice >= this.props.product.price) ?
                                <span>{priceFormat(this.props.product.price)}</span> : <div>
                                    <del style={{
                                        'font-size': '13px',
                                        'margin-right': '5px'
                                    }}>{priceFormat(this.props.product.price)}</del>
                                    <span className={classes.bold}
                                          style={{'color': '#96bf31'}}>{priceFormat(_discountPrice)}</span>
                                </div>
                            : <span>{priceFormat(this.props.product.price)}</span>
                        }
                    </Typography>
                    {this.props.product.extension_attributes.stock_item.is_in_stock && this.props.product.extension_attributes.stock_item.qty > 0 &&
                    <KUButton unelevated ripple={false}
                              loading={this.props.ui.addToCartLoading === this.props.product.sku}
                              clickHandler={() => this.onPressAddToCart(this.props.product)}
                              className={classes.addButton}
                              style={{'width': '100%'}}
                    >
                        <Icon icon='add_shopping_cart'/>
                        <Typography use='button' className={classes.aggiungiButton}>AGGIUNGI</Typography>
                    </KUButton>}
                    {
                        (!this.props.product.extension_attributes.stock_item.is_in_stock || this.props.product.extension_attributes.stock_item.qty <= 0)&&
                        <div style={{'display': 'flex', 'justify-content': 'center', 'padding-top':'5px'}}>
                            <div style={{"text-align": "center"}}>
                                <div className={classes.mdiv1}>
                                    <div className={classes.mdiv}>
                                        <div className={classes.md}></div>
                                    </div>
                                </div>
                                <div style={{
                                    'margin-bottom': "auto",
                                    "margin-top": "auto",
                                    "font-size": "15px",
                                    "text-align": "left",
                                    "padding-left": "25px"
                                }}>Non disponibile
                                </div>
                            </div>
                        </div>
                    }

                </GridCell>)
        }
        //)
    }
}

const mapStateToProps = function (state) {
    const category = state.category.current
    const {products, totalCount, loadingMore} = state.category
    const {cart, account, ui} = state
    const canLoadMoreContent = products.length < totalCount

    return {
        category,
        products,
        totalCount,
        cart,
        canLoadMoreContent,
        loadingMore,
        ui,
        customer: account.customer
    }
}

export default connect(
    mapStateToProps, {
        getProductsForCategoryOrChild,
        getProductsForCategory,
        addToCart
    })(injectSheet(style)(CategoryProduct))
