import React from 'react'
import {Grid} from "@material-ui/core";
import {Row} from "react-flexbox-grid";
import Typography from "@material-ui/core/Typography";


class NoteLegali extends React.Component {

    constructor(){
        super()
    }

    render() {
        return(
            <Grid class={'container-fluid'} style={{'padding-left':'16px', 'padding-right':'16px', 'margin-left':'auto','margin-right':'auto','width':'80%', 'margin-top':'2%','margin-bottom':'2%'}}>
                <Row>
                    <Typography use="headline5" style={{'font-size':'1.875rem'}}>Note legali</Typography>
                </Row>
                <br/>
                <Row>
                    <text>
                        <text style={{'font-weight':'bold'}}>SPEDIZIONE E CONSEGNA</text><br/>
                        Le spedizioni sono sempre con corriere espresso GLS.
                        Consegna mediamente in max 1/5 giorni lavorativi  in tutta Italia e nel resto del mondo.
                        Il Costo per l'Italia, al netto di offerte promozionali sulle spedizioni, è così suddiviso:
                        per acquisti fino a 49,90 euro è di 5,00 euro
                        per acquisti da 49,91 euro in su diventano Gratis
                        Diritto di contrassegno attualmente offerto gratuitamente.
                        <br/><br/>
                        <text style={{'font-weight':'bold'}}>TERMINI E CONDIZIONI</text><br/>
                        PREZZI DI VENDITA
                        Tutti i prezzi di vendita dei prodotti esposti ed indicati nel sito Farmakon sono comprensivi di IVA ed ogni altra imposta.
                        La presentazione di merci nel suddetto negozio online non costituisce un'offerta vincolante di farmakon.it per la stipulazione di un contratto di vendita.
                        farmakon.it può rifiutare un’offerta da parte del cliente senza indicarne le ragioni, in modo particolare se sussiste il sospetto di rivendita professionale della merce da parte del cliente.
                        <br/><br/>
                        Il modulo d’ordine sarà archiviato nella banca dati di Farmakon per il tempo necessario alla esecuzione dello stesso e, comunque, nei termini di legge. L’utente potrà accedere al modulo d’ordine e/o ai dati allo stesso relativi tramite il proprio account personale.
                        <br/>GARANZIA LEGALE DI CONFORMITA'<br/>
                        Dal 23 marzo 2002 i prodotti godono di 24 mesi di garanzia legale, ovvero garanzia per i difetti di conformità, come da D.Lgs. 2 Febbraio 2004, n.24. La garanzia legale o per i difetti di conformità, o vizi o mancanza di qualità promesse, riguarda un problema che il bene ha presentato sin dall'origine, come ad esempio un processore che non raggiunge la frequenza di indicata dal venditore. Il consumatore può in questo caso invocare la garanzia per difetto di conformità valevole per due anni dalla data d’acquisto prevista dalla nuova legge. La garanzia legale quindi trova applicazione nei casi in cui il contratto non è, a rigore, stato rispettato, perché è stato consegnato un bene diverso da quello previsto o comunque non in grado di svolgere le prestazioni concordate.
                        <br/><br/>
                        Secondo l'art. 132 Cod. Cons., la garanzia legale dura due anni dalla consegna del bene ma deve essere fatta valere dal consumatore entro 60gg dalla scoperta del difetto.
                        <br/>CONDIZIONI DI ACQUISTO<br/>
                        Tutti i prezzi riportati nelle vetrine del sito, nelle schede prodotti e durante la fase d'acquisto sono indicati in Euro. In caso di rilevata discordanza dei prezzi successivamente all'ordine di acquisto, il Cliente potrà darne comunicazione telefonicamente o a mezzo e-mail e recedere, senza applicazione di alcuna penale, dall'impegno d'acquisto assunto. Il Cliente può acquistare i prodotti presenti nel catalogo elettronico di Farmakon  al momento dell’invio dell’ordine. Le immagini e i dati tecnici a corredo della scheda di ciascun prodotto possono non essere complete ed esaustive delle caratteristiche proprie, ma recare differenze per peso, colore, etc. La corretta ricezione dell’ordine è confermata da Farmacia Loreto mediante risposta via e-mail, inviata all’indirizzo di posta elettronica comunicato dal Cliente. Tale messaggio di conferma riproporrà tutti i dati inseriti dal Cliente che si impegna a verificarne la correttezza e a comunicare tempestivamente eventuali correzioni. Il contratto stipulato tra il Cliente e Farmakon  è da intendersi concluso solo dopo l’invio della e-mail di accettazione contrattuale da parte di Farmakon. Effettuando l’ordine sul sito Farmakon, il Cliente dichiara di aver preso visione delle condizioni di vendita, delle modalità di pagamento proposte e dell’intera procedura di acquisto. La normativa espressamente richiamata per i contratti a distanza è prevista dalla sezione II del D. Lgs. n. 206 del 6 settembre 2005.
                        Il contratto si concluderà direttamente attraverso l'accesso da parte del consumatore/acquirente al sito internet corrispondente all'indirizzo www.farmakon.it, ove, seguendo le indicate procedure, arriverà a formulare la proposta per l'acquisto dei prodotti.
                        <br/>FATTURAZIONE<br/>
                        Per ogni ordine effettuato sul sito Farmakon emette fattura/scontrino fiscale del materiale spedito. La fattura viene emessa contestualmente all'evasione dell'ordine e normalmente accompagna la merce nella busta posta sull'imballo. Per l'emissione della fattura fanno fede le informazioni fornite dal Cliente durante la procedura di acquisto. Dopo l'emissione della fattura, non sarà possibile apportare alcuna modifica ai dati indicati nella stessa. Gli importi saranno riportati in fattura solo in Euro.
                        <br/><br/>
                        <text style={{'font-weight':'bold'}}>DIRITTO DI RECESSO</text>
                        <br/>
                        Lei ha il diritto di recedere dal contratto, senza indicarne le ragioni, entro 14 giorni. Il periodo di recesso scade dopo 14 giorni dal giorno in cui Lei o un terzo, diverso dal vettore e da Lei designato, acquisisce il possesso fisico dei beni.
                        Per esercitare il diritto di recesso, Lei e' tenuto a informarci tramite una dichiarazione esplicita della sua decisione di recedere dal presente contratto tramite una dichiarazione esplicita (ad esempio lettera inviata per posta, fax o posta elettronica).
                        <br/>I nostri recapiti sono:<br/>
                        Pharmamed srl<br/>
                        Via Montalbano 65 - 51100 - Pistoia<br/>
                        email: info@farmakon.it<br/>
                        Fax: +39 0573 382760
                        <br/><br/>
                        Modulo di recesso tipo ai sensi dell'art.49, comma 1, lett. h (compilare e restituire il presente modulo solo se si desidera recedere dal contratto)
                        <br/>Con la presente io/noi (*) notifichiamo il recesso dal mio/nostro(*) contratto di vendita dei seguenti beni/servizi (*)
                        <br/>Ordinato il (*)/ricevuto il (*)
                        <br/>-
                        Nome del/dei consumatore(i)<br/>
                        -<br/>
                        Indirizzo del/dei consumatore(i)<br/>
                        -<br/>
                        Firma del/dei consumatore(i) (solo se il presente modulo e' notificato in versione cartacea)<br/>
                        Per rispettare il termine di recesso, e' sufficiente che Lei invii la comunicazione relativa all'esercizio del diritto di recesso prima della scadenza del periodo di recesso.
                        <br/>EFFETTI DEL RECESSO<br/>
                        E' pregato di rispedire i beni o di consegnarli a noi senza indebiti ritardi e in ogni caso entro 14 giorni dal giorno in cui ci ha comunicato il suo recesso dal presente contratto. Il termine e' rispettato se Lei rispedisce i beni prima della scadenza del periodo di 14 giorni.
                        I costi diretti della restituzione dei beni saranno a Suo carico. Lei e' responsabile solo della diminuzione del valore dei beni risultante da una manipolazione del bene diversa da quella necessaria per stabilire la natura, le caratteristiche e il funzionamento dei beni.
                        Se Lei recede dal presente contratto, Le saranno rimborsati tutti i pagamenti che ha effettuato a nostro favore, compresi i costi di consegna (ad eccezione dei costi supplementari derivanti dalla Sua eventuale scelta di un tipo di consegna diverso dal tipo meno costoso di consegna standard da noi offerto), senza indebito ritardo e in ogni caso non oltre 14 giorni dal giorno in cui siamo informati della Sua decisione di recedere dal presente contratto.
                        Pertanto sarà cura del Cliente fornire tempestivamente le coordinate bancarie (IBAN) sulle quali ottenere il rimborso.
                        <br/>ESCLUSIONI DEL RECESSO<br/>
                        Il diritto di recesso e' escluso relativamente a:<br/>
                        a) i contratti di servizi dopo la completa prestazione del servizio se l'esecuzione e' iniziata con l'accordo espresso del consumatore e con l'accettazione della perdita del diritto di recesso a seguito della piena esecuzione del contratto da parte del professionista;<br/>
                        b) la fornitura di beni o servizi il cui prezzo e' legato a fluttuazioni nel mercato finanziario che il professionista non e' in grado di controllare e che possono verificarsi durante il periodo di recesso;<br/>
                        c) la fornitura di beni confezionati su misura o chiaramente personalizzati;<br/>
                        d) la fornitura di beni che rischiano di deteriorarsi o scadere rapidamente;<br/>
                        e) la fornitura di beni sigillati che non si prestano ad essere restituiti per motivi igienici o connessi alla protezione della salute e sono stati aperti dopo la consegna;<br/>
                        f) la fornitura di beni che, dopo la consegna, risultano, per loro natura, inscindibilmente mescolati con altri beni;<br/>
                        g) la fornitura di bevande alcoliche, il cui prezzo sia stato concordato al momento della conclusione del contratto di vendita, la cui consegna possa avvenire solo dopo trenta giorni e il cui valore effettivo dipenda da fluttuazioni sul mercato che non possono essere controllate dal professionista;<br/>
                        h) i contratti in cui il consumatore ha specificamente richiesto una visita da parte del professionista ai fini dell'effettuazione di lavori urgenti di riparazione o manutenzione.<br/>
                        Se, in occasione di tale visita, il professionista fornisce servizi oltre a quelli specificamente richiesti dal consumatore o beni diversi dai pezzi di ricambio necessari per effettuare la manutenzione o le riparazioni, il diritto di recesso si applica a tali servizi o beni supplementari;<br/>
                        i) la fornitura di registrazioni audio o video sigillate o di software informatici sigillati che sono stati aperti dopo la consegna;<br/>
                        j) la fornitura di giornali, periodici e riviste ad eccezione dei contratti di abbonamento per la fornitura di tali pubblicazioni;<br/>
                        k) i contratti conclusi in occasione di un'asta pubblica;<br/>
                        l) la fornitura di alloggi per fini non residenziali, il trasporto di beni, i servizi di noleggio di autovetture, i servizi di catering o i servizi riguardanti le attività del tempo libero qualora il contratto preveda una data o un periodo di esecuzione specifici;<br/>
                        m) la fornitura di contenuto digitale mediante un supporto non materiale se l'esecuzione e' iniziata con l'accordo espresso del consumatore e con la sua accettazione del fatto che in tal caso avrebbe perso il diritto di recesso.<br/>
                        <text style={{'font-weight':'bold'}}>RECESSO</text><br/>
                        DIRITTO DI RECESSO<br/>
                        Il diritto di recesso è regolato dal D. Lgs. 206/2005  aggiornato dal Codice del Consumo in attuazione della direttiva 2011/83/UE sui diritti dei consumatori in vigore dal 14 giugno 2014. Il Cliente (ossia una persona fisica che acquista la merce per scopi non riferibili alla propria attività professionale, ovvero non effettua l’acquisto indicando nel modulo d’ordine un riferimento di Partita IVA) ha diritto di recedere dal contratto di acquisto per qualsiasi motivo.
                        <br/>Lei ha il diritto di recedere dal contratto, senza indicarne le ragioni, entro 14 giorni. Il periodo di recesso scade dopo 14 giorni dal giorno in cui Lei o un terzo, diverso dal vettore e da Lei designato, acquisisce il possesso fisico dei beni.
                        Per esercitare il diritto di recesso, Lei e' tenuto a informarci tramite una dichiarazione esplicita della sua decisione di recedere dal presente contratto tramite una dichiarazione esplicita (ad esempio lettera inviata per posta, fax o posta elettronica).
                        <br/><br/>
                        Farmakon risponde ai reclami entro 7 giorni lavorativi.
                        <br/><br/>
                        I nostri recapiti sono:<br/>
                        Pharmamed srl<br/>
                        Via Montalbano 65 - 51100 - Pistoia<br/>
                        email: info@farmakon.it<br/>
                        Fax: +39 0573 382760<br/>
                        <br/>
                        Modulo di recesso tipo ai sensi dell'art.49, comma 1, lett. h (compilare e restituire il presente modulo solo se si desidera recedere dal contratto)
                        <br/>
                        Con la presente io/noi (*) notifichiamo il recesso dal mio/nostro(*) contratto di vendita dei seguenti beni/servizi (*)
                        <br/>Ordinato il (*)/ricevuto il (*)
                        -<br/>
                        Nome del/dei consumatore(i)<br/>
                        -<br/>
                        Indirizzo del/dei consumatore(i)<br/>
                        -<br/>
                        Firma del/dei consumatore(i) (solo se il presente modulo e' notificato in versione cartacea)
                        <br/>
                        Per rispettare il termine di recesso, e' sufficiente che Lei invii la comunicazione relativa all'esercizio del diritto di recesso prima della scadenza del periodo di recesso.
                        <br/>EFFETTI DEL RECESSO<br/>
                        E' pregato di rispedire i beni o di consegnarli a noi senza indebiti ritardi e in ogni caso entro 14 giorni dal giorno in cui ci ha comunicato il suo recesso dal presente contratto. Il termine e' rispettato se Lei rispedisce i beni prima della scadenza del periodo di 14 giorni.
                        I costi diretti della restituzione dei beni saranno a Suo carico. Lei e' responsabile solo della diminuzione del valore dei beni risultante da una manipolazione del bene diversa da quella necessaria per stabilire la natura, le caratteristiche e il funzionamento dei beni.
                        Se Lei recede dal presente contratto, Le saranno rimborsati tutti i pagamenti che ha effettuato a nostro favore, compresi i costi di consegna (ad eccezione dei costi supplementari derivanti dalla Sua eventuale scelta di un tipo di consegna diverso dal tipo meno costoso di consegna standard da noi offerto), senza indebito ritardo e in ogni caso non oltre 14 giorni dal giorno in cui siamo informati della Sua decisione di recedere dal presente contratto.
                        Pertanto sarà cura del Cliente fornire tempestivamente le coordinate bancarie (IBAN) sulle quali ottenere il rimborso.
                        <br/>ESCLUSIONI DEL RECESSO<br/>
                        Il diritto di recesso e' escluso relativamente a:<br/>
                        a) i contratti di servizi dopo la completa prestazione del servizio se l'esecuzione e' iniziata con l'accordo espresso del consumatore e con l'accettazione della perdita del diritto di recesso a seguito della piena esecuzione del contratto da parte del professionista;<br/>
                        b) la fornitura di beni o servizi il cui prezzo e' legato a fluttuazioni nel mercato finanziario che il professionista non e' in grado di controllare e che possono verificarsi durante il periodo di recesso;<br/>
                        c) la fornitura di beni confezionati su misura o chiaramente personalizzati;<br/>
                        d) la fornitura di beni che rischiano di deteriorarsi o scadere rapidamente;<br/>
                        e) la fornitura di beni sigillati che non si prestano ad essere restituiti per motivi igienici o connessi alla protezione della salute e sono stati aperti dopo la consegna;<br/>
                        f) la fornitura di beni che, dopo la consegna, risultano, per loro natura, inscindibilmente mescolati con altri beni;<br/>
                        g) la fornitura di bevande alcoliche, il cui prezzo sia stato concordato al momento della conclusione del contratto di vendita, la cui consegna possa avvenire solo dopo trenta giorni e il cui valore effettivo dipenda da fluttuazioni sul mercato che non possono essere controllate dal professionista;<br/>
                        h) i contratti in cui il consumatore ha specificamente richiesto una visita da parte del professionista ai fini dell'effettuazione di lavori urgenti di riparazione o manutenzione.<br/>
                        Se, in occasione di tale visita, il professionista fornisce servizi oltre a quelli specificamente richiesti dal consumatore o beni diversi dai pezzi di ricambio necessari per effettuare la manutenzione o le riparazioni, il diritto di recesso si applica a tali servizi o beni supplementari;<br/>
                        i) la fornitura di registrazioni audio o video sigillate o di software informatici sigillati che sono stati aperti dopo la consegna;<br/>
                        j) la fornitura di giornali, periodici e riviste ad eccezione dei contratti di abbonamento per la fornitura di tali pubblicazioni;<br/>
                        k) i contratti conclusi in occasione di un'asta pubblica;<br/>
                        l) la fornitura di alloggi per fini non residenziali, il trasporto di beni, i servizi di noleggio di autovetture, i servizi di catering o i servizi riguardanti le attività del tempo libero qualora il contratto preveda una data o un periodo di esecuzione specifici;<br/>
                        m) la fornitura di contenuto digitale mediante un supporto non materiale se l'esecuzione e' iniziata con l'accordo espresso del consumatore e con la sua accettazione del fatto che in tal caso avrebbe perso il diritto di recesso.<br/>
                        Farmacia Loreto non risponde in nessun modo per danneggiamenti o furto/smarrimento di beni restituiti con spedizioni non assicurate; al suo arrivo in magazzino, il prodotto sarà esaminato per valutare eventuali danni o manomissioni non derivanti dal trasporto. Qualora la confezione e/o l'imballo originale risultino rovinati, Farmacia Loreto provvederà a trattenere dal rimborso dovuto una percentuale, comunque non superiore al 10% dello stesso, quale contributo per le spese di ripristino.<br/>
                        <br/>
                        Il rischio della perdita o del danneggiamento dei beni, per causa non imputabile al venditore, si trasferisce al consumatore soltanto nel momento in cui quest'ultimo, o un terzo da lui designato e diverso dal vettore, entra materialmente in possesso dei beni. Tale regola non è valida quando il vettore sia stato scelto dal consumatore e non proposto dal professionista: in quest’ipotesi, il passaggio del rischio avviene già al momento della consegna del bene al vettore da parte del venditore e, quindi, se il bene è danneggiato o perisce nel trasporto la perdita verrà completamente assorbita dal consumatore salvo, ovviamente, il diritto di rivalsa nei confronti dello spedizioniere/vettore.
                        <br/>RISOLUZIONE ONLINE DELLE CONTROVERSIE<br/>
                        Si informa il Consumatore residente in Europa che la Commissione Europea ha istituito una piattaforma online che fornisce uno strumento di risoluzione alternativa delle controversie.
                        Tale strumento può essere utilizzato dal Consumatore europeo per risolvere in via non giudiziale ogni controversia relativa a contratti di vendita di beni e servizi stipulati in rete e/o derivante dagli stessi.
                        Di conseguenza, se Lei è un Consumatore stabilito in Europa, può usare tale piattaforma per la risoluzione di ogni disputa nascente dal contratto online stipulato su questo sito.
                        La piattaforma Europea ODR è disponibile al seguente link: https://webgate.ec.europa.eu/odr
                        <br/>MODALITÀ DI PAGAMENTO ACCETTATE<br/>
                        Puoi utilizzare diversi metodi di pagamento per effettuare acquisti sul nostro sito.<br/>
                        Visa (Stripe, PayPal)<br/>
                        Visa Electron (Stripe, PayPal)<br/>
                        MasterCard (Stripe, PayPal)<br/>
                        MasterPass (Stripe)<br/>
                        PayPal<br/>
                        Pagamento in contrassegno<br/>
                        Al momento della consegna del bene ordinato il cliente pagherà direttamente la somma al corriere. Se l'importo è inferiore a € 999,99 il pagamento potrà avvenire in contanti, per importi superiori dovrà essere effettuato necessariamente tramite assegno circolare intestato al corriere.<br/>
                        Pagamento con Carta di Credito (Stripe)<br/>
                        Nei casi di acquisto della merce con modalità di pagamento carta di credito, la Banca provvederà ad autorizzare il solo addebito dell'importo relativo all'acquisto effettuato. In caso di annullamento dell'ordine sia da parte del cliente che nel caso di mancata accettazione del contratto da parte di Farmakon l'annullamento della transazione e la restituzione dell'importo impegnato. I tempi di svincolo dipendono esclusivamente dal sistema bancario. Una volta effettuato l'annullamento della transazione Farmakon non potrà essere in nessun caso ritenuta responsabile per ritardi e/o danni provocati dal sistema bancario.<br/>
                        Farmakon si riserva, in casi particolari, la facoltà di richiedere al cliente informazioni aggiuntive o invio di copie di documenti che dimostrino la titolarità della carta utilizzata. Nel caso il cliente non fornisca tali informazioni e/o copie, Farmakon si riserva la facoltà di non accettare la conclusione del contratto. In nessun momento della procedura di acquisto mediante carta di credito Farmakon è in grado di conoscere informazioni personali relative al titolare della stessa atteso che le informazioni vengono trasmesse direttamente al sito dell'Istituto bancario che gestisce la transazione. Per tale motivo Farmacia Loreto non può essere ritenuta responsabile per eventuali usi fraudolenti di carte di credito da parte di terzi all'atto di pagamento di prodotti acquistati sul proprio sito.<br/>
                        Sistema di Pagamento sicuro SSL<br/>
                        Quando procedi al pagamento con carta di credito, viene utilizzato il sistema di pagamento sicuro SSL. Al momento della conferma del tuo ordine, i dati della tua carta di credito saranno trasferiti, tramite connessione protetta in crittografia SSL (Secure Socket Layer), al servizio di pagamento scelto per l'autorizzazione e l'addebito. In nessun caso riceveremo il numero della tua carta di credito. A noi giungerà soltanto l'autorizzazione fornita dal gestore della carta. Questa procedura è assolutamente sicura e garantisce completamente sia l'acquirente che chi vende. Il sistema di crittografia SSL garantisce che nessuno possa in alcun modo leggere le informazioni inviate attraverso internet.
                        <br/>Pagamento con PayPal<br/>
                        Selezionando il tipo di pagamento PayPal, sarai reindirizzato ad una pagina del sito PayPal dove inserirai il tuo indirizzo email e la tua password o utilizzare una delle carte di credito supportate ed effettuerai il pagamento.
                        I tuoi dati finanziari non saranno condivisi con Farmacia Loreto ma saranno gestiti direttamente da PayPal. In caso di annullamento dell'ordine o di mancata accettazione da parte di Farmakon, l'importo sarà rimborsato sul tuo conto PayPal. Farmakon non sarà responsabile per ritardi e/o danni provocati in fase di rimborso.
                    </text>
                </Row>
            </Grid>
        )
    }

}

export default NoteLegali