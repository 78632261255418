import React, {Component} from 'react'
import injectSheet from 'react-jss'
import {Grid, Col, Row} from 'react-flexbox-grid'
import {Button} from '@rmwc/button'
import {
    DataTable,
    DataTableContent,
    DataTableHead,
    DataTableBody,
    DataTableHeadCell,
    DataTableRow,
    DataTableCell
} from '@rmwc/data-table'
import {Typography} from '@rmwc/typography'
import {priceFormat} from '../../lib/format'
import {magento} from "../../lib/magento";
import {style} from './style'
import Link from "@material-ui/core/Link";
import {ADMIN_TYPE} from "../../lib/magento/types";
import ProfileDetailOrder from '../ProfileDetailOrder'
import KUButton from '../KUButton'
import * as ReactDOM from "react-dom";
import {getOrders, logout} from "../../actions";
import {connect} from 'react-redux'
import mapStateToProps from "react-redux/es/connect/mapStateToProps";
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import {routes} from "../../config/settings";
import Loader from "react-loader-spinner";
import {getCustomerOrders} from "../../actions";
import history from "../../lib/helper/history";


class ProfileOrders extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openMenu: null,
            orders: props.orders ? props.orders.items : false,
            total_count: props.orders ? props.orders.total_count : false,
            detail: false,
            isDesktop: false
        }
        console.log(this.state)
        this.updatePredicate = this.updatePredicate.bind(this);
        this.handleCloseDetail = this.handleCloseDetail.bind(this);
    }

    updatePredicate() {
        this.setState({isDesktop: window.innerWidth > 620});
    }

    async componentWillMount() {
        if (!this.state.orders) {
            setTimeout(async () => {
                if (!this.props.account.customer) {
                    history.push('/')
                } else {
                    await this.props.getCustomerOrders(this.props.account.customer.email)
                    this.state.orders = this.props.orders ? this.props.orders.items : false
                    this.state.total_count = this.props.orders ? this.props.orders.total_count : false
                }
            }, 2000)
        }
        window.removeEventListener("resize", this.updatePredicate);
    }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }


    getStatusOrder(stato) {
        let ret = ""
        switch (stato) {
            case 'pending':
                ret = "In attesa di pagamento"
                break;
            case 'canceled':
                ret = "Annullato"
                break;
            case 'processing':
                ret = "In evasione"
                break;
            case 'complete':
                ret = "Completato"
                break;
            case 'closed':
                ret = "Chiuso"
                break;
            case 'onhold':
                ret = "In attesa"
                break;
        }
        return ret
    }

    handleCloseDetail(){
        this.setState({
            detail: false
        })
    }

    render() {
        const {classes} = this.props
        let isDesktop = this.state.isDesktop
        let orders = this.state.orders

        return (
            <React.Fragment>
                <Grid className={classes.profileOrders}>
                    {this.state.total_count === 0 && <div style={{"padding-top":"20px", "padding-bottom":"20px"}}><Loader
                        type="TailSpin"
                        color="#000"
                        height={50}
                        width={100}
                        timeout={1000}
                    /> </div>}

                    {this.state.total_count === false && <Loader
                        type="TailSpin"
                        color="#000"
                        height={50}
                        width={100}
                        timeout={5000}
                    />}

                    {this.state.total_count > 0 && <Loader
                        type="TailSpin"
                        color="#000"
                        height={50}
                        width={100}
                        timeout={1000}
                    />}

                    {
                        this.state.detail && <ProfileDetailOrder
                            order={this.state.detail}
                            handleCloseDetail={this.handleCloseDetail}
                        />
                    }

                    {this.state.detail ?
                        <div style={{"display":"none"}}></div> :
                        this.state.total_count === 0 ?
                            <Grid className={classes.profileOrders} style={{
                                'margin-bottom': isDesktop ? false : '5%',
                                'margin-top': isDesktop ? false : '5%'
                            }}>
                                <div style={{'margin-top': '-3%'}}>
                                    <Typography className={classes.name} use='headline5'>Nessun ordine
                                        effettuato</Typography>
                                    <br/><br/>
                                    <Typography use='headline7'>Non hai ancora fatto acquisti su
                                        FarmaKon.it</Typography>
                                    <br/>
                                    <Button className={classes.buy} unelevated>
                                        <a href={'/'}><p
                                            style={{'color': 'white', 'margin': '0', 'padding': '0'}}>INIZIA SUBITO</p>
                                        </a>
                                    </Button>
                                </div>
                            </Grid>
                            :
                            isDesktop ?
                                <Grid className={classes.profileOrders}>
                                    <Row>
                                        <Col xs={12}>
                                            <DataTable className={classes.orders}>
                                                <DataTableContent>
                                                    <DataTableHead>
                                                        <DataTableRow>
                                                            <DataTableHeadCell aligncenter>N° Ordine</DataTableHeadCell>
                                                            <DataTableHeadCell aligncenter>Data</DataTableHeadCell>
                                                            <DataTableHeadCell>Destinatario</DataTableHeadCell>
                                                            <DataTableHeadCell aligncenter>Totale</DataTableHeadCell>
                                                            <DataTableHeadCell alignCenter>Stato</DataTableHeadCell>
                                                            <DataTableHeadCell alignCenter/>
                                                        </DataTableRow>
                                                    </DataTableHead>
                                                    <DataTableBody>

                                                        {(this.state.orders).map((order) => {
                                                            return (<DataTableRow>
                                                                <DataTableCell aligncenter
                                                                               className='text-center'>{order.increment_id}</DataTableCell>
                                                                <DataTableCell aligncenter
                                                                               className='text-center'>{order.created_at}</DataTableCell>
                                                                <DataTableCell aligncenter
                                                                               className='text-center'>{order.billing_address.firstname} {order.billing_address.lastname}</DataTableCell>
                                                                <DataTableCell aligncenter
                                                                               className='text-center'>{priceFormat(order.grand_total)}</DataTableCell>
                                                                <DataTableCell alignCenter
                                                                               className='text-center'>{this.getStatusOrder(order.status)}</DataTableCell>
                                                                <DataTableCell alignCenter className='text-center'>
                                                                    <KUButton className={classes.details}
                                                                              clickHandler={() => (this.setState({detail: order}))}
                                                                              unelevated>
                                                                        Dettagli
                                                                    </KUButton>
                                                                </DataTableCell>
                                                            </DataTableRow>)
                                                        })}

                                                    </DataTableBody>
                                                </DataTableContent>
                                            </DataTable>
                                            <br/>
                                            <br/>
                                        </Col>
                                    </Row>

                                </Grid> :
                                <Grid style={{
                                    'margin': '0',
                                    'padding': '0',
                                    'margin-top': '2%',
                                    'margin-bottom': '2%',
                                    'width': '100%'
                                }}>

                                    <Row className={classes.profileAddressesTitle}>
                                        <Col xs={1}>
                                            <a href={routes.personal.path}><KeyboardBackspace
                                                style={{
                                                    'color': 'black',
                                                    'margin-top': '20%',
                                                    'margin-left': '-100%'
                                                }}/></a>
                                        </Col>
                                        <Typography use='headline5'>Ordini</Typography>
                                    </Row>

                                    <br/>
                                    {(this.state.orders).map((order) => {
                                        {
                                            console.log(order)
                                        }
                                        return <React.Fragment>
                                            <Row>
                                                <Col xs={6}>N° Ordine</Col>
                                                <Col xs={6}>{order.increment_id}</Col>
                                            </Row>
                                            <Row>
                                                <Col xs={6}>Data</Col>
                                                <Col xs={6}>{order.created_at}</Col>
                                            </Row>
                                            <Row>
                                                <Col xs={6}>Destinatario</Col>
                                                <Col
                                                    xs={6}>{order.billing_address.firstname} {order.billing_address.lastname}</Col>
                                            </Row>
                                            <Row>
                                                <br/>
                                                <Col xs={6}>Totale</Col>
                                                <Col xs={6}>{priceFormat(order.grand_total)}</Col>
                                            </Row>
                                            <Row>

                                                <Col xs={6}>Stato</Col>
                                                <Col xs={6}>{this.getStatusOrder(order.status)}</Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12}>
                                                    <KUButton className={classes.details}
                                                              clickHandler={() => (this.setState({detail: order}))}
                                                              style={{'padding': '5px'}}
                                                              unelevated>
                                                        Dettagli
                                                    </KUButton>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <hr/>
                                            <br/>
                                        </React.Fragment>
                                    })}
                                </Grid>
                    } </Grid>
            </React.Fragment>

        )
    }
}

const mapStateProps = (state) => (
    {
        account: state.account,
        orders: state.account.orders
    }
)

export default connect(
    mapStateProps,
    {
        getCustomerOrders
    }
)(injectSheet(style)(ProfileOrders))
