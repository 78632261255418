import React, { Component } from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import { Grid, Col, Row } from 'react-flexbox-grid'
import { Typography } from '@rmwc/typography'
import { Radio } from 'rmwc'
import ModalAddCustomerAddress from '../ModalAddCustomerAddress'
import KUButton from '../KUButton'
import { Button } from '@rmwc/button'
import { updateCustomerProfile } from '../../actions/'
import { style } from './style'
import ModalModifyCustomerAddress from '../ModalModifyCustomerAddress'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/es/TextField/TextField";
import Link from "@material-ui/core/Link";
import {routes} from '../../config/settings'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'

class ProfileAddresses extends Component {

  constructor (props) {
    super(props)
    this.state = {
      is_visible_add_new_address_modal: false,
      isActive:false,
      id_shipping_address_selected: -1,
      open: false,
      street0: '',
      street1:'',
      cap:'',
      provincia:'',
      citta:'',
      isEditing: false,
      isDesktop: false
    }
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  toggleAddNewAddressModal = (visible = false) => {
    this.setState({
      is_visible_add_new_address_modal: visible
    })
  }

  openDialog () {
    this.setState({
      open: true,
      street0: this.props.account.customer.addresses.find(e => e.id === parseInt(this.state.id_shipping_address_selected))? this.props.account.customer.addresses.find(e => e.id === parseInt(this.state.id_shipping_address_selected)).street[0] : false,
      street1:this.props.account.customer.addresses.find(e => e.id === parseInt(this.state.id_shipping_address_selected))? this.props.account.customer.addresses.find(e => e.id === parseInt(this.state.id_shipping_address_selected)).street[1] : false,
      citta: this.props.account.customer.addresses.find(e => e.id === parseInt(this.state.id_shipping_address_selected))? this.props.account.customer.addresses.find(e => e.id === parseInt(this.state.id_shipping_address_selected)).city : false,
      cap:this.props.account.customer.addresses.find(e => e.id === parseInt(this.state.id_shipping_address_selected))? this.props.account.customer.addresses.find(e => e.id === parseInt(this.state.id_shipping_address_selected)).postcode : false,
      provincia:this.props.account.customer.addresses.find(e => e.id === parseInt(this.state.id_shipping_address_selected))? this.props.account.customer.addresses.find(e => e.id === parseInt(this.state.id_shipping_address_selected)).region.region_code : false
    })
  }

  handleClose = () => {
    this.setState({
      open:false
    })
  };


  deleteAddressHandler () {
    const customer = {
      ...this.props.account.customer,
      addresses: this.props.account.customer.addresses.filter(e => e.id !== parseInt(this.state.id_shipping_address_selected))
    }
    this.props.updateCustomerProfile(customer)
  }

  modifyAddressHandler(){
    let i = 0;
    let i_change  //indice dove cambiare l'address
    let size = this.props.account.customer.addresses.length
    for(i = 0; i<size; i++){
      if(this.props.account.customer.addresses[i].id === parseInt(this.state.id_shipping_address_selected)){
        i_change = i
      }
    }
    if(i != null){
      this.props.account.customer.addresses[i_change].city = this.state.citta
      this.props.account.customer.addresses[i_change].postcode = this.state.cap
      this.props.account.customer.addresses[i_change].region.region_code = this.state.provincia
      this.props.account.customer.addresses[i_change].region.region = this.state.provincia
      this.props.account.customer.addresses[i_change].street[0] = this.state.street0
      this.props.account.customer.addresses[i_change].street[1] = this.state.street1
      this.props.updateCustomerProfile(this.props.account.customer)
    }
  }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    updatePredicate() {
        this.setState({ isDesktop: window.innerWidth > 620 });
    }

    componentWillMount () {
        window.removeEventListener("resize", this.updatePredicate);
    }

  render () {
    const { classes } = this.props
      let isDesktop = this.state.isDesktop
    return (
      <Grid className={classes.profileAddresses}>
          {isDesktop ?
              <Row className={classes.profileDataTitle}
                style={{'margin-top':'10px'}}
              >
                  <Col xs={12}>
                      <Typography use='headline5'>I tuoi indirizzi di spedizione</Typography>
                  </Col>
              </Row>
              : <Row className={classes.profileAddressesTitle}>
                  <Col xs={1}>
                      <a href={routes.personal.path}><KeyboardBackspace
                          style={{'color': 'black', 'margin-top': '19%', 'margin-left': '-100%'}}/></a>
                  </Col>
                  <Col xs={11}><Typography use='headline7'>I tuoi indirizzi di spedizione</Typography></Col>
              </Row>
          }
        <Row style={{ marginBottom: 20 }}>
          <Col xs={12}>
            {
              (this.props.account && this.props.account.customer && this.props.account.customer.addresses) &&
              this.props.account.customer.addresses.map(addr =>
                <div key={addr.id}>
                  <Radio
                    name='id_shipping_address_selected'
                    value={addr.id}
                    checked={parseInt(this.state.id_shipping_address_selected) === parseInt(addr.id)}
                    onChange={evt => {
                      this.setState({
                        id_shipping_address_selected: parseInt(evt.target.value)
                      })
                    }}
                  >
                    <strong>{addr.company && `${addr.company} -`} {addr.firstname} {addr.lastname} </strong>
                    {addr.street.join(' ')}, {addr.city} {addr.postcode}
                  </Radio>
                  <br />
                </div>
              )
            }
            <br/>
            <br/>
            <Typography use='body2'
              style={{ marginLeft: 16, textDecoration: 'underline', color: '#333333', cursor: 'pointer' }}
              onClick={() => this.toggleAddNewAddressModal(true)}
            >
              + Aggiungi un indirizzo per la consegna
            </Typography>

            {isDesktop? false: <React.Fragment><br/><br/></React.Fragment>}
            { this.state.is_visible_add_new_address_modal &&
              <ModalAddCustomerAddress
                toggleAddNewAddressModal={this.toggleAddNewAddressModal}
                blueButton
              />
            }
          </Col>
        </Row>
        <Row className={classes.profileDataFooter} /*style={{'width':'300', 'height':'44px'}}*/>
            <Col xs={6}>
                <KUButton
                    blue unelevated
                    disabled={parseInt(this.state.id_shipping_address_selected) < 0}
                          clickHandler={() => this.openDialog()}>
                  Modifica
                </KUButton>
            </Col>
            <Col xs={6}></Col>
          <Dialog
              title="Modifica indirizzo"
              open={this.state.open}
              onClose={this.handleClose}
          >
            <form action="/" method="POST"
                  style={{'height':'auto', 'width':'500px', 'text-align':'center'}}
                  onSubmit={(e) => { e.preventDefault(); this.handleClose(); this.modifyAddressHandler() } }>
             <br/>
              <h2 style={{'font-weight':'bold', 'color':'#111B34', 'margin-left':'-209px', 'gridColumnEnd':'span 12'}}>Modifica indirizzo di spedizione</h2>
              <br/>
              <TextField id={"indirizzo"} required disabled={false}
                         style={{ gridColumnEnd: 'span 6' }}
                         label={"Indirizzo"}
                         value={this.state.street0}
                         onChange={event => this.setState({ street0: event.target.value })}
              ></TextField>
              <TextField id={"citta"} required disabled={false}
                         style={{ gridColumnEnd: 'span 6' }}
                         label={"Città"}
                         value={this.state.citta}
                         onChange={event => this.setState({ citta: event.target.value })}
              ></TextField>
              <br/><br/>
              <TextField id={"civico"} required disabled={false}
                         style={{ gridColumnEnd: 'span 6' }}
                         label={"Numero Civico"}
                         value={ this.state.street1}
                         onChange={event => this.setState({ street1: event.target.value })}
                          ></TextField>
              <TextField id={"cap"} required disabled={false}
                         style={{ gridColumnEnd: 'span 6' }}
                         label={"CAP"}
                         value={this.state.cap}
                         onChange={event => this.setState({cap: event.target.value })}
              ></TextField>
              <br/><br/>
              <TextField id={"provincia"} required disabled={false}
                         style={{ gridColumnEnd: 'span 12' }}
                         label={"Provincia"}
                         value={this.state.provincia}
                         onChange={event => this.setState({ provincia: event.target.value })}
              ></TextField>
              <br/><br/>
              <KUButton style={{gridColumnEnd: 'span 6', 'background-color':'white', 'color':'#111B34', 'border':'2px solid'}}>Annulla</KUButton>
              <Button type={'submit'} style={{gridColumnEnd: 'span 6', 'background-color':'#111B34', 'color':'white', 'margin-left':'10px'}}>Modifica</Button>
              <br/><br/>
            </form>
          </Dialog>
        </Row>
          <Row>
          <Col xs={6}>
            <KUButton blue unelevated
              disabled={this.props.account.customer.addresses.length <= 1? true : false}
              clickHandler={() => this.deleteAddressHandler()}
            >
                Elimina
            </KUButton>
          </Col>
              <Col xs={6}></Col>
        </Row>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.account
})

const mapDispatchToProps = {
  updateCustomerProfile
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectSheet(style)(ProfileAddresses))
