import React, {Component} from 'react'
import { ListItem, DrawerHeader, DrawerTitle, DrawerSubtitle, List, DrawerContent, Drawer } from 'rmwc'

export default class MobileDrawer extends Component {
  render () {
    return (
      <Drawer
        modal
        open={this.props.modalOpen}
        onClose={() => this.props.toggle()}
      >
        <DrawerHeader>
          <DrawerTitle>DrawerHeader</DrawerTitle>
          <DrawerSubtitle>Subtitle</DrawerSubtitle>
        </DrawerHeader>
        <DrawerContent>
          <List>
            <ListItem>Cookies</ListItem>
            <ListItem>Pizza</ListItem>
            <ListItem>Icecream</ListItem>
          </List>
        </DrawerContent>
      </Drawer>
    )
  }
}
