import React from 'react'
import injectSheet from 'react-jss'
import { GridCell, GridInner, Typography } from 'rmwc'
import { priceFormat } from '../../lib/format'
import { productThumb } from '../../lib/product'
import { style } from './style'

class MiniCartProduct extends React.Component {
  render () {
    const { classes, product } = this.props
    let _image
    var img = this.props.product.extension_attributes.image_url
    var result = img.indexOf("placeholder")
    if(result != -1) {
      _image = "../../../images/no-img.png"
    } else {
      _image = product.extension_attributes.image_url? product.extension_attributes.image_url : productThumb(product)
    }

    //let _image = this.props.product.extension_attributes.image_url || productThumb(product)
    let _discountPrice = this.props.product.custom_attributes
        ? this.props.product.custom_attributes.find(f => f.attribute_code === 'special_price')
            ? this.props.product.custom_attributes.find(f => f.attribute_code === 'special_price').value
            : false
        : false

    return (
        <GridCell span='12' className={classes.cartProduct}>
          <GridInner>
            <GridCell span='4' className={classes.cartProductImage}>
              { <img className={`carrelloMobile ${classes.productImage}`}  src={_image? _image : '../../../images/no-img.png'} alt={this.props.product.name} /> }
            </GridCell>
            <GridCell span='8' className={classes.cartProductQt}>
              <Typography use='body2'>{this.props.product.qty} x {this.props.product.name}</Typography>
              <br />
              <Typography use='subtitle2' className={classes.productPrice}>
                {_discountPrice
                    ? <div>
                      <del>{priceFormat(this.props.product.price)}</del>
                      <span>{priceFormat(_discountPrice)}</span>
                    </div>
                    : <span>{priceFormat(this.props.product.price)}</span>
                }
              </Typography>
            </GridCell>
          </GridInner>
          <hr/>
        </GridCell>

    )
  }
}

/* const mapStateToProps = function (state) {
  return {}
}

export default connect(mapStateToProps, { })(injectSheet(style)(MiniCartProduct)) */

export default injectSheet(style)(MiniCartProduct)
