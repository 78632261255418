export const SITE_NAME = 'FarmaKon - La tua Parafarmacia'
export const MAGENTO_STORE_VIEW = 'farmakon' // 'default'
export const IMG_URL_BASE = 'https://app.farmakon.it/pub/media/catalog/product'
export const API_HOST_URL = 'https://app.farmakon.it/'
export const ACCESS_TOKEN = 'kygyak6d5ntm8t33dtipmc22bjy4wtz4'
export const FREE_SHIPPING_MIN_ORDER = 59.90
export const CASH_PAYMENT_COST = 4
export const PAYPAL_ENV = 'production' // 'production'
export const PAYPAL_SANBOX_APP_ID = 'AVKC_qEBQv_o7QKAHdRWCw512FoZz6WKMnqG1BfuEPbX5O9j3pPwFxliEKt22wts5f-JVWD5fi3hL_Vi'
export const PAYPAL_PRODUCTION_APP_ID = 'AdKtkbKBGxTd1_ryMHLJ8_Mx9ihN6TWlLERxQV0dh8ib0cO4_CZWN8Jm89YLbmyHherESEj7iqmVUzfE'
export const STRIPE_APP_ID = 'pk_live_AnWqlgkadAvt8dx5PAr01xYn' //'pk_test_nJPzi5WCofncz3D10Yd5wGCO' //'pk_live_AnWqlgkadAvt8dx5PAr01xYn'

export const SEO_TITLE_PREFIX = `${SITE_NAME}`

export const REDIRECT_TO_CUSTOMER_AREA_AFTER_LOGIN = true

export const PWD_REGEX_VALIDATION = new RegExp(/^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/)

export const routes = {
  home: {
    path: '/'
  },
  cart: {
    path: '/carrello'
  },
  categories: {
    path: '/categoria'
  },
  product: {
    path: '/scheda-prodotto'
  },
  search: {
    path: '/cerca'
  },
  personal: {
    path: '/area-personale'
  },
  checkout: {
    path: '/checkout'
  },
  checkoutLogin: {
    path: '/checkout/#autenticazione'
  },
  checkoutRegister: {
    path: '/checkout/#registrati'
  },
  checkoutShipping: {
    path: '/checkout/#spedizione'
  },
  checkoutPayment: {
    path: '/checkout/#pagamento'
  },
  checkoutReview: {
    path: '/checkout/#riepilogo'
  },
  checkoutThankYou: {
    path: '/checkout/ordine-completato'
  },
  chiSiamo:{
    path: '/chi-siamo'
  },
  indexResetPassword:{
    path: '/reset-password'
  },
  tempiDiSpedizione:{
    path: '/tempi-spedizione'
  },
  metodiPagamento:{
    path: '/metodi-pagamento'
  },
  recessoGaranzia:{
    path: '/recesso-garanzia'
  },
  noteLegali:{
    path: '/note-legali'
  },
  contattaci:{
    path: '/contattaci'
  },
  orders:{
    path:'/orders'
  },
  profiledata:{
    path:'/dati-personali'
  },
  addresses:{
    path:'/indirizzi'
  },
  changepassword:{
    path:'/change-password'
  },
  privacypolicy:{
    path:'/privacy-policy'
  },
  cookiepolicy:{
    path:'/cookie-policy'
  },
  resetPassword:{
    path:'/recupero-password'
  },
  brand:{
    path:'/brand'
  },
  homenewsletter:{
    path:'/#newsletter'
  },
  coupons:{
    path:'/coupons'
  },
  login:{
    path:'/login'
  }
}

export const magentoOptions = {
  url: API_HOST_URL,
  // home_cms_block_id: '19',
  authentication: {
    // login: {
    //  type: 'admin',
    //  username: 'dev',
    //  password: 'q1w2e3r4'
    // },
    integration: {
      access_token: ACCESS_TOKEN
    }
  }
}


/**
 * 2019-07-24
 *
 Consumer Key
 08abz5mdkpcew3z005ecpyr48c4eytui

 Consumer Secret
 ba2iaph3ojeipbeyeznzqliadlzprhgi

 Access Token
 yyswb99o12v4plw9q3jrintm2l4xj0a9

 Access Token Secret
 moy62m1el2ef9k1470lqncdgavhfn4d6
 */


/**
 * 2018-11-28
 * Consumer Key:        nij9y81rbum70wlmbhip9etvquk2eaxu
 * Consumer Secret:     9ltjufnnsq9srn3lirgx1fg53zuvwrzd
 * Access Token:        a1b4av4jjadl23cz9ksehef5vz7sxt9a
 * Access Token Secret: 3pd2ny3a95p9vxrr06atd5jod0j6lg6t
 */
