import React from 'react'
import injectSheet from 'react-jss'
import {style} from "../../containers/Profile/style";
import {connect} from "react-redux";
import {logout} from "../../actions";
import {magento} from "../../lib/magento";
import {ADMIN_TYPE} from "../../lib/magento/types";
import {Col, Grid, Row} from "react-flexbox-grid";
import {Elevation} from '@rmwc/elevation'
import Typography from "@material-ui/core/Typography";
import { priceFormat } from '../../lib/format'
import {routes} from "../../config/settings";
import KUButton from "../KUButton";
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'

class ProfileDetailOrder extends React.Component {
    constructor(props) {
        super()
        this.state = {
            order: props.order,
            isDesktop: false
        }
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    updatePredicate() {
        this.setState({ isDesktop: window.innerWidth > 620 });
    }

    componentWillMount () {
        window.removeEventListener("resize", this.updatePredicate);
    }

    getStatusOrder(stato){
        let ret = ""
        switch (stato) {
            case 'pending': ret="In attesa di pagamento"
                break;
            case 'canceled': ret="Annullato"
                break;
            case 'processing': ret="In evasione"
                break;
            case 'complete': ret="Completato"
                break;
            case 'closed': ret="Chiuso"
                break;
            case 'onhold': ret="In attesa"
                break;
        }
        return ret
    }

    render(){
        const classes = this.props
        let isDesktop = this.state.isDesktop
        return(
            <div style={{'margin-bottom':'5%', 'margin-top': isDesktop? '20px':false}}>
                <Row center='md'>
                    <Col md={11}>
                        <Elevation style={{ textAlign: 'left' }}>
                            <Row>
                                <Col md={7}>
                                    {isDesktop ?
                                        <Row className={classes.profileDataTitle}>
                                            <Col xs={12}>
                                                <Typography use='headline4'>Ordine n° {this.state.order.increment_id}</Typography>
                                            </Col>
                                        </Row>
                                        : <Row className={classes.profileAddressesTitle}>
                                            <Col xs={1}>
                                                {/*<a href={routes.personal.path}>*/}
                                                <KUButton clickHandler={() => this.props.handleCloseDetail()}>
                                                <KeyboardBackspace
                                                    style={{'color': 'black', 'margin-top': '-36%', 'margin-left': '-100%'}}/>
                                                </KUButton>
                                            {/*</a>*/}
                                            </Col>
                                            <Col xs={11}><Typography use='headline7'>Ordine n° {this.state.order.increment_id}</Typography></Col>
                                        </Row>
                                    }
                                    <br/>
                                    <Row>
                                        <Col md={12}>
                                            <Typography>
                                                Prodotti ({ this.state.order.total_item_count } articoli)
                                            </Typography>
                                            { this.state.order.items &&
                                            Object.values(this.state.order.items)
                                                .map((product, i) => (
                                                    <Row key={i}>
                                                        <Col md={8}>
                                                            <Typography key={i}>
                                                                {product.qty_ordered} x {product.name}
                                                            </Typography>
                                                        </Col>
                                                        <Col md={4}>
                                                            <Typography key={i}>
                                                                {priceFormat(product.price)}
                                                            </Typography>
                                                        </Col>
                                                    </Row>
                                                ))
                                            }
                                            <hr />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12}>

                                            <Typography >
                                                Spedizione
                                            </Typography>
                                            <Typography >{this.state.order.customer_firstname} {this.state.order.customer_lastname}, {this.state.order.billing_address.street[0]} {this.state.order.billing_address.street[1]}
                                            <br/>
                                            {this.state.order.billing_address.city} {this.state.order.billing_address.postcode} {this.state.order.billing_address.region}</Typography>

                                            <hr />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12}>

                                            <Typography >
                                                Pagamento
                                            </Typography>
                                            { (() => {
                                                switch (this.state.order.payment.method) {
                                                    case 'stripe_payments': return <div>Carta di credito</div>
                                                    case 'paypal_rest': return <div>PayPal</div>
                                                    case 'cashondelivery': return <div>Contrassegno</div>
                                                    default: return '-'
                                                }
                                            })()
                                            }
                                            <hr />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={5}>
                                    <Row top='md'>
                                        <Col md={8}>
                                            <Typography >
                                                Totale prodotti
                                            </Typography>
                                        </Col>
                                        <Col md={4}>
                                            <Typography>
                                                { this.state.order.base_grand_total && priceFormat(this.state.order.base_grand_total) }
                                            </Typography>
                                        </Col>
                                    </Row>

                                    <Row top='md'>
                                        <Col md={8}>
                                            <Typography >
                                                Spedizione
                                            </Typography>
                                        </Col>
                                        <Col md={4}>
                                            <Typography >
                                                { this.state.order.shipping_amount === 0 ? '€ 0,00' : priceFormat(this.state.order.shipping_amount) }
                                            </Typography>
                                        </Col>
                                    </Row>
                                    <Row top='md'>
                                        <Col md={8}>
                                            <Typography>
                                                Coupon {this.state.order.coupon_code? this.state.order.coupon_code : false }
                                            </Typography>
                                        </Col>
                                        <Col md={4}>
                                            <Typography>
                                                { this.state.order.coupon_code? priceFormat(this.state.order.base_discount_amount) : '€ 0,00' }
                                            </Typography>
                                        </Col>
                                    </Row>
                                    <hr />

                                    <Row bottom='md'>
                                        <Col md={8}>
                                            <Typography >
                                                Totale (tasse incluse)
                                            </Typography>
                                        </Col>
                                        <Col md={4}>
                                            <Typography >
                                                { this.state.order.base_grand_total && priceFormat(this.state.order.base_grand_total) }
                                            </Typography>
                                        </Col>
                                        <Col md={12}>
                                            <Typography >
                                                {this.getStatusOrder(this.state.order.status)}
                                            </Typography>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Elevation>

                        { isDesktop ? <Row>
                                <KUButton
                                    style={{'border':'1px solid'}}
                                    clickHandler={() => this.props.handleCloseDetail()}
                                >Indietro</KUButton>
                        </Row> : false }
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateProps = (state) => (
    {
        account: state
    }
)

export default connect(
    mapStateProps,
)(injectSheet(style)(ProfileDetailOrder))