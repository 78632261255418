import { MAGENTO_CURRENT_CUSTOMER, MAGENTO_LOGOUT } from '../actions/types'

const INITIAL_STATE = {
  customer: null,
  orders: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MAGENTO_CURRENT_CUSTOMER:
      return { ...state, customer: action.payload }
    case MAGENTO_LOGOUT:
      return { ...state, customer: null }
    case "CUSTOMER_ORDERS":
      state.orders = action.payload
          return {...state, orders: state.orders}
    default:
      return state
  }
}
