// import variables from '../../assets/style/variables'

export const style = {
  cartProduct: {
    marginBottom: '0.5rem'
  },
  cartProductImage: {
    // backgroundColor: variables.grey
    display: 'block',
    textAlign: 'center'
  },
  productImage: {
    maxWidth: '100px',
    maxHeight: '100px'
  },
  cartProductQt: {
    textAlign: 'right'
  }
}
