import variables from '../../assets/style/variables'

export const style = {
  home: {
    minHeight: '700px',
    marginBottom: 0,

    '& .slick-next, .slick-prev': {
      zIndex: 1
    },
    '& .slick-prev:before, .slick-next:before': {
      color: '#333'
    }
  },

  mdiv1: {
    "width": "16px",
    "height": "16px",
    "float": "left",
    "border": "1px solid #F83636",
    "borderRadius": "50px",
    "margin-top": "3px"
  },
  mdiv: {
    "height": "16px",
    "width": "1px",
    "marginLeft": "8px",
    "backgroundColor": "#F83636",
    "transform": "rotate(45deg)",
    "ZIndex": "1"
  },
  md: {
    "height": "16px",
    "width": "1px",
    "backgroundColor": "#F83636",
    "transform": "rotate(90deg)",
    "ZIndex": "2"
  },

  mdiv1_closex: {
    "width": "20px",
    "float": "right",
    "height": "20px",
    "border": "1px solid #808080",
    "marginTop": "3px",
    "borderRadius": "50px"
  },
  mdiv_closex: {
    "width": "1px",
    "height": "12px",
    "ZIndex": "1",
    "transform": "rotate(\n45deg\n)",
    "marginLeft": "10px",
    "backgroundColor": "#808080",
    "marginTop": "4px"
  },
  md_closex: {
    "width": "1px",
    "height": "12px",
    "ZIndex": "2",
    "transform": "rotate(\n90deg\n)",
    "backgroundColor": "#808080",
    "marginTop": "4px",
    "marginLeft": "0px"
  },

  sconto: {
    "display": "flex", "justify-content": "center", "color":"#F64084", "height":"1.5rem", "padding-top":"4px"
  },

  productThumb: {
    minHeight: '12rem',
    maxHeight: '15rem',
    justifyContent: 'center',
    alignItems: 'center',
    height: '15rem',
    display: 'flex',
    overflow: 'hidden',

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },

  addButton:{
    backgroundColor:'#008837'
  },
  button : {
    background: "#E56E04",
    height: 54,
    borderRadius: 0
  },
  homeSlider: {
    width: '100%',
    marginBottom: 60,
    '& .slick-dots': {
      bottom: 15,
      'padding-right':'37.5%',
      'padding-left':'37.5%',
      'width':'25%'
    },

    '& .slick-next': {
      right: 12,
      zIndex: 1
    },
    '& .slick-prev': {
      left: 12,
      zIndex: 1
    }
  },

  slide: {
    height: '110%',
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    '& span': {
      fontSize: 20,
      textTransform: 'uppercase',
      fontWeight: 100,
      textShadow: '2px 2px 16px #333'
    }
  },

  featuredProduct: {
    '& .slick-slide > div': {
      backgroundColor: '#eee9e9',
      height: 220,
      margin: 10,
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      textAlign: 'center'
    },
    '& .slick-list':{
      height: '421px !important'
    }
  },

  cardBrand:{
    height: '100%',
    width: '100%',
    position: 'relative'
  },

  imgBrand:{
    width:'90%',
    height:'90%',
    position: 'absolute',
    //top: '59px',
    left: '11px'
  },

  divImgBrand:{
    'height': '100%',
    'width':'80%'
  },

  featuredBrand: {
    '& .slick-slide > div': {
      backgroundColor: '#fff',
      height: 220,
      margin: 10,
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      textAlign: 'center'
    },
    '& .slick-list':{
      height: '239px !important'
    }
  },

  homeCategories: {
    marginTop: 40,

    '& > div > div': {
      marginBottom: 30
    },
    '& > div > div > a > div': {
      height: 50,
      fontWeight: 510,
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      marginTop: 190,
      color: 'rgb(69, 194, 203)',
      background: 'rgba(255,255,255,0.7)',
      paddingTop: 10,

    },

    ' & a':{
      'height':'24% !important'
    },

    '& a:hover': {
      'transform':'translate(0,-191px)',
      'height':'101% !important',
      backgroundColor:'white',
      opacity:'0.8'
    }
  },

   'div.slick-list': {
  'height': '422px !important'
  },

  imageSlider:{
      width: '100%'
  },


  '@media (max-width: 600px)': {
    imageSlider:{
        width: '102vw',
        height: '144px'
    },
    homeSlider:{
      width: '102vw',
      height: '144px',
      marginBottom: 25
    },
    homeCategories:{
      ' & a':{
        'height':'24% !important'
      },

      '& a:hover': {
        'transform':'translate(0,-191px)',
        'height':'101% !important',
        backgroundColor:'white',
        opacity:'0.8'
      }
    }
  }
}
