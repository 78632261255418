import variables from '../../assets/style/variables'

export const style = {
  profilePassword: {
    '& .mdc-text-field__input': {
      width: '100%',
      paddingTop: '30px !important'
    }
  },

  profilePasswordTitle: {
    marginTop: 10,
    marginBottom: 30
  },

  profilePasswordFooter: {
    marginTop: 30,
    marginBottom: 30
  },
  submit: {
    marginTop: '50px',
    marginBottom: '10px',
    fontSize: '13px',
    color: variables.black + ' !important',
    backgroundColor: variables.white + ' !important',
    border: '2px solid ' + variables.black
  },
  '@media (max-width: 600px)' :{
    profilePassword:{
      'margin-top':'5%',
      'margin-bottom':'5%'
    },
    btn: {
      'margin-top':'10%'
    }
  }

}
