// IE11 Compatibility
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react'
import { render } from 'react-dom'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
// import { BrowserRouter as Router } from 'react-router-dom'
import { Router } from 'react-router-dom'
import { onAppStart } from './lib/helper/app'
import reducer from './reducers'
import history from './lib/helper/history'
import App from './containers/App'
import * as serviceWorker from './serviceWorker'
import './assets/style/index.css'
import ReactPixel from 'react-facebook-pixel';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactGA from 'react-ga';

const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
};
ReactPixel.init('1422305901297387', options);
ReactPixel.track('PageView');

ReactGA.initialize('UA-154598205-1');
ReactGA.plugin.require('ecommerce');
ReactGA.pageview(window.location.pathname + window.location.search);

serviceWorker.unregister() // unregister() to register() to work offline and load faster

const middleware = [ thunk ]
if (process.env.NODE_ENV !== 'production') {
  middleware.push(
    createLogger({
      collapsed: true
    })
  )
}

const store = createStore(
  reducer, composeWithDevTools(
    applyMiddleware(...middleware)
  )
)

onAppStart(store)

Sentry.init({
    dsn: "https://f94dd62d636c486984a044fd851de68c@o190282.ingest.sentry.io/5505971",
    integrations: [
        new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.5,
});

render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
)
