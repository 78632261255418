import variables from '../../assets/style/variables'

export const style = {

  navBarMini: {
    backgroundColor: variables.seawater,
    //backgroundColor: "#C61F43",
    color: variables.white,
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: '14px',
    '& a': {
      textDecoration: 'none',
      color: variables.white
    }
  },
  navBar: {
    backgroundColor: variables.white,
    color: variables.black,
    fontSize: 10,
    padding: 10,
    verticalAlign: 'middle',
    '& a': {
      textDecoration: 'none'
    }
  },
  navBarMobile: {
    backgroundColor: variables.white,
    color: variables.black,
    fontSize: 10,
    padding: 10,
    verticalAlign: 'middle',
    '& a': {
      textDecoration: 'none'
    }
  },
  miniCart: {
    cursor: 'pointer',
    display: 'flex',
    border: '2px solid #2d2d2d',
    justifyContent: 'space-around',
    position: 'relative',
    fontSize: 16,
    height: 32,
    verticalAlign: 'middle',
    paddingTop: 7
  },
  miniCartText: {
    marginTop: 2
  },
  loginContainer: {
    width: '25vw',
    // overflow: 'hidden'
  },
  miniLogin: {
    cursor: 'pointer'
  },
  freeShipping: {
    textAlign: 'right',
    '& a': {
      fontWeight: 'bold',
      color: variables.white
    },
    '& i': {
      fontSize: '16px',
      marginRight: 4,
    }
  }
}
