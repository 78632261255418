import {ADMIN_TYPE, GUEST_TYPE} from '../../types'

export default magento => {
  return {
    createGuestCart: () => {
      return new Promise((resolve, reject) => {
        const path = '/V1/guest-carts'

        magento
          .post(path, undefined, GUEST_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    initGuestCart: async () => {
      const localStorageCartId = window.localStorage.getItem('mage_guest_cartId')

      let cartId
      if (localStorageCartId) {
        // cartId = await magento.guest.getGuestCart(localStorageCartId)
        cartId = localStorageCartId
      } else {
        cartId = await magento.getCart()

        window.localStorage.setItem('mage_guest_cartId', cartId)
      }
      const cart = await magento.guest.getGuestCart(cartId)
      return { quote: cart.quote, cartId: cartId, items: cart.items ? cart.items : [] }
    },

    getGuestCart: cartId => {
      // GET /V1/guest-carts/{cartId}
      return new Promise((resolve, reject) => {
        const path = `/V1/guest-carts/${cartId}`

        magento
          .get(path, undefined, undefined, GUEST_TYPE)
          .then(data => {
            resolve({
              cartId: cartId,
              items: data.items || [],
              quote: data
            })
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    addItemToCart: (cartId, item) => {
      // POST /V1/guest-carts/{cartId}/items
      return new Promise((resolve, reject) => {
        const path = `/V1/guest-carts/${cartId}/items`

        magento
          .post(path, item, GUEST_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    /* 🍁code */
    removeItemFromCart: (cartId, itemId) => {
      // DELETE /V1/carts/mine/items
      return new Promise((resolve, reject) => {
        const path = `/V1/guest-carts/${cartId}/items/${itemId}`

        magento
          .delete(path, undefined, GUEST_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    changeCartItemQty: (cartId, item, operation) => {
      // PUT /V1/guest-carts/${cartId}/items/${item.item_id}

      return new Promise((resolve, reject) => {
        const path = `/V1/guest-carts/${cartId}/items/${item.item_id}`

        let itemData = {
          'cartItem': {
            item_id: item.item_id,
            qty: operation === 'inc' ? item.qty + 1 : item.qty - 1,
            quote_id: cartId
          }
        }

        magento
          .put(path, itemData, GUEST_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    addGuestCartBillingAddress: (cartId, address) => {
      // POST /V1/guest-carts/{cartId}/billing-address
      return new Promise((resolve, reject) => {
        const path = `/V1/guest-carts/${cartId}/billing-address`

        magento
          .post(path, address, GUEST_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    guestCartEstimateShippingMethods: (cartId, address) => {
      // POST /V1/guest-carts/:cartId/estimate-shipping-methods"
      return new Promise((resolve, reject) => {
        const path = `/V1/guest-carts/${cartId}/estimate-shipping-methods`

        magento
          .post(path, address, GUEST_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    addGuestCartShippingInfo: (cartId, address) => {
      // POST /V1/guest-carts/{cartId}/shipping-information
      return new Promise((resolve, reject) => {
        const path = `/V1/guest-carts/${cartId}/shipping-information`

        magento
          .post(path, address, GUEST_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    getGuestCartPaymentInfo: cartId => {
      // GET /V1/guest-carts/{cartId}/payment-information
      return new Promise((resolve, reject) => {
        const path = `/V1/guest-carts/${cartId}/payment-information`

        magento
          .get(path, undefined, undefined, GUEST_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    getGuestCartPaymentMethods: cartId => {
      // GET /V1/guest-carts/{cartId}/payment-methods
      return new Promise((resolve, reject) => {
        const path = `/V1/guest-carts/${cartId}/payment-methods`

        magento
          .get(path, undefined, undefined, GUEST_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    getGuestCartShippingMethods: (
      cartId,
      address = {
        'address': {
          'city': '',
          'country_id': 'IT',
          'postcode': ''
        }
      }
    ) => {
      // GET /V1/guest-carts/{cartId}/estimate-shipping-methods
      return new Promise((resolve, reject) => {
        const path = `/V1/guest-carts/${cartId}/estimate-shipping-methods`

        magento
          .post(path, address, GUEST_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    placeGuestCartOrder: (cartId, payment) => {
      // PUT /V1/guest-carts/{cartId}/order
      return new Promise((resolve, reject) => {
        const path = `/V1/guest-carts/${cartId}/order`

        magento
          .put(path, payment, GUEST_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    getCountries: () => {
      // GET /V1/directory/countries
      return new Promise((resolve, reject) => {
        const path = '/V1/directory/countries'

        magento
          .get(path, undefined, undefined, GUEST_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    getCountriesByCountryId: countryId => {
      // GET /V1/directory/countries/:countryId
      return new Promise((resolve, reject) => {
        const path = `/V1/directory/countries/${countryId}`

        magento
          .get(path, undefined, undefined, GUEST_TYPE)
          .then(data => {
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    createCustomer: customer => {
      // POST /V1/customers
      console.log(customer)
      return new Promise((resolve, reject) => {
        const path = '/V1/customers'

        magento
          .post(path, customer, GUEST_TYPE)
          .then(data => {
            console.log(data)
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },

    resetPassword: (email) => {
      return new Promise((resolve, reject) => {
        const path = `/V1/customers/password`
        let resetdata = {
          "email": email? email : false,
          "template": "email_reset",
          "websiteId": 2
        }
          magento
              .put(path,resetdata, ADMIN_TYPE)
              .then(token => {
                resolve(token)
              })
              .catch(e => {
                console.log(e)
                reject(e)
              })
      })
    },

    auth: (username, password) => {
      return new Promise((resolve, reject) => {
        if (username) {
          const path = '/V1/integration/customer/token'

          magento
            .post(path, { username, password }, GUEST_TYPE)
            .then(token => {
              console.log('token')
              magento.setCustomerToken(token)

              resolve(token)
            })
            .catch(e => {
              console.log(e)
              reject(e)
            })
        } else {
          reject('Email is required!')
        }
      })
    },

    putCoupon: (coupon, cartId) => {
      return new Promise((resolve, reject) => {
        let cart = localStorage.getItem('customerToken') == '' || localStorage.getItem('customerToken') == null? 'guest-carts' : 'carts'
        const path = `/V1/${cart}/${cartId}/coupons/${coupon}`
          magento
              .put(path, GUEST_TYPE)
              .then(token => {
                resolve(token)
              })
              .catch(e => {
                console.log(e)
                reject(e)
              })
      })
    },

    deleteCoupon: (coupon, cartId) => {
      return new Promise((resolve, reject) => {
        let cart = localStorage.getItem('customerToken') == '' || localStorage.getItem('customerToken') == null? 'guest-carts' : 'carts'
        const path = `/V1/${cart}/${cartId}/coupons/${coupon}`
        magento
            .put(path, GUEST_TYPE)
            .then(token => {
              resolve(token)
            })
            .catch(e => {
              console.log(e)
              reject(e)
            })
      })
    },


    initiatePasswordReset: email => {
      // PUT /V1/customers/password
      const data = {
        email,
        template: 'email_reset',
        websiteId: magento.configuration.websiteId
      }

      return new Promise((resolve, reject) => {
        const path = '/V1/customers/password'

        magento
          .put(path, data, GUEST_TYPE)
          .then(response => {
            resolve(response)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    }
  }
}
