import React from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { Dialog, DialogContent } from '@rmwc/dialog'
import SignUp from '../../components/SignUp'
import { showSignIn } from '../../actions'
import { style } from './style'

class Modal extends React.Component {
  render () {
    const { classes } = this.props
    if (this.props.showModalSignIn) {
      return (
            <SignUp />
          )
    }

    return null
  }
}

const mapStateToProps = function (state) {
  return {
    ...state.ui
  }
}

export default connect(mapStateToProps, { showSignIn })(injectSheet(style)(Modal))
