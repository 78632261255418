export function priceFormat (price, dir = 'left') {
  if (!price) { return 0.00 }
  let priceFormatted = parseFloat(price).toFixed(2).replace('.', ',')
  return dir === 'left' ? `€ ${priceFormatted}` : `${priceFormatted}€`
}

export function cartShippingFormat (checkout) {
  return checkout && checkout.shipping
    ? priceFormat(this.props.checkout.shipping[0].amount)
    : '-'
}
