import React from 'react'
import {Grid} from "@material-ui/core";
import {Row} from "react-flexbox-grid";
import Typography from "@material-ui/core/Typography";


class MetodiDiPagamento extends React.Component {

    constructor(){
        super()
    }

    render() {
        return(
            <Grid class={'container-fluid'} style={{'padding-left':'16px', 'padding-right':'16px', 'margin-left':'auto','margin-right':'auto','width':'80%', 'margin-top':'2%','margin-bottom':'2%'}}>
                <Row>
                    <Typography use="headline5" style={{'font-size':'1.875rem'}}>Metodi di pagamento</Typography>
                </Row>
                <br/>
                <Row>
                    <text>
                        Per Pharmamed Srl la sicurezza su Farmakon.it è un valore prioritario, utilizziamo come metodo di pagamento privilegiato PayPal, il primo gestore mondiale di pagamenti online, che dà il massimo della sicurezza e la massima protezione dei dati inseriti potete quindi stare tranquilli: i vostri dati sensibili sono in ottime mani.</text>
                        <br/>
                        <text>Sul nostro shop accettiamo i seguenti metodi di pagamento:
                        <br/><br/>
                            <text style={{'font-weight':'bold'}}>PAYPAL - CARTA DI CREDITO o CONTO PAYPAL</text>: <br/>Al momento del pagamento, se avete selezionato PayPal, sarete reindirizzati sul sito di PayPal, dove potrete effettuare comodamente il pagamento. PayPal riporterà i dettagli del pagamento e l'indirizzo di spedizione, come specificato al momento del perfezionamento dell'ordine.
                        Su Paypal è possibile pagare anche con le seguenti carte di credito:
                            <text style={{'font-weight':'bold'}}>Visa, MasterCard, Carta prepagata PayPal, PostePay, Discover, American Expresse Carta Aura</text> e non è obbligatorio essere registrati su PayPal potete anche decidere di inserire la vostra carta di credito esclusivamente per un singolo ordine.
                        <br/><br/>
                            <text style={{'font-weight':'bold'}}>STRIPE:</text><br/>
                            <text style={{'font-weight':'bold'}}>Visa, MasterCard, Carta prepagata PayPal, PostePay, Discover, American Expresse Carta Aura</text> è possibile utilizzare anche Stripe per i pagamenti con carta di credito.
                        E' un istituto finanziario accreditato e regolamentato secondo le più strigenti normative finanziarie.<br/>
                        A differenza di PayPal permette di semplificare la procedura di pagamento così da risultare più semplice per l'utente, garantendo comunque il massimo grado di sicurezza all'utente.
                        <br/><br/>
                            <text style={{'font-weight':'bold'}}>Intestatario</text>: Pharhamed Srl <br/>
                            <text style={{'font-weight':'bold'}}>Banca</text>: INTESA SANPAOLO S.P.A. <br/>
                            <text style={{'font-weight':'bold'}}>Iban</text>: IT46Q0306913808100000003674 <br/>
                            <text style={{'font-weight':'bold'}}>BIC SWIFT</text>: BCITITMMXXX <br/>
                        <br/>
                        <br/><br/>
                            <text style={{'font-weight':'bold'}}>CONTRASSEGNO:</text><br/>
                        Il contrassegno ha un costo aggiuntivo in fase di ordine di 4,00€. Il pagamento avverrà all'atto della ricezione della merce. Raccomandiamo di preparare l'importo esatto indicato nella e-mail di avviso della spedizione in quanto il corriere non può dare resto. Se non sarete voi a ricevere la merce ricordate di dare istruzioni ad un vostro incaricato e di lasciargli l'importo necessario.
                    </text>
                </Row>
            </Grid>
        )
    }

}

export default MetodiDiPagamento