import variables from '../../assets/style/variables'

export const style = {
  resultsForText: {
    fontWeight: 'bold',
    //marginBottom: 10
    float: 'left'
  },

  category: {
    marginTop: 20,
    'padding':0,
    'margin-left':'auto',
    'margin-right':'auto',
    'width':'80%',
    'max-width':'100%',
    'box-sizing':'border-box'
  },

  products: {
    marginBottom: '6rem'
  },

  sidebar: {
    color: '#737373',

    '& .input-range__slider': {
      background: variables.black,
      border: '1px solid ' + variables.black
    },

    '& .input-range__track--active': {
      background: variables.black
    }
  },

  filterTitle: {
    paddingBottom: '5px',
    borderBottom: '1px solid #dedede'
  },

  filterInput: {
    height: '20px',
    padding: '20px 10px',
    width: '100%',
    borderColor: 'rgb(166, 166, 166)',
    '-webkit-box-sizing': 'border-box',
    '-moz-box-sizing': 'border-box',
    boxSizing: 'border-box'
  },

  filterSelect: {
    width: '100%',
    height: '30px',
    backgroundColor: variables.white,
    borderBottomLeftRadius: '0px !important',
    borderBottomRightRadius: '0px !important',
    borderTopLeftRadius: '0px !important',
    borderTopRightRadius: '0px !important'
  },
  '@media(max-width: 600px)': {
    '& .mdc-layout-grid__inner': {
      display: 'inline-grid !important',
      'grid-gap': '105px !important'
    },
    category:{
      'width':'100%',
      'margin-left':'auto !important',
      'margin-right':'auto !important',
      'padding':'0'
    },
    resultsForText: {
      fontWeight: 'bold',
      //marginBottom: 10
      float: 'left'
    }
  }

}
