import React, { Component } from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import { routes } from '../../config/settings'
import { Icon } from '@rmwc/icon'
import { Link } from 'react-router-dom'
import { Grid } from 'react-flexbox-grid'
import { getCategoryTree, setCurrentCategory } from '../../actions'
import { ReactMegaMenu } from 'react-mega-menu'
import { randomId } from 'rmwc'
import variables from '../../assets/style/variables'
import { style } from './style'
import {magento} from "../../lib/magento";
import IosArrowDown from 'react-ionicons/lib/IosArrowDown'

class MegaMenu extends Component {
  constructor () {
    super()
    this.state = {
      showMenu: false
    }
    localStorage.setItem("currentPage", 1)
  }

  componentWillMount () {
    this.props.getCategoryTree()
  }

  toggleHoverState (menuId) {
    return {
      showMenu: menuId
    }
  }

  render () {
    var { classes, categoryTree } = this.props


    if((categoryTree && !JSON.parse(localStorage.getItem("categoryTree")))){
      localStorage.setItem("categoryTree", JSON.stringify(categoryTree));
    }
    if(categoryTree){
      localStorage.setItem("categoryTree", JSON.stringify(categoryTree));
    }
    categoryTree = JSON.parse(localStorage.getItem("categoryTree"))

    return (
      <div id='menu_desktop' className={classes.megaMenuContainer}>
        <Grid className={classes.megaMenu} onMouseLeave={() => this.setState({ showMenu: false })}>
          {categoryTree && categoryTree.children_data.map((menu) => {
            return <React.Fragment> {menu.is_active? <div key={menu.id} onMouseOver={() => this.setState({ showMenu: menu.id })}>
              <div className={classes.megaMenuItem}>

                <a
                  href={`${routes.categories.path}/${menu.id}/${encodeURIComponent(menu.name.replace(/\s/g, '-').toLowerCase())}`}
                  onClick={() => {
                    this.setState({ showMenu: null })
                    localStorage.setItem("perPage", 16)
                  }}
                >
                  <h4>
                    {menu.name}
                  </h4>
                </a>

                { menu.children_data && menu.children_data.length > 0 &&
                <IosArrowDown className={classes.icon} style={{"fill":"white"}} />
                }
              </div>
              { menu.children_data && menu.children_data.length > 0 && this.state.showMenu === menu.id &&
              <div key={randomId} className='subMenu'>

                <ReactMegaMenu
                  styleConfig={{
                    containerProps: {
                      style: {
                      }
                    },
                    menuProps: {
                      style: {
                        padding: '0px',
                        color: 'grey',
                        margin: '0 30px 10px 20px'
                      }
                    },
                    contentProps: {
                      style: {
                        width: '15em',
                        borderLeft: `1px solid ${variables.lightGrey}`,
                        padding: '0 0 0 10px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        marginLeft: 0
                      }
                    },
                    menuItemProps: {
                      style: {
                        margin: '6px 0',
                        height: '2em',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        cursor: 'pointer'
                      }
                    },
                    menuItemSelectedProps: {
                      style: {
                        /* border: "2px solid purple",
                            padding: "2px",
                            height: "2em",
                            backgroundColor: "grey" */

                        margin: '6px 0',
                        height: '2em',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        color: variables.seawater
                      }
                    }
                  }}
                  data={menu.children_data.map(item => {
                    // console.log(item)
                    return {
                      label: <a
                        href={`${routes.categories.path}/${item.id}/${encodeURIComponent(item.name.replace(/\s/g, '-').toLowerCase())}`}
                        onClick={() => {
                          this.setState({ showMenu: null })
                          localStorage.setItem("perPage", 16)
                        }}
                      >
                        {item.name}
                      </a>,
                      key: item.id,
                      items: item.children_data.length > 0 ? <div id="mySidenav" class="sidenav">
                        <a href="javascript:void(0)" className="closebtn" onClick="closeNav()">&times;</a>
                        <ul className={classes.subMenuUl}>
                          {item.children_data.map((subItem, idx) => <li key={idx}>
                            <a
                              href={`${routes.categories.path}/${subItem.id}/${encodeURIComponent(subItem.name.replace(/\s/g, '-').toLowerCase())}`}
                              onClick={() => {
                                this.setState({ showMenu: null })
                                localStorage.setItem("perPage", 16)
                              }}
                            >
                              {subItem.name}
                            </a>
                          </li>)}
                        </ul>
                      </div> : ''
                    }
                  })}
                />

              </div>

              }
            </div> : false}
            </React.Fragment>  }
          )}

        </Grid>
      </div>
    )
  }
}

const mapStateToProps = ({ magento, categoryTree }) => {
  return { magento, categoryTree }
}

export default connect(
  mapStateToProps,
  { getCategoryTree, setCurrentCategory }
)(
  injectSheet(style)(MegaMenu)
)
