import variables from '../../assets/style/variables'

export const style = {
  product: {
    marginTop: 42,
    marginBottom: 42,
    minHeight: 400,
    'padding':0,
    'margin-left':'auto',
    'margin-right':'auto',
    'width':'80%',
    'max-width':'100%',
    'box-sizing':'border-box'
  },

  headerImage:{
    'width': '100%'
  },

  mdiv1: {
    "width": "16px",
    "height": "16px",
    "float": "left",
    "border": "1px solid #F83636",
    "borderRadius": "50px",
    "margin-top": "3px"
  },
  mdiv: {
    "height": "16px",
    "width": "1px",
    "marginLeft": "8px",
    "backgroundColor": "#F83636",
    "transform": "rotate(45deg)",
    "ZIndex": "1"
  },
  md: {
    "height": "16px",
    "width": "1px",
    "backgroundColor": "#F83636",
    "transform": "rotate(90deg)",
    "ZIndex": "2"
  },

  productImageContainer: {
    "height": "20rem",
    "display": "flex",
    "minHeight": "12rem",
    "maxHeight": "20rem",
    "alignItems": "center",
    "justifyContent": "center"
  },
  productImage: {
    maxWidth: '100%',
    maxHeight: '100%'
  },

  price: {
    fontSize: '20px !important'
  },

  productName: {
    fontWeight: 'bold'
  },

  hrBox: {
    'transform':'rotate(90deg)',
    '-ms-transform':'rotate(90deg)',
    /* IE 9 */
    '-webkit-transform':'rotate(90deg)',
    'height':'1px',
    'width':'100%'
  },

  boxIcon: {
    'width':'80px',
    'height':'80px',
    backgroundColor:'#111B34'
  },

  icon:{
    background:'#111B34',
    color:'white',
    fontSize:'40px',
    margin:'20px'
  },

  boxDescrizione: {
    fontWeight: 'bold',
    paddingBottom:'0.5%',
  },

  boxDescrizioneSecondario: {
    'margin-top':'-2% !important'
  },

  boxRight: {
    'margin-top':'-5% !important'
  },

  productAvailable: {
    fontSize: 13,
    '& i': {
      fontSize: 11,
      marginRight: 5
    }
  },

  productMedical: {
      paddingTop: '0.5%',
      paddingBottom: '0.5%'
  },

  productDescriptionTitle: {
    borderBottom: '1px solid ' + variables.black,
    fontSize: '18px',
    marginTop: '16px'
  },

  sconto: {
    "color":"#F64084"
  },

  rowsicurezza :{
    'padding-top':'15px',
    'padding-bottom':'10px'
  },

  boxsicurezza : {
    backgroundColor: "#F4F8F5",
    'padding':'5px',
    'padding-top':'10px'
  },
  sicurezzaicone:{
    display: "flex",
    "align-items":"center",
    'justify-content':'center'
  },
  descrizionesicurezza:{
    "font-size":"14px",
    display: 'flex',
    'align-items': 'center'
  },
  cartHeaderItem:{
    'padding-bottom':'5px'
  }


}
