import React, { Component } from 'react'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import { Grid, Col, Row } from 'react-flexbox-grid'
import { TextField } from '@rmwc/textfield'
import { Typography } from '@rmwc/typography'
import { changePassword } from '../../actions'
import KUButton from '../KUButton'
import { style } from './style'
import {PWD_REGEX_VALIDATION, routes} from '../../config/settings'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'

class ProfilePassword extends Component {
  constructor () {
    super()
    this.state = {
      newPassword: '',
      newPasswordConfirmation: '',
      currentPassword: '',
        open:false,
        isDesktop: false
    }
      this.updatePredicate = this.updatePredicate.bind(this);
  }

    updatePredicate() {
        this.setState({ isDesktop: window.innerWidth > 620 });
    }
    componentWillMount () {
        window.removeEventListener("resize", this.updatePredicate);
    }
    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

  changePasswordHandler () {
    if (this.state.currentPassword && (this.state.newPassword === this.state.newPasswordConfirmation)) {
      this.props.changePassword(this.state.currentPassword, this.state.newPassword)
    }
    this.setState({open: true})
  }

  render () {
    const { classes } = this.props
    let isDesktop = this.state.isDesktop
    return (
      <Grid className={classes.profilePassword}>
          {isDesktop ?
              <Row className={classes.profileDataTitle}
                style={{'margin-top':'10px'}}
              >
                  <Col xs={12}>
                      <Typography use='headline5'>Cambia la tua password</Typography>
                  </Col>
              </Row>
              : <Row className={classes.profileAddressesTitle}>
                  <Col xs={1}>
                      <a href={routes.personal.path}><KeyboardBackspace
                          style={{'color': 'black', 'margin-top': '19%', 'margin-left': '-100%'}}/></a>
                  </Col>
                  <Col xs={11}><Typography use='headline7'>Cambia la tua password</Typography></Col>
              </Row>
          }
        <Row>
          <Col xs={8}>
            <TextField type='password' fullwidth label='Password attuale'
              value={this.state.currentPassword}
              onChange={event => this.setState({ currentPassword: event.target.value })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <TextField type='password' fullwidth label='Nuova password'
              value={this.state.newPassword}
              onChange={event => this.setState({ newPassword: event.target.value })} />
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <TextField type='password' fullwidth label='Conferma password'
              value={this.state.newPasswordConfirmation}
              onChange={event => this.setState({ newPasswordConfirmation: event.target.value })} />
          </Col>
        </Row>
        <Row className={classes.profilePasswordFooter}>
          <Col xs={8}>
            <KUButton unelevated blue
                      className={classes.btn}
              disabled={(
                this.state.newPasswordConfirmation === '' ||
                this.state.currentPassword === '') ||
                this.state.newPassword !== this.state.newPasswordConfirmation ||
                !this.state.newPassword.match(PWD_REGEX_VALIDATION)
              }
              clickHandler={() => this.changePasswordHandler()}
              loading={this.props.loading}
            >
              MODIFICA PASSWORD
            </KUButton>
          </Col>
            <Dialog open={this.state.open}>
                <div style={{'width':'450px', 'height':'150px', 'textAlign':'center'}}>
                    <br/>
                    <p style={{'font-size':'18px'}}>Password cambiata con successo</p>
                    <br/>
                <KUButton style={{backgroundColor:'#00a637', color:'#fff'}}>
                    <a style={{color:'#fff'}} href={'/'}>Ottimo!</a>
                </KUButton></div>
            </Dialog>
        </Row>
      </Grid>
    )
  }
}

const mapStateProps = (state) => (
  {
    loading: state.ui.reset_loading
  }
)

export default connect(
  mapStateProps,
  {
    changePassword
  }
)(injectSheet(style)(ProfilePassword))
