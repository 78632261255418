const variables = {
  black: '#434343',
  white: '#fff',
  whiteTone: '#f5f5f5',
  grey: '#999',
  blue: '#111B34',
  lightGrey: '#dedede',
  green: '#16782A',
  seawater: '#4AB2B2',
  orange: '#C74D06'
}

export default variables
