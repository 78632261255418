import React from 'react'
import {connect} from 'react-redux'
import injectSheet from 'react-jss'
import {Typography} from '@rmwc/typography'
import {ButtonIcon} from '@rmwc/button'
import {Icon} from '@rmwc/icon'
import {addToCart, removeFromCart, changeCartItemQty, getGuestCartShippingMethods} from '../../actions'
import {priceFormat} from '../../lib/format'
import {FREE_SHIPPING_MIN_ORDER, routes} from '../../config/settings'
import {Elevation} from 'rmwc'
import KUButton from '../KUButton'
import {style} from './style'
import TextField from "@material-ui/core/es/TextField/TextField";
import Input from '@material-ui/core/Input';
import {productThumb} from "../../lib/product";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import ReactGA from 'react-ga';
import HighlightOffOutlined from '@material-ui/icons/HighlightOffOutlined'
import {Grid, Col, Row} from 'react-flexbox-grid'

ReactGA.plugin.require('ecommerce');

class AddCart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            qtToAdd: 1,
            open: false
        }
    }

    async initializeTagManager() {
        ReactPixel.track('AddToCart', {
            content_type: 'product',
            currency: 'EUR',
            contents: [
                {
                    id: this.props.product.sku,
                    quantity: this.state.qtToAdd
                }
            ]
        });
        ReactGA.plugin.execute('ecommerce', 'addItem',
            {
                id: this.props.product.sku,
                name: this.props.product.name,
                sku: this.props.product.sku,
                price: this.props.product.price,
                quantity: this.state.qtToAdd,
            }
        );
        ReactGA.plugin.execute('ecommerce', 'send');
        const tagManagerArgs = {
          gtmId: 'GTM-NF73NS7',
          dataLayer: {
            event: 'eec.add',
            ecommerce: {
              add: {
                actionField: {
                  list: 'Shopping cart'
                },
                products: [{
                  id: this.props.product.sku,
                  quantity: this.state.qtToAdd
                }]
              }
            }
          }
        }
        console.log(tagManagerArgs)
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'eec.add',
          ecommerce: {
            add: {
              actionField: {
                list: 'Shopping cart'
              },
              products: [{
                id: this.props.product.sku,
                quantity: this.state.qtToAdd
              }]
            }
          }
        })
    }

    handleCartClick() {
        const {cart, customer} = this.props
        this.props.addToCart({
            cartId: cart.cartId,
            item: {
                cartItem: {
                    sku: this.props.product.sku,
                    qty: this.state.qtToAdd,
                    quoteId: cart.cartId
                    // ...productOptions
                }
            },
            customer
        })
        this.initializeTagManager()
        setTimeout(() => (
            this.state.open = true
        ), 500)
    }

    handleChangeQtyForItem(product, operation) {
        this.props.changeCartItemQty({cart: this.props.cart, item: product, operation: operation})
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    };

    render() {
        const {classes} = this.props
        let _discountPrice = this.props.product.custom_attributes
            ? this.props.product.custom_attributes.find(f => f.attribute_code === 'special_price')
                ? this.props.product.custom_attributes.find(f => f.attribute_code === 'special_price').value
                : false
            : false

        let sconto = _discountPrice ? (((this.props.product.price - _discountPrice) / this.props.product.price)*100) : false
        sconto = (sconto != false && sconto > 0)? sconto.toFixed() : false

        return (
            <div>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    style={{'text-align': 'center'}}
                    fullWidth={false}
                >
                    <div style={{'padding-right': '10px', "padding-top":"10px", "cursor":"pointer"}} onClick={this.handleClose}>
                        <div>
                            <div className={classes.mdiv1_closex}>
                                <div className={classes.mdiv_closex}>
                                    <div className={classes.md_closex}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DialogTitle style={{"width": "450px", "padding":"0px 24px 24px"}}>Prodotto aggiunto al
                        carrello</DialogTitle>
                    <DialogContent style={{'width': '450px'}}>
                        <div className={classes.immagineAggiungiAlCarrello}
                             onClick={() => this.handleProductClick(this.props.product)}
                        >
                            <img src={this.props.product.custom_attributes ?
                                (this.props.product.custom_attributes.find(e => e.attribute_code === 'image') ?
                                    productThumb(this.props.product) : "../../../images/no-img.png") :
                                productThumb(this.props.product)}
                                 style={{'max-width': '100%', 'max-height': '100%'}}
                            />
                        </div>
                        <Typography use='body3'
                                    style={{'height': '15%', 'text-align': 'center'}}
                        >
                            {this.props.product.name}
                        </Typography><br/>
                        <Typography use='body3' className={classes.productPrice}>
                            {_discountPrice
                                ? (_discountPrice >= this.props.product.price) ?
                                    <span>{priceFormat(this.props.product.price)}</span> : <div>
                                        <del style={{
                                            'font-size': '13px',
                                            'margin-right': '5px'
                                        }}>{priceFormat(this.props.product.price)}</del>
                                        <span className={classes.bold}
                                              style={{'color': '#96bf31'}}>{priceFormat(_discountPrice)}</span>
                                    </div>
                                : <span>{priceFormat(this.props.product.price)}</span>
                            }
                        </Typography>
                        <br/>
                        <div style={{'display': 'flex', 'justify-content': 'center'}}>
                            <a href={routes.cart.path} style={{'color': 'white', "width":"100%"}}>
                                <Button
                                    style={{
                                        backgroundColor: '#008837',
                                        'height': '44px',
                                        'width': '90%',
                                        'color': 'white'
                                    }}
                                >
                                    Vai al carrello
                                </Button>
                            </a>
                        </div>
                        <div style={{"justify-content":"center", "margin-top":"10px"}}>
                        oppure
                        <br/>
                            <Typography
                                onClick={this.handleClose}
                                className={classes.addButton}
                                style={{
                                    'color': 'rgb(0, 136, 55)',
                                    "text-decoration":"underline",
                                    "font-weight":"bold",
                                    "cursor":"pointer"
                                }}
                            >Continua gli acquisti
                            </Typography>
                        </div>
                    </DialogContent>
                </Dialog>
                <Elevation z={3} className={`${classes.cart} ${classes.cartContainer}`} style={{textAlign: 'left'}}>

                    <div className={classes.cartAction}>
                        <Typography use='body1' className={classes.cartActionQuantity}>
                            <Icon icon='remove' iconOptions={{strategy: 'ligature'}}
                                  onClick={() => this.setState({
                                      qtToAdd: this.state.qtToAdd > 1 ? this.state.qtToAdd - 1 : 1
                                  })}
                            />
                            <span>{this.state.qtToAdd}</span>
                            <Icon icon='add' iconOptions={{strategy: 'ligature'}}
                                  onClick={() => this.setState({
                                      qtToAdd: this.state.qtToAdd + 1
                                  })}
                            />
                        </Typography>
                        <Typography use='body1' className={classes.cartActionCost}>
                            {_discountPrice
                                ? (_discountPrice >= this.props.product.price) ?
                                    <span className={classes.price}>{priceFormat(this.props.product.price)}</span> :
                                    <div>
                                        <del style={{'font-size': '100%'}}>{priceFormat(this.props.product.price)}</del>
                                        <span className={classes.price}>{priceFormat(_discountPrice)}</span>
                                    </div>
                                : <span className={classes.price}>{priceFormat(this.props.product.price)}</span>
                            }
                        </Typography>
                    </div>
                    { this.props.product.extension_attributes.stock_item.qty < 900 && this.props.product.extension_attributes.stock_item.qty > 0 && sconto && <Typography className={classes.sconto}>Sconto {sconto}%</Typography>}
                    {this.props.product.extension_attributes.stock_item.is_in_stock && this.props.product.extension_attributes.stock_item.qty > 0 &&
                    <KUButton className={classes.add} unelevated orange
                              clickHandler={() => this.handleCartClick()}
                              loading={this.props.ui.addToCartLoading === this.props.product.sku}
                              disabled={this.props.product.extension_attributes.stock_item.is_in_stock ? false : true}
                    >
                        <ButtonIcon icon='shopping_cart'/> AGGIUNGI AL CARRELLO
                    </KUButton>}
                    {
                        (!this.props.product.extension_attributes.stock_item.is_in_stock || this.props.product.extension_attributes.stock_item.qty <= 0) &&
                        <div style={{'display': 'flex', 'justify-content': 'center'}}>
                            <div style={{"text-align": "center"}}>
                                <div className={classes.mdiv1}>
                                    <div className={classes.mdiv}>
                                        <div className={classes.md}></div>
                                    </div>
                                </div>
                                <div style={{
                                    'margin-bottom': "auto",
                                    "margin-top": "auto",
                                    "font-weight": "bold",
                                    "font-size": "16px",
                                    "text-align": "left",
                                    "padding-left": "25px"
                                }}>Non disponibile
                                </div>
                            </div>
                        </div>
                    }


                    <div>
                        <br/>
                        {this.props.product.extension_attributes.stock_item.is_in_stock ?
                            <div className={classes.cartHeader}>
                                <div className={classes.cartHeaderItem}>
                                    <Icon icon='local_shipping' iconOptions={{strategy: 'ligature'}}/>
                                    <Typography use='body1'>Spedizione gratuita
                                        da {priceFormat(FREE_SHIPPING_MIN_ORDER)}</Typography>
                                </div>

                                <div className={classes.cartHeaderItem}>
                                    <Icon icon='security' iconOptions={{strategy: 'ligature'}}/>
                                    <Typography use='body1'>Solo prodotti certificati e garantiti</Typography>
                                </div>

                                <div className={classes.cartHeaderItem}>
                                    <Icon icon='lock' iconOptions={{strategy: 'ligature'}}/>
                                    <Typography use='body1'>Pagamenti sicuri. Dati criptati</Typography>
                                </div>
                            </div> : <div>Prodotto attualmente non disponibile. Vuoi essere avvisato quando sarà
                                disponibile?'<br/><br/>
                                <Input
                                    placeholder="Inserisci il tuo indirizzo email"
                                    className={classes.input}
                                    inputProps={{
                                        'aria-label': 'Description',
                                    }}
                                    style={{'min-width': '210px'}}
                                />
                                <KUButton style={{
                                    backgroundColor: '#C74D06',
                                    color: 'white',
                                    'margin-left': '20px'
                                }}>Avvisami</KUButton></div>}

                    </div>
                    <div className={classes.cartFooter}>
                        {/* <Icon icon='favorite' iconOptions={{ strategy: 'ligature' }} />
            <Typography use='body2'>Aggiungi il prodotto alla lista dei desideri</Typography> */}
                        { /* @todo: re-activate whishinglist */}
                    </div>
                </Elevation>
                <Typography use='body1'>
                    Bisogno di assistenza? <strong>CHIAMACI 0573 4477444</strong>
                </Typography>
            </div>
        )
    }
}

const mapStateToProps = function (state) {
    return {
        products: state.cart.items,
        checkout: state.checkout,
        quote: state.cart.quote,
        cart: state.cart,
        customer: state.account.customer,
        ui: state.ui
    }
}

export default connect(
    mapStateToProps,
    {addToCart, removeFromCart, changeCartItemQty, getGuestCartShippingMethods}
)(injectSheet(style)(AddCart))
