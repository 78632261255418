// import variables from '../../assets/style/variables'

export const style = {
  addNewAddressDialog: {
    '& .mdc-dialog__container': {
      minWidth: '320px',
      maxWidth: 'inherit'
    },
    '& .mdc-button:not(:first-child)': {
      marginLeft: '22px'
    }
  },
  dialogFooter: {
    marginTop: 20
  }

}
